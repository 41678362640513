<template>
    <div v-if="!isEmptyString">
        <div class="card-header">
            <div class="card-text">
                <template v-if="close">
                    <h4 class="card-title">
                        {{ reportName }}
                        <template v-if="!lock">
                            <i class="material-icons" style="font-size: 0.8rem" v-on:click="openForm">edit</i>
                        </template>
                    </h4>
                </template>
                <template v-else>
                    <form>
                    <span class="form-row">
                        <span class="form-group col-md-4">
                            <input type="text" class="form-control" id="inputCity" v-model="name">
                        </span>
                        <span class="form-group col-md-4">
                            <button class="btn btn-default btn-link" v-on:click.stop.prevent="emitFilterItem">
                                <i class="material-icons" style="font-size: 0.8rem">edit</i>
                            </button>
                        </span>
                    </span>
                        <template v-if="validation.result">
                            <div class="text-danger">{{ validation.message }}</div>
                        </template>
                    </form>
                </template>
            </div>
        </div>
        <ReportMethods ref="reportMethods" :reportType="reportType"/>
        <FilterMethods ref="filterMethods" :reportType="reportType"/>
    </div>
</template>
<script>
    import FilterMethods from "./filter_methods.vue";
    import ReportMethods from "./report_methods.vue";

    export default {
        components: {ReportMethods, FilterMethods},
        props: {
          lock: Boolean,
          reportName: String,
          reportType: String,
          edited: Boolean,
          close: Boolean
        },
        emits: [
            'update:reportName',
            'update:edited',
            'update:close'
        ],
        data: function() {
            return {
                name: '',
                validation: {
                  result: false,
                  message: ""
                },
                errorMessage: {
                  match: "同一の名前が存在しています。",
                  lengthOver: "文字数が制限を超えています。",
                  lengthZero: "1文字以上入力してください。"
                }
            }
        },
        computed: {
            isEmptyString: function() {
                return this.reportName.length === 0
            }
        },
        methods: {
            openForm(){
                this.$emit('update:close', !this.close)
                this.name  = this.reportName
            },
            emitFilterItem: async function() {
                const result = await this._validateNameMatch(this.name)
                if(!result){
                  this.validation.result  = true
                  this.validation.message = this.errorMessage.match
                  return false
                }

                const result2 = this._validateNameLength(this.name)
                if(!result2){
                  this.validation.result  = true
                  return false
                }

                this.$emit('update:reportName', this.name)
                this.$emit('update:edited', true)
                this.openForm()
            },
            _validateNameMatch: async function(name){
              if (this.reportType === "filter") {
                return await this.$refs.filterMethods.validateName(name)
              } else {
                return await this.$refs.reportMethods.validateName(name, this.reportType)
              }
            },
            _validateNameLength(name) {
              if(name.length === 0){
                this.validation.message = this.errorMessage.lengthZero
                return false
              }

              if(name.length >= 256){
                this.validation.message = this.errorMessage.lengthOver
                return false
              }

              this.validation.message = ""
              return true
            }
        }
    }
</script>
<template>
  <UserMethods ref="methods"/>
    <div v-if="!initialized">
      <div class="loader">Loading...</div>
    </div>
    <div class="row" style="width:100%" v-else>
      <div class="col-12">
        <div class="card">
          <div class="card-title">
            <a v-if="permissions.show_add_button" :class="`btn btn-primary`" href="/mypage/users/new">追加</a>
            <div v-if="permissions.show_add_notice" class="text-secondary mb-4">最大10件まで登録できます</div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table id="user_List" class="table table-striped table-hover text-left">
                <thead>
                  <tr>
                    <th>
                      <div class="headerText">ユーザ名</div>
                    </th>
                    <th>
                      <div class="headerText">メールアドレス</div>
                    </th>
                    <th>
                      <div class="headerText">権限</div>
                    </th>
                    <th>
                      <div class="headerText">詳細</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in users" :key="user.id">
                    <td>
                      <div class="tableText">{{ user.username }}</div>
                    </td>
                    <td>
                      <div class="tableText">{{ user.email }}</div>
                    </td>
                    <td>
                      <div class="tableText">{{ roleTypeValue(user.role_type) }}</div>
                    </td>
                    <td style="text-align : center; width : 130px">
                      <a class="text-info" :href="'/mypage/users/'+user.id+'/detail'">
                        <i class="material-icons">description</i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script setup>

import { onMounted, ref } from "vue";
import UserMethods from "../../components/user_methods.vue";
import {roleTypes} from "../../utils/user_constants"

const initialized = ref(false);
const permissions = ref({})
const users = ref([]);
const methods = ref(null);
const roleTypeList = roleTypes;
const userRoleType = ref("");

onMounted(async () => {
  await getUserList();
  initialized.value = true;
})

const getUserList = async () => {
  const result = await methods.value?.getUserList();
  if(!result) {
    return false;
  }
  users.value = result.users;
  permissions.value = result.permissions;
}

const roleTypeValue = (target) => {
  const roleType = roleTypeList.find(item => target === item.value)
  return roleType.name
}

</script>
<style scoped>

.card-title {
  margin-top: 20px;
  margin-left: 20px;
}
.table table-striped table-hover text-left {
  width:100%;
}
td {
  vertical-align: middle;
}

</style>

<template>
  <Alert ref="alert"/>
</template>
<script setup>
import { ref } from "vue";
import Alert from "./alert_new.vue";
import { ApiClient } from "../lib/axios/budget/api_client";
import { downloadCsv, downloadPdf } from "../lib/download_action";

const client = new ApiClient()
const alert = ref(null)

const getBudgets = async (period) => {
  const params = { period: period };
  const res = await client.getBudgets(params);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data
}

const getBudget = async function(id, showAlert=false) {
  const res = await client.getBudget(id);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }

  if(showAlert){
    alert.value.displayModal("成功しました。", 'success');
  }

  return res.data
}

const getPastBudgets = async (range) => {
  const params = { range: range }
  const res = await client.listBudgets(params);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data.budgets
}

const getBudgetDetailEdit = async function(id) {
  const res = await client.getBudgetDetailEdit(id);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data.budget
}

const getBudgetDetail = async function(id, currency, tax) {
  const params = { currency: currency, tax: tax }
  const res = await client.getBudgetDetail(id, params);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data.budget
}

const getBudgetDetailAlert = async function(id) {
  const res = await client.getBudgetDetailAlert(id);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data.alerts
}

const downloadBudgetDetail =  async function(id) {
  const res = await client.downloadBudgetDetail(id)
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  downloadCsv(res.data, "budget-origin")
}

const downloadExpireBudgetDetail =  async function(id, currency, tax) {
  const res = await client.downloadExpireBudgetDetail(id, currency, tax)
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  downloadPdf(res.data, !res.filename? "budget-expire" : res.filename)
}

const lockBudget = async function(id) {
  const res = await client.lockBudget(id);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return true
}

const unlockBudget = async function(id) {
  const res = await client.unlockBudget(id);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return true
}

const deleteBudget = async function(id, showAlert=false){
  const res = await client.deleteBudget(id);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }

  if(showAlert){
    alert.value.displayModal("削除しました。", 'success')
  }

  return true
}

const createBudget =  async function(form) {
  const res = await client.createBudget(form)
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error')
    return false
  }
  return res.data.budget_id
}

const updateBudget = async function(id, name, monthlyBudgets){
  const form = {
    budget_name: name,
    monthly_budgets: monthlyBudgets
  }
  const res = await client.updateBudget(id, form)
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }
  alert.value.displayModal("成功しました", 'success')
  return true
}

const uploadPricingCalculatorCSV = async (id, formData) => {
  const res = await client.uploadPricingCalculatorCSV(id, formData)
  if(!res.successful){
    alert.value.displayModal(res.message, 'error');
    await deleteBudget(id)
    return false
  }

  alert.value.displayModal("成功しました", 'success')
  return true
}

const uploadBudgetEstimate = async (id, estimateParam)  => {
  const res = await client.uploadBudgetEstimate(id, estimateParam)
  if(!res.successful){
    alert.value.displayModal(res.message, 'error');
    await deleteBudget(id)
    return false
  }

  alert.value.displayModal("成功しました", 'success')
  return true
}

const estimateBudget = async (filter, costDataPeriod, startMonth, endMonth) => {
  const params = {
    filter: filter,
    cost_data_period: costDataPeriod,
    budget_start_month: startMonth,
    budget_end_month: endMonth
  };

  const res = await client.estimateBudget(params);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }

  return { cost: res.data.cost, budget: res.data.budget }
}

const getDashboardElementBudgetList = async (params) => {
  const res = await client.getDashboardElementBudgetList(params)
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }

  return res.data.filter_item
}

const getDashboardElementBudgetHistory = async (params) => {
  const res = await client.getDashboardElementBudgetHistory(params)
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }

  return res.data.filter_item
}

defineExpose({
  getBudgets,
  getBudget,
  getPastBudgets,
  getBudgetDetail,
  getBudgetDetailEdit,
  getBudgetDetailAlert,
  downloadBudgetDetail,
  downloadExpireBudgetDetail,
  lockBudget,
  unlockBudget,
  createBudget,
  deleteBudget,
  updateBudget,
  estimateBudget,
  uploadPricingCalculatorCSV,
  uploadBudgetEstimate,
  getDashboardElementBudgetList,
  getDashboardElementBudgetHistory
})
</script>

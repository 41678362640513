<template>
  <div id="signup">
    <h1>お申し込み</h1>
    <section v-if="status === 1">
      <div class="loader">Loading...</div>
    </section>
    <section v-else-if="status === 0">
      <form id="signup-email" name="signup-email" v-on:submit.prevent="submitForm">
        <div>
          <div class="signup-title">
            <h2>お申し込み</h2>
          </div>
          <div>
            サービスのお申し込み方法は、以下の通りです。
            <ol>
              <li>下記メールフォームよりメールアドレスをご入力ください。お申し込み手続きに関するご案内についてメールでご連絡いたします。</li>
              <li>送信されたメールにURLが記載されております。お客様情報入力画面で必要情報を入力してください。</li>
            </ol>
          </div>
          <div class="input">
            <div class="">
              <input name="メールアドレス" type="text" v-model="params.email" maxlength="100" @input="validationEmail">
            </div>
            <button type="submit" class="">メール送信
              <i size="30" class="material-icons">navigate_next</i>
            </button>
          </div>
        </div>
        <div>
          <div class="signup-title">
            <h2>重要なお知らせ</h2>
          </div>
          <div class="notices">
            「Cloud illuminator」は、日本国内で登記された法人・団体がお申し込みいただけます。
            <br>
            個人、日本国外の法人・団体、反社会的勢力などに該当する場合はお申し込みいただけませんのでご注意ください。
            <br>
            詳細は
            <a rel="noopener noreferrer" href="https://nhn-techorus.com/agreement/pdf/stipulation_cloud_illuminator_ext.pdf" target="_blank">約款</a>ならびに
            <a rel="noopener noreferrer" href="https://nhn-techorus.com/agreement/pdf/service_specification_cloud_illuminator_ext.pdf" target="_blank">サービス仕様書</a>をご確認ください。
          </div>
        </div>
        <div class="flow">
          <div class="signup-title">
            <h2>ご利用までの流れ</h2>
          </div>
          <img src="/images/order_flow.png">
          <div class="desc">
            <ul>
              <li>※ 本サービスのお申し込みは、法人のお客様に限定されます。</li>
              <li>※ 請求書払いによるお取引は、当社規定の審査を通過したお客様に限ります。審査結果如何ではお取引をお断りする場合がございます。</li>
              <li>※ CURのデータ容量が増大した際には、当社より月額改定の確認をさせていただく場合がございます。</li>
            </ul>
          </div>
        </div>
      </form>
    </section>
    <section v-else>
      <nav class="flow-nav step1">
        <ul>
          <li>メールアドレス入力</li>
          <li>お客様情報入力</li>
          <li>確認</li>
          <li>完了</li>
        </ul>
      </nav>
      <div class="send-desc">
        <div class="signup-title">
          <h2>お客様情報 仮登録完了</h2>
        </div>
        <p>この度は Cloud illuminator サービスへお申し込み頂きまして誠にありがとうございます。

          入力いただいたメールアドレスに、アカウント設定用のURLを送りました。
          メールに記載されたURLをクリックして、引き続きアカウントの登録作業を行ってください。

          時間が経過してもメールが届かない場合は、<a href="https://form.nhn-techorus.com/contact/" target="_blank" class="sendSignupMail__Link-sc-31pio6-2 cOtpHq">お問い合わせ</a>からご連絡ください。</p>
      </div>
      <div class="button">
        <button v-on:click="backTop">トップへ戻る
          <i size="30" class="material-icons">navigate_next</i>
        </button>
      </div>
    </section>
  </div>
</template>

<script setup>
import '../../../stylesheet/signup.css'
import {onMounted, ref} from "vue";
import { createTemporaryServiceAccount } from "../../lib/axios/api_client";

let params = ref({})
params.value = {
  email: ""
}
let status = ref(0)
const emailRegex = /^.{1,46}@[a-z0-9.\-]+\.[a-z]{2,}$/;

const backTop = () => {
  window.location.href = 'https://nhn-techorus.com/cloud-illuminator/'
}

const validationEmail = () => {
  const form = document.forms["signup-email"]
  const input = form.elements["メールアドレス"]

  if (!params.value.email || params.value.email === "") {
    input.setCustomValidity("メールアドレスを入力してください。")
    return false
  } else if (!emailRegex.test(params.value.email)) {
    input.setCustomValidity("メールアドレスの入力形式が正しくありません。")
    return false
  }

  input.setCustomValidity("")
  return true
}

const submitForm = async () => {
  if (validationEmail()) {
    const result = await createTemporaryServiceAccount(params.value)
    if (result.successful) {
      status.value = 2
    }
  }
}

onMounted( async () => {
  validationEmail()
})
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
</style>

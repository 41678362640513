import {format, parse} from "date-fns";

export function transformDate(date) {
  // string => date
  return parse(date,"yyyy-MM-dd", new Date)
}

export function formatDate(date) {
  // Date => YYYY-MM-DD
  return format(date, "yyyy-MM-dd")
}

export function validateDateFormat(date){
  const regexp = new RegExp(/(19[0-9]{2}|20[0-9]{2})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])/, 'i')
  return regexp.test(date);
}

<template>
  <Alert ref="alert"/>
  <div v-if="!loading.initialized">
    <div class="loader">Loading...</div>
    <ReportMethods ref="reportMethods" :reportType="report_type"/>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="pull-left">
          <ReportButtons
              :reportType="report_type"
              v-model:report="aws_report"
              v-model:edited="edited"
              v-on:updateReport="updateReport"
          />
          <FilterButtons
              :reportType="report_type"
              v-model:report="aws_report"
              v-on:updateReport="updateReport"
          />
        </div>
        <div class="pull-right">
          <ButtonHideFilter v-on:triggerEmit="changeDisplayFilter">{{ dashboardSizeClass.apply.text }}</ButtonHideFilter>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- start left-side -->
      <div :class="dashboardSizeClass.apply.class">
        <div class="card">
          <EditTitleCard :reportType="report_type" :lock="aws_report.report_lock" v-model:reportName="aws_report.name" v-model:edited="edited" v-model:close="close"/>
          <div class="card-header"></div>
          <div class="card-body">
            <IframeDashboard ref="dashboard" height="400px"/>
          </div>
        </div>
        <tableRightSizing :reportType="report_type" v-model:tableItem="dashboardTableItem" :cpuArchitectures="aws_report.filter.cpu_architectures" v-model:loading="this.loading" v-on:emitTrigger="updateDashboardTableItem" v-on:getDashboardElement="getDashboardElement" v-bind:class="{disabled: permissions && !permissions.active_exclusion_add_button}"/>
      </div>
      <!-- end left-side -->
      <!-- start right-side -->
      <div class="col-lg-3 col-md-3 col-sm-3" v-show="displayFilter">
        <FilterList :reportType="report_type" :reportFilter="aws_report.filter" v-on:updateFilter="updateFilter" v-on:updateDashboard="updateReport"/>
      </div>
      <!-- end right-side -->
    </div>
  </div>
</template>

<script>
import DropDownButton       from '../components/dropdown_button.vue'
import DropDownCalendar     from '../components/dropdown_calendar.vue'
import EditTitleCard        from '../components/edit_title_card.vue'
import FilterList           from '../components/filter_list.vue'
import tableRightSizing     from '../components/table_right_sizing.vue'
import Alert                from '../components/alert_new.vue'
import * as constants       from '../utils/constants'
import {
  getDashboardElementRightsizingDashboard
} from "../lib/axios/api_client";
import {extractReportId} from "../lib/common";
import ButtonHideFilter from "../components/button_hide_filter.vue";
import IframeDashboard from "../components/iframe_dashboard.vue";
import ReportButtons from "../components/report_buttons.vue";
import FilterButtons from "../components/filter_buttons.vue";
import ReportMethods from "../components/report_methods.vue";

export default {
  mounted: async function(){
    const report_id = extractReportId()
    const report = await this.$refs.reportMethods.getReport(report_id)
    if(!report){ return false }
    this.aws_report = report

    // 他画面から遷移した時でhashが付与されている時にhashからfilter取得
    if(location.hash){
      let hashFilter = location.hash.slice(1).split(',')[1];
      this.aws_report.filter.cpu_architectures[0] = hashFilter;
    }

    const result = await this.getDashboardElement()
    if(result){
      this.loading.initialized = true

      this.$nextTick(async () => {
        await this.$refs.dashboard.generateDashboard(this.aws_report)
      })
    }
  },
  components: {
    'DropDownButton'       : DropDownButton,
    'DropDownCalendar'     : DropDownCalendar,
    'EditTitleCard'        : EditTitleCard,
    'FilterList'           : FilterList,
    'tableRightSizing'     : tableRightSizing,
    'ButtonHideFilter'     : ButtonHideFilter,
    'IframeDashboard'      : IframeDashboard,
    'ReportButtons'        : ReportButtons,
    'ReportMethods'        : ReportMethods,
    'FilterButtons'        : FilterButtons,
    'Alert': Alert
  },
  data: function () {
    return {
      report_type: constants.reportTypeRightSizingEc2,
      aws_report:   {},
      edited: false,
      close: true,
      displayFilter: true,
      dashboardSizeClass: constants.dashboardSizeClass,
      dashboardTableItem: [],
      loading: {
        initialized: false,
        rightSizingTable: false
      },
      permissions: {}
    }
  },
  methods: {
    changeEdited(bool){
      this.close = true
      this.edited = bool
    },
    changeDisplayFilter(){
      this.displayFilter = !this.displayFilter
      this.dashboardSizeClass.apply = this.displayFilter ? this.dashboardSizeClass.half : this.dashboardSizeClass.full
    },
    getDashboardElement: async function(){
      this.loading.rightSizingTable = true

      const params = {
        report_type: this.report_type,
        filter: this.aws_report.filter
      }

      const res = await getDashboardElementRightsizingDashboard(params)
      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      this.loading.rightSizingTable = false
      this.dashboardTableItem = res.data.filter_item
      this.permissions = res.data.permissions

      return true
    },
    updateFilter(filter) {
      this.aws_report.filter[filter.key] = filter.value
    },
    updateReport(){
      this.changeEdited(true)
      this.$refs.dashboard.generateDashboard(this.aws_report)
      this.getDashboardElement()
    },
    updateDashboardTableItem(newItem){
      this.dashboardTableItem = newItem
    }
  }
}
</script>

<style scoped>
</style>

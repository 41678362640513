<template>
  <div v-if="status===INITIALIZED"></div>
  <div v-else-if="status===LOADING">
    <div class="loader">Loading...</div>
  </div>
  <div v-else>
    <VueApexCharts
      width="100%"
      type="bar"
      :options="chartOptions"
      :series="series"
    />
    <div style="text-align: center; margin: 10px 0">
      <span>
        <svg width="40" height="10">
          <rect width="40" height="10" style="fill:#69d2e7"></rect>
        </svg>
        実績
      </span>
        <span class="pl-3">
        <svg width="40" height="10">
          <rect width="40" height="10" style="fill:#f48024"></rect>
        </svg>
        予測
      </span>
    </div>
  </div>
</template>

<script setup>
import VueApexCharts from "vue3-apexcharts";
import { nextTick, ref } from "vue";

const INITIALIZED = "initialized"
const LOADING = "loading"
const DISPLAY = "display"

let status = ref(INITIALIZED)
let chartOptions = ref({})
let series = ref([])

const statusLoading = () => {
  status.value = LOADING
}

const statusDisplay = () => {
  status.value = DISPLAY
}

const exec = (categories, seriesData, index) => {
  nextTick(() => {
    chartOptions = {
      title: {
        text: "",
        align: "center"
      },
      chart: {
        id: "vuechart-example",
        toolbar: {
          tools: {
            download: false
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: categories
      },
      colors: [
        function({ value, seriesIndex, w, dataPointIndex }) {
          if (dataPointIndex >= index) {
            return "#f48024"
          } else {
            return "#69d2e7"
          }
        }
      ]
    }
    series = [
      {
        name: "series-1",
        data: seriesData
      },
    ]
  })
}

defineExpose({
  exec,
  statusLoading,
  statusDisplay
})
</script>

export const categoryTypes= [
    {
      type: "cost",
      name: "cost"
    },
    {
      type: "budget",
      name: "budget"
    },
    {
      type: "ri_sp",
      name: "reserved / savings plans"
    },
    {
      type: "rightsizing",
      name: "rightsizing"
    }
]

export const alertCategoryTypeList = categoryTypes.map( item  => item.type )

<template>
  <BudgetMethods ref="methods"/>
  <Alert ref="alert"/>
  <table class="table table-borderless">
    <tbody>
      <tr>
        <th style="vertical-align: top">入力方法</th>
        <td>
          <div class="form-group">
            <template v-for="item in options">
              <div class="form-check form-check-radio">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" :id="`setting-type-${item.value}`" :value="item.value" v-model="amount.setting_type" v-on:change="settingTypeAction(item.value)">{{ item.name }}
                  <span class="circle"><span class="check"></span></span>
                </label>
              </div>
            </template>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div>
      <template v-if="isProportionalDivision()">
        <div class="align-table">
          予算総額($)を期間の月数で按分します
          <table class="table table-borderless mt-2">
            <tbody>
            <tr>
              <th>予算総額($)</th>
              <td>
                <input id="budget-amount" class="form-control" type="number" min="0" max="10000000" v-model="amount.proportional_division.budget_amount"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template v-if="isGrowthRate()">
        <div class="align-table">
          初期予算額へ月毎に予算成長率をかけて算出します。
          <table class="table table-borderless mt-2">
            <tbody>
            <tr>
              <th>初期予算額($)</th>
              <td>
                <input id="budget-amount" class="form-control" type="number" min="0" max="10000000" v-model="amount.growth_rate.initial_budget_amount"/>
              </td>
            </tr>
            <tr>
              <th>予算成長率(%)</th>
              <td>
                <input id="budget-amount" class="form-control" type="number" min="0" max="100" v-model="amount.growth_rate.growth_rate"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template v-if="isExistingBudget()">
        <div class="margin-left: 75px">
          <div style="display: flex; justify-content: space-between;">
            <div class="text-left">
              <span>選択した既存予算の値をそのままコピーします。</span>
            </div>
            <div class="text-right">
              <span class="cursor-pointer btn btn" v-on:click="getPastBudgets()">
                <i class="material-icons">refresh</i>
              </span>
            </div>
          </div>
          <table id="budget_amount" class="table mt-2 form-group">
            <thead>
            <tr>
              <th></th>
              <th>予算名</th>
              <th>期限の開始</th>
              <th>期限の終了</th>
              <th>開始月の予算金額($)</th>
              <th>終了月の予算金額($)</th>
              <th>成長率(%)</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in pastBudgets" class="form-group">
              <td>
                <div class="form-check form-check-radio radio-height">
                  <label class="form-check-label">
                    <input class="form-check-input" type="radio" id="pastBudgetSelected" :value="item.budget_id" v-on:click="inputPastBudget(index)" v-model="pastBudgetSelected">
                    <span class="circle"><span class="check"></span></span>
                  </label>
                </div>
              </td>
              <td>{{ item.budget_name }}</td>
              <td>{{ item.start_date}}</td>
              <td>{{ item.end_date }}</td>
              <td>{{ item.start_month_budget }}</td>
              <td>{{ item.end_month_budget }}</td>
              <td>{{ item.growth_rate }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template v-if="isPricingCalculator()">
        <div class="align-table">
          AWS Pricing Calculatorの結果からインポートします。
          <table class="table table-borderless mt-2">
            <tbody>
            <tr>
              <th>ファイル(CSV)</th>
              <td>
                <inputCsv ref="csv"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template v-if="isActualEstimate()">
        <div class="align-table">
          <div>
            <span>過去の実績から予測します。</span>
          </div>
          <table class="table table-borderless mt-2">
            <tbody>
            <tr>
              <th>対象期間(過去xヶ月)</th>
              <td>
                <input id="estimate-budget-period" class="form-control" type="number" min="1" max="12" v-model="estimateParam.cost_data_period"/>
              </td>
            </tr>
            <tr>
              <th>バッファ追加(金額のx%)</th>
              <td>
                <input id="estimate-budget-buffer" class="form-control" type="number" min="0" max="100" v-model="estimateParam.buffer"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-3" style="display: flex; justify-content: center; margin-left: 0">
          <div>
            <button class="btn btn-primary repeated_hit_prevention" v-on:click.stop.prevent="estimateBudgets">予測する</button>
          </div>
        </div>
        <div class="align-table">
          <div class="mt-3">
            <BudgetChart ref="chart"/>
          </div>
        </div>
      </template>
      <div class="mt-3" style="display: flex; justify-content: center;">
        <div>
          <button class="btn btn-primary repeated_hit_prevention" v-on:click.stop.prevent="calcAmount">反映する</button>
        </div>
      </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import BudgetChart from "./budget_chart.vue";
import inputCsv from "./input_csv.vue"
import Alert from "./alert_new.vue";
import BudgetMethods from "./budget_methods.vue";
import { useAmount } from "../composables/budget_amount";

const { amount, options, estimateParam,
  setPastBudget, setUpfrontCost, setMonthlyCost,
  getCostDataPeriod,
  calcEstimateBudget,
  checkCostDataPeriod, checkBuffer,
  isProportionalDivision, isGrowthRate, isExistingBudget, isPricingCalculator, isActualEstimate
} = useAmount()

const props = defineProps({
  startDate: String,
  endDate: String,
  filter: Object,
  range: Number
})

const emits = defineEmits([ "emitTrigger", "resetFlag" ])

const methods = ref(null)
const chart = ref(null)
const csv = ref(null)
const alert = ref(null)

let pastBudgets = ref([])
let pastBudgetSelected = ref(0)

const settingTypeAction = async (type) => {
  if(type === "existing_budget" && pastBudgets.value.length === 0){
    await getPastBudgets()
  }
  emits('resetFlag')
}

const inputPastBudget = async (index) => {
  const pastBudgetDetail = await methods.value.getBudgetDetailEdit(pastBudgets.value[index].budget_id)
  if(!pastBudgetDetail){ return false }
  setPastBudget(pastBudgetDetail.monthly_budgets)
}

const getPastBudgets = async () => {
  const result = await methods.value.getPastBudgets(props.range);
  if(!result){ return false }
  pastBudgets.value = result
}

const estimateBudgets = async () => {

  if(checkCostDataPeriod()){
    alert.value.displayModal("対象期間は1から12までの値を指定する必要があります。", 'error');
    return false;
  }

  if(checkBuffer()){
    alert.value.displayModal("バッファは0から100までの値を指定する必要があります。", 'error');
    return false;
  }

  chart.value.statusLoading()

  const result = await methods.value.estimateBudget(props.filter, getCostDataPeriod(), props.startDate, props.endDate );
  if(!result){
    return false
  }

  const chartInfo = calcEstimateBudget(result.cost, result.budget)
  chart.value.exec(chartInfo.categories, chartInfo.seriesData, chartInfo.index)

  chart.value.statusDisplay()
}

const calcAmount = () => {
  if(isPricingCalculator()){
    const header = csv.value.result[0][0]
    if(header !== "Estimate summary" && header !== "見積りの要約"){
      alert.value.displayModal("CSVの形式が異なります。Pricing CalculatorのCSVではない可能性があります。", 'error');
      return false;
    }
    setUpfrontCost(Number(csv.value.result[2][0]))
    setMonthlyCost(Number(csv.value.result[2][1]))
  }
  emits('emitTrigger', amount.value, estimateParam.value)
}
</script>
<style>
.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

input.visually-hidden:is(:focus, :focus-within) + label {
  outline: thin dotted;
}

.align-table {
  margin-left: 75px
}

.radio-height {
  height: 40px
}
</style>

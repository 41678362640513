<template>
  <Alert ref="alert"/>
  <template v-if="loading.rightSizingTable">
    <div class="loader">Loading...</div>
  </template>
  <template v-else>
    <div class="right-sizing-table">
      <div class="card">
        <template v-for="(item, index) in tableItem">
          <!-- Modal -->
          <div class="modal fade" v-bind:id="'createRightsizingSettingModal' + index" tabindex="-1" role="dialog" aria-labelledby="createRightsizingSettingModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>
                    こちらの項目を除外しますか？<br />
                    連結アカウント：{{ item['aws_account_id'] }}<br />
                    <template v-if="reportType === 'rightsizing_ec2'">
                      インスタンスID：{{ item['instance_id'] }}<br />
                      CPUアーキテクチャ：{{ item['cpu_architectures'] }}<br />
                    </template>
                    <template v-else-if="reportType === 'rightsizing_ebs'">
                      ボリュームID：{{ item['volume_id'] }}
                    </template>
                    <template v-else-if="reportType === 'rightsizing_eip'">
                      アロケーションID：{{ item['eip_allocation_id'] }}
                    </template>
                    <template v-else>
                      ELB名：{{ item['elb_name'] }}
                    </template>
                  </p>
                  <p>
                    除外する期限を設定：<input type="date" v-model="date" v-bind:min="tomorrow"/><br />
                    ※設定しない場合は無期限になります
                  </p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">キャンセル</button>
                  <button type="button" class="btn btn-primary" v-on:click.prevent="createRightsizingSetting(item, index)">除外として設定</button>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal -->
          <div class="modal fade" v-bind:id="'contentRightsizingModal' + index" tabindex="-1" role="dialog" aria-labelledby="contentRightsizingModal" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content" style="width:1200px; margin-left: -300px;">
                <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <ReportMethods ref="reportMethods" :reportType="reportTypeDetail"/>
                  <IframeDashboard ref="iframeDashboard" height="600px"/>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="card-header">
          <div class="pull-right">
            <DropdownDownload :reportType="reportType" :tableItem="tableItem" :keys="rightsizingKeys" :titles="rightsizingTitles"/>
          </div>
          <span class="text-muted">現在の通知除外設定の登録件数は{{ countRightSizingSetting }}件です。（最大100件まで登録できます）<br/>通知除外設定は管理者のみ可能です。</span>
        </div>
        <div class="card-body">
          <table class="table table-striped table-hover">
            <thead class="sticky-top">
            <tr>
              <th>#</th>
              <template v-for="elements in rightsizing_elements[reportType].elements">
                <TableTh :tableKey="elements.key" v-on:emitTrigger="doSort">{{ elements.title }}</TableTh>
              </template>
            </tr>
            </thead>
            <tbody>
            <template v-for="(item, index) in tableItem">
              <tr>
                <td class="text-left">{{ index + 1 }}</td>
                <template v-for="elements in rightsizing_elements[reportType].elements">
                  <template v-if="elements.click">
                    <template v-if="elements.key === 'exclusion_notice'">
                      <template v-if="item[elements.key]">
                        <td><i class="material-icons">check</i></td>
                      </template>
                      <template v-else>
                        <template v-if="canCreate">
                          <td>
                            <a class="cursor-pointer" data-toggle="modal" v-bind:data-target="'#createRightsizingSettingModal' + index" type="button"  v-bind:class="{disabled: permissions && !permissions.active_exclusion_add_button}">
                              <i class="material-icons right-sizing-table-td-material-icons">add</i>
                            </a>
                          </td>
                        </template>
                        <template v-else>
                          <td><i class="material-icons">add</i></td>
                        </template>
                      </template>
                    </template>
                    <template v-else>
                    <td>
                        <a class="text-info cursor-pointer" data-toggle="modal" v-on:click="createIframeDashboard(item, index)" v-bind:data-target="'#contentRightsizingModal' + index" type="button">
                          {{ item[elements.key] }}
                        </a>
                    </td>
                    </template>
                  </template>
                  <template v-else-if="elements.isNumber">
                    <td>{{ parseFloat(item[elements.key]).toFixed(4) }}</td>
                  </template>
                  <template v-else>
                    <td>{{ item[elements.key] }}</td>
                  </template>
                </template>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import tableTh from "./table_th.vue";
import dropdownButtonDownload from './dropdown_download.vue'
import Alert from "./alert_new.vue";
import * as constants       from '../utils/constants'
import {countRightsizingSetting, createRightsizingSetting} from "../lib/axios/api_client";
import {formatDate} from "../lib/calendar";
import ReportMethods from "./report_methods.vue";
import IframeDashboard from "./iframe_dashboard.vue";

export default  {
  props: ['reportType', 'tableItem', 'keys', 'cpuArchitectures', 'loading', 'permissions'],
  components: {
    ReportMethods: ReportMethods,
    IframeDashboard: IframeDashboard,
    TableTh: tableTh,
    DropdownDownload: dropdownButtonDownload,
    Alert
  },
  data: function () {
    return {
      rightsizing_elements: {
        rightsizing_ec2: {
          elements: [
            { title: "通知除外設定",                key: "exclusion_notice",                sort: false, click: true, isNumber: false },
            { title: "連結アカウント",              key: "aws_account_id",                  sort: false, click: false, isNumber: false },
            { title: "連結アカウントの別名",         key: "account_name",                    sort: false, click: false, isNumber: false },
            { title: "リージョン",                 key: "region",                          sort: false, click: false, isNumber: false },
            { title: "インスタンスID",              key: "instance_id",                     sort: false, click: true, isNumber: false },
            { title: "インスタンス名",              key: "instance_name",                   sort: false, click: false, isNumber: false },
            { title: "結果",                      key: "finding",                         sort: false, click: false, isNumber: false },
            { title: "現在のオンデマンド料金($/時間)", key: "current_ondemand_price",         sort: false, click: false, isNumber: true },
            { title: "推奨のオンデマンド料金($/時間)", key: "recommendation_ondemand_price",   sort: false, click: false, isNumber: true },
            { title: "価格差($/時間)",              key: "price_difference",                sort: false, click: false, isNumber: true },
            { title: "推定節約額($/月)",            key: "estimated_monthly_savings_value", sort: false, click: false, isNumber: true },
            { title: "節約可能な割合(%)",           key: "savings_opportunity_percentage",  sort: false, click: false, isNumber: true },
            { title: "現在のインスタンスタイプ",      key: "current_instance_type",           sort: false, click: false, isNumber: false },
            { title: "推奨のインスタンスタイプ",      key: "recommendation_instance_type",    sort: false, click: false, isNumber: false },
            { title: "オペレーティングシステム",      key: "operating_system",                sort: false, click: false, isNumber: false },
            { title: "テナンシー",                 key: "tenancy",                          sort: false, click: false, isNumber: false },
            { title: "パフォーマンスリスク",         key: "current_performance_risk",         sort: false, click: false, isNumber: false },
            { title: "移行にかかる労力",            key: "migration_effort",                  sort: false, click: false, isNumber: false },
            { title: "プラットフォームの違い",       key: "platform_differences",              sort: false, click: false, isNumber: false },
            { title: "理由",                      key: "finding_reason",                   sort: false, click: false, isNumber: false },
            { title: "推論されるワークロードタイプ",  key: "inferred_workload_types",          sort: false, click: false, isNumber: false },
            { title: "AutoScalingグループ名",      key: "associated_auto_scaling_name",     sort: false, click: false, isNumber: false }
          ]

        },
        rightsizing_ebs: {
          elements: [
            { title: "通知除外設定",          key: "exclusion_notice",                   sort: false, click: true, isNumber: false },
            { title: "連結アカウント",         key: "aws_account_id",                     sort: false, click: false, isNumber: false },
            { title: "連結アカウントの別名",    key: "account_name",                       sort: false, click: false, isNumber: false },
            { title: "リージョン",            key: "region",                             sort: false, click: false, isNumber: false },
            { title: "ボリュームID",          key: "volume_id",                          sort: false, click: true, isNumber: false },
            { title: "結果",                 key: "finding",                            sort: false, click: false, isNumber: false },
            { title: "現在の料金($/月)",      key: "current_monthly_price",               sort: false, click: false, isNumber: true },
            { title: "推奨の料金($/月)",      key: "recommendation_monthly_price",        sort: false, click: false, isNumber: true },
            { title: "推定節約額($/月)",      key: "estimated_monthly_savings_value",     sort: false, click: false, isNumber: true },
            { title: "節約可能な割合(%)",      key: "savings_opportunity_percentage",     sort: false, click: false, isNumber: true },
            { title: "現在のボリュームタイプ",  key: "current_volume_type",                 sort: false, click: false, isNumber: false },
            { title: "現在のボリュームサイズ",  key: "current_volume_size",                 sort: false, click: false, isNumber: false },
            { title: "現在のベースラインIOPS", key: "current_volume_baseline_iops",        sort: false, click: false, isNumber: false },
            { title: "推奨のボリュームタイプ",  key: "recommendation_volume_type",          sort: false, click: false, isNumber: false },
            { title: "推奨のボリュームサイズ",  key: "recommendation_volume_size",          sort: false, click: false, isNumber: false },
            { title: "推奨のベースラインIOPS", key: "recommendation_volume_baseline_iops", sort: false, click: false, isNumber: false }
          ]
        },
        rightsizing_eip: {
          elements: [
            { title: "通知除外設定",          key: "exclusion_notice",                   sort: false, click: true, isNumber: false },
            { title: "連結アカウント",         key: "aws_account_id",                     sort: false, click: false, isNumber: false },
            { title: "連結アカウントの別名",    key: "account_name",                       sort: false, click: false, isNumber: false },
            { title: "リージョン",            key: "region",                             sort: false, click: false, isNumber: false },
            { title: "アロケーションID",       key: "eip_allocation_id",                  sort: false, click: true, isNumber: false },
            { title: "EIP名",                key: "eip_name",                           sort: false, click: false, isNumber: false },
            { title: "結果",                 key: "eip_association",                    sort: false, click: false, isNumber: false },
            { title: "現在の料金($/月)",      key: "unit_price_per_month",                sort: false, click: false, isNumber: true },
            { title: "推奨の料金($/月)",      key: "recommended_unit_price_per_month",    sort: false, click: false, isNumber: true },
            { title: "推定節約額($/月)",      key: "potential_unit_price_per_month",      sort: false, click: false, isNumber: true },
            { title: "節約可能な割合(%)",      key: "savings_opportunity_percentage",     sort: false, click: false, isNumber: true },
            { title: "パブリックIPv4アドレス", key: "eip_public_ip",                      sort: false, click: false, isNumber: false },
            { title: "アドレスプール",         key: "eip_public_ipv4_pool",               sort: false, click: false, isNumber: false },
            { title: "プライベートIP",        key: "eip_private_ip_address",             sort: false, click: false, isNumber: false },
            { title: "ネットワークインターフェイス所有アカウント", key: "eip_network_interface_owner_id",sort: false, click: false, isNumber: false },
            { title: "ネットワークボーダーグループ", key: "eip_network_border_group",       sort: false, click: false, isNumber: false }
          ]
        },
        rightsizing_elb: {
          elements: [
            { title: "通知除外設定",           key: "exclusion_notice",                 sort: false, click: true, isNumber: false },
            { title: "連結アカウント",         key: "aws_account_id",                    sort: false, click: false, isNumber: false },
            { title: "連結アカウントの別名",    key: "account_name",                      sort: false, click: false, isNumber: false },
            { title: "リージョン",            key: "region",                            sort: false, click: false, isNumber: false },
            { title: "ELB名",                key: "elb_name",                          sort: false, click: true, isNumber: false },
            { title: "DNS名",                key: "elb_dns_name",                      sort: false, click: false, isNumber: false },
            { title: "結果",                 key: "elb_association",                   sort: false, click: false, isNumber: false },
            { title: "現在の料金($/月)",      key: "unit_price_per_month",               sort: false, click: false, isNumber: true },
            { title: "推奨の料金($/月)",      key: "recommended_unit_price_per_month",   sort: false, click: false, isNumber: true },
            { title: "推定節約額($/月)",      key: "potential_unit_price_per_month",     sort: false, click: false, isNumber: true },
            { title: "節約可能な割合(%)",      key: "savings_opportunity_percentage",    sort: false, click: false, isNumber: true },
            { title: "VPC ID",              key: "elb_vpc_id",                         sort: false, click: false, isNumber: false },
            { title: "アベイラビリティゾーン",  key: "elb_availability_zones",             sort: false, click: false, isNumber: false },
            { title: "タイプ",               key: "elb_type",                           sort: false, click: false, isNumber: false }
          ]
        }
      },
      date: '',
      tomorrow: '',
      countRightSizingSetting: '',
      canCreate: true,
      reportTypeDetail: '',
    }
  },
  async mounted() {
    await this.updateRightsizingSetting();

    // 他画面から遷移した時でhashが付与されている時にモーダル表示
    if (location.hash) {
      let hashId = location.hash.slice(1).split(',')[0];
      let modalLinks = document.getElementsByClassName("text-info cursor-pointer");
      let ids = {};
      Object.keys(modalLinks).forEach(function(id){
        ids[modalLinks[id].innerText] = id;
      });
      modalLinks[ids[hashId]].click();
    };
  },
  created() {
    let tomorrowSet = new Date();
    tomorrowSet.setDate(tomorrowSet.getDate() + 1);
    this.tomorrow = formatDate(tomorrowSet);
    this.date = this.tomorrow;

    this.reportTypeDetail = this.reportType + '_detail';
  },
  computed: {
    rightsizingKeys() {
      return this.rightsizing_elements[this.reportType].elements.map(item => item.key)
    },
    rightsizingTitles() {
      return this.rightsizing_elements[this.reportType].elements.map(item => item.title)
    }
  },
  methods: {
      createIframeDashboard: async function(item, index) {
        const report = await this.$refs.reportMethods[index].getReport(0);
        if(!report){ return false }

        if(this.reportType === constants.reportTypeRightSizingEc2){
          report.filter["instance_id"].values = [ item["instance_id"] ];
          report.filter["cpu_architectures"].values = [ item['cpu_architectures'] ];
        }else if(this.reportType === constants.reportTypeRightSizingEbs){
          report.filter["volume_id"].values = [ item["volume_id"] ];
        }else if(this.reportType === constants.reportTypeRightSizingEip){
          report.filter["allocation_id"].values = [ item["eip_allocation_id"] ];
        }else{
          report.filter["name"].values = [ item["elb_name"] ];
        }

        this.$nextTick(async () => {
          await this.$refs.iframeDashboard[index].generateDashboard(report);
        })
      },
      doSort(columnKey) {
        // elementを探す
        const elementIndex = this.rightsizing_elements[this.reportType].elements.findIndex( element => element.key === columnKey)
        const element = this.rightsizing_elements[this.reportType].elements[elementIndex]

        // ソート
        const sort1 = (a,b) => this.compareSort(a,b,columnKey)
        const sort2 = (a,b) => this.compareSort(b,a,columnKey)
        const result = element.sort ? this.tableItem.sort(sort1) : this.tableItem.sort(sort2)

        // ソートの状態を更新
        const isSort = this.rightsizing_elements[this.reportType].elements[elementIndex].sort
        this.rightsizing_elements[this.reportType].elements[elementIndex].sort = !isSort

        // tableを更新
        this.$emit('emitTrigger', result)
      },
      compareSort(first, second, columnKey){
        if (first[columnKey] > second[columnKey]) {
          return -1;
        }
        if (first[columnKey] < second[columnKey]) {
          return 1;
        }
        return 0;
      },
    create: async function (params) {
      const res = await createRightsizingSetting(params);
      await this.updateRightsizingSetting();
      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error');
        return false;
      }
      this.$refs.alert.displayModal('成功しました。','success');
      this.$emit('getDashboardElement');
    },
    createRightsizingSetting(item, index) {
      const service_type = this.reportType.slice(-3);
      let params = null;
      if (service_type === 'ec2') {
        params = { aws_account_id: item['aws_account_id'], service_type: service_type, identifier: item['instance_id'], cpu_architecture: item['cpu_architectures'], end_date: this.date };
      } else if (service_type === 'ebs') {
        params = { aws_account_id: item['aws_account_id'], service_type: service_type, identifier: item['volume_id'], end_date: this.date };
      } else if (service_type === 'eip') {
        params = { aws_account_id: item['aws_account_id'], service_type: service_type, identifier: item['eip_allocation_id'], end_date: this.date };
      } else {
        params = { aws_account_id: item['aws_account_id'], service_type: service_type, identifier: item['elb_name'], end_date: this.date };
      }
      this.create(params);
      $(`#createRightsizingSettingModal${ index }`).modal('hide');
    },
    updateRightsizingSetting: async function() {
      const res = await countRightsizingSetting();
      const count = res.data.result
      this.countRightSizingSetting = count
      this.canCreate = count < 100;
    }
  }
}
</script>
<style scoped>
.disabled {
  pointer-events: none;
}

.right-sizing-table .table td .disabled .right-sizing-table-td-material-icons {
  color: gray;
}
</style>
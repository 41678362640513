<template>
  <UserMethods ref="methods"/>
  <div v-if="!initialized">
    <div class="loader">Loading...</div>
  </div>
  <div v-else class="row" style="width:100%">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="title-part">
            <div class="text-left">
              <h4 class="card-title">ユーザー詳細</h4>
            </div>
            <div class="text-right">
              <div class="editButton">
                <a :class="`nav-link btn btn-primary`"
                style="width: 120px"
                :href="'/mypage/users/'+userData.id+'/edit'">編集</a>
              </div>
            </div>
          </div>
          <!-- Modal(userDeleteConfirm) -->
          <div v-if="isModalOpen === true" id="confirmDialogModal" class="confirmModal" >
            <div class="modal">
              <div class="modal-header">
                <h4>{{userData.email}}のユーザを削除してもよろしいですか？</h4>
                <div class="text-right">
                  <i class="material-icons" @click="isModalOpen = false">close</i>
                </div>
              </div>
              <div class="modal-footer">
                <div class="cancelButton">
                  <button class="btn default" @click="isModalOpen = false">いいえ</button>
                </div>
                <div class="confirmButton">
                  <button :class="`nav-link btn btn-primary`"
                        type="submit"
                        style="width:90px" @click="handleSubmit">はい</button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isModalOpenSecond === true" id="confirmDialogModal" class="confirmModal" >
            <div class="modal">
              <div class="modal-header">
                <h4>削除が完了しました</h4>
                <div class="text-right">
                  <i class="material-icons" @click="isModalOpenSecond = false">close</i>
                </div>
              </div>
              <div class="modal-footer">
                <div class="confirmButton">
                  <a :class="`nav-link btn btn-primary`"
                    href="/mypage/users">ユーザ一覧へ戻る</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <h4 class="card-title-body">ユーザー情報</h4>
          <div class="userInfoArea">
            <div class="userInformation">
              <div class="label">
                <span class="custom_label">{{userNameLabel}}</span>
              </div>
              <div class="user">
                <p id="user_name">{{userData.username}}</p>
              </div>
            </div>
            <div class="userInformation">
              <div class="label">
                <span class="custom_label">{{mailAddressLabel}}</span>
              </div>
              <div class="user">
                <p id="mail_address">{{userData.email}}</p>
              </div>
            </div>
            <div class="userInformation">
              <div class="label">
                <span class="custom_label">{{roleLabel}}</span>
              </div>
              <div class="user">
                <p id="user_role">{{roleTypeValue(userData.role_type)}}</p>
              </div>
            </div>
          </div>
          <div class="awsAccountTable">
            <h4 class="card-title-body">AWSアカウント情報</h4>
            <div class="table-responsive" style="margin-top: 20px">
              <table id="user_List" class="table table-striped table-hover text-left">
                <thead>
                  <tr>
                    <template v-for="header in headerItems">
                      <TableTh :tableKey="header.key" v-on:emitTrigger="doSort">{{header.title}}</TableTh>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="account in userData.aws_accounts">
                    <td>
                      <div class="tableText">{{account.aws_account_id}}</div>
                    </td>
                    <td>
                      <div class="tableText">{{account.alias_name}}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card-footer" style="border-top: 1px solid lightgray;">
          <div class="deleteButton">
            <button
                :class="`nav-link btn btn-primary${!permissions.show_edit_button ? ' disabled' : ''}`"
                style="width: 120px"
                type="button"
                @click.prevent="deleteUserButton">削除</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import TableTh from "../../components/table_th.vue";
import {findTableHeaderIndex, selectSort} from "../../lib/table";
import UserMethods from "../../components/user_methods.vue";
import {extractId} from "../../lib/common";
import {roleTypes} from "../../utils/user_constants"

const initialized = ref(false);
const isModalOpen = ref(false);
const isModalOpenSecond = ref(false);
const userData = ref({});
const permissions = ref({});
const methods = ref(null);
const selectedUserId = ref(0);
const roleTypeList = roleTypes;
const userNameLabel = ref("ユーザー名");
const mailAddressLabel = ref("メールアドレス");
const roleLabel = ref("権限");
const loginUserEmail = ref("");

onMounted(async () => {
  selectedUserId.value = extractId(3);
  await getUser(selectedUserId.value);
  initialized.value = true;
});

const getUser = async (id) => {
  const res = await methods.value?.getUser(id);
  if (!res) {
    return false;
  }
  permissions.value = res.permissions;
  userData.value = res.user;
}

const headerItems = ref([
  { title: "AWSアカウントID", key: "aws_account_id", sort: false },
  { title: "AWSアカウント別名", key: "alias_name", sort: false },
]);

const doSort = (columnKey) => {
  const index = findTableHeaderIndex(headerItems.value, columnKey);
  const isSort = headerItems.value[index].sort;
  const sort = selectSort(isSort, columnKey);

  userData.value.aws_accounts.sort(sort);
  headerItems.value[index].sort = !isSort;
}

const deleteUserButton = () => {
  isModalOpen.value = true;
}

const deleteUser = async (id) => {
  const result = await methods.value?.deleteUser(id);
  if(!result){
    return false;
  }
  return true;
}

const handleSubmit = async () => {
  // ユーザーを削除するAPI呼び出し処理
  const result = await deleteUser(userData.value.id);
  if(result === true){
    isModalOpen.value = false;
    isModalOpenSecond.value = true;
  }
}

const roleTypeValue = (target) => {
  const roleType = roleTypeList.find(item => target === item.value);
  return roleType.name;
}

</script>
<style scoped>

.title-part {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  height: 60px;
}

.card-title {
  font-size: 22px;
  font-weight: 300;
  margin-top: 10px;
}

.card-title-body {
  font-size: 20px;
  font-weight: 300;
}

.label {
  display: inline-block;
  width: 200px;
  height: 30px;
}

.custom_label {
  color: #aaa;
}

.userInfoArea {
  margin-top: 20px;
  border-bottom: 1px solid lightgray;
}

.user {
  display: inline-block;
}

.awsAccountTable {
  margin-top: 20px;
}

.table table-striped table-hover text-left {
  margin-bottom: 10px;
}

.material-icons {
  color: #aaa;
  cursor: pointer;
}

.confirmModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: white;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 20%;
  margin-left: 30%;
  display: block;
  height: 180px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-link btn btn-primary {
  color: white;
}

</style>
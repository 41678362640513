// models/report_parameter.rb と一致させる

export const groupItem = [
    {name: 'サービス',         value: 'service'},
    {name: '連結アカウント',    value: 'account'},
    {name: 'リージョン',       value: 'region'},
    {name: 'インスタンスタイプ', value: 'instancetype'},
    {name: '使用タイプ',       value: 'usagetype'}
]

export const groupItemWithNormal = [
    {name: 'なし', value: 'normal' },
    {name: 'サービス',         value: 'service'},
    {name: '連結アカウント',    value: 'account'},
    {name: 'リージョン',       value: 'region'},
    {name: 'インスタンスタイプ', value: 'instancetype'},
    {name: '使用タイプ',       value: 'usagetype'}
]

export const timeUnitItem = [
    {name: '月別', value: 'monthly'},
    {name: '日別', value: 'daily'},
]

export const usageItem = [
    {name: '時間', value: 'hours'},
    {name: '正規化された単位', value: 'nua'},
]

export const graphItem = [
    {name: '棒グラフ',    value: 'bar'},
    {name: '折れ線グラフ', value: 'line'},
]

export const groupItemWithStackedBar = [
    {name: '棒グラフ',    value: 'bar'},
    {name: '折れ線グラフ', value: 'line'},
    {name: '積み重ねグラフ', value: 'stacked_bar'}
]

export const awsServiceNames = [
    { name: 'Amazon Elastic Compute Cloud', value: {condition: 'include', values: ['Amazon Elastic Compute Cloud'] } },
    { name: 'Linux/Unix', value: {condition: 'include', values: ['Linux/UNIX'] } },
    { name: 'Shared', value: {condition: 'include', values: ['Shared'] } },
    { name: 'Flexible', value: {condition: 'include', values: ['Flexible'] } }
]
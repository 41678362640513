<template>
  <Alert ref="alert"/>
</template>
<script>
import {
  lockDashboardFilter,
  unlockDashboardFilter,
  createDashboardFilter,
  deleteDashboardFilter,
  getDashboardFilter,
  listDashboardFilter,
  updateDashboardFilter,
  validateDashboardFilterName
} from "../lib/axios/api_client";
import Alert from "./alert_new.vue";

export default {
  components: {
    'Alert': Alert
  },
  methods: {
    getDashboardFilter: async function (filterId = 0) {
      const res = await getDashboardFilter(filterId)
      if (!res.successful) {
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      return res.data.filter
    },
    getFilterList: async function (page = 0) {
      const params = {params: {page: page}}
      const res = await listDashboardFilter(params)

      if (!res.successful) {
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      return res.data
    },
    createFilter: async function (name, filter) {
      filter.name = name
      const params = {filter: filter}

      const res = await createDashboardFilter(params)
      if (!res.successful) {
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      this.$refs.alert.displayModal('成功しました。', 'success')
      return true
    },
    updateFilter: async function (filterId, filter) {
      const params = {filter: filter}

      const res = await updateDashboardFilter(filterId, params)
      if (!res.successful) {
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      this.$refs.alert.displayModal('成功しました。', 'success')
      return true
    },
    deleteFilter: async function(filterIds) {
      const res = await deleteDashboardFilter(filterIds)
      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      this.$refs.alert.displayModal('成功しました。','success')
      return true
    },
    lockFilter: async function(filterIds){
      const res = await lockDashboardFilter(filterIds)
      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      this.$refs.alert.displayModal('成功しました。','success')
      return true
    },
    unlockFilter: async function(filterIds){
      const res = await unlockDashboardFilter(filterIds)
      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      this.$refs.alert.displayModal('成功しました。','success')
      return true
    },
    validateName: async function(name){
      const params = { name: name }
      const res = await validateDashboardFilterName(params)
      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      return res.data.result
    }
  }
}
</script>
validateDashboardFilterName

<template>
  <Alert ref="alert"/>
  <div v-if="!loading.initialized">
    <div class="loader">Loading...</div>
    <ReportMethods ref="reportMethods"/>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-body">
            <IframeDashboard ref="assetSummary" height="550px"/>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="row card-summary">
          <template v-for="summaryType in summaryTypes">
            <tableAssetSummary
              :summaryType="summaryType"
              v-model:tableItem="dashboardTableItem[summaryType.type]"
              v-model:loading="this.loading"
              v-on:emitTrigger="updateDashboardTableItem"
              v-on:emitDashboardTrigger="getDashboard"
              v-on:getDashboardElement="getDashboardElement(summaryType.type)"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from "../components/alert_new.vue";
import tableAssetSummary from "../components/table_asset_summary.vue";
import IframeDashboard from '../components/iframe_dashboard.vue'
import ReportMethods from "../components/report_methods.vue";
import { summaryTypes, summaryTypeList } from "../utils/asset_summary";
import { getDashboardElementAssetSummaryDashboard } from "../lib/axios/api_client";

export default {
  async mounted() {
    this.aws_report.asset_summary = await this.$refs.reportMethods.getReport(0, 'asset_summary')
    await Promise.all(summaryTypeList.map(async (summaryType) => {
      this.aws_report[summaryType] = await this.$refs.reportMethods.getReport(0, summaryType)
    }));

    this.loading.initialized = true

    this.$nextTick( async () => {
      if(this.aws_report.asset_summary){
        this.$refs.assetSummary.generateDashboard(this.aws_report.asset_summary)
      }
      summaryTypeList.forEach((summaryType)=> {
        if(this.aws_report[summaryType]){
          this.getDashboardElement(summaryType)
        }
      });
    })
  },
  components: {
      'tableAssetSummary' : tableAssetSummary,
      'IframeDashboard' : IframeDashboard,
      'ReportMethods' : ReportMethods,
      'Alert' : Alert
  },
  data: function () {
      return {
          summaryTypes: summaryTypes,
          aws_report: {},
          dashboardTableItem: {},
          loading: {
            initialized: false,
            assetSummaryTable: {}
          }
      }
  },
  computed: {
  },
  methods: {
    getDashboardElement: async function(summaryType){
      this.loading.assetSummaryTable[summaryType] = true

      const params = {
        report_type: summaryType,
        filter: this.aws_report[summaryType].filter
      }

      const res = await getDashboardElementAssetSummaryDashboard(params)
      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      this.loading.assetSummaryTable[summaryType] = false
      this.dashboardTableItem[summaryType] = res.data.filter_item
      return true
    },
    updateDashboardTableItem(summaryType, newItem){
      this.dashboardTableItem[summaryType] = newItem
    },
    getDashboard: async function(refs, summaryType){
      refs.generateDashboard(this.aws_report[summaryType.type])
    }
  }
}
</script>
<style scoped>
.card-summary {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>

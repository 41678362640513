export const status = [
  { name: "未設定",    value: "no" },
  { name: "設定済",    value: "done" },
  { name: "エラー",    value: "error" },
  { name: "自動設定中", value: "setting" },
  { name: "削除済",    value: "deleted" },
  { name: "設定不要",   value: "no-setting" },
  { name: "CUR無効",   value: "cur-inactive" },
]

export const version = [
  { name: "-", value: "none" },
  { name: "最新", value: "new" },
  { name: "更新が必要です", value: "old"},
]
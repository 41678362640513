import { TsBaseClient, } from "../ts_base_client";
export class ApiClient extends TsBaseClient {
    constructor() {
        super();
    }
    async getUserList() {
        const url = `/user`;
        return await this.get(url, {});
    }
    async createUser(params) {
        const url = `/user`;
        return await this.post(url, params);
    }
    async getUserAuthorized(tokenKey) {
        const url = `/user/create/authorized/${tokenKey}`;
        return await this.get(url);
    }
    async createUserAuthorized(params) {
        const url = `/user/create/authorized`;
        return await this.post(url, params);
    }
    async getAwsAccounts(param) {
        const url = `/aws_accounts?size=${param.size}&page=${param.page}`;
        return await this.get(url, {});
    }
    async getUser(id) {
        const url = `/user/detail/${id}`;
        return await this.get(url);
    }
    async editUser(id, param) {
        const url = `/user/edit/${id}`;
        return await this.put(url, param);
    }
    async deleteUser(id) {
        const url = `/user/delete/${id}`;
        return await this.delete(url);
    }
    async getLoginUserEmail() {
        const url = `/user/login-mail`;
        return await this.get(url);
    }
}

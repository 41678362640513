import { TsBaseClient } from "../ts_base_client";
export class ApiClient extends TsBaseClient {
    constructor() {
        super();
    }
    async login(params) {
        const url = `/login`;
        return await this.post(url, params);
    }
    async mfaSetting(params) {
        const url = `/login/mfa-setting`;
        return await this.post(url, params);
    }
    async mfaLogin(params) {
        const url = `/login/mfa`;
        return await this.post(url, params);
    }
    async sendCodeMfaLogin(params) {
        const url = `/login/mfa/sendcode`;
        return await this.post(url, params);
    }
    async logout() {
        const url = `/logout`;
        return await this.post(url);
    }
}

import axiosBase, { AxiosError, AxiosHeaders } from "axios";
export class ExBaseClient {
    DEFAULT_HEADER = new AxiosHeaders({});
    DEFAULT_HTTP_RESPONSE = {
        code: 999,
        data: '',
        successful: false,
        message: '',
        filename: ''
    };
    constructor() { }
    async get(url, params = {}, headers = this.DEFAULT_HEADER, responseType = "json") {
        const options = {
            url: url,
            method: "get",
            params: params
        };
        return await this.exec(options, headers, responseType);
    }
    async post(url, params = {}, headers = this.DEFAULT_HEADER, responseType = "json") {
        const options = {
            url: url,
            method: "post",
            data: params
        };
        return await this.exec(options, headers, responseType);
    }
    async put(url, params = {}, headers = this.DEFAULT_HEADER, responseType = "json") {
        const options = {
            url: url,
            method: "put",
            data: params
        };
        return await this.exec(options, headers, responseType);
    }
    async patch(url, params = {}, headers = this.DEFAULT_HEADER, responseType = "json") {
        const options = {
            url: url,
            method: "patch",
            data: params
        };
        return await this.exec(options, headers, responseType);
    }
    async delete(url, params = {}, headers = this.DEFAULT_HEADER, responseType = "json") {
        const options = {
            url: url,
            method: "delete"
        };
        return await this.exec(options, headers, responseType);
    }
    async exec(options, headers, responseType) {
        const axios = this.axiosClient(headers, responseType);
        let result = this.DEFAULT_HTTP_RESPONSE;
        await axios(options).then((response) => {
            result = this.createResultParams(response, responseType);
        })
            .catch((e) => {
            result = this.createErrorParams(e);
        });
        return result;
    }
    axiosClient(headers, responseType) {
        return axiosBase.create({
            headers: headers,
            responseType: responseType
        });
    }
    createResultParams(response, responseType) {
        let result = this.DEFAULT_HTTP_RESPONSE;
        result.code = response.status;
        result.message = response.statusText;
        result.successful = (response.status === 200);
        result.data = response.data;
        return result;
    }
    createErrorParams(error) {
        let result = this.DEFAULT_HTTP_RESPONSE;
        console.log(error);
        if (error.code === "ECONNABORTED") {
            // タイムアウト発生
            result.message = "データの取得ができませんでした。再読み込みをしてください。";
        }
        else if (error.response) {
            // リクエストが行われ、サーバーは 2xx の範囲から外れるステータスコードで応答しました
            result.code = error.response.status;
            result.message = 'サーバーでの処理に失敗しました。';
            // if(error.response.status >= 500){
            //   result.message = error.response.data.message ? error.response.data.message : 'サーバーでの処理に失敗しました。'
            // }else if(error.response.data.message){
            //   result.message = error.response.data.message
            // }
        }
        else if (error.request) {
            result.message = "サーバーから応答がありませんでした。";
        }
        else {
            // console.log('Error', error.message);
            result.message = "不明なエラーが発生しました。";
        }
        result.successful = false;
        return result;
    }
}

<template>
  <template v-if="!loading.tableExpireRiSp">
    <div class="loader">Loading...</div>
  </template>
  <template v-else>
    <div class="right-sizing-table">
      <div class="card">
        <div class="card-header">
          <div class="card-title">
            <h4>期限切れの対象となるRI/SP</h4>
            <p>
              所有するRI/SPの中から期限切れになるものを選択してください。<br>
              (1つ選択するとあわせて検討することが可能なものでリストが更新されます。)<br>
              <template v-if="existsRiSpId">
                有効期限のお知らせにあわせて表示しています。その他をご希望の場合は <a href="/mypage/recommend/simulator/scenario/new">こちら</a> よりお願いします。
              </template>
            </p>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-striped table-hover">
            <thead class="sticky-top">
            <tr>
              <th>#</th>
              <template v-for="elements in expire_ri_sp_elements">
                <TableTh :tableKey="elements.key" v-on:emitTrigger="doSort">{{ elements.title }}</TableTh>
              </template>
            </tr>
            </thead>
            <tbody>
            <template v-for="(item, index) in tableItem">
              <tr>
                <td class="text-left">
                  <input @change="updateCheckbox" type="checkbox" :value="item" v-model="checkedItems">
                </td>
                <td>{{ item.aws_account_id }}</td>
                <td>{{ item.account_name }}</td>
                <td>{{ item.ri_sp }}</td>
                <td>{{ item.ri_sp_id }}</td>
                <td>{{ item.remaining }}</td>
                <td>{{ item.end_date }}</td>
                <td>{{ item.offering_type }}</td>
                <td>{{ item.service }}</td>
                <td>{{ item.instance_info }}</td>
                <td>{{ item.platform }}</td>
                <td>{{ item.region }}</td>
                <td>{{ item.availability_zone }}</td>
                <td>{{ item.scope }}</td>
                <td>{{ covertResourceNumber(item.ri_sp, item.resource_number) }}</td>
                <td>{{ item.start_date }}</td>
                <td>{{ item.usage_rate }}</td>
                <td>{{ item.used_resource }}</td>
                <td>{{ item.unused_resource }}</td>
                <td>{{ item.on_demand_cost_equivalent }}</td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import tableTh from "./table_th.vue";
import { findTableHeaderIndex, selectSort } from "../lib/table";

export default  {
  props: ['tableItem', 'loading', 'existsRiSpId', 'recommendType'],
  components: {
    TableTh: tableTh,
  },
  data: function () {
    return {
      checkedItems: [],
      expire_ri_sp_elements: [
        { title: "連結アカウント",            key: "aws_account_id",    sort: false },
        { title: "連結アカウントの別名",       key: "account_name",       sort: false },
        { title: "RI/SP",                  key: "ri_sp",              sort: false },
        { title: "ID",                     key: "ri_sp_id",           sort: false },
        { title: "残日数",                  key: "remaining",          sort: false },
        { title: "終了日",                  key: "end_date",           sort: false },
        { title: "タイプ",                  key: "offering_type",      sort: false },
        { title: "サービス",                key: "service",             sort: false },
        { title: "インスタンス情報",          key: "instance_info",       sort: false },
        { title: "プラットフォーム",          key: "platform",           sort: false },
        { title: "リージョン",               key: "region",             sort: false },
        { title: "アベイラビリティゾーン",     key: "availability_zone",     sort: false },
        { title: "スコープ",                 key: "scope",             sort: false },
        { title: "リソース数",               key: "resource_number",   sort: false },
        { title: "開始日",                  key: "start_date",        sort: false },
        { title: "使用率",                  key: "usage_rate",        sort: false },
        { title: "使用リソース",             key: "used_resource",     sort: false },
        { title: "未使用リソース",           key: "unused_resource",    sort: false },
        { title: "純節約額 ($)",            key: "on_demand_cost_equivalent",    sort: false },
      ],
      category_type: {
        ec2_compute_instance: ["RI(EC2),standard","RI(EC2),convertible","SP(ComputeSavingsPlans)","SP(EC2InstanceSavingsPlans)"],
        rds: ["RI(RDS)"],
        redshift: ["RI(Redshift)"],
        opensearch: ["RI(OpenSearch)"],
        elasticache: ["RI(ElastiCache)"],
        sagemaker: ["SP(SageMakerSavingsPlans)"]
      }
    }
  },
  mounted() {
  },
  methods: {
    doSort(columnKey) {
      const index = findTableHeaderIndex(this.expire_ri_sp_elements, columnKey)
      const isSort = this.expire_ri_sp_elements[index].sort
      const sort = selectSort(isSort, columnKey);

      this.tableItem.sort(sort)
      this.expire_ri_sp_elements[index].sort = !isSort
    },
    updateCheckbox() {
      if (this.checkedItems.length) {
        let newTableItem = []
        let selectedItem = this.recommendType
        newTableItem = this.tableItem.filter( item => item.aws_account_id === this.checkedItems[0].aws_account_id)
        if (this.checkedItems[0].ri_sp === 'RI') {
          if (this.checkedItems[0].service === 'Amazon Elastic Compute Cloud') {
            newTableItem = newTableItem.filter( item => {
              return item.service === 'Amazon Elastic Compute Cloud' || item.offering_type === 'ComputeSavingsPlans' || item.offering_type === 'EC2InstanceSavingsPlans'
            })
            if (!this.category_type.ec2_compute_instance.includes(this.recommendType)) {
              selectedItem = (this.checkedItems[0].offering_type === 'standard') ? 'SP(EC2InstanceSavingsPlans)' : 'SP(ComputeSavingsPlans)'
            }
          } else if (this.checkedItems[0].service === 'Amazon Relational Database Service') {
            newTableItem = newTableItem.filter( item => {
              return item.service === 'Amazon Relational Database Service'
            })
            if (!this.category_type.rds.includes(this.recommendType)) {
              selectedItem = 'RI(RDS)'
            }
          } else if (this.checkedItems[0].service === 'Amazon Redshift') {
            newTableItem = newTableItem.filter( item => {
              return item.service === 'Amazon Redshift'
            })
            if (!this.category_type.redshift.includes(this.recommendType)) {
              selectedItem = 'RI(Redshift)'
            }
          } else if (this.checkedItems[0].service === 'Amazon OpenSearch Service') {
            newTableItem = newTableItem.filter( item => {
              return item.service === 'Amazon OpenSearch Service'
            })
            if (!this.category_type.opensearch.includes(this.recommendType)) {
              selectedItem = 'RI(OpenSearch)'
            }
          } else if (this.checkedItems[0].service === 'Amazon ElastiCache') {
            newTableItem = newTableItem.filter( item => {
              return item.service === 'Amazon ElastiCache'
            })
            if (!this.category_type.elasticache.includes(this.recommendType)) {
              selectedItem = 'RI(ElastiCache)'
            }
          }
        } else {
          if (this.checkedItems[0].offering_type === 'ComputeSavingsPlans' || this.checkedItems[0].offering_type === 'EC2InstanceSavingsPlans') {
            newTableItem = newTableItem.filter( item => {
              return item.service === 'Amazon Elastic Compute Cloud' || item.offering_type === 'ComputeSavingsPlans' || item.offering_type === 'EC2InstanceSavingsPlans'
            })
            if (!this.category_type.ec2_compute_instance.includes(this.recommendType)) {
              selectedItem = (this.checkedItems[0].offering_type === 'ComputeSavingsPlans') ? 'SP(ComputeSavingsPlans)' : 'SP(EC2InstanceSavingsPlans)'
            }
          } else {
            newTableItem = newTableItem.filter( item => {
              return item.offering_type === 'SageMakerSavingsPlans'
            })
            if (!this.category_type.sagemaker.includes(this.recommendType)) {
              selectedItem = 'SP(SageMakerSavingsPlans)'
            }
          }
        }
        this.$emit('emitTrigger', newTableItem, selectedItem)
      } else {
        this.$emit('getExpireRiSpDashboard')
      }
      this.$emit('bindCheckedItem', this.checkedItems)
    },
    defaultCheckedItems() {
      this.checkedItems = []
    },
    covertResourceNumber(ri_sp, value) {
      if (ri_sp === 'RI' || Number.isNaN(value) || Number.isInteger(value) || !Number.isFinite(value)) {
        return value
      }
      return value.toFixed(5)
    }
  }
}
</script>
<style scoped>
a {
  color: #9c27b0;
}
</style>

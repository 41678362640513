export const tabs= [
  {
    page: "detail",
    path: "/mypage/asset_visualization/usage/detail"
  },
  {
    page: "history",
    path: "/mypage/asset_visualization/usage/history"
  }
]

export const serviceTypes= [
  {
    type: "ec2",
    name: "EC2",
    detail: "asset_ec2_detail",
    history: "asset_ec2_history",
  },
  {
    type: "rds",
    name: "RDS",
    detail: "asset_rds_detail",
    history: "asset_rds_history",
  },
  {
    type: "redshift",
    name: "Redshift",
    detail: "asset_redshift_detail",
    history: "asset_redshift_history",
  },
  {
    type: "s3",
    name: "S3",
    detail: "asset_s3_detail",
    history: "asset_s3_history",
  },
  {
    type: "ebs",
    name: "EBS",
    detail: "asset_ebs_detail",
    history: "asset_ebs_history",
  },
  {
    type: "vpc",
    name: "VPC",
    detail: "asset_vpc_detail",
    history: "asset_vpc_history",
  },
  {
    type: "eip",
    name: "EIP",
    detail: "asset_eip_detail",
    history: "asset_eip_history",
  },
  {
    type: "elb",
    name: "ELB",
    detail: "asset_elb_detail",
    history: "asset_elb_history",
  },
]

export const serviceTypeList = serviceTypes.map( item  => item.type )

<template>
  <PasswordMethods ref="passwordMethod"/>
  <UserMethods ref="userMethods"/>
  <NeoidMethods ref="neoidMethod"/>
  <div v-if="!initialized">
    <div class="loader">Loading...</div>
  </div>
  <div v-else class="row" style="width:100%">
    <div class="col-12">
      <form class="card" id="password_change_form">
        <div class="card-header">
          <div class="title-part">
            <div class="text-left">
              <h4 class="card-title">パスワード変更</h4>
              <div class="helpIcon">
                <i class="material-icons" id="Help" style="font-size: 20px;" @click="isModalOpenHelp = true">help</i>
              </div>
              <div v-if="isModalOpenHelp === true" class="passwordHelp">
                <div class="confirmModal">
                  <div class="helpModal">
                    <div>
                      <div class="text-right" style="height: 10px;">
                        <i class="material-icons" id="modalClose" @click="isModalOpenHelp = false">close</i>
                      </div>
                    </div>
                    <div class="modal-body" style="margin-top: 10px;">
                      パスワードは以下をすべて使用した、8から48文字で入力してください。<br>
                      ・小文字の半角アルファベット<br>
                      ・大文字の半角アルファベット<br>
                      ・記号
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal(passwordChangeConfirm) -->
          <div v-if="isModalOpen === true" id="confirmDialogModal" class="confirmModal" >
            <div class="modal">
              <div class="modal-header">
                <h4>パスワードを変更してもよろしいですか？</h4>
                <div class="text-right" >
                  <i class="material-icons" id="modalClose" @click="isModalOpen = false">close</i>
                </div>
              </div>
              <div class="modal-footer">
                <div class="cancelButton">
                  <button class="btn default" @click="isModalOpen = false">いいえ</button>
                </div>
                <div class="confirmButton">
                  <button :class="`nav-link btn btn-primary`"
                          type="submit"
                          style="color:white;width:90px" @click.prevent="handleSubmit">はい</button>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal(passwordChangeComplete) -->
          <div v-if="isModalOpenSecond === true" id="pwdChangeCompleteModal" class="confirmModal">
            <div class="modal">
              <div class="modal-header">
                <h4>パスワードの変更が完了しました。</h4>
                <div class="text-right" >
                  <i class="material-icons" id="modalClose" @click="isModalOpenSecond = false">close</i>
                </div>
              </div>
              <div class="modal-footer">
                <div class="backToPwdChangeButton">
                  <a class="btn default" href="/mypage/password/change">パスワード変更画面に戻る</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div>
            <div class="passwordInput">
              <div class="label">
                <label for="current_password">{{ currentPwdLabel }}</label>
              </div>
              <div class="formInput">
                <input name="current_password" id="current_password" v-model="inputValueCurrent" class="form-control"
                       maxlength="19" :type='isShowCurrent?"text":"password"' @input="validatePasswordCurrent">
              </div>
              <div class="visibilityIcon">
                <i v-show="isShowCurrent === true" class="material-icons" style="cursor: pointer" @click="pushShowButtonCurrent">visibility_off</i>
                <i v-show="isShowCurrent === false" class="material-icons" style="cursor: pointer" @click="pushShowButtonCurrent">visibility</i>
              </div>
              <p v-if="validationMessageCurrent" class="validationMessage">{{ validationMessageCurrent }}</p>
            </div>
            <div class="passwordInput">
              <div class="label">
                <label for="new_password">{{ changedPwdLabel }}</label>
              </div>
              <div class="formInput">
                <input v-model="inputValueNew" name="new_password" id="new_password" class="form-control"
                       maxlength="19" :type='isShowNew?"text":"password"' @input="validatePasswordNew">
              </div>
              <div class="visibilityIcon">
                <i v-show="isShowNew === true" class="material-icons" style="cursor: pointer" @click="pushShowButtonNew">visibility_off</i>
                <i v-show="isShowNew === false" class="material-icons" style="cursor: pointer" @click="pushShowButtonNew">visibility</i>
              </div>
              <p v-if="validationMessageNew" class="validationMessage">{{ validationMessageNew }}</p>
            </div>
            <div class="passwordInput">
              <div class="label">
                <label for="password_confirm">{{ confirmPwdLabel }}</label>
              </div>
              <div class="formInput">
                <input v-model="inputValueConfirm" name="password_confirm" id="password_confirm" class="form-control"
                       maxlength="19" :type='isShowConfirm?"text":"password"' @input="validatePasswordConfirm">
              </div>
              <div class="visibilityIcon">
                <i v-show="isShowConfirm === true" class="material-icons" style="cursor: pointer" @click="pushShowButtonConfirm">visibility_off</i>
                <i v-show="isShowConfirm === false" class="material-icons" style="cursor: pointer" @click="pushShowButtonConfirm">visibility</i>
              </div>
              <p v-if="validationMessageConfirm" class="validationMessage">{{ validationMessageConfirm }}</p>
            </div>
          </div>
          <div class="buttonArea">
            <div class="cancelButton">
              <a class="btn default" href="/mypage">キャンセル</a>
            </div>
            <div class="confirmButton">
              <button
                  :class="`nav-link btn btn-primary`"
                  style="color:white; width: 120px"
                  type="button"
                  @click="showModal">確定</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import PasswordMethods from "../../components/password_methods.vue";
import UserMethods from "../../components/user_methods.vue";
import NeoidMethods from "../../components/neoid_methods.vue";

const initialized = ref(false);
const loginUserMail = ref("");
const passwordMethod = ref(null);
const userMethods = ref(null);
const neoidMethod = ref(null);
const currentPwdLabel = ref("現在のパスワード");
const changedPwdLabel = ref("新しいパスワード");
const confirmPwdLabel = ref("パスワードの再入力");
const isShowCurrent = ref(false);
const isShowNew = ref(false);
const isShowConfirm = ref(false);
const passwordChanged = ref(false);
const inputValueCurrent = ref("");
const inputValueNew = ref("");
const inputValueConfirm = ref ("");
const validationCurrent = ref(false);
const validationNew = ref(false);
const validationConfirm = ref(false);
const validationMessageCurrent = ref("");
const validationMessageNew = ref("");
const validationMessageConfirm = ref("");
const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,19}$/;
const isModalOpen = ref(false);
const isModalOpenHelp = ref(false);
const isModalOpenSecond = ref(false);
const isUserPasswordValid = ref(false);

onMounted(async () => {
  initialized.value = true;
});

const pushShowButtonNew = () => { isShowNew.value = !isShowNew.value; }
const pushShowButtonCurrent = () => { isShowCurrent.value = !isShowCurrent.value; }
const pushShowButtonConfirm = () => { isShowConfirm.value = !isShowConfirm.value; }

const validatePasswordCurrent = () => {
  if (inputValueCurrent.value === "") {
    validationMessageCurrent.value = currentPwdLabel.value+"を入力してください。";
    validationCurrent.value = false;
  } else if (!passwordRegex.test(inputValueCurrent.value)) {
    validationMessageCurrent.value = currentPwdLabel.value+"の入力形式が正しくありません。";
    validationCurrent.value = false;
  } else {
    validationMessageCurrent.value = "";
    validationCurrent.value = true;
  }
}

const validatePasswordNew = () => {
  if (inputValueNew.value === "") {
    validationMessageNew.value = changedPwdLabel.value+"を入力してください。";
    validationNew.value = false;
  } else if (!passwordRegex.test(inputValueNew.value)) {
    validationMessageNew.value = changedPwdLabel.value+"の入力形式が正しくありません。";
    validationNew.value = false;
  } else if (inputValueNew.value === inputValueCurrent.value) {
    validationMessageNew.value = "既存のパスワードと同じパスワードです。";
    validationNew.value = false;
  } else {
    validationMessageNew.value = "";
    validationNew.value = true;
  }
}

const validatePasswordConfirm = () => {
  if (inputValueConfirm.value === "") {
    validationMessageConfirm.value = confirmPwdLabel.value+"を入力してください。"
    validationConfirm.value = false;
  } else if (inputValueConfirm.value !== inputValueNew.value) {
    validationMessageConfirm.value = changedPwdLabel.value+"と確認用パスワードが一致しません。";
    validationConfirm.value = false;
  } else {
    validationMessageConfirm.value = "";
    validationConfirm.value = true;
  }
}

const showModal = () => {
  validatePasswordCurrent();
  validatePasswordNew();
  validatePasswordConfirm();

  if(validationCurrent.value === true && validationNew.value === true && validationConfirm.value === true) {
    isModalOpen.value = true;
  }

}

const changePassword = async () => {

  // ログインしているユーザーのメールアドレスを取得
  const requestLoginUserMail = await userMethods.value?.getLoginUserEmail();
  if (!requestLoginUserMail) {
    return false;
  }
  loginUserMail.value = requestLoginUserMail.email;

  // 暗号化用のRSAセッションキーを取得(既存パスワード)
  const requestRSAKeysCurrent = await neoidMethod.value?.generateRSAKeys(loginUserMail.value, inputValueCurrent.value, "CHECK_PASSWORD");

  // 暗号化用のRSAセッションキーを取得
  const requestRSAKeys = await neoidMethod.value?.generateRSAKeys(loginUserMail.value, inputValueNew.value, "CHANGE_PASSWORD");

  // パスワード変更メソッド動作用パラメーター
  const param = {
    session_key_current: requestRSAKeysCurrent.sessionKey,
    encrypt_string_current: requestRSAKeysCurrent.rsaString,
    session_key:  requestRSAKeys.sessionKey,
    encrypt_string: requestRSAKeys.rsaString
  }

  // パスワード変更メソッドを動作
  const result = await passwordMethod.value?.changePassword(param);
  if (!result) {
    return false;
  }
  return true;
}

const handleSubmit = async () => {
  // パスワード変更処理
  const isPasswordChanged = await changePassword();
  if (isPasswordChanged) {
    isModalOpen.value = false;
    isModalOpenSecond.value = true;
  }
}

watch(inputValueCurrent, inputValueNew, inputValueConfirm, () => {
  validatePasswordCurrent();
  validatePasswordNew();
  validatePasswordConfirm();
});

</script>

<style scoped>

.helpIcon {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.title-part {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  height: 60px;
}

.card-title {
  display: inline-block;
  font-size: 22px;
  font-weight: 300;
  margin-top: 10px;
}

.label {
  display: inline-block;
  width: 150px;
  height: 50px;
}

.formInput {
  display: inline-block;
  width: 200px;
}

.visibilityIcon {
  display: inline-block;
  vertical-align: middle;
  padding-top: 10px;
}

.validationMessage {
  color : red;
  display: inline-block;
  font-size: 12px;
  margin-left: 20px;
}

.buttonArea {
  margin-top: 10px;
}

.confirmModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: white;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 20%;
  margin-left: 30%;
  display: block;
  height: 180px;
}

.helpModal {
  background: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  align-self: flex-start;
  display: block;
  height: 180px;
  width: 100%;
  max-width: 600px;
  margin-top: 2%;
  margin-right: 15%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.material-icons {
  color: #aaa;
  cursor: pointer;
}

.cancelButton {
  display: inline-block;
  padding-right: 20px;
}

.confirmButton {
  display: inline-block;
}

.material-icons {
  cursor: pointer;
}

</style>
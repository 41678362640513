<template>
  <RightsizingAlertsForm :isEdit="false"/>
</template>
<script>
import RightsizingAlertsForm from "../components/rightsizing_alerts_form.vue";

export default {
  components: {
    RightsizingAlertsForm
  },
  data() {
    return {}
  },
  methods: {
  }
}
</script>
<style scoped>
</style>

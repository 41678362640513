<template>
  <div id="signup">
    <h1>お申し込み</h1>
    <section>
      <nav class="flow-nav step4">
        <ul>
          <li>メールアドレス入力</li>
          <li>お客様情報入力</li>
          <li>確認</li>
          <li>完了</li>
        </ul>
      </nav>
      <div class="send-desc">
        <div class="signup-title">
          <h2>お客様情報 登録完了</h2>
        </div>
        <p>
          この度はCloud illuminatorサービスへのお申し込み頂きまして誠にありがとうございます。<br>
          <br>
          登録作業が完了次第、お客様へ登録完了のご連絡をさせて頂きます。<br>
          恐れ入りますが、登録完了までお時間を頂けます様お願い致します。
        </p>
      </div>
      <div class="button">
        <button v-on:click="backTop">トップへ戻る
          <i size="30" class="material-icons">navigate_next</i>
        </button>
      </div>
    </section>
  </div>
</template>

<script setup>
import '../../../stylesheet/signup.css'

const backTop = () => {
  window.location.href = 'https://nhn-techorus.com/cloud-illuminator/'
}
</script>

<style scoped>
</style>
<template>
  <div>
    <ServiceAccountMethods ref="methods"/>
    <div v-if="!initialized">
      <div class="loader">Loading...</div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <form id="payment-form" v-on:submit.prevent="updatePayment()">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pull-left">
                  <ul class="nav">
                    <li class="nav-item">
                      <button type="submit" class="btn btn-primary">保存</button>
                    </li>
                  </ul>
                </div>
                <div class="pull-right">
                  <ul class="nav">
                    <li class="nav-item">
                      <a
                        class="nav-link btn btn-default"
                        href="/mypage/service-account/payment/"
                      >
                        戻る
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header card-header-rose card-header-icon">
                <div class="card-title">
                  <h4>請求先情報</h4>
                </div>
              </div>
              <div class="card-body">
                <table class="table table-borderless table-responsive">
                  <tbody>
                    <tr>
                      <th>ご担当者名</th>
                      <td>
                        <div class="form-group bmd-form-group form-width-200" >
                          <input type="text" name="name" class="form-control" minlength="3" maxlength="20" v-model="payment.billing.name" @input="validateName()"/>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>ご担当者名(フリガナ)</th>
                      <td>
                        <div class="form-group bmd-form-group form-width-200" >
                          <input type="text" name="nameKana" class="form-control" maxlength="100" v-model="payment.billing.kana" @input="validateNameKana()"/>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>部署名</th>
                      <td>
                        <div class="form-group bmd-form-group form-width-200" >
                          <input type="text" class="form-control" name="department_name" v-model="payment.billing.department_name" v-on:input="validateDepartmentNameByte()"/>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>郵便番号</th>
                      <td style="display: flex">
                        <input class="form-control" name="contact.postal_code" maxlength="8" type="text" placeholder="ハイフン有り無し、どちらでも入力可能です。" style="width: 320px;" pattern="^(?:\d{3}-\d{4}|\d{7})$" v-model="payment.billing.postal_code" @input="validatePostalCode()">
                        <button type="button" class="btn btn-sm btn-primary ml-2" v-on:click="findAddress()">
                          郵便番号検索
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th>都道府県</th>
                      <td>
                        <select class="form-control" name="contact.prefecture_code" style="width: 240px;" v-model="payment.billing.prefecture_code" @change="validatePrefecture()">
                          <option>選択してください</option>
                          <template v-for="p in pref">
                            <option :value="p.value">{{ p.name }}</option>
                          </template>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th>市区町村</th>
                      <td>
                        <div class="form-group bmd-form-group form-width-200" >
                          <input type="text" name="city" class="form-control" maxlength="100" v-model="payment.billing.city" @input="validateCity()"/>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>町名・番地</th>
                      <td>
                        <div class="form-group bmd-form-group form-width-200" >
                          <input type="text" name="streetAddress" class="form-control" maxlength="100" v-model="payment.billing.street_address" @input="validateAddress()"/>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>建築物名等</th>
                      <td>
                        <div class="form-group bmd-form-group form-width-200" >
                          <input type="text" class="form-control" minlength="0" maxlength="100" v-model="payment.billing.building"/>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>電話番号</th>
                      <td>
                        <div class="form-group bmd-form-group form-width-200" >
                          <input type="text" name="phone" class="form-control" minlength="0" maxlength="13" pattern="^(?:\d{2,4}-\d{2,4}-\d{4}|\d{10,11})$" v-model="payment.billing.phone" @input="validatePhone()"/>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>メールアドレス</th>
                      <td>
                        <div class="form-group bmd-form-group form-width-200" >
                          <input type="email" name="mailAddress" class="form-control" maxlength="100" v-model="payment.billing.email" @input="validateEmail()"/>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="mt-3 caution-message">
                  <div>
                    ※ 編集を実行した当月の請求書（当月の翌々月第二営業日発行分）への反映となります。当月以前の請求書への反映は致しません。
                  </div>
                  <div>
                    ※ こちらの項目の変更は、以下の各情報に反映されます。
                  </div>
                  <div class="text-indent-1">
                    ・請求書原本に記載される宛名情報
                  </div>
                  <div class="text-indent-1">
                    ・請求関連でご連絡させていただく際のご担当者様情報（及びメールアドレス）
                  </div>
                  <div>
                    ※ 請求書のweb発行及び、請求書発行の通知先メールアドレスの変更は、下記のWeb帳票サービスにログインの上、修正ください。
                  </div>
                  <div class="text-indent-2">
                    <a href="https://invoice.nhn-techorus.com/nhn-techorus/"> https://invoice.nhn-techorus.com/nhn-techorus/</a>
                  </div>
                  <div class="text-indent-1">
                    詳しくは下記もご参考ください。
                  </div>
                  <div class="text-indent-2">
                    <a href="https://nhn-techorus.zendesk.com/hc/ja/articles/360049269473">https://nhn-techorus.zendesk.com/hc/ja/articles/360049269473</a>
                  </div>
                  <div>
                    ※ 「楽楽明細の通知メールアドレス」とは連動しておりません。
                  </div>
                  <div class="text-indent-1">
                    「楽楽明細の通知メールアドレス」の変更は、以下のwebサイトより変更をお願いいたします。
                  </div>
                  <div class="text-indent-2">
                    <a href="https://invoice.nhn-techorus.com/nhn-techorus/">https://invoice.nhn-techorus.com/nhn-techorus/</a>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import ServiceAccountMethods from "../../../components/service_account_methods.vue";
import { pref } from "../../../utils/constants";
import { getAddress } from "../../../lib/axios/api_client";

const methods = ref(null)
let initialized = ref(false)
let payment = ref(
  {
    billing: {
      name: "",
      kana: "",
      department_name: "",
      postal_code: "",
      prefecture_code: "",
      city: "",
      street_address: "",
      building: "",
      phone: "",
      email: ""
    },
    permissions: {
      show_edit_button: false,
    }
  }
)
const mailAddressRegex = /^[a-zA-Z0-9.!#$%&*+\/=?^_`{|}~-]{1,46}@[\.a-zA-Z0-9-]+\.[a-zA-Z]+$/;
const domainRegex = /^(?=.{1,100}$)(?=.{1,46}@).+@.+\.[a-zA-Z]{2,}$/;
const kanaRegex = /(?=.*?[\u30A1-\u30FC])[\u30A1-\u30FC\s]*/
const postalRegex = /^(?:\d{3}-\d{4}|\d{7})$/

const getPayment = async () => {
  const result = await methods.value?.getPayment()
  if (!result) {
    return false
  }
  return result
}

const findAddress = async () => {
  if (7 <= payment.value.billing.postal_code.length) {
    const res = await getAddress(payment.value.billing.postal_code)
    if (!res.successful) {
      return false
    }
    payment.value.billing.prefecture_code = res.data.prefecture_code
    payment.value.billing.city = res.data.city
    payment.value.billing.street_address = res.data.town

  }
}

const validatePrefecture = () => {
  let form = document.forms["payment-form"]
  form.elements["contact.prefecture_code"].setCustomValidity("")
  if (form.elements["contact.prefecture_code"].value === "選択してください") {
    form.elements["contact.prefecture_code"].setCustomValidity("都道府県を選択してください。")
  }
}

const validateDepartmentNameByte = () => {
  let form = document.forms["payment-form"]
  form.elements["department_name"].setCustomValidity("")
  if (52 < encodeURI(form.elements["department_name"].value).replace(/%../g, "*").length) {
    form.elements["department_name"].setCustomValidity("部署名の入力形式が正しくありません。")
  }
}

const validateEmail = () => {
  let form = document.forms["payment-form"]
  form.elements["mailAddress"].setCustomValidity("")
  if (form.elements["mailAddress"].value.length === 0) {
    form.elements["mailAddress"].setCustomValidity("メールアドレスを入力してください。")
  } else if(!mailAddressRegex.test(form.elements["mailAddress"].value) || !domainRegex.test(form.elements["mailAddress"].value)) {
    form.elements["mailAddress"].setCustomValidity("メールアドレスの入力形式が正しくありません。")
  }
}

const validateName = () => {
  let form = document.forms["payment-form"]
  form.elements["name"].setCustomValidity("")
  if (form.elements["name"].value === "") {
    form.elements["name"].setCustomValidity("ご担当者名を入力してください。")
  } else if (3 > form.elements["name"].value.length) {
    form.elements["name"].setCustomValidity("ご担当者様の入力形式が正しくありません。")
  }
}

const validateNameKana = () => {
  let form = document.forms["payment-form"]
  form.elements["nameKana"].setCustomValidity("")
  if (form.elements["nameKana"].value === "") {
    form.elements["nameKana"].setCustomValidity("ご担当者名(フリガナ)を入力してください。")
  } else if (!kanaRegex.test(form.elements["nameKana"].value)) {
    form.elements["nameKana"].setCustomValidity("ご担当者様(フリガナ)の入力形式が正しくありません。")
  }
}

const validatePostalCode = () => {
  let form = document.forms["payment-form"]
  form.elements["contact.postal_code"].setCustomValidity("")
  if (form.elements["contact.postal_code"].value === "") {
    form.elements["contact.postal_code"].setCustomValidity("郵便番号を入力してください。")
  } else if (!postalRegex.test(form.elements["contact.postal_code"].value)) {
    form.elements["contact.postal_code"].setCustomValidity("郵便番号の入力形式が正しくありません。")
  }
}

const validateCity = () => {
  let form = document.forms["payment-form"]
  form.elements["city"].setCustomValidity("")
  if (form.elements["city"].value === "") {
    form.elements["city"].setCustomValidity("市区町村を入力してください。")
  }
}

const validateAddress = () => {
  let form = document.forms["payment-form"]
  form.elements["streetAddress"].setCustomValidity("")
  if (form.elements["streetAddress"].value === "") {
    form.elements["streetAddress"].setCustomValidity("町名・番地を入力してください。")
  }
}

const validatePhone = () => {
  let form = document.forms["payment-form"]
  form.elements["phone"].setCustomValidity("")
  if (form.elements["phone"].value === "") {
    form.elements["phone"].setCustomValidity("電話番号を入力してください。")
  }
}

const updatePayment = async () => {
  const param = { billing: payment.value.billing}
  const result = await methods.value?.updatePayment(param)
  if (!result) {
    return false
  }
  setTimeout(_transition, 1000)
  return true
}

const _transition = () => {
  window.location.href = "/mypage/service-account/payment/"
}

onMounted(async () => {
  const result = await getPayment()
  if(result){
    payment.value = result
    initialized.value = true
  }
})

</script>
<style scoped>
.table > tbody > tr > th {
  vertical-align: middle;
}
.caution-message {
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: rgb(136,136,136)
}
.text-indent-1 {
  text-indent: 14px;
}
.text-indent-2 {
  text-indent: 28px;
}

</style>

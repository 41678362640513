<template>
  <div>
    <SimulatorMethods ref="methods"/>
    <ReportMethods ref="reportMethods" :reportType="reportType"/>
    <div v-if="!initialized">
      <div class="loader">Loading...</div>
    </div>
    <div v-else>
      <div>
        <template v-if="isScenarioEdit">
          <a class="btn btn-primary" :href="`/mypage/recommend/simulator/scenario/${scenarioId}/edit`" >編集</a>
        </template>
        <button @click="deleteScenario" type="button" class="btn btn-primary">削除</button>
        <template v-if="isScenarioFinish">
          <button @click="downloadPdf" type="button" :class="`btn btn-primary${isScenarioPdfDownload ? '' : ' disabled'}`">DL(PDF)</button>
        </template>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">推薦</h4>
              <span>
                推薦の結果を確認することができます
              </span>
              <p v-if="isScenarioFinish">
                リスクヘッジをすることで節約額を増やせるようにあわせて3つのパターン、(低、中、高)があります<br>
                ※ 使用状況により同じ結果になることがあります。（PDFには重複したデータが記載されます）<br>
                ※ リスクとは、購入した{{ displayText }}の使用率が低下する可能性があること<br>
              </p>
              <template v-if="isScenarioNoResult">
                <div class="text-left text-danger font-weight-bold">
                  本シナリオでは購入を推薦する結果はありませんでした。
                </div>
              </template>
            </div>
            <div class="card-body">
              <template v-if="isScenarioFinish">
                <div>
                  <h4>購入を推薦する{{ displayTitle }}</h4>
                  <div>
                    <h4>サマリー</h4>
                      <TableRecommendSimulatorScenarioSummaryRiSp
                          :tableItem="scenarioSummaryDashboardItem"
                          :type="displayTitle"
                      />
                  </div>
                </div>
                <div class="card">
                  <div class="card-body">
                    <ul class="tabs">
                      <template v-for="risk in riskLevel">
                        <li v-on:click="changeIsActive(risk.key)" v-bind:class="{'active': isActive === risk.key}">{{ risk.value }}</li>
                      </template>
                    </ul>
                    <ul class="contents">
                     <li>
                       <div>
                         <h4 class="list-title">推薦リスト</h4>
                         <template v-for="risk in riskLevel">
                           <div :class="{'display-none': isActive !== risk.key}">
                             <template v-if="scenario.ri_sp === 'RI'">
                               <TableRecommendSimulatorResultRi
                                 :scenarioId="scenarioId"
                                 :riskLevel="risk.api_key"
                               />
                             </template>
                             <template v-else>
                               <TableRecommendSimulatorResultSp
                                 :scenarioId="scenarioId"
                                 :riskLevel="risk.api_key"
                               />
                             </template>
                           </div>
                         </template>
                         <p>
                           ※ 利用中のリソースを最適化できる場合があります。最適化により購入対象が変更となる場合があるため、検討の上購入されることをおすすめします。最適化できるリソースの有無は<a :href="pagePath.dashboard.rightsizing_ec2">rightsizing</a>で確認することができます。
                         </p>
                         <p>
                           ※ 購入タイミングについては、期限の管理がしやすく、効率的であることを考慮してください。具体的には、期限切れの対象となるRI/SPの中で、最も遅い終了日の翌日を推奨します。
                         </p>
                       </div>
                       <div>
                         <p>
                           グラフは以下の条件で生成しています。<br>
                           ・前払い料金は償却せず支払い月に加算する <br>
                           ・推薦したRI/SPを全て合算したものとなります <br>
                         </p>
                         <template v-for="risk in riskLevel">
                           <div :class="{'display-none': isActive !== risk.key}">
                             <iframe id="targetIframe" :src="scenarioDashboard.simulator_cost_comparison[risk.key]" height="600" width="100%"></iframe>
                           </div>
                         </template>
                       </div>
                     </li>
                    </ul>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="text-center text-danger font-weight-bold">
                  <p>実行待機中</p>
                  <p>※ 実行は{{ displayFormat(scenario.simulator_scheduled_date)}}を予定です。</p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">シナリオ</h4>
            </div>
            <div class="card-body">
              <table class="table table-borderless table-responsive">
                <tbody>
                  <tr>
                    <th>推薦する種類</th>
                    <td>{{ scenario.recommend_type }}</td>
                  </tr>
                  <tr>
                    <th>支払い期間</th>
                    <td>{{ scenario.payment_term }}年</td>
                  </tr>
                  <tr>
                    <th>支払い方法</th>
                    <td>{{ scenario.payment_option }}</td>
                  </tr>
                  <tr>
                    <th>実行予定日</th>
                    <td>{{ displayFormat(scenario.simulator_scheduled_date) }}</td>
                  </tr>
                  <tr>
                    <th>算出するための分析日数</th>
                    <td>{{ scenario.analysis_term }} ({{ displayFormat(scenario.analysis_term_start_date)}} ~ {{ displayFormat(scenario.analysis_term_end_date) }} の利用料が対象)</td>
                  </tr>
                  <tr>
                    <th>期限切れの対象となるRI/SP</th>
                  </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="pull-right">
                    <DropdownDownload reportType="expire_ri_sp_list" :tableItem="scenario.expire_ri_sp" :keys="expireRiSpListKeys()" :titles="expireRiSpListTitles()"/>
                  </div>
                </div>
              </div>
              <div class="right-sizing-table" style="overflow: scroll">
                <table id="scenario-list" class="table table-striped table-hover text-nowrap">
                  <thead>
                    <tr>
                      <template v-for="header in expireRiSpTh">
                        <TableTh :tableKey="header.key" v-on:emitTrigger="doSort">{{ header.title }}</TableTh>
                      </template>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="Object.keys(scenario.expire_ri_sp).length">
                      <template v-for="item in scenario.expire_ri_sp">
                        <tr>
                          <td>
                            <TooltipText :text="item.aws_account_id" :tooltipText="item.account_name"/>
                          </td>
                          <td>{{ item.ri_sp }}</td>
                          <td>{{ item.ri_sp_id }}</td>
                          <td>{{ item.end_date}}</td>
                          <td>{{ item.offering_type }}</td>
                          <td>{{ item.service }}</td>
                          <td>{{ item.instance_info }}</td>
                          <td>{{ item.platform }}</td>
                          <td>{{ item.region }}</td>
                          <td>{{ item.availability_zone }}</td>
                          <td>{{ item.scope }}</td>
                          <td>{{ item.resource_number }}</td>
                          <td>{{ item.on_demand_cost_equivalent }}</td>
                          <td>{{ item.net_savings }}</td>
                        </tr>
                      </template>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="14">
                          設定はありません
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import SimulatorMethods from "../../../../components/recommend_simulator_methods.vue";
import { getDashboardElementScenariosSummaryDashboard } from "../../../../lib/axios/api_client";
import { extractId } from "../../../../lib/common";
import { pagePath, riSimulationSummary, spSimulationSummary } from "../../../../utils/constants";
import { compareDesc, format, parse } from "date-fns";
import { ja } from "date-fns/locale";
import TableTh from "../../../../components/table_th.vue";
import TableRecommendSimulatorResultRi from "../../../../components/table_recommend_simulator_result_ri.vue"
import TableRecommendSimulatorResultSp from "../../../../components/table_recommend_simulator_result_sp.vue"
import TableRecommendSimulatorScenarioSummaryRiSp from "../../../../components/table_recommend_simulator_scenario_summary_ri_sp.vue"
import TooltipText from "../../../../components/tooltip_text.vue";
import DropdownDownload from '../../../../components/dropdown_download.vue'
import { findTableHeaderIndex, selectSort } from "../../../../lib/table";
import { transformDate } from "../../../../lib/calendar";
import ReportMethods from "../../../../components/report_methods.vue";

const expireRiSpTh = ref( [
  { title: "連結アカウント",                key: "aws_account_id",            sort: false },
  { title: "RI/SP",                       key: "ri_sp",                     sort: false },
  { title: "ID",                          key: "ri_sp_id",                  sort: false },
  { title: "終了日",                       key: "end_date",                  sort: false },
  { title: "タイプ",                       key: "offering_type",             sort: false },
  { title: "サービス",                      key: "service",                   sort: false },
  { title: "インスタンス情報",               key: "instance_info",             sort: false },
  { title: "プラットフォーム",               key: "platform",                   sort: false },
  { title: "リージョン",                    key: "region",                     sort: false },
  { title: "アベイラビリティゾーン",          key: "availability_zone",          sort: false },
  { title: "スコープ",                      key: "scope",                     sort: false },
  { title: "リソース数",                    key: "resource_number",            sort: false },
  { title: "推定オンデマンド費用同等額($/月)", key: "on_demand_cost_equivalent", sort: false },
  { title: "推定節約額($/月)",              key: "net_savings",               sort: false }
])

const methods = ref(null)
const reportMethods = ref(null)
const scenarioId = extractId(5)

let scenario = ref({})
let scenarioDashboard = ref({})
let scenarioSummaryDashboardItem = ref([])
let currentAwsAccountId = ref("")
let displayText = "";
let displayTitle = "";
let reportType = ref ("")
let showOtherTab = ref(0)
let riskLevel = [
  {
    key: "low",
    value: "低",
    api_key: "Low"
  },
  {
    key: "medium",
    value: "中",
    api_key: "Medium"
  },
  {
    key: "high",
    value: "高",
    api_key: "High"
  },
]

let isActive = ref("medium")
let isScenarioEdit = ref(true)
let isScenarioFinish = ref(true)
let isScenarioNoResult = ref(false)
let isScenarioPdfDownload = ref(false)
let initialized = ref(false)

const getScenario = async (id) => {
  const result = await methods.value?.getScenario(id)
  if (!result) {
    return false
  }
  return result.scenarios
}

const getScenarioDashboard = async (id) => {
  const result = await methods.value?.getScenarioDashboard(id)
  if (!result) {
    return false
  }
  return result
}

const deleteScenario = async () => {
  if (!window.confirm("本当に削除しますか？")) return;
  const result = await methods.value.deleteScenario(scenarioId);
  if(!result){
    return false
  }
  setTimeout(transition,1000);
}

const downloadAvailable = async () => {
  const result = await methods.value.getDownloadDetail(scenarioId, currentAwsAccountId.value);
  isScenarioPdfDownload.value = (result.filepath !== null)
}

const downloadPdf = async () => {
    await methods.value.downloadScenarioPdf(scenarioId, currentAwsAccountId.value)
}

const transition = () => {
  window.location.href = pagePath.recommendSimulatorScenario.index
}

const displayFormat = (date) => {
  return format(
    parse(date, 'yyyy-MM-dd', new Date()),
    'yyyy年MM月dd日',
    { locale: ja }
  )
}

const doSort = (columnKey) => {
  const index = findTableHeaderIndex(expireRiSpTh.value, columnKey)
  const isSort = expireRiSpTh.value[index].sort
  const sort = selectSort(isSort, columnKey);

  scenario.value.expire_ri_sp.sort(sort)
  expireRiSpTh.value[index].sort = !isSort
}

const judgeIsScenarioEdit = (target) => {
  const targetDate = transformDate(target)
  const now = new Date()
  const nowDateMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0,0,0)
  return compareDesc(nowDateMidnight, targetDate) === 1
}

const changeIsActive = (riskKey) => {
  if ((showOtherTab.value === 0) || (showOtherTab.value === 2 && riskKey !== 'medium') || (showOtherTab.value === 3 && riskKey !== 'high')) {
    isActive.value = riskKey
  }
}
const sortTableItemFromLow = () => {
  let sortTableItem = [
    scenarioSummaryDashboardItem.value.find( item => item.dimension === '低'),
    scenarioSummaryDashboardItem.value.find( item => item.dimension === '中'),
    scenarioSummaryDashboardItem.value.find( item => item.dimension === '高')
  ]
  scenarioSummaryDashboardItem.value = sortTableItem
}

const formatTableItem = () => {
  let on_demands = scenarioSummaryDashboardItem.value.map( item => item.table_calculation_1)
  let costs = scenarioSummaryDashboardItem.value.map( item => item.reserved__)
  let calculations = scenarioSummaryDashboardItem.value.map( item => item.table_calculation)
  if (judgeSameAll(on_demands) && judgeSameAll(costs) && judgeSameAll(calculations)) {
    scenarioSummaryDashboardItem.value = scenarioSummaryDashboardItem.value.map( item => bindBlank(item, '低'))
    return 1
  } else if (judgeSameLowMedium(on_demands) && judgeSameLowMedium(costs) && judgeSameLowMedium(calculations)) {
    scenarioSummaryDashboardItem.value = scenarioSummaryDashboardItem.value.map( item => bindBlank(item, '中'))
    return 2
  } else if (judgeSameMediumHigh(on_demands) && judgeSameMediumHigh(costs) && judgeSameMediumHigh(calculations)) {
    scenarioSummaryDashboardItem.value = scenarioSummaryDashboardItem.value.map( item => bindBlank(item, '高'))
    return 3
  } else {
    return 0
  }
}

const judgeSameAll = (array) => {
  return array.every(val => val === array[0])
}

const judgeSameLowMedium = (array) => {
  return array[0] === array[1]
}

const judgeSameMediumHigh = (array) => {
  return array[1] === array[2]
}

const bindBlank = (item, level) => {
  if (level === '低') {
    if (item.dimension !== level) {
      item.table_calculation_1 = '-'
      item.reserved__ = '-'
      item.table_calculation = '-'
    }
  } else {
    if (item.dimension === level) {
      item.table_calculation_1 = '-'
      item.reserved__ = '-'
      item.table_calculation = '-'
    }
  }
  return item
}

const checkDisableTab = (no) => {
  if (no === 0) return
  if (no === 1 || no === 2) isActive.value = 'low'
  showOtherTab.value = no
}

const expireRiSpListKeys = () => {
  return expireRiSpTh.value.map(item => item.key)
}

const expireRiSpListTitles = () => {
  return expireRiSpTh.value.map(item => item.title)
}

const getOfferingClass = (recommendType) => {
  return recommendType.includes('convertible')? "convertible" : "standard"
}

onMounted(async () => {
  const result = await getScenario(scenarioId)
  if(!result){
      return false
  }
  scenario.value = result
  currentAwsAccountId.value = result.expire_ri_sp[0].aws_account_id
  displayText = result.ri_sp === 'RI' ? "Reserved Instance" : "Savings Plans"
  displayTitle = result.ri_sp === 'RI' ? "Reserved" : "Savings Plans"
  reportType.value = result.ri_sp === 'RI' ? riSimulationSummary : spSimulationSummary
  isScenarioFinish.value = (result.status === 'finish' || result.status === 'no_result')
  isScenarioNoResult.value = (result.status === 'no_result')
  isScenarioEdit.value = judgeIsScenarioEdit(result.simulator_scheduled_date)

  if(isScenarioFinish.value) {
    // Looker
    const dashboard = await getScenarioDashboard(scenarioId)
    if(!dashboard){
      return false
    }
    scenarioDashboard.value = dashboard


    // 自作Table
    const reportTypeParams = await reportMethods.value?.getReport(0, reportType.value)
    if(!reportTypeParams){
      return false
    }
    reportTypeParams.filter.scenario_id.values = [scenario.value.id]
    reportTypeParams.filter.term.values = [scenario.value.payment_term]
    reportTypeParams.filter.payment_option.values = [scenario.value.payment_option]
    reportTypeParams.filter.offering_class.values = [getOfferingClass(scenario.value.recommend_type)]

    const dashboardItem = await getDashboardElementScenariosSummaryDashboard(reportTypeParams)
    if (!dashboardItem) {
      return false
    }
    scenarioSummaryDashboardItem.value = dashboardItem.data.filter_item
    if (scenarioSummaryDashboardItem.value.length) {
      sortTableItemFromLow()
      checkDisableTab(formatTableItem())
    } else {
      isScenarioNoResult.value = true
    }

    await downloadAvailable()
  }

  initialized.value = true
})

</script>
<style scoped>
.table>tbody>tr>th,
.table>tbody>tr>td {
  vertical-align: top;
  padding: 12px 8px;
  border-color: transparent;
}

.table.table-borderless.table-responsive {
  margin-bottom: -3rem;
}

.display-none {
  display: none;
}

ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.tabs {
  overflow: hidden;
}
.tabs li,
.tabs label {
  float: left;
  padding: 10px 20px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: .3s;
}
.tabs li:not(:first-child),
.tabs label:not(:first-of-type) {
  border-left: none;
}
.tabs li.active,
.tabs :checked + label {
  background-color: #002341;
  border-color: #000;
  color: #fff;
  cursor: auto;
}
.contents {
  overflow: hidden;
  margin-top: -1px;
}
.contents li {
  padding: 20px;
  border: 1px solid #ccc;
}

.jquery .contents li {
  display: none;
}
.jquery .contents li.active {
  display: block;
}

.vue_radio input {
  display: none;
}

.list-title {
  margin-bottom: -2.5rem;
}
</style>

<template>
  <input
    type="file"
    id="inputCsv"
    accept="text/csv"
    class="visually-hidden"
    @change="onFileChange"
  />
  <label for="inputCsv" class="mb-0">{{ fileName }}</label>
</template>
<script>
export default {
  data() {
    return {
      fileName: "CSVをアップロードしてください。",
      result: []
    }
  },
  methods: {
    onFileChange(e) {
      const selectedFile = document.getElementById("inputCsv").files[0];
      this.fileName = selectedFile.name

      //FileReaderの準備
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        //ファイルから読み取った文字列を変数resultに格納
        const { result } = reader;

        //改行コードで区切って行の配列を作成
        const tmp = result.split("\n")

        //行ごとにカンマで区切り、２次元配列を作成
        this.result = tmp.map(line => line.split(','))
      });

      //選択されたCSVを読み込み
      reader.readAsText(selectedFile)
    }
  }
}
</script>
<style>

</style>

<template>
  <div>
    <Alert ref="alert"/>
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="card">
          <div class="card-header card-header-rose card-header-icon d-block">
            <div class="card-title">
              <h4 class="d-inline-block">フィルター</h4>
            </div>
          </div>
          <div class="card-body pt-0">
            <div v-for="item in columnList">
              <template v-if="item.type === 'single'">
                <FilterItemSingleSelect
                  :filterKey="item.key"
                  :reportType="reportType"
                  :reportFilter="reportFilter"
                  v-on:emitFilterItem="emitFilterItem"
                >
                  {{ item.title }}
                </FilterItemSingleSelect>
              </template>
              <template v-else>
                <FilterItemMultiSelect
                  :filterKey="item.key"
                  :reportType="reportType"
                  :reportFilter="reportFilter"
                  :condition="item.condition"
                  v-on:emitFilterItem="emitFilterItem"
                >
                  {{ item.title }}
                </FilterItemMultiSelect>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="card">
          <div class="card-header card-header-rose card-header-icon">
            <h4 class="card-title">選択したフィルター</h4>
          </div>
          <div class="card-body">
            <div class="scrollable-selected-menu">
              <template v-for="item in filterSelected">
                <div class="mb-3">
                  <span>{{ item.title }}:</span>
                  <template v-for="value in item.value">
                    <div>{{ value }}</div>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FilterItemMultiSelect from "./filter_item_multi_select.vue"
import FilterItemSingleSelect from "./filter_item_single_select.vue"
import { budget } from "../utils/filter_column_list";
import Alert from "./alert_new.vue";

export default {
  props: ['reportType', 'reportFilter'],
  components: {
    FilterItemMultiSelect,
    FilterItemSingleSelect,
    Alert
  },
  computed: {
    filterSelected() {
      let list = []
      if (this.columnList.length === 0) {
        return list
      }
      for (const item of this.columnList) {
        if (item.key in this.reportFilter && this.reportFilter[item.key].values.length > 0) {
          const params = {'title': item.title, 'value': this.reportFilter[item.key].values}
          list.push(params)
        }
      }
      return list
    }
  },
  data: function () {
    return {
      edited: false,
      columnList: budget
    }
  },
  methods: {
    emitFilterItem(params) {
      this.edited = true
      this.$emit('updateFilter', params)
    }
  }
}
</script>
<style scoped>
</style>

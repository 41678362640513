<template>
    <span class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" v-bind:id="dropdownId" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ dropdownTitle }}
        </button>
        <div class="dropdown-menu" v-bind:aria-labelledby="dropdownId">
            <span v-for="item in dropdownItem" class="dropdown-item cursor-pointer" type="button" v-on:click="triggerEvent(item.value)">{{ item.name }}</span>
        </div>
    </span>
</template>
<script>
export default {
  props: {
    type: String,
    dropdownId: String,
    dropdownItem: Array,
    item: String,
  },
  emit: [
      'update:item'
  ],
  computed: {
    dropdownTitle() {
      let title = this.dropdownItem.find(item => item.value === this.item)
      return title.name
    }
  },
  methods: {
    triggerEvent(value) {
      this.$emit('updateReport', this.type, value)
    }
  }
}
</script>
<template>
  <div v-if="!initialized">
    <Alert ref="alert"/>
    <div class="loader">Loading...</div>
  </div>
  <div v-else>
    <Alert ref="alert"/>
    <form class="card" @submit.prevent="createReportSubscription()">
      <div class="card-body">
        <div style="display: flex; justify-content: space-between;">
          <div class="text-left">
            <button :class="`btn btn-primary repeated_hit_prevention ${ disabled ? 'disabled' : ''}`" type="submit">保存</button>
          </div>
          <div class="text-right">
            <a class="btn default" href="/mypage/report_subscriptions">一覧</a>
          </div>
        </div>
        <table class="table table-borderless mt-5" v-if="initialized">
          <tbody>
          <tr>
            <th>subscription種類</th>
            <td>
              <select class="form-control" v-model="reportType">
                <template v-for="(type) in reportTypes">
                  <option :value="type.value">
                    {{ type.name }}
                  </option>
                </template>
              </select>
            </td>
          </tr>
          </tbody>
        </table>
        <template v-if="reportType === 'cost'">
          <ReportSubscriptionCostForm ref="costForm" :reportSubscription="reportCostSubscription" v-model:disabled="disabled"/>
        </template>
        <template v-else>
          <ReportSubscriptionBudgetForm ref="budgetForm" :reportSubscription="reportBudgetSubscription" v-model:disabled="disabled"/>
        </template>
      </div>
    </form>
  </div>
</template>
<script>

import * as constants from '../utils/report_subscriptions'
import { format } from 'date-fns'
import FormEndDate from '../components/report_subscription_form_end_date.vue'
import {createReportSubscriptions} from "../lib/axios/api_client";
import Alert from "../components/alert_new.vue";
import {pagePath} from "../utils/constants";
import ReportSubscriptionCostForm from "../components/report_subscription_cost_form.vue"
import ReportSubscriptionBudgetForm from "../components/report_subscription_budget_form.vue"
import {changeOrderEmails} from "../lib/common";
import {reportBudgetSubscription, reportCostSubscription} from "../utils/report_subscriptions";

export default {
  mounted() {
    this.initializeTemplate()
    this.initialized = true
  },
  components: {
    ReportSubscriptionCostForm: ReportSubscriptionCostForm,
    ReportSubscriptionBudgetForm: ReportSubscriptionBudgetForm,
    FormEndDate: FormEndDate,
    Alert: Alert
  },
  data: function() {
    return {
      initialized: false,
      reportCostSubscription: constants.reportCostSubscription,
      reportBudgetSubscription: constants.reportBudgetSubscription,
      reportType: "cost",
      reportTypes: constants.reportTypes,
      path: pagePath.reportSubscription.index,
      disabled: false
    }
  },
  methods: {
    initializeTemplate(){
      this.$nextTick(async () => {
        const dateNow = new Date
        const date3MonthLater = new Date(dateNow.getFullYear(), dateNow.getMonth() + 3, dateNow.getDate() )
        this.reportCostSubscription.end_date = format(date3MonthLater, 'yyyy-MM-dd')
      })
    },
    createReportSubscription: async function(){
      if(this.disabled){ return false }

      this.disabled = true

      let form = this.getRefForm()
      form.emails = changeOrderEmails(form.emails)

      const res = await createReportSubscriptions(form, this.reportType)
      if(!res.successful){
        this.disabled = false
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }
      this.$refs.alert.displayModal("成功しました", 'success')
      setTimeout(this._transition, 1000)
    },
    getRefForm(){
      if (this.reportType === 'cost') {
        return this.$refs.costForm.form
      } else {
        return this.$refs.budgetForm.form
      }
    },
    _transition(){
      window.location.href = this.path
    },
  }
}
</script>

<template>
  <div v-if="!initialized">
    <Alert ref="alert"/>
  </div>
  <div v-else>
    <div class="card">
      <Alert ref="alert"/>
      <div class="card-header">
        <div style="display: flex; justify-content: space-between;">
          <div class="text-left">
            <div class="card-title">ダウンロード</div>
          </div>
          <div class="text-right">
            <a class="btn default" href="/mypage/report_subscriptions">一覧</a>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="d-flex justify-content-center">
          <div>
            以下のボタンを押下することにより、
            <template v-if="reportType === 'cost'">
              {{ year }}年{{ month }}月分の
            </template>
            <template v-else>
              <template v-if="Object.keys(reportDownload).length !== 0">
                {{ formatDate(reportDownload.publication_date) }}時点の
              </template>
            </template>
            Subscriptionをダウンロードすることができます。
          </div>
        </div>
        <div class="d-flex justify-content-center my-5">
          <template v-if="disabled">
            <span class="text-secondary cursor-pointer">
              <span class="text-danger font-weight-bold">{{ messages.show }}</span>
            </span>
          </template>
          <template v-else>
            <span class="text-secondary cursor-pointer"  v-on:click="download">
              <i class="material-icons" style="font-size: 100px">download</i>
            </span>
          </template>
        </div>
        <div class="d-flex justify-content-start">
          <div class="text-danger font-weight-bold">
            <template v-if="Object.keys(reportDownload).length !== 0">
              <template v-if="reportType === 'cost'">
                <p>
                  ダウンロードの有効期限は当月中となっています。
                </p>
                <p>
                  {{ nextYear }}年{{ nextMonth }}月01日になると{{ year }}年{{ month }}月分はダウンロードできなくなります。
                </p>
              </template>
              <template v-else>
                <template v-if="isMonthly">
                  <p>
                    ダウンロードの有効期限は配信より１ヶ月となっています。
                  </p>
                </template>
                <template v-else>
                  <p>
                    ダウンロードの有効期限は配信より１週間となっています。
                  </p>
                </template>
                <p>
                  {{ formatDate(reportDownload.expire_date) }}になると{{ formatDate(reportDownload.publication_date) }}時点のものはダウンロードできなくなります。
                </p>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {format, addMonths, addWeeks, parse} from "date-fns";
import Alert from "../components/alert_new.vue";
import {getDownloadFileInfoReportSubscriptions, getPdfReportSubscriptions} from "../lib/axios/api_client";
import {downloadPdf} from "../lib/download_action";

export default {
  components: {
    Alert: Alert,
  },
  data() {
    return {
      reportDownload: {},
      reportType: "",
      isMonthly: false,
      disabled: false,
      messages: {
        show: "",
        noPdf: "PDFが存在していません。",
        expireDate: "PDFの有効期限が切れています。"
      },
      initialized: false
    }
  },
  computed: {
    year() {
      return format(new Date, 'yyyy')
    },
    month() {
      return format(new Date, 'MM')
    },
    nextYear(){
      const nextMonth = addMonths(new Date, 1)
      return format(nextMonth, 'yyyy')
    },
    nextMonth(){
      const nextMonth = addMonths(new Date, 1)
      return format(nextMonth, 'MM')
    }
  },
  mounted: async function(){
    const result = await this.getDownloadFileInfo()
    // エラーの場合initializeしない
    if(result === 'error') return
    if (result === 'ok') {
      // データが存在する時にバリデーション
      this.validateExpireDate()
      if (this.reportType === 'budget') {
        this.checkBudgetType()
      }
    } else if (result === 'ng') {
      // データが存在しない時
      this.messages.show = this.messages.noPdf
      this.disabled = true
    }
    this.initialized = true
  },
  methods: {
    formatDate(dateTime){
      let date = dateTime.substring(0, dateTime.indexOf('T'))
      let splitYmd = date.split('-')
      return splitYmd[0] + '年' + splitYmd[1] + '月' + splitYmd[2] + '日'
    },
    validateExpireDate(){
      const parsedDate = parse(this.reportDownload.expire_date, 'yyyy-MM-dd\'T\'HH:mm:ss', new Date())
      if(parsedDate < new Date()){
        this.messages.show = this.messages.expireDate
        this.disabled = true
      }
    },
    checkBudgetType(){
      const parsedExpireDate = parse(this.reportDownload.expire_date, 'yyyy-MM-dd\'T\'HH:mm:ss', new Date())
      const parsedPublicationDate = parse(this.reportDownload.publication_date, 'yyyy-MM-dd\'T\'HH:mm:ss', new Date())
      const publicationDateNextWeek = addWeeks(parsedPublicationDate, 1)
      if(publicationDateNextWeek < parsedExpireDate){
        this.isMonthly = true
      }
    },
    getDownloadFileInfo: async function(){
      const id = this._getId()
      const type = this._getType()
      this.reportType = type
      const res = await getDownloadFileInfoReportSubscriptions(id, type)
      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error')
        return 'error'
      }
      if (res.data.report_subscription_downloads === null) {
        return 'ng'
      } else {
        this.reportDownload = res.data.report_subscription_downloads
        return 'ok'
      }
    },
    download: async function(){
      const res = await getPdfReportSubscriptions(this.reportDownload.id, this.reportType)
      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error')
      }else{
        downloadPdf(res.data, this.reportDownload.filename)
      }
    },
    _getId(){
      const pathArray = location.pathname.split("/")
      if(pathArray[4] === "undefined" ){
        return false
      }
      return pathArray[4]
    },
    _getType(){
      const pathArray = location.pathname.split("/")
      return pathArray[3]
    }
  }
}
</script>
<style scoped>
</style>
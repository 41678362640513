import { TsBaseClient } from "../ts_base_client";
import { ExBaseClient } from "../ex_base_client";
export class ApiClient extends TsBaseClient {
    constructor() {
        super();
    }
    async getRsaSessionKey(ip, type) {
        const url = `/rsa-session-key?ip=${ip}&type=${type}`;
        return await this.get(url);
    }
}
export class ExApiClient extends ExBaseClient {
    async getIpAddress() {
        const url = 'https://checkip.amazonaws.com/';
        return await this.get(url);
    }
}

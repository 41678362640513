
<template>
  <Alert ref="alert"/>
</template>
<script setup>
import { ref } from "vue";
import Alert from "./alert_new.vue";
import { ApiClient } from "../lib/axios/serviceaccount/api_client";

const client = new ApiClient()
const alert = ref(null)

const getContract = async () => {
  const res = await client.getContract();
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data
}

const updateContract = async function(form){
  const res = await client.updateContract(form)
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }
  alert.value.displayModal("成功しました", 'success')
  return true
}

const updateContractStatusFromFreeToFormal = async function(){
  const res = await client.updateContractStatusFromFreeToFormal()
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }
  alert.value.displayModal("成功しました", 'success')
  return true
}

const getContractor = async () => {
  const res = await client.getContractor();
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data
}

const updateContractor = async function(form){
  const res = await client.updateContractor(form)
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }
  alert.value.displayModal("成功しました", 'success')
  return true
}

const getPayment = async () => {
  const res = await client.getPayment();
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data
}

const updatePayment = async function(form){
  const res = await client.updatePayment(form)
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }
  alert.value.displayModal("成功しました", 'success')
  return true
}

const getCpSignupUrl = async function(){
  const res = await client.getCpSignupUrl()
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }
  return res.data
}

const getQuit = async function(form){
  const res = await client.getQuit(form)
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }
  return res.data
}

const quitRequest = async function(form){
  const res = await client.requestQuit(form)
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }
  return true
}

defineExpose({
  getContract,
  getPayment,
  getContractor,
  updateContract,
  updateContractStatusFromFreeToFormal,
  updateContractor,
  updatePayment,
  getCpSignupUrl,
  getQuit,
  quitRequest
})
</script>

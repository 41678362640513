<template>
  <div>
    <Alert ref="alert"/>
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <h4 class="card-title">選択したフィルター</h4>
      </div>
      <div class="card-body">
        <div class="scrollable-selected-menu">
          <template v-for="item in filterSelected">
            <div class="mb-3">
              <span>{{ item.title }}:</span>
              <template v-for="value in item.value">
                <div>{{ value }}</div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header card-header-rose card-header-icon d-block">
        <div class="card-title">
          <h4 class="d-inline-block">フィルター</h4>
          <button :class="`btn btn-primary btn-sm cursor-pointer pull-right mt-0 ${ edited ? '' : ' disabled'}`" v-on:click.prevent="emitUpdateDashboard">適用</button>
        </div>
        <p class="category mb-0" v-show="edited">
          <span class="text-danger caution-text">反映するには「適用」をクリックしてください。</span>
        </p>
      </div>
      <div class="card-body pt-0">
        <template v-if="columnList.length > 0">
          <div v-for="item in columnList.slice(0,filterShowNum.now)">
            <template v-if="item.type === 'single'">
              <FilterItemSingleSelect
                  :filterKey="item.key"
                  :reportType="reportType"
                  :reportFilter="reportFilter"
                  v-on:emitFilterItem="emitFilterItem"
              >
                {{ getTagFormat(item) }}
              </FilterItemSingleSelect>
            </template>
            <template v-else>
              <FilterItemMultiSelect
                  :filterKey="item.key"
                  :reportType="reportType"
                  :reportFilter="reportFilter"
                  :condition="item.condition"
                  v-on:emitFilterItem="emitFilterItem"
              >
                {{ getTagFormat(item) }}
              </FilterItemMultiSelect>
            </template>
          </div>
          <template v-if="targetColumnListLength > filterShowNum.default">
            <a v-on:click="changeFilterShow" class="text-info cursor-pointer" role="button">
              <template v-if="filterShow">簡易表示</template>
              <template v-else>その他のフィルター</template>
            </a>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import FilterItemMultiSelect from "./filter_item_multi_select.vue"
import FilterItemSingleSelect from "./filter_item_single_select.vue"
import * as filterColumnList from "../utils/filter_column_list"
import Alert from "./alert_new.vue";
import {getIlluminatorTags} from "../lib/axios/api_client";

export default {
  props: ['reportType', 'reportFilter'],
  components: {
    'FilterItemMultiSelect': FilterItemMultiSelect,
    'FilterItemSingleSelect': FilterItemSingleSelect,
    'Alert': Alert
  },
  mounted: async function () {
    await this.getFilterColumns()
    await this.getTags();
  },
  computed: {
    targetColumnListLength() {
      return this.columnList.length
    },
    filterSelected() {
      let list = []
      if (this.columnList.length === 0) {
        return list
      }
      for (const item of this.columnList) {
        if (item.key in this.reportFilter && this.reportFilter[item.key].values.length > 0) {
          const title = this.reportFilter[item.key].condition === "include" ? `${item.title} (含む)` : `${item.title} (除く)`
          const params = {'title': title, 'value': this.reportFilter[item.key].values}
          list.push(params)
        }
      }
      return list
    }
  },
  data: function () {
    return {
      edited: false,
      columnList: [],
      filterShow: false,
      filterShowNum: {
        now: 7,
        default: 7
      },
      illuminatorTags: null,
      permissions:{}
    }
  },
  methods: {
    changeFilterShow() {
      this.filterShow = !this.filterShow
      this.filterShowNum.now = this.filterShow ? this.targetColumnListLength : this.filterShowNum.default
    },
    getFilterColumns: async function () {
      switch (this.reportType) {
        case "cost_explorer":
          this.columnList = filterColumnList.costExplorer;
          break
        case "ri_utilization_report":
          this.columnList = filterColumnList.riUtilizationReport;
          break;
        case "ri_coverage_report":
          this.columnList = filterColumnList.riCoverageReport
          break;
        case "ri_recommendation":
          this.columnList = filterColumnList.riRecommendation
          break;
        case "sp_utilization_report":
          this.columnList = filterColumnList.spUtilizationReport
          break;
        case "sp_coverage_report":
          this.columnList = filterColumnList.spCoverageReport
          break;
        case "sp_recommendation":
          this.columnList = filterColumnList.spRecommendation
          break;
        case "rightsizing_ec2":
          this.columnList = filterColumnList.rightsizingEc2
          break;
        case "rightsizing_ebs":
          this.columnList = filterColumnList.rightsizingEbs
          break;
        case "rightsizing_eip":
          this.columnList = filterColumnList.rightsizingEip
          break;
        case "rightsizing_elb":
          this.columnList = filterColumnList.rightsizingElb
          break;
        case "expire_ri_sp":
          this.columnList = filterColumnList.expireRiSp
          break;
      }
    },
    getTags: async function ()  {
      const illuminatorRes = await getIlluminatorTags()
      if (!illuminatorRes.successful) {
        alert.value.displayModal(illuminatorRes.message, 'error')
        return false
      }
      this.illuminatorTags = illuminatorRes.data.tags
      this.permissions = illuminatorRes.data.permissions
    },
    getTagFormat(column) {
      if (!this.illuminatorTags) {
        return column.title
      }

      if (this.permissions.show_tag_alias && column.extTitle) {
        const illuminatorTag = this.illuminatorTags.find((tag) => tag.tag_type === column.tag_type)
        if(illuminatorTag && illuminatorTag.tag_alias) {
          return `${column.extTitle} (${illuminatorTag.tag_alias})`
        } else {
          return column.extTitle
        }
      }

      return column.title
    },
    emitFilterItem(params) {
      this.edited = true
      this.$emit('updateFilter', params)
    },
    emitUpdateDashboard(){
      this.edited = false
      this.$emit('updateDashboard')
    }
  }
}
</script>
<style scoped>
.caution-text {
  font-size: 0.7rem
}
</style>

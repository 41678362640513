import { TsBaseClient, DOWNLOAD_PDF_HEADER } from "../../ts_base_client";
export class ApiClient extends TsBaseClient {
    constructor() {
        super();
    }
    // 予算関連
    async getScenarioList() {
        const url = `/recommend/simulator/scenarios/list`;
        return await this.get(url, {});
    }
    async getScenario(id) {
        const url = `/recommend/simulator/scenarios/${id}`;
        return await this.get(url);
    }
    async createScenario(params) {
        const url = `/recommend/simulator/scenarios`;
        return await this.post(url, params);
    }
    async updateScenario(id, params) {
        const url = `/recommend/simulator/scenarios/edit/${id}`;
        return await this.put(url, params);
    }
    async deleteScenario(id) {
        const url = `/recommend/simulator/scenarios/${id}`;
        return await this.delete(url);
    }
    async getScenarioExpireRiSp(id) {
        const url = `/recommend/simulator/scenarios/expire_ri_sp/${id}`;
        return await this.get(url);
    }
    async getExpireRiSp() {
        const url = "/recommend/simulator/scenarios/expire_ri_sp";
        return await this.get(url);
    }
    async getScenarioDashboard(id) {
        const url = `/recommend/simulator/scenarios/dashboard/${id}`;
        return await this.get(url);
    }
    async getScenarioDashboardResult(id, riskLevel) {
        const url = `/recommend/simulator/scenarios/dashboard/result/${id}?risk_level=${riskLevel}`;
        return await this.get(url);
    }
    async getScenarioDashboardBasisData(param) {
        const url = `/recommend/simulator/scenarios/dashboard/basis_data`;
        return await this.post(url, param);
    }
    async getDownloadDetail(id, awsAccountId) {
        const url = `/recommend/simulator/scenarios/results/${id}/downloads?aws_account_id=${awsAccountId}`;
        return await this.get(url);
    }
    async downloadPdf(id, awsAccountId) {
        const url = `/recommend/simulator/scenarios/results/direct_downloads/${id}?aws_account_id=${awsAccountId}`;
        return await this.get(url, {}, DOWNLOAD_PDF_HEADER, "blob");
    }
}

<template>
  <mfaMethods ref="methods"/>
  <Alert ref="alert"/>
  <div v-if="!initialized">
    <div class="loader">Loading...</div>
  </div>
  <div v-else class="row" style="width:100%">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="title-part">
            <div class="text-left">
              <h4 class="card-title">MFA設定</h4>
            </div>
          </div>
          <div v-show="isModalOpen === true" id="authCodeModal" class="confirmModal">
            <form id="mfa_setting_form">
              <div class="modal">
                <div class="modal-header">
                  <div class="text-left">
                    <h4>認証コード確認</h4>
                  </div>
                  <div class="text-right">
                    <i class="material-icons" id="modalClose" @click="isModalOpen = false; tokenKey = ''; inputAuthCode = '';">close</i>
                  </div>
                </div>
                <div class="modal-footer">
                  <div class="description">
                    <p>登録されているメールアドレス宛に認証コードを送信しました。<br>メールに記載されている認証コードを入力して「設定する」を押してください。</p>
                  </div>
                  <div class="authCodeInput">
                    <input name="auth_code" maxlength="6" v-model="inputAuthCode" type="text" class="form-control">
                  </div>
                  <div class="buttonArea">
                    <div class="cancelButton">
                      <button class="btn default" @click="isModalOpen = false; tokenKey = ''; inputAuthCode = '';">キャンセル</button>
                    </div>
                    <div class="confirmButton">
                      <button :class="`nav-link btn btn-primary`"
                              type="submit"
                              @click.prevent="handleSubmit(mfaTypes[0].name)">設定する</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div v-show="isModalOpenSecond === true" id="mfaOffModal" class="confirmModal" >
            <div class="modalSecond">
              <div class="modal-header">
                <div class="text-left">
                  <p>MFA認証を無効にしてもよろしいですか？</p>
                </div>
                <div class="text-right">
                  <i class="material-icons" id="modalCloseSecond" @click="isModalOpenSecond = false">close</i>
                </div>
              </div>
              <div class="modal-footer">
                <div class="buttonArea">
                  <div class="cancelButton">
                    <button class="btn default" @click="isModalOpenSecond = false">いいえ</button>
                  </div>
                  <div class="confirmButton">
                    <button :class="`nav-link btn btn-primary`"
                            type="button"
                            @click.prevent="mfaOffModalClose(mfaTypes[0].name)">はい</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="mfa-setting-part">
            <div>
              <p>ONにすると、ユーザのメールアドレス宛にコードが送信されます。</p>
            </div>
            <div class="label">
              <p style="margin-bottom: 0; color:#aaa">メール認証</p>
            </div>
            <div class="toggle-buttons" id="mfa_setting_toggle" data-toggle="checkbox">
              <template v-if="enableMfaTypeMail">
                <label for="mfa_setting_on">
                  <input name="mfa_setting_on" id="mfa_setting_on" type="checkbox">
                  <i class="material-icons toggle-button toggle-button-on" @click="mfaClickOff">toggle_on</i>
                  <div class="on-off-label">ON</div>
                </label>
              </template>
              <template v-else>
                <label for="mfa_setting_off">
                  <input name="mfa_setting_off" id="mfa_setting_off" type="checkbox">
                  <i class="material-icons toggle-button toggle-button-off" @click="mfaClickOn(mfaTypes[0].name)">toggle_off</i>
                  <div class="on-off-label">OFF</div>
                </label>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import { onMounted, ref } from "vue";
import mfaMethods from "../components/mfa_methods.vue";
import Alert from "../components/alert_new.vue";
import { mfaTypes } from "../utils/mfa_constants"

const initialized = ref(false);
const enableMfaTypeMail = ref(false);
const isModalOpen = ref(false);
const isModalOpenSecond = ref(false);
const noInputCodeMsg = "認証コードを入力してください。";
const codeTypeIncorrectMsg = "認証コードの入力形式が正しくありません。";
const codeIncorrectMsg = "認証コードが誤っています。";
const mfaSettingCompleteMsg = "設定を有効化しました。";
const mfaDisableQuestionMsg = "MFA認証を無効にしてもよろしいですか？";
const mfaDisabledMsg = "MFA設定を無効にしました。";
const inputAuthCode = ref("");
const mfaSettingList = ref([]);
const authCodeRegex = /^\d{6}$/;
const methods = ref(null);
const tokenKey = ref("");
const alert = ref(null);

onMounted(async () => {
  await getMfaSettingList();
  initialized.value = true;
})

const getMfaSettingList = async () => {
  const result = await methods.value?.getMfaSettingList();
  if (!result) {
    return false;
  }
  mfaSettingList.value = result.mfa_types;
  enableMfaTypeMail.value = mfaSettingList.value.includes(mfaTypes[0].name);
}

const sendAuthCode = async (mfaType) => {
  const result = await methods.value?.sendAuthCode(mfaType);
  if (!result) {
    return false;
  }
  tokenKey.value = result.token_key;
}

const createMfaSetting = async (mfaType, params) => {
  const result = await methods.value?.createMfaSetting(mfaType, params);
  if (!result) {
    return false;
  }
  return true;
}

const deleteMfaSetting = async (mfaType) => {
  const result = await methods.value?.deleteMfaSetting(mfaType);
  if (!result) {
    return false;
  }
  return true;
}

const mfaClickOn = (mfaType) => {
  sendAuthCode(mfaType);
  isModalOpen.value = true;
}

const mfaClickOff = () => {
  // 「MFA認証を無効にしてもよろしいですか？」のダイアログボックス
  isModalOpen.value = false;
  isModalOpenSecond.value = true;
}

const handleSubmit = async (mfaType) => {
  if (inputAuthCode.value === "") {
    alert.value.displayModal(noInputCodeMsg, 'error');
  } else if (!authCodeRegex.test(inputAuthCode.value)) {
    alert.value.displayModal(codeTypeIncorrectMsg, 'error');
  } else {
    const createParam = {token_key : tokenKey.value, auth_code : inputAuthCode.value};
    const createResult = await createMfaSetting(mfaType, createParam);
    if (createResult) {
      alert.value.displayModal(mfaSettingCompleteMsg, 'success');
      await getMfaSettingList();
      isModalOpen.value = false;
      tokenKey.value = "";
      inputAuthCode.value = "";
    }
  }
}

const mfaOffModalClose = async (mfaType) => {
  const deleteResult = await deleteMfaSetting(mfaType);
  if (!deleteResult) {
    return false;
  } else {
    alert.value.displayModal(mfaDisabledMsg, 'success');
    await getMfaSettingList();
    isModalOpenSecond.value = false;
  }
}

</script>

<style scoped>

.card-title {
  font-size: 22px;
  font-weight: 300;
  margin-top: 10px;
}

.title-part {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  height: 60px;
}

.label {
  display: inline-block;
  vertical-align: middle;
}

.toggle-button {
  vertical-align: middle;
  display: inline-block;
  margin-left: 80px;
}

.on-off-label {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}

.toggle-buttons {
  display: inline-block;
}

.toggle-buttons label input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-button-on {
  color:#2854C5;
  font-size: 70px;
}

.toggle-button-off {
  color:#aaa;
  font-size: 70px;
}

.description {
  font-size: 14px;
}

.confirmModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal {
  background: white;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 20%;
  margin-left: 30%;
  display: block;
  height: 300px;
}

#modalClose {
  margin-top: 10px;
  color: #aaa;
}

.modalSecond {
  background: white;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 10%;
  margin-left: 10%;
  display: block;
  height: 200px;
}

#modalCloseSecond {
  margin-top: 10px;
  color: #aaa;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.authCodeInput {
  margin-top: 10px;
  display: block;
  width: 500px;
}

.buttonArea {
  margin-top: 20px;
  width: 700px;
  text-align: center;
}

.cancelButton {
  display: inline-block;
  width: 120px;
}

.confirmButton {
  margin-left: 20px;
  display: inline-block;
  width: 120px;
}

.nav-link btn btn-primary {
  color:white;
  width:110px;
}

.material-icons {
  cursor: pointer;
}

.modal-footer {
  display: inline-block;
  border: none;
}

</style>
<template>
  <div>
    <BudgetMethods ref="methods"/>
    <div v-if="!initialized">
      <div class="loader">Loading...</div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card">
            <div class="card-header">
              <ul class="nav">
                <li class="nav-item">
                  <a
                    :class="`nav-link btn btn-primary ${totalCount >= limit ? ' disabled' : ''}`"
                    href="/mypage/budget/new"
                  >
                    新規
                  </a>
                </li>
              </ul>
              <div class="text-secondary">最大{{ limit }}件まで登録できます</div>
              <div>
                <div class="pull-left">
                  <DropDownButton
                    type="period"
                    dropdownId="periodMenuButton"
                    :dropdownItem="periodItems"
                    v-model:item="period"
                    v-on:updateReport="getBudgets"/>
                </div>
                <div class="pull-right">
                  <DropdownDownload reportType="budget_list" :tableItem="budgets" :keys="budgetKeys" :titles="budgetTitles"/>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table id="budget_list" class="table table-striped table-hover text-nowrap">
                  <thead>
                  <tr>
                    <template v-for="element in elements">
                      <TableTh :tableKey="element.key" v-on:emitTrigger="doSort">{{ element.title }}</TableTh>
                    </template>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-if="Object.keys(budgets).length">
                    <template v-for="budget in budgets">
                      <tr :style="{color: [nowDate <= budget.end_date ? '#3C4858' : '#ccc' ]}">
                        <td>
                          <a :href="`/mypage/budget/${budget.budget_id}/detail`" :style="{color: [nowDate <= budget.end_date ? '#00bcd4' : '#ccc' ]}">{{budget.budget_name}}</a>
                        </td>
                        <td class="text-center">
                          <template v-if="budget.alert_status === 'normal'">
                            <i class="material-icons color-green align-middle">check_circle</i>
                          </template>
                          <template v-else-if="budget.alert_status === 'warning'">
                            <i class="material-icons color-red align-middle">warning</i>
                          </template>
                          <template v-else>
                            <span>-</span>
                          </template>
                        </td>
                        <td>{{ budget.start_date  }}</td>
                        <td>{{ budget.end_date }}</td>
                        <td>
                          <template v-if="budget.current_month_budget_amount !== null">{{ budget.current_month_budget_amount.toFixed(2) }}</template>
                          <template v-else>-</template>
                        </td>
                        <td>
                          <template v-if="budget.current_month_actual_amount !== null">{{ budget.current_month_actual_amount.toFixed(2) }}</template>
                          <template v-else>-</template>
                        </td>
                        <td>
                          <template v-if="budget.current_month_estimate_amount !== null">{{ budget.current_month_estimate_amount.toFixed(2) }}</template>
                          <template v-else>-</template>
                        </td>
                        <td>
                          <template v-if="budget.current_month_actual_budget_ratio !== null">
                            <BudgetListProgress :ratio.number="Math.round(budget.current_month_actual_budget_ratio)"/>
                          </template>
                          <template v-else>-</template>
                        </td>
                        <td>
                          <template v-if="budget.current_month_estimate_budget_ratio !== null">
                            <BudgetListProgress :ratio.number="Math.round(budget.current_month_estimate_budget_ratio)"/>
                          </template>
                          <template v-else>-</template>
                        </td>
                        <td>{{ budget.budget_amount_sum.toFixed(2) }}</td>
                        <td>
                          <template v-if="budget.actual_amount_sum !== null">{{ budget.actual_amount_sum.toFixed(2) }}</template>
                          <template v-else>-</template>
                        </td>
                        <td>
                          <template v-if="budget.estimate_amount_sum !== null">{{ budget.estimate_amount_sum.toFixed(2) }}</template>
                          <template v-else>-</template>
                        </td>
                        <td>
                          <template v-if="budget.actual_budget_ratio !== null">
                            <BudgetListProgress :ratio.number="Math.round(budget.actual_budget_ratio)"/>
                          </template>
                          <template v-else>-</template>
                        </td>
                        <td>
                          <template v-if="budget.estimate_budget_ratio !== null">
                            <BudgetListProgress :ratio.number="Math.round(budget.estimate_budget_ratio)"/>
                          </template>
                          <template v-else>-</template>
                        </td>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="9">
                        設定はありません
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { nextTick, onMounted, ref } from "vue";
import BudgetListProgress from "../components/budget_list_progress.vue";
import BudgetMethods from "../components/budget_methods.vue"
import DropDownButton from "../components/dropdown_button";
import DropdownDownload from "../components/dropdown_download.vue";
import TableTh from "../components/table_th.vue"

const periodItems = [
  {
    name: '全て',
    value: 'all'
  },
  {
    name: '期間前の表示',
    value: 'upcoming'
  },
  {
    name: '期間内の表示',
    value: ''
  },
  {
    name: '期限切れの表示',
    value: 'expired'
  }
]

const elements = [
  { title: "予算名",               key: "budget_name",                        sort: false, isNumber: false },
  { title: "アラートの状態",        key: "alert_status",                        sort: false, isNumber: false },
  { title: "期間(開始)",           key: "start_date",                          sort: false, isNumber: false },
  { title: "期間(終了)",           key: "end_date",                            sort: false, isNumber: false },
  { title: "当月の予算金額($)",     key: "current_month_budget_amount",         sort: false, isNumber: true },
  { title: "当月の実績金額($)",     key: "current_month_actual_amount",         sort: false, isNumber: true },
  { title: "当月の予測金額($)",     key: "current_month_estimate_amount",       sort: false, isNumber: true },
  { title: "当月の実績/予算(%)",    key: "current_month_actual_budget_ratio",   sort: false, isNumber: true },
  { title: "当月の予測/予算(%)",    key: "current_month_estimate_budget_ratio", sort: false, isNumber: true },
  { title: "期間全体の予算累計($)",  key: "budget_amount_sum",                   sort: false, isNumber: true },
  { title: "期間全体の実績累計($)",  key: "actual_amount_sum",                   sort: false, isNumber: true },
  { title: "期間全体の予測累計($)",  key: "estimate_amount_sum",                 sort: false, isNumber: true },
  { title: "期間全体の実績/予算(%)", key: "actual_budget_ratio",                 sort: false, isNumber: true },
  { title: "期間全体の予測/予算(%)", key: "estimate_budget_ratio",               sort: false, isNumber: true }
]
const budgetKeys = elements.map(item => item.key)
const budgetTitles = elements.map(item => item.title)

let initialized = ref(false)
let period = ref("")
let budgets = ref([])
let nowDate = ref("")
let totalCount = ref(0)
const limit = ref(-1)
const methods = ref(null)

const getNow = () => {
  const date = new Date()
  const month = date.getMonth() + 1

  nowDate = date.getFullYear() + '-' + month.toString().padStart(2, "0")
}

const getBudgets = async () => {
  const result = await methods.value?.getBudgets(period.value)
  if (!result.budgets) {
    return false
  }
  budgets.value = result.budgets
  totalCount.value = result.total_count
  limit.value = result.limit
}

const doSort = (columnKey) => {
  // elementを探す
  const elementIndex = elements.findIndex( element => element.key === columnKey)
  const element = elements[elementIndex]

  // ソート
  const sort1 = (a,b) => compareSort(a,b,columnKey)
  const sort2 = (a,b) => compareSort(b,a,columnKey)

  nextTick(() => {
    element.sort ? budgets.value.sort(sort1) : budgets.value.sort(sort2)
  })

  // ソートの状態を更新
  const isSort = elements[elementIndex].sort
  elements[elementIndex].sort = !isSort
}

const compareSort = (first, second, columnKey) => {
  let firstVal = conversionValue(first[columnKey])
  let secondVal = conversionValue(second[columnKey])
  if (firstVal > secondVal) {
    return -1;
  }
  if (firstVal < secondVal) {
    return 1;
  }
  return 0;
}

const conversionValue = (value) => {
  if (value === 'warning') {
    return 2;
  } else if (value === 'normal') {
    return 1;
  }
  return value;
}

onMounted(async () => {
  await getBudgets()
  getNow()
  initialized.value = true
})

</script>
<style scoped>
.color-red {
  color: #ff0000;
}

.color-green {
  color: #008000;
}
</style>

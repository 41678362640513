<template>
  <Alert ref="alert"/>
</template>
<script>
import {createReport, deleteReports, lockReports, unlockReports, getReport, getReportList, updateReport, validateReportName} from "../lib/axios/api_client";
import Alert from "./alert_new.vue";

export default {
  components: {
    'Alert': Alert
  },
  props: {
      reportType: String,
  },
  data() {return {}},
  mounted: async function () {},
  methods: {
    getReport: async function (reportId = 0, reportType = this.reportType) {
      const params = (reportId === 0) ? { params: { reportType: reportType } } : { params: {} }

      const res = await getReport(reportId, params)
      if (!res.successful) {
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      return res.data.report
    },
    getReportList: async function (page = 0) {
      const params = {params: {reportType: this.reportType, page: page}}

      const res = await getReportList(params)
      if (!res.successful) {
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      return res.data
    },
    createReport: async function (name, report) {
      report.name = name
      const params = {report: report}

      const res = await createReport(params)
      if (!res.successful) {
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      this.$refs.alert.displayModal('成功しました。', 'success')

      return res.data.id
    },
    updateReport: async function (report) {
      const res = await updateReport(report.id, { report: report })
      if (!res.successful) {
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }
      this.$refs.alert.displayModal('成功しました。', 'success')
      return true
    },
    deleteReport: async function(reportIds) {
      const res = await deleteReports(reportIds)

      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      this.$refs.alert.displayModal('成功しました。','success')
      return true
    },
    lockReport: async function(reportIds){
      const res = await lockReports(reportIds)
      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      this.$refs.alert.displayModal('成功しました。','success')
      return true
    },
    unlockReport: async function(reportIds){
      const res = await unlockReports(reportIds)
      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      this.$refs.alert.displayModal('成功しました。','success')
      return true
    },
    validateName: async function(name, reportType){
      const params = { name: name, report_type: reportType }
      const res = await validateReportName(params)

      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      return res.data.result
    }
  }
}
</script>

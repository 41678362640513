<template>
  <Alert ref="alert"/>
  <p>
    設定の追加は<a class="text-info" :href="url">rightsizing {{ name }} 一覧</a>から可能です。<br />
    <span class="text-muted">現在の登録件数は{{ countRightSizingSetting }}件です。（最大100件まで登録できます）</span>
  </p>
  <template v-if="rightSizingSettingList.length">
    <div class="card-body">
      <div class="table-responsive pb-3">
        <table class="table table-no-bordered table-hover text-left" cellspacing="0" width="100%" style="width:100%">
          <thead>
          <tr class="white-space-nowrap">
            <th>連結アカウント</th>
            <template v-if="this.serviceType === 'ec2'">
              <th>インスタンスID</th>
              <th>CPUアーキテクチャ</th>
            </template>
            <template v-else-if="this.serviceType === 'ebs'">
              <th>ボリュームID</th>
            </template>
            <template v-else-if="this.serviceType === 'eip'">
              <th>アロケーションID</th>
            </template>
            <template v-else>
              <th>ELB名</th>
            </template>
            <th>期限</th>
            <th>アクション</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(item, index) in rightSizingSettingList">
            <tr>
              <template v-if="item.end_date <= today">
                <td><span class="text-muted">{{ item.aws_account_id }}</span></td>
                <td><span class="text-muted">{{ item.identifier }}</span></td>
                <template v-if="this.serviceType === 'ec2'">
                  <td><span class="text-muted">{{ item.cpu_architecture }}</span></td>
                </template>
              </template>
              <template v-else>
                <td>{{ item.aws_account_id }}</td>
                <td>{{ item.identifier }}</td>
                <template v-if="this.serviceType === 'ec2'">
                  <td>{{ item.cpu_architecture }}</td>
                </template>
              </template>
              <td>
                <template v-if="item.end_date === null">
                  <i>-</i>
                </template>
                <template v-else-if="item.end_date < today">
                  <span class="text-danger">{{ item.end_date }}</span>
                </template>
                <template v-else>
                  {{ item.end_date }}
                </template>
              </td>
              <td>
                <a class="cursor-pointer text-info" data-toggle="modal" v-bind:data-target="'#updateRightsizingSettingModal' + item.service_type + index" v-on:click.prevent="initializeEndDate(item.end_date)" type="button">
                  <i class="material-icons">article</i>
                </a>
                <a @click.prevent="itemDelete(item.exclusion_id, '本当に削除しますか？')" class="text-danger" href="#">
                  <i class="material-icons">delete</i>
                </a>
              </td>
              <!-- Modal -->
              <div class="modal fade" v-bind:id="'updateRightsizingSettingModal' + item.service_type + index" tabindex="-1" role="dialog" aria-labelledby="updateRightsizingSettingModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="updateRightsizingSettingModalLabel">除外する期限を再設定</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <p>
                        <br />
                      </p>
                      <form v-bind:id="'updateRightsizingSettingForm' + item.service_type + index">
                        <p>
                          期限：<input type="date" v-bind:value="date" v-on:input="bindEndDate" v-bind:min="tomorrow" max="2099-12-31" /><br />
                          ※設定しない場合は無期限になります
                        </p>
                      </form>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-dismiss="modal">キャンセル</button>
                      <button type="button" class="btn btn-primary" v-on:click.prevent="updateAndUpdatePage(index, item, date)">除外として再設定</button>
                    </div>
                  </div>
                </div>
              </div>
            </tr>
          </template>
          </tbody>
        </table>
        <Pagination ref="pagination" :pageNumber.number="pageNumber" :totalPages.number="totalPages" v-on:emitPage="updatePage"/>
      </div>
    </div>
  </template>
  <template v-else>
    設定はありません
  </template>
</template>
<script>
import Pagination from "./pagination.vue";
import Alert from "./alert_new.vue";
import {getRightsizingSettings, updateRightsizingSetting, deleteRightsizingSetting} from "../lib/axios/api_client";
import {formatDate} from "../lib/calendar";

export default {
  props: ['serviceType', 'url', 'name', 'countRightSizingSetting'],
  emits: ['countRightsizingSettings'],
  components: {
    Alert,
    Pagination
  },
  data: function () {
    return {
      rightSizingSettingList: {},
      pageNumber: "",
      totalPages: "",
      date: "",
      tomorrow: "",
      today: "",
    }
  },
  mounted: async function(){
    await this.updatePage(0)
  },
  created() {
    this.today = formatDate(new Date());
    let tomorrowSet = new Date();
    tomorrowSet.setDate(tomorrowSet.getDate() + 1);
    this.tomorrow = formatDate(tomorrowSet);
  },
  methods: {
    initializeEndDate: function (date) {
      this.date = date;
    },
    bindEndDate: function (e) {
      this.date = e.target.value;
    },
    updatePage: async function(page){
      const result = await this.getRightsizingSettings(page);
      if(result){
        this.rightSizingSettingList = result.exclusions;
        this.pageNumber = result.page_number;
        this.totalPages = result.total_pages;
      }
    },
    getRightsizingSettings: async function(page) {
      const params = {params: {page: page}};
      const res = await getRightsizingSettings(this.serviceType, params);
      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error');
        return false;
      }
      return res.data;
    },
    update: async function (exclusion_id, params) {
      const res = await updateRightsizingSetting(exclusion_id, params);
      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error');
        return false;
      }
      this.$refs.alert.displayModal('成功しました。','success');
      return true;
    },
    updateAndUpdatePage: async function(index, item, date) {
      const form = $(`#updateRightsizingSettingForm${ item.service_type }${ index }`);
      if (form[0].reportValidity()) {
        const params = {"end_date": date};
        const result = await this.update(item.exclusion_id, params);
        if (!result) { return false };
        $(`#updateRightsizingSettingModal${ item.service_type }${ index }`).modal('hide');
        if (this.rightSizingSettingList.length === 0 && this.pageNumber !== 0) {
          await this.updatePage(this.pageNumber - 1);
        } else {
          await this.updatePage(this.pageNumber);
        }
        this.$emit('countRightsizingSettings');
      }
    },
    delete: async function (exclusion_id) {
      const res = await deleteRightsizingSetting(exclusion_id);
      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error');
        return false;
      }
      this.$refs.alert.displayModal('成功しました。','success');
      return true;
    },
    deleteAndUpdatePage: async function (exclusion_id) {
      const result = await this.delete(exclusion_id);
      if (!result) { return false }
      if (this.rightSizingSettingList.length === 0 && this.pageNumber !== 0) {
        await this.updatePage(this.pageNumber - 1);
      } else {
        await this.updatePage(this.pageNumber);
      }
      this.$emit('countRightsizingSettings');
    },
    itemDelete: function (exclusion_id, confirm) {
      if (confirm) {
        if (!window.confirm(confirm)) return;
      }

      this.deleteAndUpdatePage(exclusion_id);
    },
  }
}
</script>
<template>
  <SupportMethod ref="supportMethod"/>
  <button style="margin-top: 20px" class="nav-link btn btn-primary" @click="supportButtonClick">サポート問い合わせ画面へ移動</button>
</template>
<script setup>
import SupportMethod from "../components/support_method.vue";
import { ref } from "vue";
import * as constants from '../utils/constants'

const supportMethod = ref(null);
const zendeskUrl = ref("");

const supportButtonClick = async () => {
  const param = { return_to : constants.pagePath.support.index }
  const result = await supportMethod.value?.getZendeskSupportUrl(param);
  if (result) {
    zendeskUrl.value = result.url;
  }
  window.open(zendeskUrl.value, "_blank");
}
</script>
<style scoped>

</style>
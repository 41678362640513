export const summaryTypes= [
    {
      type: "asset_summary_computing",
      ref: "assetSummaryComputing",
      name: "コンピューティングサーバーステータス"
    },
    {
      type: "asset_summary_database",
      ref: "assetSummaryDatabase",
      name: "データベース"
    },
    {
      type: "asset_summary_disk",
      ref: "assetSummaryDisk",
      name: "ディスク"
    },
    {
      type: "asset_summary_network",
      ref: "assetSummaryNetwork",
      name: "ネットワーク"
    },
    {
      type: "asset_summary_storage",
      ref: "assetSummaryStorage",
      name: "オブジェクトストレージ"
    }
  ]

export const summaryTypeList = summaryTypes.map( item  => item.type )

<template>
  <div v-if="!initialized">
    <div class="loader">Loading...</div>
    <FilterMethods ref="filterMethods"/>
  </div>
  <div v-else>
    <div class="row">
      <FilterMethods ref="filterMethods"/>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="pull-left">
            <FilterCreateButton type="filter" v-on:childMethod="createDashboardFilter"/>
          </div>
          <div class="pull-right"></div>
        </div>
      </div>
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-title"></div>
        </div>
        <div class="card-body">
          <div class="table-responsive filter-table">
            <table class="table table-no-bordered table-hover text-center" style="width:100%">
              <thead class="sticky-top">
              <tr>
                <th>項目</th>
                <th>Cost Viewer</th>
                <th>Reserved Usage</th>
                <th>Reserved Coverage</th>
                <th>Savings Plans Usage</th>
                <th>Savings Plans Coverage</th>
                <th>Recommend Reserved</th>
                <th>Recommend Savings Plan</th>
                <th>Right Sizing EC2</th>
                <th>Right Sizing EBS</th>
                <th>Right Sizing EIP</th>
                <th>Right Sizing ELB</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in columnList">
                <td>
                  <template v-if="item.type === 'single'">
                    <FilterItemSingleSelect
                      :filterKey="item.key"
                      :reportType="item.report_type"
                      :reportFilter="filter.filter"
                      v-on:emitFilterItem="emitFilterItem"
                    >
                      {{ item.title }}
                    </FilterItemSingleSelect>
                  </template>
                  <template v-else>
                    <FilterItemMultiSelect
                      :filterKey="item.key"
                      :reportType="item.report_type"
                      :reportFilter="filter.filter"
                      :condition="item.condition"
                      v-on:emitFilterItem="emitFilterItem"
                    >
                      {{ item.title }}
                    </FilterItemMultiSelect>
                  </template>
                </td>
                <template v-for="available in mappingTable[item.key]">
                  <td>
                    <template v-if="available === true">◯</template>
                  </td>
                </template>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import FilterItemMultiSelect from '../components/filter_item_multi_select.vue'
import FilterItemSingleSelect from '../components/filter_item_single_select.vue'
import * as constants from "../utils/constants"
import ReportCreateButton from '../components/report_create_button.vue'
import {allColumns, mappingTable} from "../utils/filter_column_list"
import FilterMethods from "../components/filter_methods.vue";

export default {
  components: {
    'FilterItemMultiSelect': FilterItemMultiSelect,
    'FilterItemSingleSelect': FilterItemSingleSelect,
    'FilterCreateButton': ReportCreateButton,
    'FilterMethods': FilterMethods
  },
  mounted: async function() {
    const resultFilter = await this.$refs.filterMethods.getDashboardFilter()
    if(!resultFilter){ return false }
    this.filter = resultFilter

    const resultFilterList = await this.$refs.filterMethods.getFilterList()
    if(!resultFilterList){ return false }
    this.filterList = resultFilterList

    this.$nextTick(() => {
      this.initialized = true
    })
  },
  data: function () {
    return {
      initialized: false,
      filter: {},
      filterList: [],
      columnList: allColumns,
      mappingTable: mappingTable,
      path: constants.pagePath.dashboardFilter
    }
  },
  methods: {
    createDashboardFilter: async function(name){
      const result = await this.$refs.filterMethods.createFilter(name, this.filter)
      if(result){
        window.location.href = this.path.list
      }
    },
    emitFilterItem(params) {
      this.filter.filter[params.key] = params.value
    }
  }
}
</script>

<template>
  <BudgetMethods ref="budgetMethodsRef"/>
  <div v-if="!initialized">
    <div class="loader">Loading...</div>
    <ReportMethods ref="reportMethodsRef"/>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title">{{ title }}の傾向　(先月との比較)</div>
          </div>
          <div class="card-body">
            <IframeDashboard ref="homeSummaryRef" height="400px"/>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title">{{ title }}の傾向 (先月同日までのサービス別の比較)</div>
          </div>
          <div class="card-body">
            <IframeDashboard ref="homeTrendRef" height="300px"/>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-header">
            <span class="card-title mr-2">先月と今月の日別コスト</span>
            <DropDownButton
                dropdownId="graphMenuButton"
                :dropdownItem="graphItem"
                v-on:updateReport="updateReport"
                v-model:item="homeGraph.graph_style.visual_type"
            />
          </div>
          <div class="card-body">
            <IframeDashboard ref="homeGraphRef" height="500px"/>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12" v-if="budgetListShow">
        <div class="card">
          <div class="card-header">
            <div class="card-title">予算の状況（{{ budgetTitle }}）</div>
          </div>
          <div class="card-body text-center">
            <IframeDashboard ref="budgetListRef" height="240px"/>
            <a href="/mypage/budget">さらに表示する（一覧へ）</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from "vue";
import { format } from 'date-fns'
import DropDownButton from '../components/dropdown_button.vue'
import IframeDashboard from '../components/iframe_dashboard.vue'
import ReportMethods from "../components/report_methods.vue"
import BudgetMethods from "../components/budget_methods.vue"
import * as constants from "../utils/constants"
import * as utils    from '../utils/format_graph_style'

const graphItem = utils.graphItem

let homeSummary = ref({})
let homeTrend = ref({})
let homeGraph = ref({})
let budgetList = ref({})
let budgetListShow = ref(false)

const budgetMethodsRef = ref(null)
const reportMethodsRef = ref(null)
const homeSummaryRef = ref(null)
const homeGraphRef = ref(null)
const homeTrendRef = ref(null)
const budgetListRef = ref(null)

let initialized = ref(false)

const title = computed(() => {
  return format(new Date, "yyyy年MM月")
})

const budgetTitle = computed(() => {
  let date = new Date
  date.setDate(date.getDate() - 2)
  return format(date, "yyyy年MM月")
})

const updateReport = async () =>{
  await homeGraphRef.value.generateDashboard(homeGraph.value)
}

const checkBudgetListLength = async () => {
  const result = await budgetMethodsRef.value.getDashboardElementBudgetList(budgetList.value)
  if(!result){
    return false
  }

  return result.length > 0
}

onMounted(async () => {
  homeSummary.value = await reportMethodsRef.value.getReport(0, constants.homeSummary)
  homeTrend.value = await reportMethodsRef.value.getReport(0, constants.homeTrend)
  homeGraph.value = await reportMethodsRef.value.getReport(0, constants.homeGraph)
  budgetList.value = await reportMethodsRef.value.getReport(0, constants.budgetList)

  budgetListShow.value = await checkBudgetListLength()
  initialized.value = true

  await nextTick(async () => {
    if (homeSummary.value) {
      await homeSummaryRef.value.generateDashboard(homeSummary.value)
    }
    if (homeTrend.value) {
      await homeTrendRef.value.generateDashboard(homeTrend.value)
    }
    if (homeGraph.value) {
      await homeGraphRef.value.generateDashboard(homeGraph.value)
    }
    if (budgetList.value && budgetListShow.value) {
      await budgetListRef.value.generateDashboard(budgetList.value)
    }
  })
})

</script>
<style scoped>
</style>

<template>
  <Alert ref="alert"/>
  <UserMethods ref="methods"/>
    <div v-if="!initialized">
      <div class="loader">Loading...</div>
    </div>
    <div v-else class="row" style="width:100%">
      <div class="col-12">
        <form id="new_user_form" class="card">
          <div class="card-header">
            <div class="title-part">
              <div class="text-left">
                <h4 class="card-title">ユーザー追加</h4>
              </div>
              <div class="text-right">
                <a v-if="isModalOpen === false" class="text-info" href="/mypage/users">
                  <i class="material-icons" style="cursor: pointer;">close</i>
                </a>
                <i v-else class="material-icons" style="color: dimgray">close</i>
              </div>
            </div>
            <div v-if="isModalOpen === true" id="userCreateModal" class="confirmModal">
              <div class="modal">
                <div class="modal-header">
                  <div class="text-left">
                    <h4>登録したメールアドレスに認証メールを送信しました。</h4>
                  </div>
                  <div class="text-right">
                    <a class="text-info" href="/mypage/users">
                      <i class="material-icons" style="cursor: pointer;">close</i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="text-left">
              <h4 class="card-title">ユーザー情報</h4>
            </div>
            <div class="userInformation">
              <div class="userInput">
                <div class="label">
                  <label for="mail_address">{{ emailLabel }}</label>
                </div>
                <div class="formInput">
                  <input name="mail_address" id="mail_address" class="form-control" maxlength="100" type="email" v-model="inputMailAddress" @input="validateEmail"/>
                </div>
                <p v-if="validationMessage" class="validationMessage">{{ validationMessage }}</p>
              </div>
              <div class="userInput">
                <div class="label">
                  <label for="user_role">{{ roleLabel }}</label>
                </div>
                <div class="formInput">
                  <select name="user_role" id="user_role" class="form-control" v-model="selectedRole" @change="roleChange" style="text-align: left;">
                    <template v-for=" item in roleTypeList">
                      <option :value="item.value">{{ item.name }}</option>
                    </template>
                  </select>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <h4 class="card-title" style="margin-top: 40px;">{{ awsAccountLabel }}</h4>
              <table id="aws_account_List" class="table table-striped table-hover text-left" style="margin-top: 20px">
                <thead>
                  <tr>
                    <th>
                      <div class="checkBox">
                        <input name="account_all_select" class="form-check-input" type="checkbox" :checked="isAllChecked" @change="handleAllChange" :disabled="isDisabled">
                      </div>
                    </th>
                    <template v-for="header in headerItems">
                      <TableTh :tableKey="header.key" v-on:emitTrigger="doSort">{{header.title}}</TableTh>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="account in awsAccounts" :key="account.aws_account_id">
                    <td>
                      <div class="checkBox">
                        <input name="account_items" class="form-check-input" type="checkbox" :id="account.aws_account_id"
                               :checked="getChecked(account.aws_account_id)" @change="handleListCheckbox(account.aws_account_id)" :disabled="isDisabled">
                      </div>
                    </td>
                    <td>
                      <div class="tableText">{{account.aws_account_id}}</div>
                    </td>
                    <td>
                      <div class="tableText">{{account.alias_name}}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Pagination ref="pagination" :pageNumber.number="pageNumber" :totalPages.number="totalPages" v-on:emitPage="setPage"/>
              <div class="buttonArea">
                <div class="cancelButton">
                  <a class="btn default" href="/mypage/users" style="width: 120px">キャンセル</a>
                </div>
                <div class="confirmButton">
                  <button :class="`nav-link btn btn-primary`" style="width: 120px; color:white" @click.prevent="handleSubmit">確定</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
</template>
<script setup>
import {computed, onMounted, ref, watch } from "vue";
import UserMethods from "../../components/user_methods.vue";
import TableTh from "../../components/table_th.vue";
import {findTableHeaderIndex, selectSort} from "../../lib/table";
import Pagination from "../../components/pagination.vue"
import Alert from "../../components/alert_new.vue";
import {roleTypes} from "../../utils/user_constants"

const initialized = ref(false);
const selectedRole = ref("USER");
const isDisabled = ref(false);
const checkedItems = ref([]);
const headerCheckbox = ref({});
const awsAccounts = ref([]);
const methods = ref(null);
const pagination = ref(null);
const emailLabel = ref("メールアドレス");
const roleLabel = ref("権限");
const awsAccountLabel = ref("AWSアカウント情報");
const inputMailAddress = ref("");
const validationMessage = ref("");
const mailAddressRegex = /^[a-zA-Z0-9.!#$%&*+\/=?^_`{|}~-]{1,46}@[\.a-zA-Z0-9-]+\.[a-zA-Z]+$/;
const domainRegex = /^(?=.{1,100}$)(?=.{1,46}@).+@.+\.[a-zA-Z]{2,}$/;
const isMailAddressPassed = ref(false);
const isModalOpen = ref(false);
const noCheckedAccountMessage = ref("権限が利用者の場合は、参照可能なAWSアカウントを１件以上選択してください。");
const pageNumber = ref(0);
const totalPages = ref(0);
const nowPage = ref(0);
const alert = ref(null);
const isUncheckedAll = ref(true);
const roleTypeList = roleTypes;

onMounted(async () => {
  await setPage(0);
  initialized.value = true;
});

const headerItems = ref([
  { title: "AWSアカウントID", key: "aws_account_id", sort: false },
  { title: "AWSアカウント別名", key: "alias_name", sort: false },
]);

const doSort = (columnKey) => {
  const index = findTableHeaderIndex(headerItems.value, columnKey);
  const isSort = headerItems.value[index].sort;
  const sort = selectSort(isSort, columnKey);

  awsAccounts.value.sort(sort);
  headerItems.value[index].sort = !isSort;
}

const setPage = async (page) => {
  const result = await getAwsAccounts(page);
  if(result){
    awsAccounts.value = result.aws_accounts;
    pageNumber.value = result.page_number;
    totalPages.value = result.total_pages;
  }
}

const getChecked = (id) => {
  if (selectedRole.value === "ADMIN") {
    return true;
  } else {
    return checkedItems.value.includes(id);
  }
}

const handleListCheckbox = (id) => {
  if (!checkedItems.value.includes(id)) {
    checkedItems.value.push(id);
  } else {
    checkedItems.value = checkedItems.value.filter(item => item !== id);
  }
}

const isAllChecked = computed(() => {
  if (selectedRole.value === "ADMIN") {
    return true;
  } else {
    return awsAccounts.value.every(account => checkedItems.value.includes(account.aws_account_id));
  }
});

const handleAllChange = () => {
  if (isAllChecked.value) {
    checkedItems.value = checkedItems.value.filter(item => !awsAccounts.value.some(account => account.aws_account_id === item));
  } else {
    awsAccounts.value.forEach(account => {
      checkedItems.value.push(account.aws_account_id);
    });
  }
}

const validateEmail = () => {
  if (inputMailAddress.value === "") {
    validationMessage.value = emailLabel.value+"を入力してください。";
    isMailAddressPassed.value = false;
  } else if (!mailAddressRegex.test(inputMailAddress.value)||!domainRegex.test(inputMailAddress.value)) {
    validationMessage.value = emailLabel.value+"の入力形式が正しくありません。";
    isMailAddressPassed.value = false;
  } else {
    validationMessage.value = "";
    isMailAddressPassed.value = true;
  }
}

const roleChange = () => {
  if (selectedRole.value === "ADMIN") {
    isDisabled.value = true;
  } else if (selectedRole.value === "USER") {
    checkedItems.value = [];
    isDisabled.value = false;
  }
}

const getAwsAccounts = async (page) => {
  const params = {size: 100, page: page};
  const res = await methods.value?.getAwsAccounts(params);
  if (!res.successful) {
    return false;
  }
  return res.data;
}

const createUser = async (form) => {
  const result = await methods.value?.createUser(form);
  if (!result) {
    return false;
  }
  return result;
}

const handleSubmit = async () => {
  await validateEmail();
  if (selectedRole.value === "USER" && checkedItems.value.length === 0) {
    alert.value.displayModal(noCheckedAccountMessage.value, "error");
    return false;
  }
  if (isMailAddressPassed.value === true) {
    const params = {
      email: inputMailAddress.value,
      role_type: selectedRole.value,
      aws_accounts: selectedRole.value === "USER" ? checkedItems.value : [],
    };
    const isPassed = await createUser(params);
    if(!isPassed){
      return false;
    } else {
      isModalOpen.value = true;
    }
  }
}

watch(inputMailAddress, () =>{
  validateEmail();
})

</script>
<style scoped>

.title-part {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  height: 60px;
}

.form-control {
  width: 400px;
  margin-left: 10px;
}

.card-title {
  font-size: 22px;
  font-weight: 300;
  margin-top: 10px;
}

.userInformation{
  border-bottom: 1px solid lightgray;
}

.userInput {
  margin-top: 20px;
  margin-bottom: 30px;
}

.label {
  display: inline-block;
  width: 120px;
}

.formInput {
  display: inline-block;
}

.material-icons {
  color: #aaa;
}

.table table-striped table-hover text-left {
  width:100%;
}

.checkBox{
  height: 20px;
  vertical-align: middle;
  text-align: end;
}

.buttonArea {
  text-align: center;
}

.cancelButton {
  display: inline-block;
  padding-right: 30px;
}

.confirmButton {
  display: inline-block;
}

.validationMessage {
  color : red;
  display: inline-block;
  font-size: 12px;
  margin-left: 20px;
  height: 10px;
}

.confirmModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: white;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 20%;
  margin-left: 30%;
  display: block;
  height: 200px;
}

</style>

<template>
  <Alert ref="alert"/>
  <div v-if="!initialized">
    <div class="loader">Loading...</div>
  </div>
  <div v-else>
    <div>Message: {{ message }}</div>
    <div id="count">カウント: {{ count }}</div>
    <button type="button" class="btn btn-primary" v-on:click="addCount">カウント</button>
  </div>
</template>
<script>
import Alert from "../components/alert_new.vue";
import { getSampleFunction } from "../lib/axios/api_client";

export default {
  components: {
    Alert
  },
  data() {
    return {
      initialized: false,
      message: 'Hello World!',
      count: 0
    }
  },
  mounted() {
    const result = this.sampleApiFunction()
    if(!result){
      return false
    }

    this.initialized = true
  },
  methods: {
    addCount(){
      this.count++
    },
    sampleApiFunction: async function () {
      const params = { sample: "sample" }

      const res = await getSampleFunction(params)
      if (!res.successful) {
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      this.$refs.alert.displayModal('成功しました。', 'success')
      return true
    }
  }
}
</script>
<style scoped>
</style>

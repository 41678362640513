<template>
  <template v-if="loading.assetUsageDetailTable">
    <div class="loader">Loading...</div>
  </template>
  <template v-else>
    <div class="right-sizing-table">
      <div class="card">
        <div class="card-header">
          <div class="pull-right">
            <DropdownDownload :reportType="reportType" :tableItem="tableItem" :keys="assetUsageDetailKeys" :titles="assetUsageDetailTitles"/>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-striped table-hover">
            <thead class="sticky-top">
            <tr>
              <th>#</th>
              <template v-for="elements in asset_elements[reportType].elements">
                <TableTh :tableKey="elements.key" v-on:emitTrigger="doSort">{{ elements.title }}</TableTh>
              </template>
            </tr>
            </thead>
            <tbody>
              <template v-for="(item, index) in tableItem">
                <tr>
                  <td class="text-left">{{ index + 1 }}</td>
                  <template v-for="elements in asset_elements[reportType].elements">
                    <template v-if="elements.url">
                      <td class="url">
                        <a class="text-info" target="_blank" rel="noopener noreferrer" :href="item[elements.key]">
                          <i class="material-icons">description</i>
                        </a>
                      </td>
                    </template>
                    <template v-else-if="elements.isNumber">
                      <td>{{ parseFloat(item[elements.key]).toFixed(4) }}</td>
                    </template>
                    <template v-else-if="elements.key === 'bucket_total_size_byte' && item[elements.key] === null">
                      <td>-</td>
                    </template>
                    <template v-else>
                      <td>{{ item[elements.key] }}</td>
                    </template>
                  </template>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import tableTh from "./table_th.vue";
import dropdownButtonDownload from './dropdown_download.vue'
import * as constants from '../utils/constants'
import {formatDate} from "../lib/calendar";

export default  {
  props: ['reportType', 'tableItem', 'keys', 'loading'],
  components: {
    TableTh: tableTh,
    DropdownDownload: dropdownButtonDownload,
  },
  data: function () {
    return {
      asset_elements: {
        asset_ec2_detail: {
          path: constants.pagePath.dashboard.asset_ec2_detail,
          elements: [
            { title: "連結アカウント",              key: "aws_account_id",                  sort: false, url: false, isNumber: false },
            { title: "連結アカウントの別名",         key: "account_name",                    sort: false, url: false, isNumber: false },
            { title: "インスタンス名",              key: "instance_name",                   sort: false, url: false, isNumber: false },
            { title: "インスタンスID",              key: "instance_id",                     sort: false, url: false, isNumber: false },
            { title: "インスタンス状態",            key: "instance_state",                  sort: false, url: false, isNumber: false },
            { title: "リージョン",                 key: "region",                          sort: false, url: false, isNumber: false },
            { title: "アベイラビリティーゾーン",      key: "availability_zone",              sort: false, url: false, isNumber: false },
            { title: "インスタンスタイプ",           key: "instance_type",                  sort: false, url: false, isNumber: false },
            { title: "オペレーティングシステム",      key: "operating_system",                sort: false, url: false, isNumber: false },
            { title: "パブリックIPv4アドレス",       key: "public_ipv4_address",             sort: false, url: false, isNumber: false },
            { title: "起動時刻",                   key: "launch_time",                     sort: false, url: false, isNumber: false },
            { title: "コンソール",                 key: "console_url",                     sort: false, url: true, isNumber: false }
          ]

        },
        asset_rds_detail: {
          path: constants.pagePath.dashboard.asset_rds_detail,
          elements: [
            { title: "連結アカウント",         key: "aws_account_id",                     sort: false, url: false, isNumber: false },
            { title: "連結アカウントの別名",    key: "account_name",                       sort: false, url: false, isNumber: false },
            { title: "DB識別子",             key: "rds_instance_identifier",            sort: false, url: false, isNumber: false },
            { title: "ステータス",            key: "rds_instance_status",                sort: false, url: false, isNumber: false },
            { title: "エンジン",              key: "rds_engine",                         sort: false, url: false, isNumber: false },
            { title: "リージョン",            key: "region",                             sort: false, url: false, isNumber: false },
            { title: "アベイラビリティーゾーン", key: "availability_zone",                  sort: false, url: false, isNumber: false },
            { title: "サイズ",               key: "rds_instance_size",                  sort: false, url: false, isNumber: false },
            { title: "マルチAZ",             key: "rds_multi_az",                        sort: false, url: false, isNumber: false },
            { title: "コンソール",            key: "console_url",                         sort: false, url: true, isNumber: false }
          ]
        },
        asset_redshift_detail: {
          path: constants.pagePath.dashboard.asset_redshift_detail,
          elements: [
            { title: "連結アカウント",         key: "aws_account_id",                       sort: false, url: false, isNumber: false },
            { title: "連結アカウントの別名",    key: "account_name",                         sort: false, url: false, isNumber: false },
            { title: "クラスタ識別子",         key: "redshift_cluster_identifier",          sort: false, url: false, isNumber: false },
            { title: "ノードタイプ",          key: "redshift_node_type",                   sort: false, url: false, isNumber: false },
            { title: "ノード数",              key: "redshift_number_of_nodes",             sort: false, url: false, isNumber: false },
            { title: "状態",                 key: "redshift_cluster_availability_status", sort: false, url: false, isNumber: false },
            { title: "リージョン",            key: "region",                               sort: false, url: false, isNumber: false },
            { title: "アベイラビリティゾーン",  key: "availability_zone",                   sort: false, url: false, isNumber: false },
            { title: "マルチAZ",             key: "redshift_multi_az",                   sort: false, url: false, isNumber: false },
            { title: "コンソール",            key: "console_url",                         sort: false, url: true, isNumber: false }
          ]
        },
        asset_s3_detail: {
          path: constants.pagePath.dashboard.asset_s3_detail,
          elements: [
            { title: "連結アカウント",                   key: "aws_account_id",                     sort: false, url: false, isNumber: false },
            { title: "連結アカウントの別名",              key: "account_name",                       sort: false, url: false, isNumber: false },
            { title: "バケット名",                      key: "s3_bucket_name",                     sort: false, url: false, isNumber: false },
            { title: "リージョン",                      key: "region",                             sort: false, url: false, isNumber: false },
            { title: "バケットオブジェクトサイズの合計(GB)", key: "bucket_total_size_byte",            sort: false, url: false, isNumber: false },
            { title: "作成時間",                        key: "s3_bucket_creation_date",            sort: false, url: false, isNumber: false },
            { title: "コンソール",                       key: "console_url",                         sort: false, url: true, isNumber: false }
          ]
        },
        asset_ebs_detail: {
          path: constants.pagePath.dashboard.asset_ebs_detail,
          elements: [
            { title: "連結アカウント",         key: "aws_account_id",         sort: false, url: false, isNumber: false },
            { title: "連結アカウントの別名",    key: "account_name",           sort: false, url: false, isNumber: false },
            { title: "ボリューム名",          key: "volume_name",            sort: false, url: false, isNumber: false },
            { title: "ボリュームID",          key: "volume_id",              sort: false, url: false, isNumber: false },
            { title: "タイプ",               key: "volume_type",            sort: false, url: false, isNumber: false },
            { title: "サイズ",               key: "volume_size",            sort: false, url: false, isNumber: false },
            { title: "IOPS",               key: "volume_iops",             sort: false, url: false, isNumber: false },
            { title: "スループット",          key: "volume_throughput",      sort: false, url: false, isNumber: false },
            { title: "作成時間",             key: "volume_create_time",      sort: false, url: false, isNumber: false },
            { title: "リージョン",            key: "region",                 sort: false, url: false, isNumber: false },
            { title: "アベイラビリティゾーン",  key: "availability_zone",      sort: false, url: false, isNumber: false },
            { title: "ボリュームの状態",      key: "volume_state",            sort: false, url: false, isNumber: false },
            { title: "コンソール",           key: "console_url",            sort: false, url: true, isNumber: false }
          ]
        },
        asset_vpc_detail: {
          path: constants.pagePath.dashboard.asset_vpc_detail,
          elements: [
            { title: "連結アカウント",         key: "aws_account_id",          sort: false, url: false, isNumber: false },
            { title: "連結アカウントの別名",    key: "account_name",            sort: false, url: false, isNumber: false },
            { title: "VPC名",                key: "vpc_name",                sort: false, url: false, isNumber: false },
            { title: "VPC ID",                key: "vpc_id",                sort: false, url: false, isNumber: false },
            { title: "状態",                 key: "vpc_state",               sort: false, url: false, isNumber: false },
            { title: "リージョン",            key: "region",                  sort: false, url: false, isNumber: false },
            { title: "IPv4 CIDR",           key: "vpc_ipv4_cidr_block",     sort: false, url: false, isNumber: false },
            { title: "テナンシー",            key: "vpc_instance_tenancy",    sort: false, url: false, isNumber: false },
            { title: "デフォルトVPC",         key: "vpc_is_default",          sort: false, url: false, isNumber: false },
            { title: "所有者ID",             key: "vpc_owner_id",             sort: false, url: false, isNumber: false },
            { title: "コンソール",            key: "console_url",            sort: false, url: true, isNumber: false }
          ]
        },
        asset_eip_detail: {
          path: constants.pagePath.dashboard.asset_eip_detail,
          elements: [
            { title: "連結アカウント",            key: "aws_account_id",               sort: false, url: false, isNumber: false },
            { title: "連結アカウントの別名",       key: "account_name",                 sort: false, url: false, isNumber: false },
            { title: "EIP名",                   key: "address_name",                 sort: false, url: false, isNumber: false },
            { title: "リージョン",                key: "region",                       sort: false, url: false, isNumber: false },
            { title: "パブリックIPv4アドレス",     key: "public_ipv4_address",          sort: false, url: false, isNumber: false },
            { title: "割り当て状態",              key: "address_allocation_status",    sort: false, url: false, isNumber: false },
            { title: "関連付けられたインスタンスID", key: "associated_instance_id",       sort: false, url: false, isNumber: false },
            { title: "コンソール",                key: "console_url",                  sort: false, url: true, isNumber: false }
          ]
        },
        asset_elb_detail: {
          path: constants.pagePath.dashboard.asset_elb_detail,
          elements: [
            { title: "連結アカウント",         key: "aws_account_id",         sort: false, url: false, isNumber: false },
            { title: "連結アカウントの別名",    key: "account_name",           sort: false, url: false, isNumber: false },
            { title: "ELB名",                key: "elb_name",               sort: false, url: false, isNumber: false },
            { title: "DNS名",                key: "elb_dns_name",           sort: false, url: false, isNumber: false },
            { title: "状態",                 key: "elb_state",               sort: false, url: false, isNumber: false },
            { title: "VPC ID",              key: "elb_vpc_id",             sort: false, url: false, isNumber: false },
            { title: "リージョン",            key: "region",                 sort: false, url: false, isNumber: false },
            { title: "アベイラビリティゾーン",  key: "availability_zone",      sort: false, url: false, isNumber: false },
            { title: "タイプ",               key: "elb_type",               sort: false, url: false, isNumber: false },
            { title: "作成時間",             key: "elb_created_time",       sort: false, url: false, isNumber: false },
            { title: "コンソール",           key: "console_url",             sort: false, url: true, isNumber: false }
          ]
        }
      }
    }
  },
  async mounted() {
  },
  computed: {
    assetUsageDetailKeys() {
      return this.asset_elements[this.reportType].elements.map(item => item.key)
    },
    assetUsageDetailTitles() {
      return this.asset_elements[this.reportType].elements.map(item => item.title)
    }
  },
  methods: {
      doSort(columnKey) {
        // elementを探す
        const elementIndex = this.asset_elements[this.reportType].elements.findIndex( element => element.key === columnKey)
        const element = this.asset_elements[this.reportType].elements[elementIndex]

        // ソート
        const sort1 = (a,b) => this.compareSort(a,b,columnKey)
        const sort2 = (a,b) => this.compareSort(b,a,columnKey)
        const result = element.sort ? this.tableItem.sort(sort1) : this.tableItem.sort(sort2)

        // ソートの状態を更新
        const isSort = this.asset_elements[this.reportType].elements[elementIndex].sort
        this.asset_elements[this.reportType].elements[elementIndex].sort = !isSort

        // tableを更新
        this.$emit('emitTrigger', result)
      },
      compareSort(first, second, columnKey){
        if (first[columnKey] > second[columnKey]) {
          return -1;
        }
        if (first[columnKey] < second[columnKey]) {
          return 1;
        }
        return 0;
      }
  }
}
</script>
<style scoped>
.url {
  text-align: center;
}
</style>

<template>
  <Alert ref="alert"/>
  <div v-if="!initialized">
    <div class="loader">Loading...</div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-header card-header-rose card-header-icon">
            <div class="card-title">
              <p class="warn-text">
                Cloud illuminator画面で利用するコスト配分タグを設定します。<br>
                illuminatorCost_Tag1〜illuminatorCost_Tag5まで最大5件までコスト配分タグを割り当てすることが可能です。
              </p>
            </div>
          </div>
          <div class="pl-3">
            <ul class="nav">
              <li class="nav-item">
                <button type="button" class="nav-link btn btn-primary" v-on:click="updateTags()">
                  更新
                </button>
              </li>
            </ul>
          </div>
          <div class="card-body">
            <div class="table-responsive pb-3">
              <table class="table table-no-bordered table-hover text-left" cellspacing="0" width="100%" style="width:100%">
                <thead>
                <tr class="white-space-nowrap">
                  <th>イルミネータTag</th>
                  <th>マッピング対象のコスト配分タグ</th>
                  <th>タグ名称</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in illuminatorTags">
                  <td>{{ item.tag_type }}</td>
                  <td>
                    <select class="form-control" v-model="illuminatorTags[index].resource_tag_id">
                      <option :value="null">タグを選択</option>
                      <template v-for="tag in resourceTags">
                        <option :value="tag.id">
                          {{ tag.name }}
                        </option>
                      </template>
                    </select>
                  </td>
                  <td>
                    <input class="form-control" type="text" maxlength="20" v-model="illuminatorTags[index].tag_alias">
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { getIlluminatorTags, getResourceTags, updateIlluminatorTags } from "../lib/axios/api_client";
import Alert from "../components/alert_new.vue";

const alert = ref(null)
const transition = () => { return  window.location.reload() }

let initialized = ref(false)
let illuminatorTags = ref([])
let resourceTags = ref([])

// validation
const validateSameTag = () => {
  const ids = illuminatorTags.value
                .map((tag) => tag.resource_tag_id)
                .filter((id) => id !== null)
  const setter = new Set(ids)
  return setter.size === ids.length
}

const updateTags = async () => {
  if (!validateSameTag()) {
      alert.value.displayModal('別行で同じコスト配分タグは選択できません', 'error')
      return false
  }
  const params = {tags: illuminatorTags.value}
  const result = await updateIlluminatorTags(params)
  if (!result.successful) {
    alert.value.displayModal(result.message, 'error')
    return false
  }

  alert.value.displayModal('成功しました。','success')
  setTimeout(transition, 1000)
}

onMounted( async () => {
  const illuminatorRes = await getIlluminatorTags()
  if (!illuminatorRes.successful) {
    alert.value.displayModal(illuminatorRes.message, 'error')
    return false
  }
  illuminatorTags.value = illuminatorRes.data.tags

  const ResourceRes = await getResourceTags()
  if (!ResourceRes.successful) {
    alert.value.displayModal(ResourceRes.message, 'error')
    return false
  }
  resourceTags.value = ResourceRes.data.resource_tags

  initialized.value = true
})

</script>
<style scoped>
</style>
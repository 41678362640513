<template>
    <ClickOutside v-on:click-outside="close">
        <span class="btn btn-secondary dropdown-toggle dropdown_date-btn" type="button" v-on:click="open">{{ title }}</span>
        <transition name="slide-fade">
            <div v-show="show" class="card dropdown_date">
                <DatePicker v-model="date" />
            </div>
        </transition>
    </ClickOutside>
</template>
<script>
    import {transformDate, formatDate, validateDateFormat} from "../lib/calendar"
    import {format} from "date-fns";
    import ClickOutside from "./click_outside.vue";
    import { DatePicker } from 'v-calendar';
    export default {
        components: {
          ClickOutside,
          DatePicker
        },
        props: {
          reference_date: String
        },
        emits: [
          'update:reference_date',
          'updateReport'
        ],
        data() {
            return {
                show: false,
                date: null,
            }
        },
        watch: {
            date: function(value){
                this.date = value;
                this.triggerEmit()
            },
        },
        computed: {
            title() {
                if (this.date) {
                    return format(this.date, "yyyy年MM月dd日")
                }
                return '残日数基準日';
            },
        },
        methods: {
            open(){
                if(this.reference_date){
                  const reference_date = this.transformDate(this.reference_date)
                  if(formatDate(this.date) !== formatDate(reference_date)){
                    this.date = reference_date
                  }
                }
                this.show = !this.show
            },
            close(){
                this.show = false
            },
            transformDate(date) {
              if(!validateDateFormat(date)){ return false }
              return transformDate(date)
            },
            triggerEmit(){
                this.$emit('update:reference_date', formatDate(this.date))
                this.$emit('updateReport')
                this.show = false
            },
        }
    }
</script>

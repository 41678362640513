<template>
  <NeoidMethods ref="neoidMethods"/>
  <div id="signup">
    <h1>お申し込み</h1>
    <section>
      <nav class="flow-nav step3">
        <ul>
          <li>メールアドレス入力</li>
          <li>お客様情報入力</li>
          <li>確認</li>
          <li>完了</li>
        </ul>
      </nav>
      <div id="signup-form" class="conf">
        <div>
          <h2>申し込み日: {{ props.formDetails.signup_date }}</h2>
        </div>
        <div>
          <h2>組織情報</h2>
          <ul>
            <li>法人名</li>
            <li>{{ props.form.company.name }}</li>
          </ul>
          <ul>
            <li>法人名(フリガナ)</li>
            <li>{{ props.form.company.kana }}</li>
          </ul>
          <ul>
            <li>代表者</li>
            <li>{{ props.form.company.representative }}</li>
          </ul>
          <ul>
            <li>URL</li>
            <li>{{ props.form.company.url }}</li>
          </ul>
          <ul>
            <li>設立日</li>
            <li>{{ props.form.company.establishment_date }}</li>
          </ul>
          <ul>
            <li>資本金</li>
            <li>{{ props.form.company.capital ? props.form.company.capital + ' 円' : '' }}</li>
          </ul>
          <ul>
            <li>直近年度の売上高</li>
            <li>{{ props.form.company.recent_year_sales ? props.form.company.recent_year_sales + ' 円' : '' }}</li>
          </ul>
          <ul>
            <li>従業員数</li>
            <li>{{ props.form.company.number_of_employees ? props.form.company.number_of_employees + ' 名' : '' }}</li>
          </ul>
        </div>
        <div>
          <h2>パスワード</h2>
          <ul>
            <template v-if="props.formDetails.show_password_tab">
              <li>パスワード</li>
              <li>**********</li>
            </template>
            <template v-else>
              <li class="password">
                パスワードは既に登録済みとなります。<br>
                ログイン時は登録済みのパスワードをご利用ください。
              </li>
            </template>
          </ul>
        </div>
        <div>
          <h2>担当者情報</h2>
          <ul>
            <li>ご担当者名</li>
            <li>{{ props.form.contact.name }}</li>
          </ul>
          <ul>
            <li>ご担当者名(フリガナ)</li>
            <li>{{ props.form.contact.kana }}</li>
          </ul>
          <ul>
            <li>部署名</li>
            <li>{{ props.form.contact.department_name }}</li>
          </ul>
          <ul>
            <li>郵便番号</li>
            <li>{{ props.form.contact.postal_code }}</li>
          </ul>
          <ul>
            <li>都道府県</li>
            <li>{{ convertPref(props.form.contact.prefecture_code) }}</li>
          </ul>
          <ul>
            <li>市区町村</li>
            <li>{{ props.form.contact.city }}</li>
          </ul>
          <ul>
            <li>町名・番地</li>
            <li>{{ props.form.contact.street_address }}</li>
          </ul>
          <ul>
            <li>建物名等</li>
            <li>{{ props.form.contact.building }}</li>
          </ul>
          <ul>
            <li>電話番号</li>
            <li>{{ props.form.contact.phone }}</li>
          </ul>
          <ul>
            <li>メールアドレス</li>
            <li>{{ props.form.contact.email }}</li>
          </ul>
        </div>
        <div>
          <h2>請求先情報</h2>
          <template v-if="props.form.same">
            <ul>
              <li class="single">ご担当者様と同じ</li>
            </ul>
          </template>
          <template v-else>
            <ul>
              <li>請求先ご担当者名</li>
              <li>{{ props.form.billing.name }}</li>
            </ul>
            <ul>
              <li>請求先ご担当者名(フリガナ)</li>
              <li>{{ props.form.billing.kana }}</li>
            </ul>
            <ul>
              <li>部署名</li>
              <li>{{ props.form.billing.department_name }}</li>
            </ul>
            <ul>
              <li>郵便番号</li>
              <li>{{ props.form.billing.postal_code }}</li>
            </ul>
            <ul>
              <li>都道府県</li>
              <li>{{ convertPref(props.form.billing.prefecture_code) }}</li>
            </ul>
            <ul>
              <li>市区町村</li>
              <li>{{ props.form.billing.city }}</li>
            </ul>
            <ul>
              <li>町名・番地</li>
              <li>{{ props.form.billing.street_address }}</li>
            </ul>
            <ul>
              <li>建物名等</li>
              <li>{{ props.form.billing.building }}</li>
            </ul>
            <ul>
              <li>電話番号</li>
              <li>{{ props.form.billing.phone }}</li>
            </ul>
            <ul>
              <li>メールアドレス</li>
              <li>{{ props.form.billing.email }}</li>
            </ul>
          </template>
        </div>
        <div>
          <button type="button" class="back" v-on:click="backPage">入力画面に戻る
            <i size="30" class="material-icons">navigate_next</i>
          </button>
          <button type="button" v-on:click="toggleModal(true)">登録
            <i size="30" class="material-icons">navigate_next</i>
          </button>
        </div>
      </div>
    </section>
  </div>
  <template v-if="modal">
    <div class="signup-modal">
      <div>
        <div>
          <div class="header">
            <h1>お申し込み情報登録</h1>
            <i size="30" class="material-icons" v-on:click="toggleModal(false)">clear</i>
          </div>
          <p>登録しますか？</p>
          <div class="buttons">
            <button class="" v-on:click="toggleModal(false)">キャンセル</button>
            <button class="" v-on:click="submitForm">登録</button>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script setup>
import '../../../../stylesheet/signup.css'
import { createServiceAccount } from "../../../lib/axios/api_client";
import { onMounted, ref } from "vue";
import { pagePath } from "../../../utils/constants";
import { pref } from "../../../utils/constants"
import NeoidMethods from "../../../components/neoid_methods.vue";

const emits = defineEmits([ "emitTrigger" ])
const props = defineProps(
    {
      form: Object,
      formDetails: Object
    }
)

let modal = ref(false)
const neoidMethods = ref(null)

const toggleModal = (state) => {
  modal.value = state
}

const convertPref = (prefCode = null) => {
  if (prefCode) {
    const result = pref.find(({ value }) => value === prefCode)
    return result.name
  }
}

const backPage = () => {
  history.pushState(null, null, `/signup/${props.form.token_key}`)
  emits('emitTrigger')
}

const submitForm = async () => {

  let ip = "", sessionKey = "", rsaString = "";

  // パスワード登録の場合
  if (props.form.password && props.form.password !== "") {
    // 暗号化
    ({ ip, sessionKey, rsaString } = await neoidMethods.value?.generateRSAKeys(props.form.contact.email, props.form.password, 'SIGN_UP'))

    // パスワードクリア
    props.form.password = ""
  }

  // props.form.password除外
  const { password, same, ...rest } = props.form;

  // session_key, encrypt_string, ip追加
  const params = {
    ...rest,
    session_key: sessionKey,
    encrypt_string: rsaString,
    ip: ip
  }

  const result = await createServiceAccount(params)
  if (!result.successful) {
    return false
  }
  window.location.href = pagePath.signup.complete
}

</script>

<style scoped>
#signup h1 + section {
  width: auto;
}
.password {
  text-align: center !important;
}
</style>
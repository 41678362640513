<template>
  <Alert ref="alert"/>
  <template v-if="noticeList.length">
    <div class="card-body">
      <div class="table-responsive pb-3">
        <table class="table table-no-bordered table-hover text-left notices" cellspacing="0" width="100%" style="width:100%">
          <thead>
          <tr class="white-space-nowrap">
            <th>掲載日</th>
            <th>タイトル</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="item in noticeList">
            <tr :class=" item.is_checked ? '' : 'unread' ">
              <td>{{ item.release_date }}</td>
              <td><a :href="goToDetailPage(item.id, item.category_type)" >{{ item.title }}</a></td>
            </tr>
          </template>
          </tbody>
        </table>
        <Pagination ref="pagination" :pageNumber.number="pageNumber" :totalPages.number="totalPages" v-on:emitPage="updatePage"/>
      </div>
    </div>
  </template>
  <template v-else>
    お知らせはありません
  </template>
</template>
<script>
import Pagination from "./pagination.vue";
import Alert from "./alert_new.vue";
import { getNoticeList } from "../lib/axios/api_client";
import { extractPageNumber } from "../lib/common";
import notice from "../pages/notice.vue";
import { categoryTypes } from "../utils/notice";

export default {
  computed: {
    notice() {
      return notice
    }
  },
  props: ['categoryType'],
  components: {
    Alert,
    Pagination
  },
  data: function () {
    return {
      noticeList: [],
      pageNumber: "",
      totalPages: "",
      today: ""
    }
  },
  mounted: async function(){
    const pageNumber = extractPageNumber()
    if (!pageNumber) {
      await this.updatePage(0)
    } else {
      await this.updatePage(pageNumber)
    }
  },
  methods: {
    goToDetailPage: function(id, type) {
      if (categoryTypes.find(categoryType => categoryType.type === type)) {
        return `/mypage/notices/${id}?type=${type}&page=${this.pageNumber}`;
      } else {
        return `/mypage/alerts/${id}?type=${type}&page=${this.pageNumber}`;
      }
    },
    updatePage: async function(page){
      const result = await this.getNotice(this.categoryType, page);
      if(result){
        this.noticeList = result.notice_list
        this.pageNumber = result.page_number
        this.totalPages = result.total_pages
      }
    },
    getNotice: async function(categoryType, page) {
      const res = await getNoticeList(categoryType, page)
      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error');
        return false;
      }
      return res.data;
    }
  }
}
</script>

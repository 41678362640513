<template>
  <ClickOutside v-on:click-outside="close">
      <div class="dropdown-download-open" type="button" v-on:click="open">
        <i class="material-icons dropdown-download-icon cursor-pointer" >more_vert</i>
      </div>
      <transition name="slide-fade">
          <div v-if="show" class="card dropdown-download-dropdown-item">
              <span class="dropdown-item cursor-pointer" v-on:click="downloadCsv">Download(CSV)</span>
          </div>
      </transition>
  </ClickOutside>
</template>
<script>
import {createCsv, downloadCsv} from "../lib/download_action";
import ClickOutside from "./click_outside.vue";
export default {
  components: {ClickOutside},
  props: ['reportType', 'tableItem', 'keys', 'titles'],
  data() {
    return {
      show: false
    }
  },
  methods: {
    open(){
      this.show = !this.show
    },
    close(){
      this.show = false
    },
    downloadCsv(){
      const csvString = createCsv(this.tableItem, this.keys, this.titles)
      downloadCsv(csvString, this.reportType)
    }
  }
}
</script>
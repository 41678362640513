import { ref } from "vue";
import { addMonths, differenceInMonths, format } from "date-fns";
export function usePeriod() {
    const VALUE_FORMAT = "yyyy-MM";
    let start = ref({
        year: "",
        month: "",
        format: ""
    });
    let end = ref({
        year: "",
        month: "",
        format: ""
    });
    let isValid = ref(false);
    let range = ref(0);
    const getPeriod = () => {
        return {
            start: start.value,
            end: end.value,
            is_valid: isValid.value,
            range: range.value
        };
    };
    const getStartFormat = () => {
        return start.value.format;
    };
    const getEndFormat = () => {
        return end.value.format;
    };
    const setPeriod = (newPeriod) => {
        start.value = newPeriod.start;
        end.value = newPeriod.end;
        isValid.value = newPeriod.is_valid;
        range.value = newPeriod.range;
    };
    const setYearMonth = (type1, type2, value) => {
        if (type1 === "start") {
            start.value[type2] = value;
        }
        if (type1 === "end") {
            end.value[type2] = value;
        }
        if (checkPeriod()) {
            const { startDate, endDate } = _createStartEndDate();
            start.value.format = format(startDate, VALUE_FORMAT);
            end.value.format = format(endDate, VALUE_FORMAT);
            range.value = differenceInMonths(endDate, startDate);
            isValid.value = true;
        }
        else {
            isValid.value = false;
        }
    };
    const checkPeriod = () => {
        const thisMonth = new Date();
        const baseDate = new Date(thisMonth.getFullYear(), thisMonth.getMonth(), 1, 0, 0, 0);
        // 空判定
        if (start.value.year === "" || start.value.month === "" || end.value.year === "" || end.value.month === "") {
            return false;
        }
        const { startDate, endDate } = _createStartEndDate();
        // 期間の開始が現在以降か
        if (startDate < baseDate) {
            return false;
        }
        // 開始と終了が12ヶ月以内か, endがstart以下でないか
        const result = differenceInMonths(endDate, startDate);
        if (result <= 0 || result >= 12) {
            return false;
        }
        return true;
    };
    const createMonthlyMap = () => {
        const { startDate, endDate } = _createStartEndDate();
        const result = {};
        let baseDate = startDate;
        while (baseDate <= endDate) {
            let value = format(baseDate, VALUE_FORMAT);
            result[value] = 0;
            baseDate = addMonths(baseDate, 1);
        }
        return result;
    };
    const _createStartEndDate = () => {
        const startDate = _createDate(Number(start.value.year), Number(start.value.month));
        const endDate = _createDate(Number(end.value.year), Number(end.value.month));
        return { startDate, endDate };
    };
    const _createDate = (year, month) => {
        return new Date(year, month - 1, 1, 0, 0, 0);
    };
    return {
        start,
        end,
        isValid,
        range,
        setYearMonth,
        setPeriod,
        createMonthlyMap,
        getPeriod,
        getStartFormat,
        getEndFormat
    };
}

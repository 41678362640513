import { TsBaseClient, } from "../ts_base_client";
export class ApiClient extends TsBaseClient {
    constructor() {
        super();
    }
    async getContract() {
        const url = `/service-account`;
        return await this.get(url);
    }
    async updateContract(params) {
        const url = `/service-account`;
        return await this.put(url, params);
    }
    async updateContractStatusFromFreeToFormal() {
        const url = `/service-account/contract/status`;
        return await this.put(url);
    }
    async getContractor() {
        const url = `/service-account/contractor`;
        return await this.get(url);
    }
    async updateContractor(params) {
        const url = `/service-account/contractor`;
        return await this.put(url, params);
    }
    async getPayment() {
        const url = `/service-account/payment`;
        return await this.get(url);
    }
    async updatePayment(params) {
        const url = `/service-account/payment`;
        return await this.put(url, params);
    }
    async getCpSignupUrl() {
        const url = `/service-account/cp-signup-url`;
        return await this.get(url);
    }
    async getQuit() {
        const url = `/quit`;
        return await this.get(url);
    }
    async requestQuit(params) {
        const url = `/quit/request`;
        return await this.post(url, params);
    }
}

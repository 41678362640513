<template>
  <div>
    <ServiceAccountMethods ref="methods"/>
    <div v-if="!initialized">
      <div class="loader">Loading...</div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div>
            <ul class="nav">
              <li class="nav-item" v-if="contractor.permissions.show_edit_button">
                <a
                  class="nav-link btn btn-primary"
                  href="/mypage/service-account/contractor/edit"
                >
                  編集
                </a>
              </li>
            </ul>
          </div>
          <div class="card">
            <div class="card-header card-header-rose card-header-icon">
              <div class="card-title">
                <h4>組織情報</h4>
              </div>
            </div>
            <div class="card-body">
              <table class="table table-borderless table-responsive">
                <tbody>
                  <tr>
                    <th>契約ID</th>
                    <td>{{ contractor.signup_id }}</td>
                  </tr>
                  <tr>
                    <th>法人名</th>
                    <td>{{ contractor.company.name }}</td>
                  </tr>
                  <tr>
                    <th>法人名(フリガナ)</th>
                    <td>{{ contractor.company.kana }}</td>
                  </tr>
                  <tr>
                    <th>代表者名</th>
                    <td>{{ contractor.company.representative }}</td>
                  </tr>
                  <tr>
                    <th>URL</th>
                    <td>{{ contractor.company.url }}</td>
                  </tr>
                  <tr>
                    <th>設立日</th>
                    <td>{{ contractor.company.establishment_date }}</td>
                  </tr>
                  <tr>
                    <th>資本金</th>
                    <td>
                      <template v-if="contractor.company.capital !== null">
                        ¥{{ contractor.company.capital.toLocaleString() }}
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <th>前年度売上高</th>
                    <td>
                      <template v-if="contractor.company.recent_year_sales !== null">
                        ¥{{ contractor.company.recent_year_sales.toLocaleString() }}
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <th>従業員数</th>
                    <td>
                      <template v-if="contractor.company.number_of_employees !== null">
                        {{ contractor.company.number_of_employees.toLocaleString() }}
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card">
            <div class="card-header card-header-rose card-header-icon">
              <div class="card-title">
                <h4>ご担当者様情報</h4>
              </div>
            </div>
            <div class="card-body">
              <table class="table table-borderless table-responsive">
                <tbody>
                  <tr>
                    <th>ご担当者名</th>
                    <td>{{ contractor.contact.name }}</td>
                  </tr>
                  <tr>
                    <th>ご担当者名(フリガナ)</th>
                    <td>{{ contractor.contact.kana }}</td>
                  </tr>
                  <tr>
                    <th>部署名</th>
                    <td>{{ contractor.contact.department_name }}</td>
                  </tr>
                  <tr>
                    <th>郵便番号</th>
                    <td>{{ contractor.contact.postal_code }}</td>
                  </tr>
                  <tr>
                    <th>都道府県</th>
                    <td>{{ prefName }}</td>
                  </tr>
                  <tr>
                    <th>市区町村</th>
                    <td>{{ contractor.contact.city }}</td>
                  </tr>
                  <tr>
                    <th>町名・番地</th>
                    <td>{{ contractor.contact.street_address }}</td>
                  </tr>
                  <tr>
                    <th>建築物名等</th>
                    <td>{{ contractor.contact.building }}</td>
                  </tr>
                  <tr>
                    <th>電話番号</th>
                    <td>{{ contractor.contact.phone }}</td>
                  </tr>
                  <tr>
                    <th>メールアドレス</th>
                    <td>{{ contractor.contact.email }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from "vue";
import ServiceAccountMethods from "../../../components/service_account_methods.vue";
import { pref } from "../../../utils/constants";

const methods = ref(null)
let initialized = ref(false)
let contractor = ref(
  {
    signup_id: "",
    company: {
      name: "",
      kana: "",
      representative: "",
      url: "",
      establishment_date: "",
      capital: 0,
      recent_year_sales: 0,
      number_of_employees: 0
    },
    contact: {
      name: "",
      kana: "",
      department_name: "",
      postal_code: "",
      prefecture_code: "",
      city: "",
      street_address: "",
      building: "",
      phone: "",
      email: ""
    },
    permissions: {
      show_edit_button: false,
    }
  }
)

const getContractor = async () => {
  const result = await methods.value?.getContractor()
  if (!result) {
    return false
  }
  return result
}

const prefName = computed( () => {
  return pref.find( p => p.value === contractor.value.contact.prefecture_code).name
})

onMounted(async () => {
  const result = await getContractor()
  if(result){
    contractor.value = result
    initialized.value = true
  }
})

</script>
<style scoped>
</style>

<template>
  <Alert ref="alert"/>
  <div v-if="!initialized">
    <div class="loader">Loading...</div>
  </div>
  <div v-else>
    <div class="mx-auto" style="width: 80%;">
      <div class="card">
        <div class="card-header card-header-primary card-header-icon">
          <div class="card-icon">
            <i class="material-icons">assignment</i>
          </div>
          <h4 class="card-title">別名の設定</h4>
        </div>
        <template v-if="!isEdit">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-sm-3 text-right">契約ID</div>
                <div class="col-sm-9">{{ signup_id }}</div>
              </div>
              <div class="row">
                <div class="col-sm-3 text-right">別名</div>
                <div class="col-sm-9">{{ alias_name }}</div>
              </div>
            </div>
          <div class="card-footer ml-auto mr-auto">
            <a href="/mypage" class="btn btn-primary cursor-pointer">Home</a>
            <button type="submit" class="btn btn-primary" v-on:click="isEdit = true">編集</button>
          </div>
        </template>
        <template v-else>
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-sm-3 text-right">契約ID</div>
              <div class="col-sm-9">{{ signup_id }}</div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">別名</label>
              <div class="col-sm-7">
                <div class="form-group bmd-form-group">
                  <input type="text" class="form-control" minlength="0" maxlength="64" v-model="alias_name"/>
                  <p>
                    <small class="form-text text-muted">別名は{{ aliasNameMax }}文字までです。</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer ml-auto mr-auto">
            <span class="btn btn-primary cursor-pointer" v-on:click="isEdit = false">戻る</span>
            <button type="submit" class="btn btn-primary" v-on:click="updateAccount">更新</button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import Alert from "../components/alert_new.vue";
import { getContractAccountAlias, updateContractAccountAlias } from "../lib/axios/api_client";

export default {
  components: {
    Alert
  },
  data() {
    return {
      signup_id: "",
      alias_name: "",
      initialized: false,
      isEdit: false,
      aliasNameMax: 64
    }
  },
  mounted: async function() {
    const result = await this.getAccount()
    if(!result){
      return false
    }
    this.signup_id = result.signup_id
    this.alias_name = result.alias_name

    this.initialized = true
  },
  methods: {
    getAccount: async function () {
      const res = await getContractAccountAlias()

      if (!res.successful) {
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      return res.data
    },
    updateAccount: async function () {
      if(this.alias_name.length > this.aliasNameMax){
        this.$refs.alert.displayModal(this.aliasNameMax + "文字以内に入力してください", 'error')
        return false
      }

      const params = { alias_name: this.alias_name }
      const res = await updateContractAccountAlias(params)
      if (!res.successful) {
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      this.$refs.alert.displayModal('成功しました。', 'success')
      this.isEdit = false

      setTimeout(this._transition, 1000)

      return true
    },
    _transition() {
      window.location.reload()
    }
  }
}
</script>
<style scoped>
</style>

<template>
  <!-- Modal -->
  <div class="right-sizing-table" style="overflow: scroll">
    <table id="scenario-list" class="table table-striped table-hover text-nowrap">
      <thead>
        <tr>
          <template v-for="header in expireRiSpTh">
            <TableTh :tableKey="header.key" v-on:emitTrigger="doSort">{{ header.title }}</TableTh>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-if="Object.keys(expireRiSp).length">
          <template v-for="item in expireRiSp">
            <tr>
              <td>
                <TooltipText :text="item.aws_account_id" :tooltipText="item.account_name"/>
              </td>
              <td>{{ item.ri_sp }}</td>
              <td>{{ item.ri_sp_id }}</td>
              <td>{{ item.end_date}}</td>
              <td>{{ item.offering_type }}</td>
              <td>{{ item.service }}</td>
              <td>{{ item.instance_info }}</td>
              <td>{{ item.platform }}</td>
              <td>{{ item.region }}</td>
              <td>{{ item.availability_zone }}</td>
              <td>{{ item.scope }}</td>
              <td>{{ item.resource_number }}</td>
            </tr>
          </template>
        </template>
        <template v-else>
          <tr>
            <td colspan="12">
              設定はありません
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script setup>
import { ref } from "vue";
import TableTh from "./table_th.vue";
import { findTableHeaderIndex, selectSort } from "../lib/table";
import TooltipText from "./tooltip_text.vue";

const props = defineProps({
  expireRiSp: Object,
  awsAccountIds: Object
})

const expireRiSpTh = ref( [
  { title: "連結アカウント",            key: "aws_account_id",            sort: false },
  { title: "RI/SP",                   key: "ri_sp",                     sort: false },
  { title: "ID",                      key: "ri_sp_id",                  sort: false },
  { title: "終了日",                   key: "end_date",                  sort: false },
  { title: "タイプ",                   key: "offering_type",             sort: false },
  { title: "サービス",                  key: "service",                   sort: false },
  { title: "インスタンス情報",           key: "instance_info",             sort: false },
  { title: "プラットフォーム",           key: "platform",                   sort: false },
  { title: "リージョン",                key: "region",                     sort: false },
  { title: "アベイラビリティゾーン",      key: "availability_zone",          sort: false },
  { title: "スコープ",                  key: "scope",                     sort: false },
  { title: "リソース数",                key: "resource_number",            sort: false }
])

const doSort = (columnKey) => {
  const index = findTableHeaderIndex(expireRiSpTh.value, columnKey)
  const isSort = expireRiSpTh.value[index].sort
  const sort = selectSort(isSort, columnKey);

  props.expireRiSp.sort(sort)
  expireRiSpTh.value[index].sort = !isSort
}

</script>
<style>
.table>tbody>tr>th,
.table>tbody>tr>td {
  vertical-align: top;
  padding: 12px 8px;
  border-color: transparent;
}

table.table-striped.table-hover.text-nowrap {
  margin-bottom: 2rem;
}
</style>

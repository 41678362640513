<template>
  <template v-if="loading.assetSummaryTable[summaryType.type]">
    <div class="loader">Loading...</div>
  </template>
  <template v-else>
    <div class="right-sizing-table col-lg-6 col-md-6 col-sm-12">
      <div class="card summary">
        <div class="card-header">
          <div class="card-title">
            <div class="row">
              <div class="col">{{ summaryType.name }}</div>
              <div class="col text-right">
                <button
                  type="button"
                  :class="`btn ${popover.show ? ' btn-primary' : ''}`"
                  v-on:click="popoverChangeAction"
                  v-on:emitDashboardTrigger="getDashboard"
                >
                  <i class="material-icons">show_chart</i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div :class="`popover bs-popover-bottom bs-popover-bottom-docs${popover.show ? '' : ' invisible'}`">
          <div class="arrow" />
          <div class="popover-body">
            <IframeDashboard :ref="summaryType.ref" height="400px" />
          </div>
        </div>
        <div class="card-body">
          <table class="table table-striped table-hover">
            <thead class="sticky-top">
            <tr>
              <template v-for="elements in asset_summary_elements[summaryType.type].elements">
                <TableTh :tableKey="elements.key" v-on:emitTrigger="doSort">{{ elements.title }}</TableTh>
              </template>
            </tr>
            </thead>
            <tbody>
              <template v-for="(item, index) in tableItem">
                <tr>
                  <template v-for="elements in asset_summary_elements[summaryType.type].elements">
                    <td>{{ item[elements.key] }}</td>
                  </template>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import tableTh from "./table_th.vue";
import * as constants from '../utils/constants'
import IframeDashboard from "../components/iframe_dashboard.vue";

export default  {
  props: ['refs', 'summaryType', 'tableItem', 'loading'],
  components: {
    TableTh: tableTh,
    IframeDashboard: IframeDashboard
  },
  data: function () {
    return {
      asset_summary_elements: {
        asset_summary_computing: {
          path: constants.pagePath.dashboard.asset_summary_computing,
          elements: [
            { title: "サーバーステータス",  key: "server_status",   sort: false, isNumber: false },
            { title: "リソース数",         key: "count",           sort: false, isNumber: false }
          ]
        },
        asset_summary_database: {
          path: constants.pagePath.dashboard.asset_summary_database,
          elements: [
            { title: "サービス名",             key: "service_name",     sort: false, isNumber: false },
            { title: "リソース数",             key: "count",            sort: false, isNumber: false },
            { title: "データベース使用量(GB)",  key: "database_usage",   sort: false, isNumber: false }
          ]
        },
        asset_summary_disk: {
          path: constants.pagePath.dashboard.asset_summary_disk,
          elements: [
            { title: "サービス名",             key: "service_name",     sort: false, isNumber: false },
            { title: "リソース数",             key: "count",            sort: false, isNumber: false },
            { title: "ディスク使用量(GB)",     key: "disk_storage_usage",sort: false, isNumber: false }
          ]
        },
        asset_summary_network: {
          path: constants.pagePath.dashboard.asset_summary_network,
          elements: [
            { title: "サービス名",             key: "service_name",     sort: false, isNumber: false },
            { title: "リソース数",             key: "count",            sort: false, isNumber: false },
          ]
        },
        asset_summary_storage: {
          path: constants.pagePath.dashboard.asset_summary_storage,
          elements: [
            { title: "サービス名",             key: "service_name",     sort: false, isNumber: false },
            { title: "リソース数",             key: "count",            sort: false, isNumber: false },
            { title: "ストレージ使用量(GB)",    key: "disk_storage_usage",sort: false, isNumber: false }
          ]
        }
      },
      popover: {
        show: false
      }
    }
  },
  async mounted() {
  },
  computed: {},
  methods: {
      doSort(columnKey) {
        const reportType = this.summaryType.type
        // elementを探す
        const elementIndex = this.asset_summary_elements[reportType].elements.findIndex( element => element.key === columnKey)
        const element = this.asset_summary_elements[reportType].elements[elementIndex]

        // ソート
        const sort1 = (a,b) => this.compareSort(a,b,columnKey)
        const sort2 = (a,b) => this.compareSort(b,a,columnKey)
        const result = element.sort ? this.tableItem.sort(sort1) : this.tableItem.sort(sort2)

        // ソートの状態を更新
        const isSort = this.asset_summary_elements[reportType].elements[elementIndex].sort
        this.asset_summary_elements[reportType].elements[elementIndex].sort = !isSort

        // tableを更新
        this.$emit('emitTrigger', reportType, result)
      },
      compareSort(first, second, columnKey){
        if (first[columnKey] > second[columnKey]) {
          return -1;
        }
        if (first[columnKey] < second[columnKey]) {
          return 1;
        }
        return 0;
      },
      popoverChangeAction(){
        if(this.popover.show){
          this.popover.show = false;
        } else {
          this.$emit('emitDashboardTrigger', this.$refs[this.summaryType.ref], this.summaryType)
          this.popover.show = true;
        }
      }
  }
}
</script>
<style scoped>
.arrow {
  right: 30px;
}
.summary {
  height: 500px;
}
.right-sizing-table .card-body {
  overflow: auto;
}
.popover {
  top: 50px;
  left: 15px;
  right: 15px;
  max-width: none;
}
</style>

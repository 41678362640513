<template>
  <ClickOutside v-on:click-outside="close">
    <span class="btn btn-secondary dropdown-toggle" type="button" v-on:click="open" style="min-width: 270px">{{ title }}</span>
    <transition name="slide-fade">
      <div v-show="show" class="card dropdown_calendar">
        <div class="card-body mx-auto">
          <div class="row mb-3">
              <span class="col-lg-2 col-md-2 col-sm-2 text-right">
                  <label class="col-form-label label-strong">開始:</label>
              </span>
              <span class="col-lg-4 col-md-4 col-sm-4">
                  <input id="startDateRange" type="text" class="form-control" v-model="form_start" placeholder="YYYY/MM/DD">
              </span>
              <span class="col-lg-2 col-md-2 col-sm-2 text-right">
                  <label class="col-form-label label-strong">終了:</label>
              </span>
              <span class="col-lg-4 col-md-4 col-sm-4">
                  <input id="endDateRange"   type="text" class="form-control" v-model="form_end" placeholder="YYYY/MM/DD">
              </span>
          </div>
          <div class="row mb-3 ml-2 text-danger calendar-alert-height">
              <span v-show="disabled">
                {{ error.display }}
              </span>
          </div>
          <DatePicker
              ref="calendar"
              v-model.range="range"
              mode="date"
              :columns.number="2"
              :rules="rules"
              :min-date="date.minDate"
              :max-date="date.maxDate"
              :select-attribute="selectDragAttribute"
              :drag-attribute="selectDragAttribute"
              :timezone="timezone"
              @drag="updateDateRange('custom')"
              @update:modelValue="updateFormValue($event)"
          >
            <template #day-popover="{ format }">
              <div class="text-sm">
                {{ format(dragValue ? dragValue.start : range.start, 'MMM D') }}
                -
                {{ format(dragValue ? dragValue.end : range.end, 'MMM D') }}
              </div>
            </template>
          </DatePicker>
          <div class="border-bottom">
              <small>自動選択:</small>
              <button :class="is_button_active('day')"           @click="day">1D</button>
              <button :class="is_button_active('week')"          @click="week">7D</button>
              <button :class="is_button_active('current_month')" @click="current_month">今月</button>
              <button :class="is_button_active('month_3')"       @click="month_3">3M</button>
              <button :class="is_button_active('month_6')"       @click="month_6">6M</button>
              <button :class="is_button_active('year')"          @click="year">1Y</button>
              <button :class="is_button_active('custom')">カスタム</button>
          </div>
          <div class="mt-1 text-right">
              <button type="button" class="btn btn-secondary btn-sm" @click="close">キャンセル</button>
              <button type="button" :class="`btn btn-primary btn-sm ${ disabled ? 'disabled' : '' }`" @click="triggerEmit">適用</button>
          </div>
        </div>
        {{ date_range }}
      </div>
    </transition>
  </ClickOutside>
</template>
<script>
import {differenceInCalendarDays, format, isAfter, isBefore, isSameDay, subDays, subMonths, subYears} from "date-fns"
import {transformDate, formatDate, validateDateFormat} from "../lib/calendar"
import ClickOutside from "./click_outside.vue";
import { DatePicker } from 'v-calendar';

    export default {
        components: {
          ClickOutside,
          DatePicker
        },
        props: {
          date_range: String,
          start: String,
          end: String,
          timeUnit: String,
          reportType: String
        },
        emits: [
          'update:date_range',
          'update:start',
          'update:end',
          'updateReport'
        ],
        mounted() {
            const dateNow = new Date
            this.date.monthDay1   = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1, 0,0,0,0 )
            this.date.monthDayEnd = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1, 23,59,59,0 )
            this.date.minDate     = subYears(this.date.monthDay1, 1)
        },
        data() {
            return {
                date: {
                  now: new Date,
                  monthDay1: "",
                  monthDayEnd: "",
                  minDate: "",
                  maxDate: new Date
                },
                dragValue: null,
                range: {
                  start: new Date(),
                  end: new Date()
                },
                rules: [
                  {
                    hours: 9,
                    minutes: 0,
                    seconds: 0,
                    milliseconds: 0,
                  },
                  {
                    hours: 23,
                    minutes: 59,
                    seconds: 59,
                    milliseconds: 999,
                  }
                ],
                timezone: "Asia/Tokyo",
                form_start: '',
                form_end: '',
                show: false,
                disabled: false,
                error: {
                  display: '形式が正しくありません',
                  messages: {
                    badFormat: '形式が正しくありません',
                    overDateRange: '日別の時は半年以上の期間が設定できません',
                    error: '入力が正しくありません。'
                  }
                }
            }
        },
        watch: {
            form_start: function(value){
                if(value.length < 10){
                  this.updateDateRange('custom')
                }

                if(!this.validateDate(value)){
                  return false
                }

                const new_start = transformDate(value)
                this.updateCalendar(new_start, this.range.end)
            },
            form_end: function(value){
                if(value.length < 10){
                  this.updateDateRange('custom')
                }

                if(!this.validateDate(value)){
                  return false
                }

                const new_end = transformDate(value)
                this.updateCalendar(this.range.start, new_end)
            },
        },
        computed: {
            title() {
                if(this.date_range === 'day'){return '過去1日間'}
                if(this.date_range === 'week'){ return '過去7日間'}
                if(this.date_range === 'current_month'){ return '今月'}
                if(this.date_range === 'month_3'){ return '過去3ヶ月'}
                if(this.date_range === 'month_6'){ return '過去6ヶ月'}
                if(this.date_range === 'year'){ return '過去1年間'}
                if(this.date_range === 'custom'){
                    if(this.start === "" || this.end === ""){
                      return '選択中'
                    }else {
                      const startParsed = transformDate(this.start)
                      const endParsed   = transformDate(this.end)

                      const startFormat = format(startParsed, "yyyy年MM月dd日")
                      const endFormat   = format(endParsed, "yyyy年MM月dd日")

                      return `${startFormat} - ${endFormat}`
                    }
                }
                return '期間'
            },
            selectDragAttribute() {
                return {
                    popover: {
                        visibility: 'hover',
                        isInteractive: false,
                    },
                };
            }
        },
        methods: {
            open(){
                if(!this.show) {
                  if(this.date_range === 'day'){
                    this.day()
                  }else if(this.date_range === 'week'){
                    this.week()
                  }else if(this.date_range === 'current_month'){
                    this.current_month()
                  }else if(this.date_range === 'month_3'){
                    this.month_3()
                  }else if(this.date_range === 'month_6'){
                    this.month_6()
                  }else if(this.date_range === 'year'){
                    this.year()
                  }else if(this.date_range === 'custom'){
                    const startParsed = transformDate(this.start)
                    const endParsed   = transformDate(this.end)
                    this.updateCalendar(startParsed, endParsed)
                  }
                }
                this.show = !this.show
            },
            close(){
                this.show = false
            },
            move: async function(year, month){
              await this.$refs.calendar.move({ year: year, month: month });
            },
            is_button_active(value){
                return this.date_range === value ? 'btn btn-info btn-sm' : 'btn btn-link btn-sm'
            },
            validateDate(date){
                // 形式
                if(!validateDateFormat(date)){
                    this.disabled = true
                    this.error.display = this.error.messages.badFormat
                    return false 
                }

                // 期間
                const dateParsed = transformDate(date)
                const before = isBefore(dateParsed, this.date.now)
                const after = isAfter(dateParsed, this.date.minDate)
                const sameDay = isSameDay(dateParsed, this.date.minDate)

                if(before && after || before && sameDay){
                    this.disabled = false
                    return true
                }else{
                    this.disabled = true
                    this.error.display = this.error.messages.error
                    return false
                }
            },
            day(){
                const start = subDays(this.date.now,1)
                const end   = subDays(this.date.now,1)

                this.updateCalendar(start, end)
                this.updateDateRange('day')
            },
            week(){
                const start = subDays(this.date.now,7)
                const end   = subDays(this.date.now,1)

                this.updateCalendar(start, end)
                this.updateDateRange('week')
            },
            current_month(){
                const start = this.date.monthDay1
                const end   = subDays(this.date.now,1)

                this.updateCalendar(start, end)
                this.updateDateRange('current_month')
            },
            month_3(){
                const start = subMonths(this.date.monthDay1, 3)
                const end   = subDays(this.date.monthDayEnd, 1)

                this.updateCalendar(start, end)
                this.updateDateRange('month_3')
            },
            month_6(){
                const start = subMonths(this.date.monthDay1,6)
                const end   = subDays(this.date.monthDayEnd, 1)

                this.updateCalendar(start, end)
                this.updateDateRange('month_6')
            },
            year(){
                const start = subYears(this.date.monthDay1, 1)
                const end   = subDays(this.date.monthDayEnd, 1)

                this.updateCalendar(start, end)
                this.updateDateRange('year')
            },
            validateDateRange(start, end){
              let result = false

              if(this.timeUnit === 'daily' && this.reportType === 'cost_explorer'){
                const maxHalfMonthDays = 184
                const interval = differenceInCalendarDays(end, start) + 1

                result = (interval <= maxHalfMonthDays)
              }else{
                result = true
              }

              return result
            },
            updateCalendar(start, end){
                const bool = this.validateDateRange(start, end)
                if(!bool){
                  this.disabled = true
                  this.error.display = this.error.messages.overDateRange
                  return false
                }
                this.disabled = false

                this.range = { start: start, end: end }
                this.move(end.getFullYear(), end.getMonth())
            },
            updateFormValue(value){
              this.dragValue = value
              this.form_start = formatDate(value.start)
              this.form_end = formatDate(value.end)
            },
            updateDateRange(dateRange){
              this.$emit('update:date_range', dateRange)
            },
            triggerEmit(){
                if(this.date_range === 'custom'){
                    this.$emit('update:start', this.form_start)
                    this.$emit('update:end', this.form_end)
                }
                this.$emit('updateReport')
                this.show = false
            }
        }
    }
</script>
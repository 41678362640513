<template>
  <SimulatorMethods ref="methods"/>
  <Alert ref="alert"/>
  <div v-if="!loading.initialized">
    <div class="loader">Loading...</div>
    <ReportMethods ref="reportMethods" :reportType="report_type"/>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="pull-left"></div>
        <div class="pull-right">
          <ButtonHideFilter v-on:triggerEmit="changeDisplayFilter">{{ dashboardSizeClass.apply.text }}</ButtonHideFilter>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- start left-side -->
      <div :class="dashboardSizeClass.apply.class">
        <tableExpireRiSp ref="table" v-model:tableItem="dashboardTableItem" v-model:loading="loading" v-model:existsRiSpId="existsRiSpId" v-model:recommendType="scenario.recommend_type" v-on:emitTrigger="updateDashboardTableItem" v-on:getExpireRiSpDashboard="getExpireRiSpDashboard" v-on:bindCheckedItem="bindCheckedItem"/>
        <div v-if="!loading.settingCard">
          <div class="card">
            <div class="card-header">
              <div class="card-title">設定項目</div>
            </div>
            <div class="card-body">
              <p>
                RI/SPの中から期限切れになるものを選択してください。
              </p>
            </div>
          </div>
        </div>
        <div v-else>
          <form v-on:submit.prevent="createScenario(scenario)">
            <div class="card">
              <div class="card-header">
                <div class="card-title">設定項目</div>
              </div>
              <div class="card-body">
                <table class="table table-borderless table-responsive">
                  <tbody>
                  <tr>
                    <th>シナリオ名</th>
                    <td>
                      <div class="form-group">
                        <input id="scenario-name" class="form-control" type="text" minlength="1" maxlength="255" required v-model="scenario.name"/>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>推薦する種類</th>
                    <td>
                      <select class="form-control" required v-model="scenario.recommend_type">
                        <template v-for="recommendType in recommendTypesSelector[0].select">
                          <option :value="recommendType">
                            {{ recommendType }}
                          </option>
                        </template>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>支払い期間</th>
                    <td>
                      <select class="form-control" required v-model="scenario.payment_term">
                        <template v-for="paymentTerm in paymentTerms">
                          <option :value="paymentTerm.value">
                            {{ paymentTerm.name }}
                          </option>
                        </template>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>支払い方法</th>
                    <td>
                      <select class="form-control" required v-model="scenario.payment_option">
                        <template v-for="paymentOption in paymentOptions">
                          <option :value="paymentOption.name">
                            {{ paymentOption.name }}
                          </option>
                        </template>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>実行予定日</th>
                    <td>
                      <input class="form-control" type="date" required v-model="scenario.simulator_scheduled_date" :min="methods.minDate()" :max="methods.maxDate(scenario.expire_ri_sp)" @change="bindStartEnd()"/>
                    </td>
                  </tr>
                  <tr>
                    <th>算出するための分析日数</th>
                    <td>
                      <select class="form-control" required v-model="scenario.analysis_term" @change="bindStartEnd()">
                        <template v-for="analysisTerm in methods.calcAnalysisTermStartToEnd(scenario.simulator_scheduled_date)">
                          <option :value="analysisTerm.term">
                            {{ analysisTerm.term }} ({{ analysisTerm.start }} ~ {{ analysisTerm.end }} の利用料が対象)
                          </option>
                        </template>
                      </select>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div>
                  <small>※ 実行予定日は、翌日から選択した「期限切れの対象となるRI/SP」の終了日の２日前まで選択可能です。</small>
                </div>
              </div>
              <div class="card-header">
                <div class="card-title">結果通知</div>
              </div>
              <div class="card-body">
                <p>
                  結果が出た際はシナリオを作成したユーザにメールでお知らせします。
                </p>
                <div>
                  <button type="submit" class="btn btn-primary">作成</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- end left-side -->
      <!-- start right-side -->
      <div class="col-lg-3 col-md-3 col-sm-3" v-show="displayFilter">
        <FilterList :reportType="report_type" :reportFilter="aws_report.filter" v-on:updateFilter="updateFilter" v-on:updateDashboard="updateReport"/>
      </div>
      <!-- end right-side -->
    </div>
  </div>

</template>
<script setup>
import Alert from "../../../../components/alert_new.vue";
import { onMounted, ref } from "vue";
import SimulatorMethods from "../../../../components/recommend_simulator_methods.vue";
import { getDashboardElementExpireRiSpDashboard } from "../../../../lib/axios/api_client";
import { paymentTerms, paymentOptions, recommendTypeGroups } from "../../../../utils/simulator";
import tableExpireRiSp from "../../../../components/table_expire_ri_sp.vue";
import { expireRiSp, pagePath } from "../../../../utils/constants";
import FilterList from "../../../../components/filter_list.vue";
import ButtonHideFilter from "../../../../components/button_hide_filter.vue";
import * as constants from "../../../../utils/constants";
import { extractReportId, extractRiSpId } from "../../../../lib/common";
import ReportMethods from "../../../../components/report_methods.vue";

const alert = ref(null)
const methods = ref(null)
const reportMethods = ref(null)
const table = ref(null)

let loading = ref(false)
let existsRiSpId = ref(false)
let scenario = ref({})
let defaultDashboardTableItem = ref([])
let dashboardTableItem = ref({})
let recommendTypesSelector = ref({})
let displayFilter = ref(true)
let dashboardSizeClass = ref({})
let report_type = ref("")
let aws_report = ref({})

loading.value = {
  initialized: false,
  tableExpireRiSp: false,
  settingCard: false
}

const scenarioDefaultValue = {
  name: "",
  recommend_type: "",
  payment_term: 1,
  payment_option: "All Upfront",
  simulator_scheduled_date: "",
  analysis_term: 7,
  analysis_term_start_date: "",
  analysis_term_end_date: "",
  expire_ri_sp: []
}

scenario.value = scenarioDefaultValue

dashboardSizeClass.value = constants.dashboardSizeClass

report_type.value = constants.expireRiSp

const bindStartEnd = () => {
  let selected = methods.value?.calcAnalysisTermStartToEnd(scenario.value.simulator_scheduled_date).find( ({term}) => term === scenario.value.analysis_term)
  if (selected) {
    scenario.value.analysis_term_start_date = selected.start
    scenario.value.analysis_term_end_date = selected.end
  }
}

const getDashboardElement = async () => {
  const params = {
    report_type: report_type.value,
    filter: aws_report.value.filter
  }
  const result = await getDashboardElementExpireRiSpDashboard(params)
  if (!result) {
    return false
  }
  return result.data.filter_item
}

const getExpireRiSpDashboard = async () => {
  if (!defaultDashboardTableItem.value.length) {
    loading.value.tableExpireRiSp = false
    const result = await getDashboardElement()
    if (!result) {
      return false
    }
    defaultDashboardTableItem.value = dashboardTableItem.value = result
    loading.value.tableExpireRiSp = true
    return
  }
  dashboardTableItem.value = defaultDashboardTableItem.value
}

const updateDashboardTableItem = (newItems, selectedItem) => {
  dashboardTableItem.value = newItems
  if (scenario.value.recommend_type !== "" && scenario.value.recommend_type !== selectedItem) {
    window.alert('設定項目の推薦する種類が変更されました。ご確認ください。')
  }
  scenario.value.recommend_type = selectedItem
}

const bindCheckedItem = (checkedItem) => {
  scenario.value.expire_ri_sp = checkedItem
  if (scenario.value.expire_ri_sp[0]) {
    recommendTypesSelector = recommendTypeGroups.filter( item => {
      return item.name === scenario.value.expire_ri_sp[0].service || item.name === scenario.value.expire_ri_sp[0].offering_type
    })
  } else {
    table.value?.defaultCheckedItems()
    scenario.value = scenarioDefaultValue
  }
  loading.value.settingCard = checkedItem.length ? true : false
}

const createScenario = async (scenario) => {
  const result = await methods.value?.createScenario(scenario)
  if (!result) {
    return false
  }
  setTimeout(_transition,1000);
}

const _transition = () => {
  window.location.href = pagePath.recommendSimulatorScenario.index
}

// filter
const changeDisplayFilter = () => {
  displayFilter.value = !displayFilter.value
  dashboardSizeClass.value.apply = displayFilter.value ? dashboardSizeClass.value.half : dashboardSizeClass.value.full
}

const updateFilter = (filter) => {
  aws_report.value.filter[filter.key] = filter.value
}

const updateReport = async () => {
  loading.value.tableExpireRiSp = false
  bindCheckedItem([])
  const result = await getDashboardElement()
  if (!result) {
    return false
  }
  defaultDashboardTableItem.value = dashboardTableItem.value = result
  loading.value.tableExpireRiSp = true
}

onMounted(async () => {
  const report_id = extractReportId()
  const ri_sp_id = extractRiSpId()
  const report = await reportMethods.value?.getReport(report_id)
  if(!report){ return false }
  aws_report.value = report
  if (ri_sp_id) {
    aws_report.value.filter['ri_sp_id'].values = ri_sp_id
    existsRiSpId = true
  }

  await getExpireRiSpDashboard()
  loading.value.initialized = true
})

const recommendTypeChange = () => {
  window.confirm('設定項目の推薦する種類が変更されました。ご確認ください。')
}

</script>
<style scoped>
.table>tbody>tr>th,
.table>tbody>tr>td {
  vertical-align: top;
  padding: 12px 8px;
  border-color: transparent;
}
</style>

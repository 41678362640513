<template>
  <Alert ref="alert"/>
</template>
<script setup>
import { ref } from "vue";
import Alert from "./alert_new.vue";
import {ApiClient} from "../lib/axios/mfa/api_client";

const client = new ApiClient();
const alert = ref(null);

// ユーザのMFA認証設定情報を取得
const getMfaSettingList = async () => {
  const res = await client.getMfaSettingList();
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data;
}

// ユーザのメールに認証コードを送付
const sendAuthCode = async (mfaType) => {
  const res = await client.sendAuthCode(mfaType);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data;
}

const createMfaSetting = async (mfaType, form) => {
  const res = await client.createMfaSetting(mfaType, form);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return true;
}

const deleteMfaSetting = async (mfaType) => {
  const res = await client.deleteMfaSetting(mfaType);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return true;
}

defineExpose({
  sendAuthCode,
  getMfaSettingList,
  createMfaSetting,
  deleteMfaSetting,
})

</script>
<style scoped>

</style>
<template>
  <Alert ref="alert"/>
  <div v-if="!initialized">
    <div class="loader">Loading...</div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <ul class="tabs">
          <template v-for="tab in tabs">
            <template v-if="tab.page === currentTab">
              <li class="active">{{ tab.page }}</li>
            </template>
            <template v-else>
              <li v-on:click="changeTab(tab.path)">{{ tab.page }}</li>
            </template>
          </template>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-body">
            <ul class="tabs">
              <template v-for="serviceType in serviceTypes">
                <li v-on:click="change(serviceType.type)" v-bind:class="{'active': isActive === serviceType.type}">{{ serviceType.name }}</li>
              </template>
            </ul>
            <ul class="contents">
              <template v-for="serviceType in serviceTypes">
                <template v-if="isActive === serviceType.type">
                  <li>
                    <AssetUsageHistoryDashboardTable :serviceType="serviceType"/>
                  </li>
                </template>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AssetUsageHistoryDashboardTable from "../components/asset_usage_history_dashboard_table.vue";
import Alert from "../components/alert_new.vue";
import { extractServiceType } from "../lib/common";
import { serviceTypes, tabs } from "../utils/asset_usage";

export default {
  components: {
    AssetUsageHistoryDashboardTable,
    Alert
  },
  data() {
    return {
      serviceTypes: serviceTypes,
      tabs: tabs,
      currentTab: "history",
      isActive: "",
      initialized: false,
    }
  },
  mounted: async function() {
    const serviceType = extractServiceType();
    if(!serviceType){
      this.isActive = "ec2";
    } else {
      this.isActive = serviceType;
    }

    this.initialized = true;
  },
  methods: {
    changeTab: function(path){
      window.location.href = path + "?type=" + this.isActive;
    },
    change: function(type){
      this.isActive = type;
    }
  }
}
</script>
<style scoped>
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.tabs {
  overflow: hidden;
}
.tabs li,
.tabs label {
  float: left;
  padding: 10px 20px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: .3s;
}
.tabs li:not(:first-child),
.tabs label:not(:first-of-type) {
  border-left: none;
}
.tabs li.active,
.tabs :checked + label {
  background-color: #002341;
  border-color: #000;
  color: #fff;
  cursor: auto;
}
.contents {
  overflow: hidden;
  margin-top: -1px;
}
.contents li {
  padding: 20px;
  border: 1px solid #ccc;
}

.jquery .contents li {
  display: none;
}
.jquery .contents li.active {
  display: block;
}

.vue_radio input {
  display: none;
}
</style>
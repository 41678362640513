import { TsBaseClient, DOWNLOAD_CSV_HEADER, DOWNLOAD_PDF_HEADER, UPLOAD_CSV_HEADER } from "../ts_base_client";
export class ApiClient extends TsBaseClient {
    constructor() {
        super();
    }
    // 予算関連
    async getBudgets(params) {
        const url = `/budgets`;
        return await this.get(url, params);
    }
    async getBudget(id) {
        const url = `/budgets/${id}`;
        return await this.get(url);
    }
    async getBudgetDetail(id, params) {
        const url = `/budgets/detail/${id}`;
        return await this.get(url, params);
    }
    async getBudgetDetailEdit(id) {
        const url = `/budgets/detail/edit/${id}`;
        return await this.get(url);
    }
    async getBudgetDetailAlert(id) {
        const url = `/budgets/detail/alert/${id}`;
        return await this.get(url);
    }
    async listBudgets(params) {
        const url = `/budgets/list`;
        return await this.get(url, params);
    }
    async downloadBudgetDetail(id) {
        const url = `/budgets/detail/direct_downloads/${id}`;
        return await this.get(url, {}, DOWNLOAD_CSV_HEADER, "blob");
    }
    async downloadExpireBudgetDetail(id, currency, tax) {
        const url = `/budgets/detail/expire/direct_downloads/${id}?currency=${currency}&tax=${tax}`;
        return await this.get(url, {}, DOWNLOAD_PDF_HEADER, "blob");
    }
    async uploadPricingCalculatorCSV(id, formData) {
        const url = `/budgets/direct_uploads/${id}`;
        return await this.post(url, formData, UPLOAD_CSV_HEADER);
    }
    async uploadBudgetEstimate(id, params) {
        const url = `/budgets/estimate/uploads/${id}`;
        return await this.post(url, params);
    }
    async createBudget(params) {
        const url = `/budgets`;
        return await this.post(url, params);
    }
    async updateBudget(id, params) {
        const url = `/budgets/edit/${id}`;
        return await this.put(url, params);
    }
    async deleteBudget(id) {
        const url = `/budgets/${id}`;
        return await this.delete(url);
    }
    async lockBudget(id) {
        const url = `/budgets/lock/${id}`;
        return await this.patch(url);
    }
    async unlockBudget(id) {
        const url = `/budgets/unlock/${id}`;
        return await this.patch(url);
    }
    async getDashboardElementBudgetList(params) {
        const url = "/dashboard/element/dashboard/budget/list";
        return await this.post(url, params);
    }
    async getDashboardElementBudgetHistory(params) {
        const url = "/dashboard/element/dashboard/budget/history";
        return await this.post(url, params);
    }
    // DS 実績・予測
    async estimateBudget(params) {
        const url = `/budget/estimate/cost_budget`;
        return await this.post(url, params);
    }
}

<template>
  <span>
      <ReportMethods ref="reportMethods" :reportType="reportType"/>
      <ReportCreateButton type="report" :reportType="reportType" v-on:childMethod="createAction"/>
      <ReportUpdateButton :report="report" :edited="edited" v-on:childMethod="updateAction"/>
      <ReportListButton
          :report_list="reportList"
          :pageNumber="pageNumber"
          :totalPages="totalPages"
          type="report"
          v-on:childMethod="selectAction"
          v-on:pagingAction="pagingAction">
        レポート
      </ReportListButton>
  </span>
</template>
<script>
import ReportCreateButton from "./report_create_button.vue";
import ReportListButton from "./report_list_button.vue";
import ReportUpdateButton from "./report_update_button.vue";
import ReportMethods from "./report_methods.vue"
import Alert from "./alert_new.vue";

export default {
  props: {
    reportType: String,
    report: Object,
    edited: Boolean
  },
  emits: [
    'update:report',
    'update:edited',
    'updateReport'
  ],
  components: {
    'ReportCreateButton': ReportCreateButton,
    'ReportListButton': ReportListButton,
    'ReportUpdateButton': ReportUpdateButton,
    'ReportMethods'     : ReportMethods,
    'Alert': Alert
  },
  data() {
    return {
      reportList: [],
      pageNumber: 0,
      totalPages: 1,
    }
  },
  mounted: async function(){
    await this.listAction()
  },
  methods: {
    createAction: async function (name) {
      const reportId = await this.$refs.reportMethods.createReport(name, this.report)
      if (reportId) {
        await this.listAction()
        this.$emit('update:edited', false)

        let newReport = this.report
        newReport.id = reportId
        this.$emit('update:report', newReport)
      }
    },
    updateAction: async function () {
      const isSuccess = await this.$refs.reportMethods.updateReport(this.report)
      if (isSuccess) {
        await this.listAction()
        this.$emit('update:edited', false)
      }
    },
    selectAction: async function (reportId) {
      const newReport = await this.$refs.reportMethods.getReport(reportId)
      if (newReport) {
        this.$emit('update:report', newReport)
        this.$emit('update:edited', false)

        this.$emit('updateReport')
      }
    },
    listAction: async function(page=0){
      const result = await this.$refs.reportMethods.getReportList(page)
      if(result){
        this.reportList = result.reports
        this.pageNumber = result.page_number
        this.totalPages = result.total_pages
      }
    },
    pagingAction: async function(action){
      if(action === "back"){
        if(this.pageNumber > 0){
          await this.listAction(this.pageNumber - 1)
        }
      }else if(action === "next"){
        if(this.pageNumber  < this.totalPages - 1 ){
          await this.listAction(this.pageNumber + 1)
        }
      }
    }
  }
}
</script>
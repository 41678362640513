<template>
    <span class="row">
        <!--modal-->
        <Alert ref="alert"/>
      <!--modal-->
        <span class="col-lg-12 col-md-12 col-sm-12 dropdown">
            <span @click="loadFilterItem" :id="filterId" class="btn btn-secondary btn-block dropdown-toggle multiline" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <template v-if="reportFilter[filterKey].values.length === 0">
                <slot></slot>
              </template>
              <template v-else>
                <span class="text-info"><slot></slot></span>
              </template>
            </span>
            <div class="dropdown-menu" :aria-labelledby="filterId">
              <form class="px-4">
                <div class="form-group">
                  <span class="pr-1">選択中</span>
                  <span class="pr-1">
                    <span class="badge badge-secondary badge-pill">{{ selectedItem.length }}</span>
                  </span>
                  <span  @click="clearSelected" class="clear-selected-button pr-1">選択解除</span>
                  <span  @click="selectAll" class="clear-selected-button pr-1">全て選択</span>
                </div>
              </form>
              <div class="scrollable-menu-selected">
                <form class="px-4 dropdown-filter-scrollable">
                  <div v-for="item in selectedItem">
                    <div class="form-check">
                      <label class="form-check-label" :for="inputId(filterKey, item.name)">
                        <input class="form-check-input" type="checkbox" :id="inputId(filterKey, item.name)" :value="item.name" v-model="selected">
                        {{ item.name }}
                        <span class="form-check-sign form-check-sign-fix"><span class="check"></span></span>
                      </label>
                    </div>
                  </div>
                </form>
              </div>
              <div class="dropdown-divider"></div>
              <form class="px-4">
                <div class="input-group">
                  <input v-model="keyword" type="text" class="form-control" placeholder="Search" autocomplete="off"/>
                  <span class="input-group-btn">
                      <span type="button" class="btn btn-fab btn-fab-mini btn-round btn-default" @click="clearKeyword">
                          <i class="material-icons">clear</i>
                      </span>
                  </span>
                </div>
                <template v-if="condition">
                  <div class="form-group">
                    <div class="form-check form-check-radio d-inline-block">
                      <label class="form-check-label">
                        <input class="form-check-input" type="radio" id="currencyType1" value="include" v-model="isInclude">含む
                        <span class="circle"><span class="check"></span></span>
                      </label>
                    </div>
                    <div class="form-check form-check-radio d-inline-block">
                      <label class="form-check-label">
                        <input class="form-check-input" type="radio" id="currencyType2" value="exclude" v-model="isInclude">除く
                        <span class="circle"><span class="check"></span></span>
                      </label>
                    </div>
                  </div>
                </template>
              </form>
              <div class="scrollable-menu">
                <template v-if="isLoad">
                    <span class="dropdown-item text-center">Loading・・・</span>
                </template>
                <template v-else>
                  <template v-if="filteredItem.length < 1">
                      <span class="dropdown-item text-center">No Data</span>
                  </template>
                  <template v-else>
                    <form class="px-4 dropdown-filter-scrollable">
                      <div v-for="item in filteredItem">
                        <div class="form-check">
                          <label class="form-check-label" :for="inputId(filterKey, item.name)">
                            <input class="form-check-input" type="checkbox" :id="inputId(filterKey, item.name)" :value="item.name" v-model="selected">
                            {{ item.name }}
                            <span class="form-check-sign form-check-sign-fix"><span class="check"></span></span>
                          </label>
                        </div>
                      </div>
                    </form>
                  </template>
                </template>
              </div>
              <div class="d-block">
                <div @click="emitFilterItem" class="btn btn-secondary btn-block text-center d-inline-block pull-center filter-item-apply-button" type="button">保存</div>
              </div>
            </div>
        </span>
    </span>
</template>

<script setup>
import { ref, computed } from "vue";
import Alert from './alert_new.vue'
import {getFilterItems} from "../lib/axios/api_client";
import { useDashboardFilter } from "../composables/dashboard_filter";

const { selected, filteredItem, isInclude, keyword, selectedItem,
  setSelected, setFilterItem, getSelected,
  clearKeyword, selectAll, clearSelected
} = useDashboardFilter()

const props = defineProps(
  {
    filterKey: String,
    reportType: String,
    reportFilter: Object,
    condition: Boolean
  }
)
const emits = defineEmits([ "emitFilterItem" ])

const alert = ref(null)
let isLoad = ref(false)
let isFilterItemFetched = false

const filterId = computed( () => {
  return `${props.filterKey}_filter`
})

const inputId = (filterKey, name) => {
  return`${filterKey}-${name.replace(' ', '')}`
}

const loadFilterItem = async () => {
  setSelected( props.filterKey, props.reportFilter)
  clearKeyword()
  if(!isFilterItemFetched){
    const result = await getFilterItem()
    if(!result){ return false }
    setFilterItem(result)
    isFilterItemFetched = true
  }
}

const getFilterItem = async () => {
  changeLoadStatus()
  const params = { filter_key: props.filterKey, report_type: props.reportType}
  const res = await getFilterItems(params)
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }

  changeLoadStatus()
  return res.data.filter_item
}

const changeLoadStatus = () => {
  isLoad.value = !isLoad.value
}

const emitFilterItem = () => {
  const params = { key: props.filterKey, value: getSelected() }
  emits('emitFilterItem', params)
}
</script>
<style scoped>
.filter-item-apply-button {
  width: 99%
}
.multiline {
  word-wrap: break-word;
  white-space: normal !important;
}
</style>

import { categoryTypeList } from "../utils/notice";
import { alertCategoryTypeList } from "../utils/alert";
import { serviceTypeList } from "../utils/asset_usage";

function extractId(index) {
    const pathArray = location.pathname.split("/")
    return pathArray[index]
}

function extractReportId(){
    const report_id = urlSearchParams("reportId")
    return report_id ? report_id : 0
}

function extractFilterId(){
    const filter_id = urlSearchParams("filterId")
    return filter_id ? filter_id : 0
}

function extractCategoryType(){
    const categoryType = urlSearchParams("type")
    return categoryTypeList.includes(categoryType) ? categoryType : 0
}

function extractServiceType(){
    const serviceType = urlSearchParams("type")
    return serviceTypeList.includes(serviceType) ? serviceType : 0
}

function extractAlertCategoryType(){
    const categoryType = urlSearchParams("type")
    return alertCategoryTypeList.includes(categoryType) ? categoryType : 0
}

function extractBudgetId(){
    const budgetId = urlSearchParams("budget_id")
    return budgetId ? budgetId : 0
}

function extractRiSpId(){
    const riSpId = urlSearchParams("id")
    if (riSpId) {
        const ids = riSpId.split(',')
        return ids
    }
    return 0
}

function extractPageNumber(){
    const pageNumber = urlSearchParams("page")
    return pageNumber ? pageNumber : 0
}

function urlSearchParams(name){
    const queryString = window.location.search
    const params = new URLSearchParams(queryString);
    return params.get(name);
}

function changeOrderEmails(emails){
    let result = []
    for(const email of emails){
        if(email){ result.push(email) }
    }

    while(result.length < 3){
        result.push("")
    }

    return result
}

export {
    extractId,
    extractReportId,
    extractFilterId,
    extractCategoryType,
    extractServiceType,
    extractAlertCategoryType,
    extractBudgetId,
    extractRiSpId,
    extractPageNumber,
    urlSearchParams,
    changeOrderEmails
}

<template>
  <table class="table table-borderless mt-5" v-if="initialized">
    <tbody>
    <tr v-if="form.id !== 0">
      <th>subscription種類</th>
      <td>予算</td>
    </tr>
    <tr>
      <th>予算選択</th>
      <td>
        <select class="form-control" v-model="form.budget_id" @change="bindValue()" required>
          <option value="">予算を選択</option>
          <template v-for="(name, id) in budgetNames">
            <option :value="id">
              {{ name }}
            </option>
          </template>
        </select>
      </td>
    </tr>
    <tr>
      <th>名前</th>
      <td>予算：{{ budgetNames[form.budget_id] }}</td>
    </tr>
    <tr>
      <th>配信する</th>
      <td>
        <div class="form-check form-check-radio" style="display: inline-block">
          <label class="form-check-label">
            <input class="form-check-input" type="radio" id="notification1" value="true" v-model="form.enabled">配信する
            <span class="circle"><span class="check"></span></span>
          </label>
        </div>
        <div class="form-check form-check-radio" style="display: inline-block">
          <label class="form-check-label">
            <input class="form-check-input" type="radio" id="notification2" value="false" v-model="form.enabled">配信しない
            <span class="circle"><span class="check"></span></span>
          </label>
        </div>
      </td>
    </tr>
    <tr>
      <th>周期</th>
      <td>
        <select class="form-control" v-model="form.cycle_type" @change="changeCycle()" required>
          <template v-for=" item in cycleTypes">
            <option :value="item.value">{{ item.name }}</option>
          </template>
        </select>
      </td>
    </tr>
    <template v-if="form.cycle_type === 'monthly'">
      <tr>
        <th></th>
        <td>毎月 / 4日 / 10時</td>
      </tr>
    </template>
    <template v-else>
      <tr>
        <th></th>
        <td>
          <select class="form-control" v-model="form.cycle_schedule.day_of_week" required>
            <template v-for="item in dayOfWeeks">
              <option :value="item.value">{{ item.name }}</option>
            </template>
          </select>
        </td>
      </tr>
      <tr>
        <th></th>
        <td>10時</td>
      </tr>
    </template>
    <tr>
      <th>配信終了日</th>
      <td>
        予算期間翌月
        <template v-if="form.cycle_type === 'weekly'">
          （配信終了月内はデータの更新がない場合は配信しません）
        </template>
      </td>
    </tr>
    <tr>
      <th>配信メールアドレス</th>
      <td>
        <input id="inputEmail1" class="form-control" type="email" v-model="form.emails[0]" v-on:input="emailValidation"/>
      </td>
    </tr>
    <tr>
      <th></th>
      <td>
        <input id="inputEmail2" class="form-control" type="email" v-model="form.emails[1]" v-on:input="emailValidation"/>
      </td>
    </tr>
    <tr>
      <th></th>
      <td>
        <input id="inputEmail3" class="form-control" type="email" v-model="form.emails[2]" v-on:input="emailValidation"/>
        <p>
          <span class="text-danger">{{ message }}</span>
        </p>
      </td>
    </tr>
    <tr>
      <th>PDF サイズ</th>
      <td>
        <select class="form-control" v-model="form.size_type">
          <template v-for=" item in sizeTypes">
            <option :value="item.value">{{ item.name }}</option>
          </template>
        </select>
      </td>
    </tr>
    <tr>
      <th>PDF 向き</th>
      <td>
        <select class="form-control" v-model="form.landscaped">
          <template v-for=" item in landscapeTypes">
            <option :value="item.value">{{ item.name }}</option>
          </template>
        </select>
      </td>
    </tr>
    </tbody>
  </table>
</template>
<script>
import * as constants from "../utils/report_subscriptions";
import FormEndDate from "./report_subscription_form_end_date.vue";
import {getAlertsBudgetNames} from "../lib/axios/api_client";
import { ApiClient } from "../lib/axios/budget/api_client";

const client = new ApiClient()

export default {
  props: {
    reportSubscription: Object,
    disabled: Boolean
  },
  emits: [
    'update:disabled'
  ],
  components: {
    FormEndDate: FormEndDate
  },
  created: async function() {
    await this.getBudgetNames();
  },
  mounted() {
    this.form = this.reportSubscription
    this.emailValidation()
    this.initialized = true
  },
  data: function(){
    return {
      initialized: false,
      budgetNames: [],
      form: constants.reportBudgetSubscription,
      termTypes: constants.termTypes,
      cycleTypes: constants.cycleTypes,
      sizeTypes: constants.sizeTypes,
      landscapeTypes: constants.landscapeTypes,
      dayOfWeeks: constants.dayOfWeeks,
      message: ""
    }
  },
  methods: {
    getBudgetNames: async function () {
      const res = await getAlertsBudgetNames();
      if (!res.successful) {
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }
      this.budgetNames = res.data.budget_names
      return true
    },
    getBudgetEndDate: async function(id, showAlert=false) {
      const res = await client.getBudget(id);
      if (!res.successful) {
        alert.value.displayModal(res.message, 'error');
        return false;
      }

      if(showAlert){
        alert.value.displayModal("成功しました。", 'success');
      }

      return res.data.end_date
    },
    bindValue: async function () {
      // 名前
      this.form.name = this.budgetNames[this.form.budget_id]
      // 配信終了日
      const selectBudgetEndDate = await this.getBudgetEndDate(this.form.budget_id)
      this.form.end_date = this.calcEndDate(selectBudgetEndDate)
    },
    calcEndDate: function (selectBudgetEndDate) {
      const yearMonth = selectBudgetEndDate.split('-')
      const year = yearMonth[0]
      const month = this.monthIndex(yearMonth[1])

      let endDate = new Date(year, month, 0)
      return endDate.getFullYear() + '-' + ((endDate.getMonth() + 1).toString().padStart(2, "0")) + '-' + endDate.getDate()
    },
    monthIndex: function (month) {
      return Number(month) + 1
    },
    changeCycle: function () {
      this.form.cycle_schedule.day_of_week = this.dayOfWeeks[0].value
    },
    syncDisabled(bool){
      this.$emit('update:disabled', bool)
    },
    emailValidation() {
      if(this.form.emails[0] === "" && this.form.emails[1] === ""  && this.form.emails[2] === "" ){
        this.syncDisabled(true)
        this.message = "最低1件登録する必要があります"
        return true
      }

      if(this.form.emails[0] && this.form.emails[1] && this.form.emails[0] === this.form.emails[1]){
        this.syncDisabled(true)
        this.message = "同じメールアドレスは登録できません"
        return true
      }

      if(this.form.emails[0] && this.form.emails[2] && this.form.emails[0] === this.form.emails[2]){
        this.syncDisabled(true)
        this.message = "同じメールアドレスは登録できません"
        return true
      }

      if(this.form.emails[1] && this.form.emails[2] && this.form.emails[1] === this.form.emails[2]){
        this.syncDisabled(true)
        this.message = "同じメールアドレスは登録できません"
        return true
      }

      this.syncDisabled(false)
      this.message = ""
      return false
    }
  }
}
</script>

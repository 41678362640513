<template>
  <table class="table table-borderless mt-2">
    <tbody>
    <tr>
      <th style="width: 86px">通貨</th>
      <td>
        <div class="form-group">
          <div class="form-check form-check-radio d-inline-block">
            <label class="form-check-label">
              <input class="form-check-input" type="radio" id="currencyType1" value="usd" v-model="form.currency">$
              <span class="circle"><span class="check"></span></span>
            </label>
          </div>
          <div class="form-check form-check-radio d-inline-block">
            <label class="form-check-label">
              <input class="form-check-input" type="radio" id="currencyType2" value="jpy" v-model="form.currency">円
              <span class="circle"><span class="check"></span></span>
            </label>
          </div>
        </div>
      </td>
    </tr>
    <tr v-show="form.currency === 'jpy'">
      <th>予算レート</th>
      <td>
        <div class="width-30">
          <input id="budget-name" class="form-control" type="number" min="0.01" max="1000" step="0.01" v-model="form.exchangeRate"/>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</template>
<script setup>
import { onMounted, reactive, watch } from "vue";

const props = defineProps({
  currency: String,
  exchangeRate: Number
})
const emits = defineEmits(["update:currency", "update:exchangeRate"])

let form = reactive({
  currency: "usd",
  exchangeRate: 0,
})

watch(form, ( value ) => {
  emits('update:currency', form.currency)
  emits('update:exchangeRate', form.exchangeRate)
})

onMounted(async () => {
  form.currency = props.currency
  form.exchangeRate = props.exchangeRate
})

</script>

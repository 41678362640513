<template>
  <div v-if="!initialized">
    <div class="loader">Loading...</div>
    <ReportMethods ref="reportMethods" :reportType="reportType"/>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="pull-left">
          <FilterButtons
              :reportType="reportType"
              v-model:report="aws_report"
              v-on:updateReport="updateReport"
          />
        </div>
        <div class="pull-right">
          <ButtonHideFilter v-on:triggerEmit="changeDisplayFilter">{{ dashboardSizeClass.apply.text }}</ButtonHideFilter>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- start left-side -->
      <div :class="dashboardSizeClass.apply.class">
        <div class="card">
          <div class="card-header"></div>
          <div class="card-body">
            <IframeDashboard ref="dashboard" height="600px"/>
          </div>
        </div>
      </div>
      <!-- end left-side -->
      <!-- start right-side -->
      <div class="col-lg-3 col-md-3 col-sm-3" v-show="displayFilter">
        <FilterList :reportType="reportType" :reportFilter="aws_report.filter" v-on:updateFilter="updateFilter" v-on:updateDashboard="updateReport"/>
      </div>
      <!-- end right-side -->
    </div>
  </div>
</template>

<script>
import * as constants       from '../utils/constants'
import FilterList from '../components/filter_list.vue'
import ButtonHideFilter from "../components/button_hide_filter.vue";
import IframeDashboard from "../components/iframe_dashboard.vue";
import FilterButtons from "../components/filter_buttons.vue";
import {extractReportId} from "../lib/common";
import ReportMethods from "../components/report_methods.vue";

export default {
  mounted: async function() {
    const report_id = extractReportId()
    const report = await this.$refs.reportMethods.getReport(report_id)
    if(!report){ return false }
    this.aws_report = report

    this.initialized = true

    this.$nextTick(async () => {
      await this.$refs.dashboard.generateDashboard(this.aws_report)
    })
  },
  components: {
    'ButtonHideFilter'     : ButtonHideFilter,
    'FilterList': FilterList,
    'IframeDashboard'      : IframeDashboard,
    'ReportMethods'        : ReportMethods,
    'FilterButtons'        : FilterButtons
  },
  data: function () {
    return {
      reportType: constants.reportTypeSpRecommend,
      embed_url: constants.defaultUrl,
      aws_report: {},
      displayFilter: true,
      dashboardSizeClass: constants.dashboardSizeClass,
      initialized: false
    }
  },
  methods: {
    changeDisplayFilter(){
      this.displayFilter = !this.displayFilter
      this.dashboardSizeClass.apply = this.displayFilter ? this.dashboardSizeClass.half : this.dashboardSizeClass.full
    },
    updateFilter: async function(filter) {
      this.aws_report.filter[filter.key] = filter.value
    },
    updateReport(){
      this.$refs.dashboard.generateDashboard(this.aws_report)
    },
  }
}
</script>

<style scoped>
</style>

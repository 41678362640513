export const findTableHeaderIndex = (tableHeaders, columnKey) => {
    return tableHeaders.findIndex(tableHeader => tableHeader.key === columnKey);
};
export const selectSort = (isSort, columnKey) => {
    // ソート
    const sort1 = (a, b) => compareSort(a, b, columnKey);
    const sort2 = (a, b) => compareSort(b, a, columnKey);
    return isSort ? sort1 : sort2;
};
const compareSort = (first, second, columnKey) => {
    if (first[columnKey] > second[columnKey]) {
        return -1;
    }
    if (first[columnKey] < second[columnKey]) {
        return 1;
    }
    return 0;
};

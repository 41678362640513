<template>
    <span>
        <button type="button" class="btn btn-primary btn-sm text-white"  data-toggle="modal" data-target="#nameEditModal">名前をつけて保存</button>
      <!-- Modal -->
        <div class="modal fade" id="nameEditModal" tabindex="-1" role="dialog" aria-labelledby="nameEditModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="nameEditModalLabel">名前をつけて保存</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form class="form">
                            <div class="card-body">
                                <div class="form-group bmd-form-group">
                                    <div class="input-group">
                                        <input type="text" class="form-control" v-model="name" placeholder="名前">
                                    </div>
                                    <div class="input-group">
                                        <div v-show="validation.result">
                                            <p class="text-danger pl-3">{{validation.message}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">キャンセル</button>
                        <button type="button" class="btn btn-primary" v-on:click.stop.prevent="createReport">保存</button>
                    </div>
                </div>
            </div>
        </div>
      <ReportMethods ref="reportMethods" reportType=""/>
      <FilterMethods ref="filterMethods" reportType=""/>
    </span>
</template>
<script>
import ReportMethods from "./report_methods.vue";
import FilterMethods from "./filter_methods.vue"

export default {
  props: {
    type: String,
    reportType: String
  },
  components: {
    'ReportMethods': ReportMethods,
    'FilterMethods': FilterMethods
  },
  data: function () {
    return {
      name: '',
      validation: {
        result: false,
        message: ""
      },
      errorMessage: {
        match: "同一の名前が存在しています。",
        lengthOver: "文字数が制限を超えています。",
        lengthZero: "1文字以上入力してください。"
      }
    }
  },
  methods: {
    createReport: async function() {
      const result = await this._validateNameMatch(this.name)
      if(!result){
        this.validation.result  = true
        this.validation.message = this.errorMessage.match
        return false
      }

      const result2 = this._validateNameLength(this.name)
      if(!result2){
        this.validation.result  = true
        return false
      }

      $('#nameEditModal').modal('hide')
      this.$emit('childMethod', this.name)
      this.name = ""
    },
    _validateNameMatch: async function(name){
      if(this.type === "filter"){
        return await this.$refs.filterMethods.validateName(name)
      }else {
        return await this.$refs.reportMethods.validateName(name, this.reportType)
      }
    },
    _validateNameLength(name) {
      if(name.length === 0){
        this.validation.message = this.errorMessage.lengthZero
        return false
      }

      if(name.length >= 256){
        this.validation.message = this.errorMessage.lengthOver
        return false
      }

      this.validation.message = ""
      return true
    }
  }

}
</script>
<template>
  <div>
    <ServiceAccountMethods ref="methods"/>
    <div v-if="!initialized">
      <div class="loader">Loading...</div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div>
            <ul class="nav">
              <li class="nav-item" v-if="contract.permissions.show_edit_button">
                <a
                  class="nav-link btn btn-primary"
                  href="/mypage/service-account/edit"
                >
                  編集
                </a>
              </li>
              <li class="nav-item" v-if="contract.permissions.show_quit_button">
                <a
                  class="nav-link btn btn-primary"
                  href="/mypage/service-account/quit"
                >
                  解約
                </a>
              </li>
            </ul>
          </div>
          <div class="card">
            <div class="card-header card-header-rose card-header-icon">
              <div class="card-title">
                <h4>契約情報</h4>
              </div>
            </div>
            <div class="card-body">
              <table class="table table-borderless table-responsive">
                <tbody>
                <tr>
                  <th>契約ID</th>
                  <td>{{ contract.signup_id }}</td>
                </tr>
                <tr>
                  <th>別名</th>
                  <td>{{ contract.alias_name }}</td>
                </tr>
                <tr v-if="contract.permissions.show_start_contract">
                  <th>契約開始日</th>
                  <td>{{ contract.contract_start_date }}</td>
                </tr>
                <tr v-if="contract.permissions.show_monthly_fee">
                  <th>月額料金</th>
                  <td>{{ contract.current_monthly_fee }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card" v-if="contract.permissions.show_cp_contract_guidance">
            <div class="card-header card-header-rose card-header-icon">
              <div class="card-title">
                <h4>ChorusPortalのAWS/GoogleCloudリセールへの契約のご案内</h4>
              </div>
            </div>
            <div class="card-body">
              <div>
                <p>
                  AWSだけでなく、Google Cloudやオンプレミスなど、NHNテコラスは<br>
                  ITインフラ事業者として20年以上にわたりお客様のITインフラ環境の<br>活用支援を行って来ました。
                  豊富な実績から学んだノウハウを生かして、お客様に最適な環境を提供します。
                </p>
                <p>
                  ※ 詳しくは各サービスのページをご覧ください
                  <ul>
                    <li class="list-style-none">AWS請求代行サービス(<a href="https://nhn-techorus.com/c-chorus/payment/">https://nhn-techorus.com/c-chorus/payment/</a>)</li>
                    <li class="list-style-none">Google Cloud請求代行サービス(<a href="https://nhn-techorus.com/c-chorus/gcp/">https://nhn-techorus.com/c-chorus/gcp/</a>)</li>
                  </ul>
                </p>
              </div>
              <div>
                <p>
                  <span>契約方法のご案内</span>
                </p>
                <p>
                  契約をご希望される場合、以下の「契約を申し込む」からお願いします。<br>
                  現在の契約情報を元に進めることで簡略化することができます
                </p>
              </div>
              <template v-if="contract.permissions.show_cp_contract_button">
                <div>
                  <button @click="getCpSignupUrl" type="button" class="btn btn-primary">契約を申し込む</button>
                </div>
              </template>
            </div>
          </div>
          <div class="card" v-if="contract.permissions.show_formal_contract_guidance">
            <div class="card-header card-header-rose card-header-icon">
              <div class="card-title">
                <h4>本契約のご案内</h4>
              </div>
            </div>
            <div class="card-body">
              <div>
                <div>
                  本契約について
                  <ul>
                    <li class="list-style-none">現在のご契約は評価期間中であり、終了日は{{ contract.free_period_end_date }}までとなっております。</li>
                    <li class="list-style-none">引き続きご利用いただくためには、本契約をしていただく必要があります。</li>
                  </ul>
                </div>
                <div>
                  月額料金の案内
                  <ul>
                    <li class="list-style-none">月額:¥{{ monthlyFee }}(税抜)</li>
                    <li class="list-style-none">上記価格は基本料金となっています。分析対象のデータが弊社の基準を越える場合には、料金の変更についてご連絡いたします。</li>
                  </ul>
                </div>
              </div>
              <template v-if="contract.permissions.show_formal_contract_button">
                <div>
                  <button @click="updateFormalContract" type="button" class="btn btn-primary">本契約</button>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="cpTransitionModal" tabindex="-1" role="dialog" aria-labelledby="cpTransitionModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <span>ChorusPortalのお申し込み画面に移動します。問題なければ以下から移動をお願いします。</span>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">キャンセル</button>
              <button type="button" class="btn btn-primary" data-dismiss="modal" v-on:click="transitionCpSignup">ChorusPortalに移動する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from "vue";
import ServiceAccountMethods from "../../../components/service_account_methods.vue";

const methods = ref(null)
let initialized = ref(false)
let chorusPortalUrl = ref("")
let contract = ref(
  {
    signup_id: 0,
    alias_name: "",
    contract_start_date: "",
    monthly_fee: 0,
    current_monthly_fee: 0,
    free_period_end_date: "",
    permissions: {
      show_start_contract: false,
      show_monthly_fee: true,
      show_edit_button: false,
      show_quit_button: false,
      show_formal_contract_guidance: false,
      show_formal_contract_button: false,
      show_cp_contract_guidance: false,
      show_cp_contract_button: false
    }
  }
)

const monthlyFee = computed(() => {
  return contract.value.monthly_fee.toLocaleString()
})

const getContract = async () => {
  const result = await methods.value?.getContract()
  if (!result) {
    return false
  }
  return result
}

const updateFormalContract = async () => {
  const message = "月額料金：¥" + monthlyFee.value +"\n"
    + "\n"
    + "※現在のご利用状況をもとに算定しています。変動があった場合には翌月以降で調整させていただきます。\n"
    + "\n"
    + "本当に本契約をしますか？"

  if (!window.confirm(message)) return;
  const result = await methods.value?.updateContractStatusFromFreeToFormal()
  if (!result) {
    return false
  }
  setTimeout(_transition, 3000)
}

const _transition = () => {
  location.reload();
}

const getCpSignupUrl = async () => {
  const result = await methods.value?.getCpSignupUrl()
  if (!result) {
    return false
  }
  chorusPortalUrl.value = result.url
  $('#cpTransitionModal').modal('show')
}

const transitionCpSignup = () => {
  window.open(chorusPortalUrl.value, '_blank')
}

onMounted(async () => {
  const result = await getContract()
  if(result){
    contract.value = result
    initialized.value = true
  }
  initialized.value = true
})

</script>
<style scoped>
.list-style-none {
  list-style: none;
}
</style>

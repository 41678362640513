export const reportCostSubscription  = {
    id: 0,
    locked: false,
    name: "前月の使用料金",
    enabled: true,
    term_type: "prev_1m",
    cycle_type: "monthly",
    cycle_schedule: {
      hour: 10,
      day: 4,
      day_of_week: "mon",
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false
    },
    emails: ["", "", ""],
    end_date: "",
    size_type: "a4",
    landscaped: false
}

export const reportBudgetSubscription  = {
  id: 0,
  budget_id: 0,
  locked: false,
  name: "",
  enabled: true,
  cycle_type: "monthly",
  cycle_schedule: {
    hour: 10,
    day: 4,
    day_of_week: "mon",
  },
  emails: ["", "", ""],
  end_date: "",
  size_type: "a4",
  landscaped: false
}

export const termTypes = [
  {name: '前月分', value: 'prev_1m'}
]

export const cycleTypes = [
  // {name: '毎日', value: 'daily' },
  // {name: '曜日', value: 'days_of_week' },
  {name: '月次', value: 'monthly' },
  {name: '週次', value: 'weekly' },
]

export const sizeTypes = [
  {name: 'A0', value: 'a0' },
  {name: 'A1', value: 'a1' },
  {name: 'A2', value: 'a2' },
  {name: 'A3', value: 'a3' },
  {name: 'A4', value: 'a4' },
  {name: 'レター', value: 'letter' },
  {name: 'リーガル', value: 'legal' }
]

export const landscapeTypes = [
  {name: '縦', value: false },
  {name: '横', value: true },
]

export const reportTypes = [
  {name: 'コスト', value: 'cost'},
  {name: '予算', value: 'budget'},
]

export const dayOfWeeks = [
  {name: '月曜日', value: 'mon'},
  {name: '火曜日', value: 'tue'},
  {name: '水曜日', value: 'wed'},
  {name: '木曜日', value: 'thu'},
  {name: '金曜日', value: 'fri'},
  {name: '土曜日', value: 'sat'},
  {name: '日曜日', value: 'sun'},
]
<template>
  <div>
    <ServiceAccountMethods ref="methods"/>
    <div v-if="!initialized">
      <div class="loader">Loading...</div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <form id="contractor-form" v-on:submit.prevent="updateContractor()">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pull-left">
                  <ul class="nav">
                    <li class="nav-item">
                      <button type="submit" class="btn btn-primary">保存</button>
                    </li>
                  </ul>
                </div>
                <div class="pull-right">
                  <ul class="nav">
                    <li class="nav-item">
                      <a
                        class="nav-link btn btn-default"
                        href="/mypage/service-account/contractor/"
                      >
                        キャンセル
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header card-header-rose card-header-icon">
                <div class="card-title">
                  <h4>組織情報</h4>
                </div>
              </div>
              <div class="card-body">
                <table class="table table-borderless table-responsive">
                  <tbody>
                  <tr>
                    <th>契約ID</th>
                    <td>{{ contractor.signup_id }}</td>
                  </tr>
                  <tr>
                    <th>法人名</th>
                    <td>{{ contractor.company.name }}</td>
                  </tr>
                  <tr>
                    <th>法人名(フリガナ)</th>
                    <td>{{ contractor.company.kana }}</td>
                  </tr>
                  <tr>
                    <th>代表者名</th>
                    <td>
                      <div class="form-group bmd-form-group form-width-200" >
                        <input type="text" name="representative" class="form-control" minlength="0" maxlength="100" v-model="contractor.company.representative" @input="validateRepresentative()"/>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>URL</th>
                    <td>
                      <div class="form-group bmd-form-group form-width-200" >
                        <input type="text" name="company.url" class="form-control" maxlength="255" v-model="contractor.company.url" @input="validateUrl()"/>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>設立日</th>
                    <td>{{ contractor.company.establishment_date }}</td>
                  </tr>
                  <tr>
                    <th>資本金</th>
                    <td>
                      <div class="form-group bmd-form-group form-width-200" >
                        <input type="text" name="capital" class="form-control" minlength="0" maxlength="16" v-model="contractor.company.capital" @input="validateCapital()"/>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>前年度売上高</th>
                    <td>
                      <div class="form-group bmd-form-group form-width-200" >
                        <input type="text" name="recentYear" class="form-control" minlength="0" maxlength="16" pattern="^[0-9]+$" v-model="contractor.company.recent_year_sales" @input="validateRecentYear()"/>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>従業員数</th>
                    <td>
                      <div class="form-group bmd-form-group form-width-200" >
                        <input type="number" name="employees" class="form-control" max="999999999" v-model="contractor.company.number_of_employees" @input="validateEmployees()"/>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card">
              <div class="card-header card-header-rose card-header-icon">
                <div class="card-title">
                  <h4>ご担当者様情報</h4>
                </div>
              </div>
              <div class="card-body">
                <table class="table table-borderless table-responsive">
                  <tbody>
                    <tr>
                      <th>ご担当者名</th>
                      <td>
                        <div class="form-group bmd-form-group form-width-200" >
                          <input type="text" name="name" class="form-control" maxlength="20" v-model="contractor.contact.name" @input="validateName()"/>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>ご担当者名(フリガナ)</th>
                      <td>
                        <div class="form-group bmd-form-group form-width-200" >
                          <input type="text" class="form-control" name="nameKana" maxlength="100" v-model="contractor.contact.kana" @input="validateNameKana()"/>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>部署名</th>
                      <td>
                        <div class="form-group bmd-form-group form-width-200" >
                          <input type="text" class="form-control" name="department_name" v-model="contractor.contact.department_name" v-on:input="validateDepartmentNameByte()"/>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>郵便番号</th>
                      <td style="display: flex">
                        <input class="form-control" name="contact.postal_code" maxlength="8" type="text" placeholder="ハイフン有り無し、どちらでも入力可能です。" style="width: 320px;" v-model="contractor.contact.postal_code" @input="validatePostalCode()">
                        <button type="button" class="btn btn-sm btn-primary ml-2" v-on:click="findAddress()">
                          郵便番号検索
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th>都道府県</th>
                      <td>
                        <select class="form-control" name="contact.prefecture_code" style="width: 240px;" required v-model="contractor.contact.prefecture_code" @change="validatePrefecture()">
                          <option>選択してください</option>
                          <template v-for="p in pref">
                            <option :value="p.value">{{ p.name }}</option>
                          </template>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th>市区町村</th>
                      <td>
                        <div class="form-group bmd-form-group form-width-200" >
                          <input type="text" name="city" class="form-control" maxlength="100" v-model="contractor.contact.city" @input="validateCity()"/>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>町名・番地</th>
                      <td>
                        <div class="form-group bmd-form-group form-width-200" >
                          <input type="text" name="streetAddress" class="form-control" maxlength="100" v-model="contractor.contact.street_address" @input="validateAddress()"/>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>建築物名等</th>
                      <td>
                        <div class="form-group bmd-form-group form-width-200" >
                          <input type="text" class="form-control" minlength="0" maxlength="100" v-model="contractor.contact.building"/>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>電話番号</th>
                      <td>
                        <div class="form-group bmd-form-group form-width-200" >
                          <input type="text" name="phone" class="form-control" maxlength="13" pattern="^(?:\d{2,4}-\d{2,4}-\d{4}|\d{10,11})$" v-model="contractor.contact.phone" @input="validatePhone()"/>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>メールアドレス</th>
                      <td>
                        <div class="form-group bmd-form-group form-width-200" >
                          <input type="email" name="mailAddress" class="form-control" maxlength="100" v-model="contractor.contact.email" @input="validateEmail()"/>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import ServiceAccountMethods from "../../../components/service_account_methods.vue";
import { pref } from "../../../utils/constants";
import { getAddress } from "../../../lib/axios/api_client";

const methods = ref(null)
let initialized = ref(false)
let contractor = ref(
  {
    signup_id: "",
    company: {
      name: "",
      kana: "",
      representative: "",
      url: "",
      establishment_date: "",
      capital: 0,
      recent_year_sales: 0,
      number_of_employees: 0
    },
    contact: {
      name: "",
      kana: "",
      department_name: "",
      postal_code: "",
      prefecture_code: "",
      city: "",
      street_address: "",
      building: "",
      phone: "",
      email: ""
    },
    permissions: {
      show_edit_button: false,
    }
  }
)
const amountRegex = /^[0-9]+$/
const kanaRegex = /(?=.*?[\u30A1-\u30FC])[\u30A1-\u30FC\s]*/
const postalRegex = /^(?:\d{3}-\d{4}|\d{7})$/

const mailAddressRegex = /^[a-zA-Z0-9.!#$%&*+\/=?^_`{|}~-]{1,46}@[\.a-zA-Z0-9-]+\.[a-zA-Z]+$/;
const domainRegex = /^(?=.{1,100}$)(?=.{1,46}@).+@.+\.[a-zA-Z]{2,}$/;

const getContractor = async () => {
  const result = await methods.value?.getContractor()
  if (!result) {
    return false
  }
  return result
}

const findAddress = async () => {
  validatePostalCode()
  if (7 <= contractor.value.contact.postal_code.length) {
    const res = await getAddress(contractor.value.contact.postal_code)
    if (!res.successful) {
      return false
    }
    contractor.value.contact.prefecture_code = res.data.prefecture_code
    contractor.value.contact.city = res.data.city
    contractor.value.contact.street_address = res.data.town
  }
}

const validateCapital = () => {
  let form = document.forms["contractor-form"]
  form.elements["capital"].setCustomValidity("")
  if(!amountRegex.test(form.elements["capital"].value) && form.elements["capital"].value !== "") {
    form.elements["capital"].setCustomValidity("資本金の入力形式が正しくありません。")
  }
}

const validateRecentYear = () => {
  let form = document.forms["contractor-form"]
  form.elements["recentYear"].setCustomValidity("")
  if (!amountRegex.test(form.elements["recentYear"].value) && form.elements["recentYear"].value !== "") {
    form.elements["recentYear"].setCustomValidity("前年度売上高の入力形式が正しくありません。")
  }
}

const validateEmployees = () => {
  const maxLength = 9
  let form = document.forms["contractor-form"]
  if (maxLength < form.elements["employees"].value.length) {
    form.elements["employees"].value = form.elements["employees"].value.slice(0, maxLength)
  }
}

const validateUrl = () => {
  let form = document.forms["contractor-form"]
  form.elements["company.url"].setCustomValidity("")
  if (!/^$|^https:\/\/[^\s$.?#].[^\s]*$/.test(form.elements['company.url'].value)) {
    form.elements["company.url"].setCustomValidity("URLの入力形式が正しくありません。")
  }
}

const validateRepresentative = () => {
  let form = document.forms["contractor-form"]
  form.elements["representative"].setCustomValidity("")
  if (form.elements["representative"].value === "") {
    form.elements["representative"].setCustomValidity("代表者名を入力してください。")
  }
}

const validatePrefecture = () => {
  let form = document.forms["contractor-form"]
  form.elements["contact.prefecture_code"].setCustomValidity("")
  if (form.elements["contact.prefecture_code"].value === "選択してください") {
    form.elements["contact.prefecture_code"].setCustomValidity("都道府県を選択してください。")
  }
}

const validateDepartmentNameByte = () => {
  let form = document.forms["contractor-form"]
  form.elements["department_name"].setCustomValidity("")
  if (52 < encodeURI(form.elements["department_name"].value).replace(/%../g, "*").length) {
    form.elements["department_name"].setCustomValidity("部署名の入力形式が正しくありません。")
  }
}

const validateEmail = () => {
  let form = document.forms["contractor-form"]
  form.elements["mailAddress"].setCustomValidity("")
  if (form.elements["mailAddress"].value.length === 0) {
    form.elements["mailAddress"].setCustomValidity("メールアドレスを入力してください。")
  } else if (!mailAddressRegex.test(form.elements["mailAddress"].value) || !domainRegex.test(form.elements["mailAddress"].value)) {
    form.elements["mailAddress"].setCustomValidity("メールアドレスの入力形式が正しくありません。")
  }
}

const validateName = () => {
  let form = document.forms["contractor-form"]
  form.elements["name"].setCustomValidity("")
  if (form.elements["name"].value === "") {
    form.elements["name"].setCustomValidity("ご担当者名を入力してください。")
  } else if (3 > form.elements["name"].value.length) {
    form.elements["name"].setCustomValidity("ご担当者様の入力形式が正しくありません。")
  }
}

const validateNameKana = () => {
  let form = document.forms["contractor-form"]
  form.elements["nameKana"].setCustomValidity("")
  if (form.elements["nameKana"].value === "") {
    form.elements["nameKana"].setCustomValidity("ご担当者名(フリガナ)を入力してください。")
  } else if (!kanaRegex.test(form.elements["nameKana"].value)) {
    form.elements["nameKana"].setCustomValidity("ご担当者様(フリガナ)の入力形式が正しくありません。")
  }
}

const validatePostalCode = () => {
  let form = document.forms["contractor-form"]
  form.elements["contact.postal_code"].setCustomValidity("")
  if (form.elements["contact.postal_code"].value === "") {
    form.elements["contact.postal_code"].setCustomValidity("郵便番号を入力してください。")
  } else if (!postalRegex.test(form.elements["contact.postal_code"].value)) {
    form.elements["contact.postal_code"].setCustomValidity("郵便番号の入力形式が正しくありません。")
  }
}

const validateCity = () => {
  let form = document.forms["contractor-form"]
  form.elements["city"].setCustomValidity("")
  if (form.elements["city"].value === "") {
    form.elements["city"].setCustomValidity("市区町村を入力してください。")
  }
}

const validateAddress = () => {
  let form = document.forms["contractor-form"]
  form.elements["streetAddress"].setCustomValidity("")
  if (form.elements["streetAddress"].value === "") {
    form.elements["streetAddress"].setCustomValidity("町名・番地を入力してください。")
  }
}

const validatePhone = () => {
  let form = document.forms["contractor-form"]
  form.elements["phone"].setCustomValidity("")
  if (form.elements["phone"].value === "") {
    form.elements["phone"].setCustomValidity("電話番号を入力してください。")
  }
}

const updateContractor = async () => {
  const param = {
    company: {
      representative: contractor.value.company.representative,
      url: contractor.value.company.url,
      capital: contractor.value.company.capital === "" ? "" : Number(contractor.value.company.capital),
      recent_year_sales: contractor.value.company.recent_year_sales === "" ? "" : Number(contractor.value.company.recent_year_sales),
      number_of_employees: contractor.value.company.number_of_employees
    },
    contact: contractor.value.contact
  }
  const result = await methods.value?.updateContractor(param)
  if (!result) {
    return false
  }
  setTimeout(_transition, 1000)
  return true
}

const _transition = () => {
  window.location.href = "/mypage/service-account/contractor/"
}

onMounted(async () => {
  const result = await getContractor()
  if(result){
    contractor.value = result
    initialized.value = true
  }
})

</script>
<style scoped>
.table > tbody > tr > th {
  vertical-align: middle;
}
</style>

<template>
  <Alert ref="alert"/>
</template>

<script setup>
import { ref } from "vue";
import Alert from "./alert_new.vue";
import {ApiClient} from "../lib/axios/support/api_client";

const client = new ApiClient();
const alert = ref(null);

const getZendeskSupportUrl = async (param) => {
  const res = await client.requestZendeskSupport(param);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
  }
  return res.data;
}

defineExpose({
  getZendeskSupportUrl
})

</script>

<style scoped>

</style>
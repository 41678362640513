<template>
  <Alert ref="alert"/>
  <div v-if="!initialized">
    <div class="loader">Loading...</div>
  </div>
  <div v-else>
    <SupportMethod ref="supportMethod"/>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-header card-header-rose card-header-icon">
            <div class="card-title">
              <p class="warn-text">
                利用年ごとに表示できます
              </p>
            </div>
          </div>
          <div class="pl-3">
            <ul class="nav">
              <li class="nav-item">
                <select id="invoice-year" class="form-control" v-model="year" v-on:change="updateYear()">
                  <template v-for="y in years">
                    <option :value="y">{{ y }}</option>
                  </template>
                </select>
              </li>
            </ul>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-no-bordered table-hover text-right">
                <thead>
                <tr class="white-space-nowrap">
                  <th class="text-left">利用月</th>
                  <th>税抜価格</th>
                  <th>消費税</th>
                  <th>合計金額</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="month in months">
                   <TableInvoice :year="year" :month="month" :invoices="invoices"/>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-header">
            <div class="card-title">■ご請求書原本について</div>
          </div>
          <div class="card-body">
            <ul>
              <li>本サービスのご請求書原本は、「楽楽明細」を利用して、下記webサイトよりダウンロードいただけます（当月ご利用分は、翌々月第２営業日に発行され取得頂けます）</li>
              <li><a :href=constants.pagePath.invoice.index target="_blank">{{ constants.pagePath.invoice.index }}</a></li>
            </ul>
            <ul>
              <li>Cloud illuminatorサービス開通後は、初回のお手続きが必要となります。上記「楽楽明細」のログイン情報のご確認を含めまして、下記をご参照ください。</li>
              <li>▼請求書はPDFで取得できますか？</li>
              <li><a href=# @click.prevent="zendeskButtonClick(invoiceGuide)">{{ invoiceGuide }}</a></li>
            </ul>
            <ul>
              <li>ご請求に関する詳細は、以下のサービス仕様書からもご確認ください。</li>
              <li><a :href=constants.pagePath.invoice.specification target="_blank">{{ constants.pagePath.invoice.specification }}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { getInvoices, getInvoiceYears } from "../lib/axios/api_client";
import Alert from "../components/alert_new.vue";
import SupportMethod from "../components/support_method.vue";
import TableInvoice from "../components/table_invoice.vue";
import * as constants from '../utils/constants'

const alert = ref(null)
const supportMethod = ref(null);
const months = ref(["01","02","03","04","05","06","07","08","09","10","11","12"])
let initialized = ref(false)
let year = ref("")
let years = ref([])
let invoices = ref([])
let invoiceGuide = ref("")

const getYears = async () => {
  const result = await getInvoiceYears()
  if (!result.successful) {
    alert.value.displayModal(result.message, 'error')
    return false
  }
  years.value = result.data.years
}

const getCertainInvoice = async (cur) => {
  const params = {params: {year: cur}};
  const result = await getInvoices(params)
  if (!result.successful) {
    alert.value.displayModal(result.message, 'error')
    return false
  }
  invoices.value = result.data.invoices
}

const updateYear = async () => {
  initialized.value = false
  await getCertainInvoice(year.value)

  initialized.value = true
}

const invoiceGuidePath = () => {
   let zendeskUrl = constants.pagePath.zendeskUrl[process.env.ENV_TYPE]
   invoiceGuide.value = zendeskUrl + constants.pagePath.invoice.guide
}

const zendeskButtonClick = async (returnTo) => {
  const param = { return_to : returnTo }
  const result = await supportMethod.value?.getZendeskSupportUrl(param);
  window.open(result.url, "_blank");
}

onMounted( async () => {
  invoiceGuidePath()
  await getYears()
  if (!years.value.length) {
    const today = new Date()
    year.value = today.getFullYear().toString()
    years.value.push(today.getFullYear().toString())
  } else {
    year.value = years.value[years.value.length-1]
  }
  await getCertainInvoice(year.value)

  initialized.value = true
})
</script>
<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
</style>
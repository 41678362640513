<template>
  <SimulatorMethods ref="methods"/>
  <Alert ref="alert"/>
  <div v-if="!initialized">
    <div class="loader">Loading...</div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title">期限切れの対象となるRI/SP</div>
          </div>
          <div class="card-body">
            <TableExpireRiSp :expireRiSp="scenario.expire_ri_sp" :awsAccountIds="scenario.aws_account_ids"/>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title">設定項目</div>
          </div>
          <div class="card-body">
            <table class="table table-borderless table-responsive">
              <tbody>
              <tr>
                <th>シナリオ名</th>
                <td>
                  <div class="form-group">
                    <input id="scenario-name" class="form-control" type="text" minlength="1" maxlength="255" required v-model="scenario.name"/>
                  </div>
                </td>
              </tr>
              <tr>
                <th>推薦する種類</th>
                <td>
                  <select class="form-control" v-model="scenario.recommend_type">
                    <template v-for="recommendType in recommendTypes">
                      <option :value="recommendType.name">
                        {{ recommendType.name }}
                      </option>
                    </template>
                  </select>
                </td>
              </tr>
              <tr>
                <th>支払い期間</th>
                <td>
                  <select class="form-control" v-model="scenario.payment_term">
                    <template v-for="paymentTerm in paymentTerms">
                      <option :value="paymentTerm.value">
                        {{ paymentTerm.name }}
                      </option>
                    </template>
                  </select>
                </td>
              </tr>
              <tr>
                <th>支払い方法</th>
                <td>
                  <select class="form-control" v-model="scenario.payment_option">
                    <template v-for="paymentOption in paymentOptions">
                      <option :value="paymentOption.name">
                        {{ paymentOption.name }}
                      </option>
                    </template>
                  </select>
                </td>
              </tr>
              <tr>
                <th>実行予定日</th>
                <td>
                  <input class="form-control" type="date" v-model="scenario.simulator_scheduled_date" :min="methods.minDate()" :max="methods.maxDate(scenario.expire_ri_sp)" @change="bindStartEnd()"/>
                </td>
              </tr>
              <tr>
                <th>算出するための分析日数</th>
                <td>
                  <select class="form-control" v-model="scenario.analysis_term" @change="bindStartEnd()">
                    <template v-for="analysisTerm in methods.calcAnalysisTermStartToEnd(scenario.simulator_scheduled_date)">
                      <option :value="analysisTerm.term">
                        {{ analysisTerm.term }} ({{ analysisTerm.start }} ~ {{ analysisTerm.end }} の利用料が対象)
                      </option>
                    </template>
                  </select>
                </td>
              </tr>
              </tbody>
            </table>
            <div>
              <button @click="updateScenario(scenarioId, scenario)" type="button" class="btn btn-primary">保存</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Alert from "../../../../components/alert_new.vue";
import { onMounted, ref } from "vue";
import SimulatorMethods from "../../../../components/recommend_simulator_methods.vue";
import { extractId } from "../../../../lib/common";
import { recommendTypes, paymentTerms, paymentOptions } from "../../../../utils/simulator";
import TableExpireRiSp from "../../../../components/table_recommend_simulator_expire_ri_sp.vue";

const methods = ref(null)
const scenarioId = extractId(5)

let initialized = ref(false)
let scenario = ref({})

const bindStartEnd = () => {
  let selected = methods.value?.calcAnalysisTermStartToEnd(scenario.value.simulator_scheduled_date).find( ({term}) => term === scenario.value.analysis_term)
  scenario.value.analysis_term_start_date = selected.start
  scenario.value.analysis_term_end_date = selected.end
}

const getScenario = async (id) => {
  const result = await methods.value?.getScenario(id)
  if (!result) {
    return false
  }
  return result.scenarios
}

const updateScenario = async (id, scenario) => {
  const result = await methods.value?.updateScenario(id, scenario)
  if (!result) {
    return false
  } else {
    setTimeout(_transition,1000);
  }
}

const _transition = () => {
  window.location.href = "/mypage/recommend/simulator/scenario/" + scenarioId + "/detail"
}

onMounted(async () => {
  const result = await getScenario(scenarioId)
  if(!result){
    return false
  }

  scenario.value = result
  initialized.value = true
})

</script>
<style scoped>
.table>tbody>tr>th,
.table>tbody>tr>td {
  vertical-align: top;
  padding: 12px 8px;
  border-color: transparent;
}
</style>

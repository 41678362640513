<template>
  <div>
    <Alert ref="alert"/>
    <div v-if="!initialized">
      <div class="loader">Loading...</div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                <div class="row">
                  <div class="col">一覧</div>
                  <div class="col text-right">
                    <a class="btn btn-default" style="display: inline" href="/mypage/alert/setting/rightsizing">通知設定へ</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <ul class="tabs">
                <template v-for="(serviceType, name) in serviceTypes">
                  <li v-on:click="change(serviceType.type)" v-bind:class="{'active': isActive === serviceType.type}">{{ name }}</li>
                </template>
              </ul>

              <ul class="contents">
                <template v-for="(serviceType, name) in serviceTypes">
                  <li v-show="isActive === serviceType.type">
                    <IndexRightSizingSetting :serviceType="serviceType.type" :url="serviceType.url" :name="name" :countRightSizingSetting="countRightSizingSetting" v-on:countRightsizingSettings="countRightsizingSettings"/>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from "../components/alert_new.vue";
import IndexRightSizingSetting from "../components/index_right_sizing_setting.vue";
import * as constants from '../utils/constants';
import {countRightsizingSetting} from "../lib/axios/api_client";

export default {
  mounted: async function() {
    await this.countRightsizingSettings()
  },
  components: {
    Alert: Alert,
    IndexRightSizingSetting: IndexRightSizingSetting
  },
  data: function () {
    return {
      serviceTypes: {
        EC2: {
          type: constants.EC2,
          url: constants.pagePath.dashboard.rightsizing_ec2,
        },
        EBS: {
          type: constants.EBS,
          url: constants.pagePath.dashboard.rightsizing_ebs,
        },
        EIP: {
          type: constants.EIP,
          url: constants.pagePath.dashboard.rightsizing_eip,
        },
        ELB: {
          type: constants.ELB,
          url: constants.pagePath.dashboard.rightsizing_elb,
        },
      },
      isActive: 'ec2',
      initialized: true,
      countRightSizingSetting: ''
    }
  },
  methods: {
    change: function(type){
      this.isActive = type;
    },
    countRightsizingSettings: async function() {
      const res = await countRightsizingSetting();
      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error');
        return false;
      }
      this.countRightSizingSetting = res.data.result;
      return true;
    }
  },
}
</script>

<style scoped>
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
  }
  .tabs {
    overflow: hidden;
  }
  .tabs li,
  .tabs label {
    float: left;
    padding: 10px 20px;
    border: 1px solid #ccc;
    cursor: pointer;
    transition: .3s;
  }
  .tabs li:not(:first-child),
  .tabs label:not(:first-of-type) {
    border-left: none;
  }
  .tabs li.active,
  .tabs :checked + label {
    background-color: #002341;
    border-color: #000;
    color: #fff;
    cursor: auto;
  }
  .contents {
    overflow: hidden;
    margin-top: -1px;
  }
  .contents li {
    padding: 20px;
    border: 1px solid #ccc;
  }

  .jquery .contents li {
    display: none;
  }
  .jquery .contents li.active {
    display: block;
  }

  .vue_radio input {
    display: none;
  }
</style>

<template>
  <div class="row">
    <div class="col-lg-10 col-md-10 col-sm-5">
      <div class="progress-container">
        <div class="progress">
          <div class="progress-bar" role="progressbar" :aria-valuenow="ratio" aria-valuemin="0" aria-valuemax="100" :style="`width: ${ratio}%;`">
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2">
      {{ ratio.toFixed(2) }}%
    </div>
  </div>
</template>
<script setup>
defineProps({ ratio: Number })
</script>
<style scoped>
.progress {
  background: #edeeee;
}
.progress-bar {
  background-color: #97a3a4;
}
</style>

<template>
  <button
      @click="$emit('triggerEmit')"
      class="dropdown-item btn btn-secondary btn-sm text-center"
      type="button"
  >
    <slot></slot>
  </button>
</template>
<script>
export default {

}
</script>
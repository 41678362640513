<template>
  <template v-if="findInvoice(month, invoices) !== undefined">
    <td class="text-left">{{ invoice.usage_month }}</td>
    <td>{{ formatPrice(invoice.amount_excluding_tax) }}</td>
    <td>{{ formatPrice(invoice.sales_tax) }}</td>
    <td>{{ formatPrice(invoice.total_amount) }}</td>
  </template>
  <template v-else>
    <td class="text-left">{{ year + '-' + month }}</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
  </template>
</template>
<script setup>
import { ref } from "vue";
const invoice = ref(null)

const props = defineProps({
  year: String,
  month: String,
  invoices: Object
})

const getMonth = (yearMonth) => {
  return yearMonth.split('-')[1]
}

const findInvoice = (month, invoices) => {
  invoice.value = invoices.find(item => getMonth(item.usage_month) === month)
  return invoice.value
}

const formatPrice = (price) => {
  if (!price || price === '') {
    return '-'
  } else {
    return `¥${price.toLocaleString()}`
  }
}
</script>

<template>
  <div id="signup">
    <h1>お申し込み</h1>
    <section v-if="!status">
      <div class="loader">Loading...</div>
    </section>
    <section v-else>
      <template v-if="tokenExpired == null || tokenExpired">
        <div class="send-desc">
          <p>
            この度はCloud illuminatorサービスへのお申し込み頂きまして誠にありがとうございます。<br>
            <br>
            <span v-if="tokenExpired === null">URLは利用済のため、お申し込みを最初からお願いします。</span>
            <span v-else-if="tokenExpired">URLの有効期限が切れているため、お申し込みを最初からお願いします。</span>
          </p>
        </div>
        <div class="button">
          <button v-on:click="backTop">メールアドレス入力へ戻る
            <i size="30" class="material-icons">navigate_next</i>
          </button>
        </div>
      </template>
      <template v-else>
        <nav class="flow-nav step2">
          <ul>
            <li>メールアドレス入力</li>
            <li>お客様情報入力</li>
            <li>確認</li>
            <li>完了</li>
          </ul>
        </nav>
        <form id="signup-form" :ref="signupForm" v-on:submit.prevent="submitForm">
          <div>
            <h2>申し込み日: {{ props.formDetails.signup_date }}</h2>
          </div>
          <div>
            <h2>組織情報</h2>
            <div class="input">
              <label>法人名<span>※</span></label>
              <input name="company.name" type="text" maxlength="100" v-model="props.form.company.name" @input="commonValidate('company.name')">
            </div>
            <div class="input">
              <label>法人名(フリガナ)<span>※</span></label>
              <input name="company.kana" type="text" maxlength="200" v-model="props.form.company.kana"  @input="commonValidate('company.kana')">
              <span>（全角カタカナ）</span>
            </div>
            <div class="input">
              <label>代表者<span>※</span></label>
              <input name="company.representative" type="text" maxlength="100" required v-model="props.form.company.representative"  @input="commonValidate('company.representative')">
            </div>
            <div class="input">
              <label>URL</label>
              <input name="company.url" type="text" maxlength="255" v-model="props.form.company.url" @input="commonValidate('company.url')">
              <span>（半角数字記号）</span>
            </div>
            <div class="input">
              <label>設立日</label>
              <input name="company.establishment_date.year" type="text" maxlength="4" style="width: 220px;" v-model="formEstablishmentDate.year" v-on:change="formatEstablishmentDate()" @input="commonValidate('company.establishment_date.year')">
              <span>-</span>
              <div class="with-icon">
                <select name="company.establishment_date.month" style="width: 215px;" v-model="formEstablishmentDate.month" v-on:change="formatEstablishmentDate()">
                  <option value=""></option>
                  <template v-for="m in month">
                    <option :value="m">{{ m }}</option>
                  </template>
                </select>
                <i size="30" class="material-icons">keyboard_arrow_down</i>
              </div>
              <span>（半角数字）</span>
            </div>
            <div class="input">
              <label>資本金</label>
              <input name="company.capital" type="text" style="width: 220px;" maxlength="16" v-model="props.form.company.capital" @input="commonValidate('company.capital')">
              <span>円</span>
              <span>（半角数字）</span>
            </div>
            <div class="input">
              <label>直近年度の売上高</label>
              <input name="company.recent_year_sales" type="text" style="width: 220px;" maxlength="16" v-model="props.form.company.recent_year_sales" @input="commonValidate('company.recent_year_sales')">
              <span>円</span>
              <span>（半角数字）</span>
            </div>
            <div class="input">
              <label>従業員数</label>
              <input name="company.number_of_employees" type="number" max="999999999" style="width: 220px;" v-model="props.form.company.number_of_employees">
              <span>名</span>
              <span>（半角数字）</span>
            </div>
            <div class="supplement">入力していただいた情報をもとに、与信審査をさせていただいております。<br>
              省略せずに入力していただくと審査がスムーズになります。
            </div>
          </div>
          <div>
            <h2>パスワード</h2>
            <template v-if="props.formDetails.show_password_tab">
              <div class="input">
                <label>パスワード<span>※</span></label>
                <div class="with-icon">
                  <input name="password" :type="passwordVisible? 'text':'password'" maxlength="19" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,19}$" required placeholder="8文字以上、大・小文字・記号を必ず1文字以上ずつ含んでください。" v-model="props.form.password" @input="commonValidate('password')">
                  <button type="button" class="password-button" @click="passwordVisible = !passwordVisible">
                    <i size="38" class="material-icons">{{!passwordVisible? "visibility": "visibility_off"}}</i>
                  </button>
                </div>
                <span>（半角英数記号）</span>
              </div>
              <div class="input">
                <label>パスワード(確認)<span>※</span></label>
                <div class="with-icon">
                  <input name="passwordConfirm" :type="passwordConfirmVisible? 'text':'password'" maxlength="19" placeholder="確認のため再度パスワードを入力してください。" v-on:input="validatePassword">
                  <button type="button" class="password-button" @click="passwordConfirmVisible = !passwordConfirmVisible">
                    <i size="38" class="material-icons">{{!passwordConfirmVisible? "visibility": "visibility_off"}}</i>
                  </button>
                </div>
                <span>（半角英数記号）</span>
              </div>
              <div class="supplement">
                パスワードは以下をすべて使用した、8から19文字で入力してください。<br>
                ・小文字の半角アルファベット<br>
                ・大文字の半角アルファベット<br>
                ・記号（.,!"#$%&'()=-~^|\{[}]+;*:&lt;&gt;?_`@）
              </div>
            </template>
            <template v-else>
              <div class="supplement">
                パスワードは既に登録済みとなります。<br>
                ログイン時は登録済みのパスワードをご利用ください。
              </div>
            </template>
          </div>
          <div>
            <h2>担当者情報</h2>
            <div class="input">
              <label>ご担当者名<span>※</span></label>
              <input name="contact.name" type="text" minlength="3" maxlength="20" required v-model="props.form.contact.name" @input="commonValidate('contact.name')">
            </div>
            <div class="input">
              <label>ご担当者名(フリガナ)<span>※</span></label>
              <input name="contact.kana" type="text" minlength="1" maxlength="100" v-model="props.form.contact.kana" @input="commonValidate('contact.kana')">
              <span>（全角カタカナ）</span>
            </div>
            <div class="input">
              <label>部署名</label>
              <input name="contact.department_name" type="text" v-model="props.form.contact.department_name" v-on:input="validateDepartmentNameByte('contact')">
            </div>
            <div class="input">
              <label>郵便番号<span>※</span></label>
              <input name="contact.postal_code" type="text" placeholder="ハイフン有り無し、どちらでも入力可能です。" style="width: 320px;" v-model="props.form.contact.postal_code"  @input="commonValidate('contact.postal_code')">
              <button type="button" class="zip-button" v-on:click="bindAddress('contact')">
                郵便番号検索
                <i size="30" class="material-icons">navigate_next</i>
              </button>
              <span>（半角数字）</span>
            </div>
            <div class="input">
              <label>都道府県<span>※</span></label>
              <div class="with-icon">
                <select name="contact.prefecture_code" style="width: 240px;" v-model="props.form.contact.prefecture_code" @input="commonValidate('contact.prefecture_code')">
                  <option value="">選択してください</option>
                  <template v-for="p in pref">
                    <option :value="p.value">{{ p.name }}</option>
                  </template>
                </select>
                <i size="30" class="material-icons">keyboard_arrow_down</i>
              </div>
            </div>
            <div class="input">
              <label>市区町村<span>※</span></label>
              <input name="contact.city" type="text" maxlength="100" required v-model="props.form.contact.city" @input="commonValidate('contact.city')">
            </div>
            <div class="input">
              <label>町名・番地<span>※</span></label>
              <input name="contact.street_address" type="text" maxlength="100" required v-model="props.form.contact.street_address" @input="commonValidate('contact.street_address')">
            </div>
            <div class="input">
              <label>建物名等</label>
              <input name="contact.building" type="text" maxlength="100" v-model="props.form.contact.building">
            </div>
            <input type="hidden" value="Japan">
            <div class="input">
              <label>電話番号<span>※</span></label>
              <input name="contact.phone" type="tel" maxlength="13" placeholder="ハイフン有り無し、どちらでも入力可能です。" v-model="props.form.contact.phone" @input="commonValidate('contact.phone')">
              <span>（半角数字）</span>
            </div>
            <div class="input">
              <label>メールアドレス<span>※</span></label>
              {{ props.form.contact.email }}
            </div>
          </div>
          <div>
            <h2>請求先情報</h2>
            <div class="checkboxes">
              <input type="radio" id="billingsFalse" name="billings" :value="true" checked v-model="props.form.same" v-on:change="changeBilling()">
              <label for="billingsFalse">ご担当者様と同じ</label>
              <input type="radio" id="billingsTrue" name="billings" :value="false" v-model="props.form.same" v-on:change="changeBilling()">
              <label for="billingsTrue">ご担当者様と異なる</label>
            </div>
            <template v-if="!props.form.same">
              <div class="input">
                <label>ご担当者名<span>※</span></label>
                <input name="billing.name" type="text" minlength="3" maxlength="20" v-model="props.form.billing.name" @input="commonValidate('billing.name')">
              </div>
              <div class="input">
                <label>ご担当者名(フリガナ)<span>※</span></label>
                <input name="billing.kana" type="text" minlength="1" maxlength="100" v-model="props.form.billing.kana" @input="commonValidate('billing.kana')">
                <span>（全角カタカナ）</span>
              </div>
              <div class="input">
                <label>部署名</label>
                <input name="billing.department_name" type="text" v-model="props.form.billing.department_name" v-on:input="validateDepartmentNameByte('billing')">
              </div>
              <div class="input">
                <label>郵便番号<span>※</span></label>
                <input name="billing.postal_code" type="text" placeholder="ハイフン有り無し、どちらでも入力可能です。" style="width: 320px;" v-model="props.form.billing.postal_code" @input="commonValidate('billing.postal_code')">
                <button type="button" class="zip-button" v-on:click="bindAddress('billing')">
                  郵便番号検索
                  <i size="30" class="material-icons">navigate_next</i>
                </button>
                <span>（半角数字）</span>
              </div>
              <div class="input">
                <label>都道府県<span>※</span></label>
                <div class="with-icon">
                  <select name="billing.prefecture_code" style="width: 240px;" v-model="props.form.billing.prefecture_code"  @input="commonValidate('billing.prefecture_code')">
                    <option value="">選択してください</option>
                    <template v-for="p in pref">
                      <option :value="p.value">{{ p.name }}</option>
                    </template>
                  </select>
                  <i size="30" class="material-icons">keyboard_arrow_down</i>
                </div>
              </div>
              <div class="input">
                <label>市区町村<span>※</span></label>
                <input name="billing.city" type="text" maxlength="100" required v-model="props.form.billing.city"  @input="commonValidate('billing.city')">
              </div>
              <div class="input">
                <label>町名・番地<span>※</span></label>
                <input name="billing.street_address" type="text" maxlength="100" required v-model="props.form.billing.street_address"  @input="commonValidate('billing.street_address')">
              </div>
              <div class="input">
                <label>建物名等</label>
                <input name="billing.building" type="text" maxlength="100" v-model="props.form.billing.building">
              </div>
              <div class="input">
                <label>電話番号<span>※</span></label>
                <input name="billing.phone" type="tel" maxlength="13" placeholder="ハイフン有り無し、どちらでも入力可能です。" v-model="props.form.billing.phone"  @input="commonValidate('billing.phone')">
                <span>（半角数字）</span>
              </div>
              <div class="input">
                <label>メールアドレス<span>※</span></label>
                <input name="billing.email" type="email" maxlength="100" v-model="props.form.billing.email"  @input="commonValidate('billing.email')">
              </div>
            </template>
          </div>
          <div>
            <h2>同意</h2>
            <div class="checkboxes">
              お申し込み前に、<a target="_blank" href="https://nhn-techorus.com/agreement/pdf/stipulation_cloud_illuminator_ext.pdf">「Cloud illuminatorサービス利用約款」</a>をご確認のうえ、チェックを入れてください。
            </div>
            <div class="checkboxes">
              <input type="checkbox" id="agreeTermsOfUse" name="agreeTermsOfUse" required oninvalid="this.setCustomValidity('利用約款へのチェックをお願いします。')" oninput="this.setCustomValidity('')">
              <label for="agreeTermsOfUse">利用約款に同意します</label>
            </div>
            <div class="checkboxes">
              当社<a target="_blank" href="https://nhn-techorus.com/privacy/">「個人情報保護方針」</a>をご確認のうえ、チェックを入れてください。
            </div>
            <div class="checkboxes">
              <input type="checkbox" id="agreePrivacy" name="agreePrivacy" required  oninvalid="this.setCustomValidity('個人情報保護方針へのチェックをお願いします。')" oninput="this.setCustomValidity('')">
              <label for="agreePrivacy">個人情報保護方針に同意します</label>
            </div>
          </div>
          <div>
            <button type="submit">登録確認画面へ
              <i size="30" class="material-icons">navigate_next</i>
            </button>
          </div>
        </form>
      </template>
    </section>
  </div>
</template>

<script setup>
import '../../../../stylesheet/signup.css'
import {nextTick, onMounted, ref} from "vue";
import { getTemporaryServiceAccount, getAddress } from "../../../lib/axios/api_client";
import { month, pref } from "../../../utils/constants"

const emits = defineEmits([ "emitTrigger" ])
const props = defineProps(
    {
      form: Object,
      formDetails: Object
    }
)

const tokenExpired = ref(true)
let status = ref(false)
let formEstablishmentDate = ref({})
const signupForm = ref(null);
const passwordVisible = ref(false)
const passwordConfirmVisible = ref(false)

formEstablishmentDate.value = {
  year: "",
  month: ""
}

const validations = {
  "company.name": {
    rules: [
      {
        regex: /\S/,
        message: "法人名を入力してください。"
      },
    ]
  },
  "company.kana": {
    rules: [
      {
        regex: /\S/,
        message: "法人名(フリガナ)を入力してください。"
      },
      {
        regex: /(?=.*?[\u30A1-\u30FC])[\u30A1-\u30FC\s]*/,
        message: "法人名(フリガナ)の入力形式が正しくありません。"
      },
    ]
  },
  "company.representative": {
    rules: [
      {
        regex: /\S/,
        message: "代表者を入力してください。"
      },
    ]
  },
  "company.url": {
    rules: [
      {
        regex: /^$|^https:\/\/[^\s$.?#].[^\s]*$/,
        message: "URLの入力形式が正しくありません。"
      }
    ]
  },
  "company.establishment_date.year": {
    rules: [
      {
        regex: /^$|^[1-9]\d{3}$/,
        message: "設立日(年)の入力形式が正しくありません。"
      }
    ]
  },
  "company.capital": {
    rules: [
      {
        regex: /^\d*$/,
        message: "資本金の入力形式が正しくありません。"
      },
    ]
  },
  "company.recent_year_sales": {
    rules: [
      {
        regex: /^\d*$/,
        message: "直近年度の売上高の入力形式が正しくありません。"
      },
    ]
  },
  "password": {
    rules: [
      {
        regex: /\S/,
        message: "パスワードを入力してください。"
      },
      {
        regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,19}$/,
        message: "パスワードの入力形式が正しくありません。"
      }
    ]
  },
  "contact.name": {
    rules: [
      {
        regex: /\S/,
        message: "ご担当者名を入力してください。"
      },
      {
        regex: /^.{3,20}$/,
        message: "ご担当者名の入力形式が正しくありません。"
      },
    ]
  },
  "contact.kana": {
    rules: [
      {
        regex: /\S/,
        message: "ご担当者名(フリガナ)を入力してください。"
      },
      {
        regex: /(?=.*?[\u30A1-\u30FC])[\u30A1-\u30FC\s]*/,
        message: "ご担当者名(フリガナ)の入力形式が正しくありません。"
      },
    ]
  },
  "contact.postal_code": {
    rules: [
      {
        regex: /\S/,
        message: "郵便番号を入力してください。"
      },
      {
        regex: /^(?:\d{3}-\d{4}|\d{7})$/,
        message: "郵便番号の入力形式が正しくありません。"
      },
    ]
  },
  "contact.prefecture_code": {
    rules: [
      {
        regex: /\S/,
        message: "都道府県を選択してください。"
      },
    ]
  },
  "contact.city": {
    rules: [
      {
        regex: /\S/,
        message: "市区町村を入力してください。"
      },
    ]
  },
  "contact.street_address": {
    rules: [
      {
        regex: /\S/,
        message: "町名・番地を入力してください。"
      },
    ]
  },
  "contact.phone": {
    rules: [
      {
        regex: /\S/,
        message: "電話番号を入力してください。"
      },
      {
        regex: /^\d{2}-\d{4}-\d{4}$|^\d{4}-\d{2}-\d{4}$|^\d{4}-\d{4}-\d{2}$|^\d{3}-\d{3}-\d{4}$|^\d{3}-\d{4}-\d{3}$|^\d{4}-\d{3}-\d{3}$|^\d{10}$|^\d{11}$|^\d{3}-\d{4}-\d{4}$|^\d{3}-\d{3}-\d{5}$/,
        message: "電話番号の入力形式が正しくありません。"
      },
    ]
  },
  "billing.name": {
    rules: [
      {
        regex: /\S/,
        message: "ご担当者名を入力してください。"
      },
      {
        regex: /^.{3,20}$/,
        message: "ご担当者名の入力形式が正しくありません。"
      },
    ]
  },
  "billing.kana": {
    rules: [
      {
        regex: /\S/,
        message: "ご担当者名(フリガナ)を入力してください。"
      },
      {
        regex: /(?=.*?[\u30A1-\u30FC])[\u30A1-\u30FC\s]*/,
        message: "ご担当者名(フリガナ)の入力形式が正しくありません。"
      },
    ]
  },
  "billing.postal_code": {
    rules: [
      {
        regex: /\S/,
        message: "郵便番号を入力してください。"
      },
      {
        regex: /^(?:\d{3}-\d{4}|\d{7})$/,
        message: "郵便番号の入力形式が正しくありません。"
      },
    ]
  },
  "billing.prefecture_code": {
    rules: [
      {
        regex: /\S/,
        message: "都道府県を選択してください。"
      },
    ]
  },
  "billing.city": {
    rules: [
      {
        regex: /\S/,
        message: "市区町村を入力してください。"
      },
    ]
  },
  "billing.street_address": {
    rules: [
      {
        regex: /\S/,
        message: "町名・番地を入力してください。"
      },
    ]
  },
  "billing.phone": {
    rules: [
      {
        regex: /\S/,
        message: "電話番号を入力してください。"
      },
      {
        regex: /^\d{2}-\d{4}-\d{4}$|^\d{4}-\d{2}-\d{4}$|^\d{4}-\d{4}-\d{2}$|^\d{3}-\d{3}-\d{4}$|^\d{3}-\d{4}-\d{3}$|^\d{4}-\d{3}-\d{3}$|^\d{10}$|^\d{11}$|^\d{3}-\d{4}-\d{4}$|^\d{3}-\d{3}-\d{5}$/,
        message: "電話番号の入力形式が正しくありません。"
      },
    ]
  },
  "billing.email": {
    rules: [
      {
        regex: /\S/,
        message: "メールアドレスを入力してください。"
      },
      {
        regex: /^.{1,46}@[a-z0-9.\-]+\.[a-z]{2,}$/,
        message: "メールアドレスの入力形式が正しくありません。"
      },
    ]
  },
}

const commonValidate = (inputName) => {
  const validation = validations[inputName]
  const form = document.forms["signup-form"]
  if (!form) {
    return true
  }
  const input = form.elements[inputName]
  if (!input) {
    return true
  }
  const value = input.value

  if (validation) {
    for (let {regex, message} of validation.rules) {
      if (!regex.test(value)) {
        input.setCustomValidity(message)
        return false
      } else {
        input.setCustomValidity('')
      }
    }
  }

  return true
}

const checkAllValidations = () => {
  let result = true;
  Object.entries(validations).forEach(([name]) => {
    if (!commonValidate(name)) {
      result = false;
    }
  });

  validatePassword()

  return result;
}

// validate
const validateCash = (name) => {
  if (name === 'capital') {
    props.form.company.capital = props.form.company.capital.slice(0, 310)
  } else {
    props.form.company.recent_year_sales = props.form.company.recent_year_sales.slice(0, 310)
  }
}

const validatePassword = () => {
  let form = document.forms[0]
  form.passwordConfirm.setCustomValidity("")
  if (!form.passwordConfirm.value) {
    form.passwordConfirm.setCustomValidity("パスワード(確認)を入力してください。")
  }
  if (form.password.value !== form.passwordConfirm.value) {
    form.passwordConfirm.setCustomValidity("パスワードと確認用パスワードが一致しません。")
  }
}

const validateDepartmentNameByte = (category) => {
  let form = document.forms[0]
  if (category === "contact") {
    form.elements["contact.department_name"].setCustomValidity("")
    if (52 < encodeURI(form.elements["contact.department_name"].value).replace(/%../g, "*").length) {
      form.elements["contact.department_name"].setCustomValidity("部署名の入力形式が正しくありません。")
    }
  } else {
    form.elements["billing.department_name"].setCustomValidity("")
    if (52 < encodeURI(form.elements["billing.department_name"].value).replace(/%../g, "*").length) {
      form.elements["billing.department_name"].setCustomValidity("部署名の入力形式が正しくありません。")
    }
  }
}

const changeBilling = async () => {
  if (props.form.same) {
    props.form.billing = {...props.form.contact}
  }
  else {
    props.form.billing = {
      name: "",
      kana: "",
      department_name: "",
      postal_code: "",
      prefecture_code: "",
      city: "",
      street_address: "",
      building: "",
      phone: "",
      email: "",
    }
  }

  await nextTick();
  checkAllValidations()
}

const formatEstablishmentDate = () => {
  if (formEstablishmentDate.value.year && formEstablishmentDate.value.month) {
    props.form.company.establishment_date = formEstablishmentDate.value.year + '-' + formEstablishmentDate.value.month
  }
  else {
    props.form.company.establishment_date = ""
  }
}

const bindAddress = async (category) => {
  let postalCode = category === 'contact' ? props.form.contact.postal_code : props.form.billing.postal_code

  // ハイフンを除去
  postalCode = postalCode.replace(/-/g, '');

  if (7 <= postalCode.length) {
    const res = await getAddress(postalCode)
    if (!res.successful) {
      return false
    }
    if (category === 'contact') {
      props.form.contact.prefecture_code = res.data.prefecture_code
      props.form.contact.city = res.data.city
      props.form.contact.street_address = res.data.town
    } else {
      props.form.billing.prefecture_code = res.data.prefecture_code
      props.form.billing.city = res.data.city
      props.form.billing.street_address = res.data.town
    }

    await nextTick();
    ['prefecture_code','city','street_address'].forEach((inputName) => {
      commonValidate(`${category}.${inputName}`)
    })
  }
}

const getTokenKey = () => {
  const pathArray = location.pathname.split("/")
  if(pathArray[2] === "undefined" ){
    return false
  }
  return pathArray[2]
}

const submitForm = async () => {
  if (props.form.same) props.form.billing = {...props.form.contact}
  history.pushState(null, null, '/signup/confirm')
  emits('emitTrigger', props.form, props.formDetails)
}

const backTop = () => {
  window.location.href = '/signup'
}

const inputConfirmData = () => {
  if (props.form.company.establishment_date) {
    [formEstablishmentDate.value.year,formEstablishmentDate.value.month] = props.form.company.establishment_date.split('-')
  }
}

onMounted(async () => {
  const tokenKey = getTokenKey()
  const result = await getTemporaryServiceAccount(tokenKey)

  if (result.successful) {
    props.form.token_key = tokenKey
    props.form.contact.email = result.data.email
    props.form.signup_date = result.data.signup_date
    props.formDetails.signup_date = result.data.signup_date.slice(0, 10)
    props.formDetails.show_password_tab = result.data.show_password_tab
    tokenExpired.value = result.data.expired
    status.value = true
  }

  inputConfirmData();

  await nextTick();
  checkAllValidations();
})
</script>

<template>
  <tr>
    <th>配信終了日</th>
    <td>
      <div class="display-flex">
        <input class="form-control w-25" type="date" v-model="date" v-on:input="directInput" :min="minDateFormat" :max="maxDateFormat"/>
        <span class="btn btn-default mx-2" v-on:click="addMonth(3)">+3month</span>
        <span class="btn btn-default mx-2" v-on:click="addMonth(6)">+6month</span>
        <span class="btn btn-default mx-2" v-on:click="addMonth(12)">+1year</span>
      </div>
      <div>
        <span class="end-date-info">{{ minDateFormatJp}} ~ {{ maxDateFormatJp }}まで登録可能です</span>
      </div>
    </td>
  </tr>
</template>
<script>
import { format, parse, addMonths } from 'date-fns'

export default {
  props: {
    endDate: String
  },
  mounted() {
    this.date = this.endDate
    this.nowDate = new Date
  },
  data: function() {
    return {
      date: "",
      nowDate: "",
      dateFormat: 'yyyy-MM-dd',
      dateFormatJp: "yyyy年MM月dd日"
    }
  },
  computed: {
    parsedDate(){
      return parse(this.date, this.dateFormat, new Date)
    },
    minDateFormat(){
      return format(this.minDate(), this.dateFormat)
    },
    minDateFormatJp(){
      return format(this.minDate(), this.dateFormatJp)
    },
    maxDateFormat(){
      return format(this.maxDate(), this.dateFormat)
    },
    maxDateFormatJp(){
      return format(this.maxDate(), this.dateFormatJp)
    }
  },
  methods: {
    minDate(){
      const dateNow = new Date
      return new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), 0,0,0,0 )
    },
    maxDate(){
      const dateNow = new Date
      return new Date(dateNow.getFullYear() + 1, dateNow.getMonth(), dateNow.getDate() - 1, 23,59,59 )
    },
    directInput(){
      if(this._validateDate(this.parsedDate)){
        this.emitItem()
      }
    },
    addMonth(addValue){
      const newDate = addMonths(this.nowDate, addValue)

      if(this._validateDate(newDate)){
        this.date = format(newDate, this.dateFormat)
        this.emitItem()
      }

      if(newDate > this.maxDate()){
        this.date = this.maxDateFormat
        this.emitItem()
      }
    },
    emitItem() {
      this.$emit('updateEndDate', this.date)
    },
    _validateDate(newDate){
      return newDate >= this.minDate() && newDate <= this.maxDate()
    }
  }
}
</script>
<style scoped>
.display-flex{
  display: flex
}
.end-date-info{
  font-size: 0.7rem
}
</style>

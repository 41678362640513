<template>
  <table class="table table-borderless mt-2">
    <tbody>
      <tr>
        <th>期間(開始)</th>
        <td>
          <div class="d-inline-block w-25">
            <BudgetPeriod type1="start" type2="year" :selectList="yearList" v-on:emitTrigger="inputDate">年</BudgetPeriod>
          </div>
          <div class="d-inline-block w-25">
            <BudgetPeriod type1="start" type2="month" :selectList="monthList" v-on:emitTrigger="inputDate">月</BudgetPeriod>
          </div>
        </td>
      </tr>
      <tr>
        <th>期間(終了)</th>
         <td>
           <div class="d-inline-block w-25">
             <BudgetPeriod type1="end" type2="year" :selectList="yearList" v-on:emitTrigger="inputDate">年</BudgetPeriod>
           </div>
           <div class="d-inline-block w-25">
             <BudgetPeriod type1="end" type2="month" :selectList="monthList" v-on:emitTrigger="inputDate">月</BudgetPeriod>
           </div>
         </td>
      </tr>
    </tbody>
  </table>
  <div>
    <p>
      <small>※ 期間開始は、今月以降である必要があります。</small>
    </p>
    <p>
      <small>※ 期間終了は、開始から12ヶ月以内である必要があります。</small>
    </p>
  </div>
</template>

<script setup>
import BudgetPeriod from "./budget_period.vue";
import { usePeriod } from "../composables/budget_period";

const { setYearMonth, getPeriod } = usePeriod()
const emits = defineEmits([ "emitTrigger" ])

const createYearList = () => {
  const start = new Date()
  return [start.getFullYear(), start.getFullYear()+1, start.getFullYear()+2]
}

const yearList = createYearList()
const monthList = [1,2,3,4,5,6,7,8,9,10,11,12]

const inputDate = (type1, type2, value) => {
  setYearMonth(type1, type2, value)
  emits('emitTrigger', getPeriod())
}
</script>

<template>
  <table class="table table-borderless mt-5" v-if="initialized">
    <tbody>
    <tr v-if="form.id !== 0">
      <th>subscription種類</th>
      <td>コスト</td>
    </tr>
    <tr>
      <th>名前</th>
      <td>{{ form.name }}</td>
    </tr>
    <tr>
      <th>配信する</th>
      <td>
        <div class="form-check form-check-radio" style="display: inline-block">
          <label class="form-check-label">
            <input class="form-check-input" type="radio" id="notification1" value="true" v-model="form.enabled">配信する
            <span class="circle"><span class="check"></span></span>
          </label>
        </div>
        <div class="form-check form-check-radio" style="display: inline-block">
          <label class="form-check-label">
            <input class="form-check-input" type="radio" id="notification2" value="false" v-model="form.enabled">配信しない
            <span class="circle"><span class="check"></span></span>
          </label>
        </div>
      </td>
    </tr>
    <tr>
      <th>データ基準</th>
      <td>前月分</td>
    </tr>
    <tr>
      <th>周期</th>
      <td>毎月 / 4日 / 10時</td>
    </tr>
    <FormEndDate :endDate="form.end_date"  v-on:updateEndDate="updateEndDate"/>
    <tr>
      <th>配信メールアドレス</th>
      <td>
        <input id="inputEmail1" class="form-control" type="email" v-model="form.emails[0]" v-on:input="emailValidation"/>
      </td>
    </tr>
    <tr>
      <th></th>
      <td>
        <input id="inputEmail2" class="form-control" type="email" v-model="form.emails[1]" v-on:input="emailValidation"/>
      </td>
    </tr>
    <tr>
      <th></th>
      <td>
        <input id="inputEmail3" class="form-control" type="email" v-model="form.emails[2]" v-on:input="emailValidation"/>
        <p>
          <span class="text-danger">{{ message }}</span>
        </p>
      </td>
    </tr>
    <tr>
      <th>PDF サイズ</th>
      <td>
        <select class="form-control" v-model="form.size_type">
          <template v-for=" item in sizeTypes">
            <option :value="item.value">{{ item.name }}</option>
          </template>
        </select>
      </td>
    </tr>
    <tr>
      <th>PDF 向き</th>
      <td>
        <select class="form-control" v-model="form.landscaped">
          <template v-for=" item in landscapeTypes">
            <option :value="item.value">{{ item.name }}</option>
          </template>
        </select>
      </td>
    </tr>
    </tbody>
  </table>
</template>
<script>
import * as constants from "../utils/report_subscriptions";
import FormEndDate from "./report_subscription_form_end_date.vue";

export default {
  props: {
    reportSubscription: Object,
    disabled: Boolean
  },
  emits: [
    'update:disabled'
  ],
  components: {
    FormEndDate: FormEndDate
  },
  mounted() {
    this.form = this.reportSubscription
    this.emailValidation()
    this.initialized = true
  },
  data: function(){
    return {
      initialized: false,
      form: constants.reportCostSubscription,
      termTypes: constants.termTypes,
      cycleTypes: constants.cycleTypes,
      sizeTypes: constants.sizeTypes,
      landscapeTypes: constants.landscapeTypes,
      message: ""
    }
  },
  methods: {
    updateEndDate(endDate){
      this.reportSubscription.end_date = endDate
    },
    syncDisabled(bool){
      this.$emit('update:disabled', bool)
    },
    emailValidation() {
      if(this.form.emails[0] === "" && this.form.emails[1] === ""  && this.form.emails[2] === "" ){
        this.syncDisabled(true)
        this.message = "最低1件登録する必要があります"
        return true
      }

      if(this.form.emails[0] && this.form.emails[1] && this.form.emails[0] === this.form.emails[1]){
        this.syncDisabled(true)
        this.message = "同じメールアドレスは登録できません"
        return true
      }

      if(this.form.emails[0] && this.form.emails[2] && this.form.emails[0] === this.form.emails[2]){
        this.syncDisabled(true)
        this.message = "同じメールアドレスは登録できません"
        return true
      }

      if(this.form.emails[1] && this.form.emails[2] && this.form.emails[1] === this.form.emails[2]){
        this.syncDisabled(true)
        this.message = "同じメールアドレスは登録できません"
        return true
      }

      this.syncDisabled(false)
      this.message = ""
      return false
    }
  }
}
</script>

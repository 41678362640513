<template>
  <UserMethods ref="methods"/>
  <Alert ref="alert"/>
  <div v-if="!initialized">
    <div class="loader">Loading...</div>
  </div>
  <div v-else class="row" style="width:100%">
    <div class="col-12">
      <form id="user_edit_form" class="card">
        <div class="card-header">
          <div class="title-part">
            <div class="text-left">
              <h4 class="card-title">ユーザー詳細</h4>
            </div>
          </div>
          <div v-if="isModalOpen === true" id="confirmDialogModal" class="confirmModal" >
            <div class="modal">
              <div class="modal-header">
                <h4>ユーザの編集が完了しました。</h4>
                <div class="text-right">
                  <i class="material-icons" @click="isModalOpen = false">close</i>
                </div>
              </div>
              <div class="modal-footer">
                <div class="cancelButton">
                  <a class="btn default" :href="'/mypage/users/'+userData.id+'/detail'">ユーザ詳細画面へ戻る</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <h4 class="card-title-body">ユーザー情報</h4>
          <div class="userInfoArea">
            <div class="userInformation">
              <div class="label">
                <label for="user_name">{{ userNameLabel }}</label>
              </div>
              <div class="formInput">
                <input name="user_name" id="user_name" maxlength="100" class="form-control" v-model="editUserName" type="text"/>
              </div>
              <p v-if="validationMessageCurrent" class="validationMessage">{{ validationMessageCurrent }}</p>
            </div>
            <div class="userInformation">
              <div class="label">
                <label for="user_role">{{ roleLabel }}</label>
              </div>
              <div class="formInput">
                <select name="user_role" id="user_role" class="form-control" style="text-align: left;" v-model="selectedRole" @change="roleChange" :disabled="roleDisabled">
                  <template v-for="item in roleTypeList">
                    <option :value="item.value">{{item.name}}</option>
                  </template>
                </select>
              </div>
            </div>
            <div class="userInformation" style="margin-bottom: 10px;">
              <div class="label">
                <span class="custom_label">{{ emailLabel }}</span>
              </div>
              <div class="user" style="display: inline-block;">
                <p>{{userData.email}}</p>
              </div>
            </div>
          </div>
          <div class="awsAccountTable">
            <h4 class="card-title-body">AWSアカウント情報</h4>
            <div class="table-responsive" style="margin-top: 20px">
              <table id="user_List" class="table table-striped table-hover text-left">
                <thead>
                <tr>
                  <th>
                    <div class="checkBox">
                      <input name="account_all_select" class="form-check-input" type="checkbox" :checked="isAllChecked" @change="handleAllChange" :disabled="isDisabled">
                    </div>
                  </th>
                  <template v-for="header in headerItems">
                    <TableTh :tableKey="header.key" v-on:emitTrigger="doSort">{{header.title}}</TableTh>
                  </template>
                </tr>
                </thead>
                <tbody>
                <tr v-for="account in awsAccounts" :key="account.aws_account_id">
                  <td>
                    <div class="checkBox">
                      <input name="account_items" class="form-check-input" :id="account.aws_account_id" type="checkbox"
                             :checked="getChecked(account.aws_account_id)" @change="handleListCheckbox(account.aws_account_id)" :disabled="isDisabled">
                    </div>
                  </td>
                  <td>
                    <div class="tableText">{{account.aws_account_id}}</div>
                  </td>
                  <td>
                    <div class="tableText">{{account.alias_name}}</div>
                  </td>
                </tr>
                </tbody>
              </table>
              <Pagination :pageNumber.number="pageNumber" :totalPages.number="totalPages" v-on:emitPage="setPage"/>
            </div>
          </div>
        </div>
        <div class="card-footer" style="border-top: 1px solid rgb(221, 221, 221);">
          <div class="buttonArea">
            <div class="cancelButton">
              <a class="btn default" :href="'/mypage/users/'+userData.id+'/detail'" style="width: 120px">キャンセル</a>
            </div>
            <div class="confirmButton">
              <button :class="`nav-link btn btn-primary`" style="width: 120px; color:white" @click.prevent="handleSubmit">変更</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script setup>
import {computed, onMounted, ref, watch} from "vue";
import TableTh from "../../components/table_th.vue";
import {findTableHeaderIndex, selectSort} from "../../lib/table";
import UserMethods from "../../components/user_methods.vue";
import Pagination from "../../components/pagination.vue"
import {extractId} from "../../lib/common";
import {roleTypes} from "../../utils/user_constants";
import Alert from "../../components/alert_new.vue";

const initialized = ref(false);
const selectedUserId = ref(0);
const editUserName = ref("");
const validationMessageCurrent = ref("");
const awsAccounts = ref([]);
const user = ref({});
const permissions = ref({});
const validationCurrent = ref(false);
const selectedRole = ref("USER");
const userNameLabel = ref("ユーザー名");
const emailLabel = ref("メールアドレス");
const roleLabel = ref("権限");
const awsAccountLabel = ref("AWSアカウント情報");
const roleTypeList = roleTypes;
const isDisabled = ref(false);
const roleDisabled = ref(false);
const checkedItems = ref([]);
const headerCheckbox = ref({});
const isModalOpen = ref(false);
const methods = ref(null);
const userData = ref({});
const alert = ref(null);
const pageNumber = ref(0);
const totalPages = ref(0);
const nowPage = ref(0);
const noCheckedAccountMessage = ref("権限が利用者の場合は、参照可能なAWSアカウントを１件以上選択してください。");

onMounted(async () => {
  selectedUserId.value = extractId(3);
  await setPage(0);
  await getUser(selectedUserId.value);
  userData.value.aws_accounts.forEach(account => {
    handleListCheckbox(account.aws_account_id);
  });
  editUserName.value = userData.value.username;
  selectedRole.value = userData.value.role_type;
  if (selectedRole.value === "ADMIN") {
    isDisabled.value = true;
  }
  if (permissions.value.same_user === true) {
    roleDisabled.value = true;
    isDisabled.value = true;
  }
  initialized.value = true;
});

const headerItems = ref([
  { title: "AWSアカウントID", key: "aws_account_id", sort: false },
  { title: "AWSアカウント別名", key: "alias_name", sort: false },
]);

const getUser = async (id) => {
  const res = await methods.value?.getUser(id);
  if (!res) {
    return false;
  }
  permissions.value = res.permissions;
  userData.value = res.user;
}

const setPage = async (page) => {
  const result = await getAwsAccounts(page);
  if(result){
    awsAccounts.value = result.aws_accounts;
    pageNumber.value = result.page_number;
    totalPages.value = result.total_pages;
  }
}

const doSort = (columnKey) => {
  const index = findTableHeaderIndex(headerItems.value, columnKey);
  const isSort = headerItems.value[index].sort;
  const sort = selectSort(isSort, columnKey);

  awsAccounts.value.sort(sort);
  headerItems.value[index].sort = !isSort;
}

const getChecked = (id) => {
  if (selectedRole.value === "ADMIN") {
    return true;
  } else {
    return checkedItems.value.includes(id);
  }
}

const handleListCheckbox = (id) => {
  if (!checkedItems.value.includes(id)) {
    checkedItems.value.push(id);
  } else {
    checkedItems.value = checkedItems.value.filter(item => item !== id);
  }
}

const isAllChecked = computed(() => {
  if (selectedRole.value === "ADMIN") {
    return true;
  } else {
    return awsAccounts.value.every(account => checkedItems.value.includes(account.aws_account_id));
  }
});

const handleAllChange = () => {
  if (isAllChecked.value) {
    checkedItems.value = checkedItems.value.filter(item => !awsAccounts.value.some(account => account.aws_account_id === item));
  } else {
    awsAccounts.value.forEach(account => {
      checkedItems.value.push(account.aws_account_id);
    });
  }
}

const roleChange = () => {
  if (selectedRole.value === "ADMIN") {
    isDisabled.value = true;
  } else if (selectedRole.value === "USER") {
    checkedItems.value = [];
    isDisabled.value = false;
  }
}

const validateUserName = () => {
  if (editUserName.value === "") {
    validationMessageCurrent.value = userNameLabel.value+"を入力してください。";
    validationCurrent.value = false;
  } else if (editUserName.value.length > 100) {
    validationMessageCurrent.value = userNameLabel.value+"の入力形式が正しくありません。";
    validationCurrent.value = false;
  } else {
    validationMessageCurrent.value = "";
    validationCurrent.value = true;
  }
}

const getAwsAccounts = async (page) => {
  const params = {size: 100, page: page};
  const res = await methods.value?.getAwsAccounts(params);
  if (!res.successful) {
    return false;
  }
  return res.data;
}

const editUser = async (id, form) => {
  const res = await methods.value?.editUser(id, form);
  if (!res) {
    return false;
  }
  return true;
}

const handleSubmit = async () => {
  await validateUserName();
  if (selectedRole.value === "USER" && checkedItems.value.length === 0) {
    alert.value.displayModal(noCheckedAccountMessage.value, "error");
    return false;
  }
  if (validationCurrent.value === true) {
    const userForEdit = {
      username: editUserName.value,
      role_type: selectedRole.value,
      aws_accounts: selectedRole.value === "USER" ? checkedItems.value : [],
    }
    const isPassed = await editUser(userData.value.id, userForEdit);
    if (isPassed === false) {
      return false;
    } else {
      isModalOpen.value = true;
    }
  }
}

watch(editUserName, () => {
  validateUserName();
});

</script>
<style scoped>

.title-part {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(221, 221, 221);
  height: 60px;
}

.card-title {
  font-size: 22px;
  font-weight: 300;
  margin-top: 10px;
}

.card-title-body {
  font-size: 20px;
  font-weight: 300;
}

.label {
  display: inline-block;
  width: 200px;
  height: 30px;
}

.custom_label {
  color: #aaa;
}

.formInput {
  display: inline-block;
}

.form-control {
  width: 200px;
  display: inline-block;
}

.validationMessage {
  color : red;
  display: inline-block;
  font-size: 12px;
  margin-left: 20px;
  height: 10px;
}

.userInfoArea {
  border-bottom: 1px solid rgb(221, 221, 221);
}

.userInformation {
  margin-top: 20px;
}

.checkBox{
  height: 20px;
  vertical-align: middle;
  text-align: end;
}

.table table-striped table-hover text-left {
  margin-bottom: 10px;
}

.awsAccountTable {
  margin-top: 20px;
}

.cancelButton {
  display: inline-block;
  padding-right: 30px;
}

.confirmButton {
  display: inline-block;
}

.confirmModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: white;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 20%;
  margin-left: 30%;
  display: block;
  height: 180px;
}


</style>
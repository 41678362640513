<template>
  <BudgetMethods ref="methods"/>
  <Alert ref="alert"/>
  <div v-if="!initialized">
    <div class="loader">Loading...</div>
  </div>
  <div v-else>
    <form class="card" @submit.prevent="createBudget()">
      <div class="card-body">
        <div style="display: flex; justify-content: space-between;">
          <div class="text-left"></div>
          <div class="text-right">
            <a class="btn default" href="/mypage/budget/">一覧</a>
          </div>
        </div>
        <table class="table table-borderless mt-2">
          <tbody>
            <tr>
              <th>予算名</th>
              <td>
                <div class="form-group">
                  <input id="budget-name" class="form-control width-30" type="text" minlength="1" maxlength="255" required v-model="form.budget_name"/>
                </div>
              </td>
            </tr>
            <tr>
              <th>費用の集計基準</th>
              <td>
                <div>費用の償却コスト</div>
                <small>前払い費用は前払い金額を対象期間の月数を按分したものとします</small>
              </td>
            </tr>
          </tbody>
        </table>
        <!--- filter --->
        <div class="card-body form-border">
          予算の範囲
          <BudgetFilterList :reportType="reportType" :reportFilter="form.filter" v-on:updateFilter="updateFilter"/>
        </div>
        <!--- filter --->
        <!--- currency --->
        <div class="card-body form-border mt-3">
          通貨設定
          <BudgetCurrencyTemplate v-model:currency="form.currency" v-model:exchangeRate="form.exchange_rate"/>
        </div>
        <!--- currency --->
        <!--- period --->
        <div class="card-body form-border mt-3">
          予算設定
          <BudgetPeriodTemplate v-on:emitTrigger="updatePeriod"/>
        </div>
        <!--- period --->
        <!--- amount --->
        <div class="card-body form-border mt-3">
          予算額($)を決める
          <template v-if="isValid">
            <BudgetAmountTemplate :startDate="form.start_date" :endDate="form.end_date" :filter="form.filter" :range="range" v-on:emitTrigger="updateAmount" v-on:resetFlag="amountFlag=false"/>
          </template>
          <template v-else>
            <div>
              適切な期間を設定すると、選択できるようになります。
            </div>
          </template>
        </div>
        <!--- amount --->
        <!--- result --->
        <div class="card-body form-border mt-3" v-if="isValid && amountFlag">
          <span>各月の予算</span>
          <div>
            <BudgetResultTemplate :monthly_budgets="form.monthly_budgets" :currency="form.currency" :exchange_rate="form.exchange_rate" v-on:emitTrigger="updateMonthlyBudgets"/>
          </div>
        </div>
        <!--- result --->
        <!--- save --->
        <div class="mt-3" style="display: flex; justify-content: center;">
          <div v-if="isValid && amountFlag">
            <button class="btn btn-primary repeated_hit_prevention" type="submit">保存する</button>
          </div>
        </div>
        <!--- save --->
      </div>
    </form>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import BudgetFilterList from "../components/budget_filter_list.vue";
import { pagePath, reportTypeCostExplorer } from "../utils/constants";
import BudgetPeriodTemplate from "../components/budget_period_template.vue"
import BudgetAmountTemplate from "../components/budget_amount_template.vue"
import BudgetCurrencyTemplate from "../components/budget_currency_template.vue"
import BudgetResultTemplate from "../components/budget_result_template.vue"
import BudgetMethods from "../components/budget_methods.vue";
import { getCsvFromForm } from "../lib/download_action";
import { usePeriod } from "../composables/budget_period";
import { useAmount } from "../composables/budget_amount";
import Alert from "../components/alert_new.vue";

const reportType = reportTypeCostExplorer
const path = pagePath.budget.index

const { isValid, range, setPeriod, createMonthlyMap, getStartFormat, getEndFormat } = usePeriod()
const { amount, setAmount, setEstimateParam, getSettingType, getEstimateParam, getGrowthRate, calcMonthlyBudgets, isPricingCalculator, isActualEstimate } = useAmount()

const methods = ref(null)
const alert = ref(null)

let initialized = ref(false)
let disabled = ref(false)
let amountFlag = ref(false)

let form = ref({
  budget_name: "",
  start_date: "",
  end_date: "",
  currency: "usd",
  exchange_rate: 100,
  setting_type: "fixed",
  growth_rate: 0,
  filter: {
    linked_account: {
      condition: "include",
      values: []
    }
    ,
    charge_type: {
      condition: "include",
      values: []
    }

  },
  monthly_budgets: {}
})

const updateFilter = (filter) => {
  form.value.filter[filter.key] = filter.value
}

const updatePeriod = (value) => {
  setPeriod(value)
  form.value.start_date = getStartFormat()
  form.value.end_date = getEndFormat()
}

const updateAmount = (newAmount, estimateParam) => {
  // 開始、終了月のform作成
  const monthlyBudgets = createMonthlyMap()
  setAmount(newAmount)
  setEstimateParam(estimateParam)
  const budgets = calcMonthlyBudgets(monthlyBudgets)

  form.value.monthly_budgets = budgets

  form.value.setting_type = getSettingType()
  form.value.growth_rate = getGrowthRate()

  amountFlag.value = true
}

const updateMonthlyBudgets = (key, value) => {
  form.value.monthly_budgets[key] = value
}

const createBudget = async function(){
  if(disabled.value){ return false }
  disabled.value = true

  if(form.value.currency === 'usd'){
    form.value.exchange_rate = null
  }


  // 予算アップロード
  const id = await methods.value.createBudget(form.value)
  if(!id){
    this.disabled = false
    return false
  }
  const result = await uploadCSV(id)
  if(result){
    alert.value.displayModal("成功しました。", 'success');
    setTimeout(transition, 1000)
  }
}

const uploadCSV = async function(id) {
  let result = false
  if(isPricingCalculator()){
    const formData = getCsvFromForm("#inputCsv")
    result = await methods.value.uploadPricingCalculatorCSV(id, formData)
  } else if(isActualEstimate()) {
    result = await methods.value.uploadBudgetEstimate(id, getEstimateParam())
  } else {
    result = true
  }
  return result
}

const transition = () => {
  window.location.href = path
}

onMounted(async () => {
  initialized.value = true
})

</script>
<style scoped>
.width-30 {
  width: 30%
}
.form-border {
  border: 0.1rem solid;
  border-color: rgb(135,135,135);
  border-radius: 10px
}
</style>

<template>
  <div>
    <Alert ref="alert"/>
    <template v-if="isLoad">
      <span class="dropdown-item text-center">Loading・・・</span>
    </template>
    <template v-else>
      <iframe id="targetIframe" :src="embed_url" :height="height" width="100%"></iframe>
    </template>
  </div>
</template>
<script>
import * as constants from "../utils/constants";
import {generateDashboardUrl} from "../lib/axios/api_client";
import Alert from "./alert_new.vue";

export default {
  props: [ 'height' ],
  components: {
    'Alert': Alert,
  },
  data() {
    return {
      isLoad: false,
      embed_url: constants.defaultUrl,
    }
  },
  methods: {
    generateDashboard: async function(aws_report) {
      this.isLoad = true
      const res = await generateDashboardUrl(aws_report)

      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      this.$nextTick(() => {
        this.isLoad = false
        this.embed_url  = res.data.embed_url
      })
    },
  }
}
</script>
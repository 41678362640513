<template>
  <div class="col-lg-12 col-md-12 col-sm-12">
    <div class="d-flex justify-content-center my-5">
      <span class="text-secondary text-center">
        <span class="text-danger font-weight-bold">
          集計待機中<br>※集計は{{ processDate }}の予定です
        </span>
      </span>
    </div>
  </div>
</template>
<script setup>
defineProps({ processDate: String })
</script>

<template>
    <span>
        <span class="btn btn-default btn-sm dropdown-toggle" type="button" id="reportList" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><slot></slot></span>
        <div class="dropdown-menu" aria-labelledby="reportList" style="width: 300px;">
            <template v-if="report_list.length === 0">
              <span class="dropdown-item">No data</span>
            </template>
            <template v-else>
              <div v-for="(report, i) in report_list" style="justify-content: center;">
                <span class="dropdown-item cursor-pointer" :id="`${type}_${report.id}`" v-on:click="setReportId(report.id)" v-on:mouseover="mouseOverAction(i, report.id)" v-on:mouseleave="mouseLeaveAction()">
                  {{ report.name }}
                </span>
                <template v-if="hover.flag && i === hover.index">
                  <div class="tooltip-text">
                    {{ report.name }}
                  </div>
                </template>
              </div>
              <template v-if="totalPages > 1">
                <nav class="mt-3">
                  <ul class="pagination" style="justify-content: center;">
                    <template v-if="pageNumber > 0">
                      <li class="page-item">
                        <span class="page-link" tabindex="-1" v-on:click.stop.prevent="backPage">前</span>
                      </li>
                    </template>
                    <li class="page-item active">
                      <a class="page-link" href="#">{{ pageNumber + 1 }}</a>
                    </li>
                    <template v-if="pageNumber < totalPages - 1">
                      <li class="page-item">
                        <span class="page-link" tabindex="-1" v-on:click.stop.prevent="nextPage">次</span>
                      </li>
                    </template>
                  </ul>
                </nav>
              </template>
            </template>
        </div>
    </span>
</template>
<script>
export default {
  props: ['report_list', 'pageNumber', 'totalPages', 'type'],
  data: function () {
    return {
      hover: {
        flag: false,
        index: null
      },
    }
  },
  methods: {
    mouseOverAction(index, reportId) {
      const ele = document.getElementById(this.type + '_' + reportId)
      if(ele.offsetWidth >= ele.scrollWidth) return
      this.hover.flag = true
      this.hover.index = index
    },
    mouseLeaveAction() {
      this.hover.flag = false
    },
    setReportId(report_id) {
      this.$emit('childMethod', report_id)
    },
    backPage(){
      this.$emit('pagingAction', "back")
    },
    nextPage(){
      this.$emit('pagingAction', "next")
    }
  }

}
</script>

<style scoped>
.dropdown-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.tooltip-text{
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  padding: 0.1rem 0.2rem;
  z-index: 1000;
  border: 1px solid #dddddd;
  border-radius: 0.25rem;
}
</style>

export const categoryTypes= [
    {
      type: "info",
      name: "お知らせ"
    },
    {
      type: "incident",
      name: "障害情報"
    }
  ]

export const categoryTypeList = categoryTypes.map( item  => item.type )


function createCsv(downloadItem, keys, titles){
  let data = []

  // key一覧
  data.push(titles)

  // value一覧
  for (const item of downloadItem){
    let set = []
    for (let i = 0; i < keys.length; i++){
      let key = keys[i]
      let value = ''
      if (key.includes(".")) {
        let subKey = key.split(".")
        value = _escapeComma(item[subKey[0]].map(function(e) {return e[subKey[1]];}))
      } else {
        value = _escapeComma(item[key])
      }
      set.push(value)
    }
    data.push(set)
  }

  //作った二次元配列をCSV文字列に直す。
  let csv_string  = "";
  for (let d of data) {
    csv_string += d.join(",");
    csv_string += '\r\n';
  }

  return csv_string
}

function getCsvFromForm(selectors) {
  const fileInput = document.querySelector(selectors);
  const file = fileInput.files[0];
  const formData = new FormData();
  formData.append('file', file);
  return formData
}

function downloadCsv(data, fileName){
  const fileNameWithEx = fileName + ".csv"
  _downloadAction(data, fileNameWithEx, "text/csv")
}

function downloadPdf(data, fileName){
  _downloadAction(data, fileName, "application/pdf")
}

function _downloadAction(data, fileName, contentType){
  // バイナリデータを作る
  let blob        = new Blob([data], {type: contentType});
  let uri         = URL.createObjectURL(blob);

  //リンクタグを作る
  let link        = document.createElement("a");
  link.download   = fileName;
  link.href       = uri;

  //作ったリンクタグをクリックさせる
  document.body.appendChild(link);
  link.click();

  //クリックしたら即リンクタグを消す
  document.body.removeChild(link);
}

function _escapeComma(value){
  const regexp = /,/g
  if(regexp.test(value)){
    value = "\""+ value +"\""
  }
  return value
}

export {
  createCsv,
  getCsvFromForm,
  downloadCsv,
  downloadPdf
}

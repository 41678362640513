<template>
  <div>
    <SimulatorMethods ref="methods"/>
    <div v-if="!initialized">
      <div class="loader">Loading...</div>
    </div>
    <div v-else>
      <div class="row">
        <div class="right-sizing-table col-lg-12 col-md-12 col-sm-12">
          <div class="card">
            <!-- Modal -->
            <template v-for="scenario in scenarios">
              <div class="modal fade" v-bind:id="'expireRiSpModal' + scenario.id" tabindex="-1" role="dialog" aria-labelledby="expireRiSpModal" aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content" style="width:1200px; margin-left: -300px;">
                    <div class="modal-header">
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <TableExpireRiSp :expireRiSp="expireRiSp" :awsAccountIds="scenario.aws_account_ids"/>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <!-- Modal -->
            <div class="card-header">
              <ul class="nav">
                <li class="nav-item">
                  <a
                    :class="`nav-link btn btn-primary ${scenarios.length >= 10 ? ' disabled' : ''}`"
                    href="/mypage/recommend/simulator/scenario/new"
                  >
                    新規
                  </a>
                </li>
              </ul>
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="pull-left text-secondary">最大10件まで登録することができます</div>
                  <div class="pull-right">
                    <DropdownDownload reportType="scenario_list" :tableItem="scenarios" :keys="scenarioListKeys()" :titles="scenarioListTitles()"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div>
                <table id="scenario-list" class="table table-striped table-hover text-nowrap">
                  <thead>
                  <tr>
                    <template v-for="header in scenarioTh">
                      <TableTh :tableKey="header.key" v-on:emitTrigger="doSort">{{ header.title }}</TableTh>
                    </template>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-if="Object.keys(scenarios).length">
                    <template v-for="scenario in scenarios">
                      <tr>
                        <td>
                          <a :href="`/mypage/recommend/simulator/scenario/${scenario.id}/detail`">{{ scenario.name }}</a>
                        </td>
                        <td class="text-center">
                          <template v-if="scenario.status === 'finish' || scenario.status === 'no_result'">
                            <i class="material-icons color-green align-middle">check_circle</i>
                          </template>
                          <template v-else-if="scenario.status === 'warning'">
                            <i class="material-icons color-red align-middle">warning</i>
                          </template>
                          <template v-else>
                            <i class="material-icons align-middle">hourglass_empty</i>
                          </template>
                        </td>
                        <td style="white-space: normal">
                          <template v-for="awsAccount in scenario.aws_account_ids">
                            <TooltipText :text="awsAccount.aws_account_id" :tooltipText="awsAccount.account_name"/>
                          </template>
                        </td>
                        <td>
                          <a class="text-info cursor-pointer" data-toggle="modal" v-on:click="getScenarioExpireRiSp(scenario.id)" v-bind:data-target="'#expireRiSpModal' + scenario.id" type="button">
                            {{ scenario.expire_ri_sp_count }}
                          </a>
                        </td>
                        <td>{{ scenario.recommend_type }}</td>
                        <td>{{ scenario.payment_term }}</td>
                        <td>{{ scenario.payment_option }}</td>
                        <td>{{ scenario.simulator_scheduled_date }}</td>
                        <td>{{ scenario.created_at }}</td>
                        <td>{{ scenario.simulator_completed_date }}</td>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="9">
                        設定はありません
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import SimulatorMethods from "../../../../components/recommend_simulator_methods.vue";
import { findTableHeaderIndex, selectSort } from "../../../../lib/table";
import TableTh from "../../../../components/table_th.vue";
import TableExpireRiSp from "../../../../components/table_recommend_simulator_expire_ri_sp.vue";
import TooltipText from "../../../../components/tooltip_text.vue";
import DropdownDownload from '../../../../components/dropdown_download.vue'

const scenarioTh = ref([
  { title: "シナリオ名",              key: "name",                     sort: false },
  { title: "実行状態",                key: "status",                   sort: false },
  { title: "連結アカウント",           key: "aws_account_ids.aws_account_id", sort: false },
  { title: "期限切れの対象となるRI/SP", key: "expire_ri_sp_count",       sort: false },
  { title: "推薦する種類",             key: "recommend_type",           sort: false },
  { title: "支払い期間",               key: "payment_term",             sort: false },
  { title: "支払い方法",               key: "payment_option",           sort: false },
  { title: "実行予定日",               key: "simulator_scheduled_date", sort: false },
  { title: "作成日",                  key: "created_at",               sort: false },
  { title: "実行日",                  key: "simulator_completed_date", sort: false },
])

const methods = ref(null)

let initialized = ref(false)
let scenarios = ref([])
let expireRiSp = ref({})

const doSort = (columnKey) => {
  const index = findTableHeaderIndex(scenarioTh.value, columnKey)
  const isSort = scenarioTh.value[index].sort
  const sort = selectSort(isSort, columnKey);

  scenarios.value.sort(sort)
  scenarioTh.value[index].sort = !isSort
}

const getScenarioList = async () => {
  const result = await methods.value?.getScenarioList()
  if (!result) {
    return false
  }
  scenarios.value = result.scenarios
}

const getScenarioExpireRiSp = async (id) => {
  const result = await methods.value?.getScenarioExpireRiSp(id)
  if (!result) {
    return false
  }
  expireRiSp.value = result.expire_ri_sp
}

const scenarioListKeys = () => {
  return scenarioTh.value.map(item => item.key)
}

const scenarioListTitles = () => {
  return scenarioTh.value.map(item => item.title)
}

onMounted(async () => {
  await getScenarioList()
  initialized.value = true
})

</script>
<style scoped>
.color-red {
  color: #ff0000;
}

.color-green {
  color: #008000;
}

.pull-left.text-secondary {
  margin-top: 1rem;
}
</style>

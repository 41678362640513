<template>
  <Alert ref="alert"/>
  <div v-if="!initialized">
    <div class="loader">Loading...</div>
  </div>
  <div v-else>
    <form class="card repeated_hit_prevention_form">
      <div class="card-body">
        <div style="display: flex;justify-content: space-between;">
          <div style="display: flex;" class="text-left">
            <button type="button" class="btn btn-primary" v-on:click="updaterAwsAccount()">保存</button>
            <a class="btn btn-primary" :href="`/mypage/settings/aws_accounts/${awsAccount.aws_account_id}/detail`">キャンセル</a>
          </div>
          <div class="text-right">
            <a class="btn default" href="/mypage/settings/aws_accounts">一覧</a>
          </div>
        </div>
        <table class="table table-borderless mt-5">
          <tbody>
          <tr>
            <th>AWSアカウントID</th>
            <td>{{ awsAccount.aws_account_id }}</td>
          </tr>
          <tr>
            <th>別名</th>
            <td>
              <input class="form-control w-50" type="text" maxlength="64" v-model="awsAccount.alias_name"/>
            </td>
          </tr>
          <template v-if="permissions.show_organization_integration">
            <tr>
              <th>Organizationsへの統合の有無</th>
              <td>
                <template v-if="originallyLinked || hasLinkedAccount">
                  <input type="checkbox" v-model="awsAccount.linked" readonly onclick="return false;" onfocus="this.blur();">
                </template>
                <template v-else>
                  <input type="checkbox" v-model="awsAccount.linked" v-on:change="resetPayerAccountId()">
                </template>
              </td>
            </tr>
          </template>
          <template v-if="permissions.show_admin_account && awsAccount.linked">
            <tr>
              <th>管理アカウント</th>
              <td>
                <select class="form-control w-50" v-model="awsAccount.payer_account_id">
                  <option :value="null">選択してください</option>
                  <template v-for="item in payerAwsAccounts">
                    <option :value="item.aws_account_id">{{ item.aws_account_id }}</option>
                  </template>
                </select>
              </td>
            </tr>
          </template>
          <tr>
            <th>ステータス</th>
            <td>{{ statusValue(awsAccount.status) }}</td>
          </tr>
          <tr>
            <th>IAM Role ARN</th>
            <td>{{ awsAccount.iam_role_arn }}</td>
          </tr>
          <tr>
            <th>バーション</th>
            <td>{{ versionValue(awsAccount.version) }}</td>
          </tr>
          <tr>
            <th>Role作成・更新</th>
            <td>
              編集できません。一覧または詳細よりお願いします。
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </form>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue"
import { getAwsAccount, getLinkedAwsAccounts, getPayerAwsAccounts, updateAwsAccount } from "../lib/axios/api_client"
import { status, version } from "../utils/aws_accounts";
import Alert from "../components/alert_new.vue"

const alert = ref(null)
const statusList = status
const versionList = version

const initialized = ref(false)
const awsAccount = ref({})
const permissions = ref({})
const originallyLinked = ref(false)
const hasLinkedAccount = ref(false)
const payerAwsAccounts = ref([])

const statusValue = (target) => {
  const status = statusList.find(item => target === item.value)
  return status.name
}

const versionValue = (target) => {
  const version = versionList.find(item => target === item.value)
  return version.name
}

const getterLinkedAwsAccounts = async () => {
  const res = await getLinkedAwsAccounts(_getId())
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }
  hasLinkedAccount.value = res.data.linked_account_ids.length ? true : false
}

const resetPayerAccountId = async () => {
  if (!awsAccount.value.linked) {
    awsAccount.value.payer_account_id = null
  }
}

const getterAwsAccount = async () => {
  const res = await getAwsAccount(_getId())
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }
  awsAccount.value = res.data.aws_accounts
  originallyLinked.value = res.data.aws_accounts.linked
  permissions.value = res.data.permissions
}

const getterPayerAwsAccounts = async () => {
  const res = await getPayerAwsAccounts()
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }

  payerAwsAccounts.value = res.data.aws_accounts.filter(account => account.aws_account_id !== awsAccount.value.aws_account_id)
  return true
}

const validateForm = () => {
  if (awsAccount.value.linked && !awsAccount.value.payer_account_id) {
    alert.value.displayModal("管理アカウントが選択されていません。", 'error')
    return false
  }
  return true
}

const updaterAwsAccount = async () => {
  if (!validateForm()) return false

  const res = await updateAwsAccount(awsAccount.value.aws_account_id, awsAccount.value)
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }
  alert.value.displayModal('成功しました。','success')
  setTimeout(_transition, 1000)
}

const _getId = () => {
  const pathArray = location.pathname.split("/")
  if(pathArray[4] === "undefined" ){
    return false
  }
  return pathArray[4]
}

const _transition = () => {
  window.location.href = `/mypage/settings/aws_accounts/${awsAccount.value.aws_account_id}/detail`
}

onMounted( async () => {
  await getterAwsAccount()
  if (permissions.value.show_admin_account) {
    await getterLinkedAwsAccounts()
    await getterPayerAwsAccounts()
  }
  initialized.value = true
})

</script>
<style scoped>
</style>
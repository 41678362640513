<template>
  <div class="row">
    <ReportMethods ref="reportMethods" :reportType="report_type"/>
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-title">
            <h4>保存済みレポート</h4>
            <template v-if="disabledBulkDelete">
              <button type="button" class="btn btn-primary disabled cursor-not-allowed">削除</button>
            </template>
            <template v-else>
              <button @click="deleteReport" type="button" class="btn btn-primary">削除</button>
            </template>
            <button @click="lockReport(selected.join(','))"  type="button" :class="`btn btn-primary cursor-not-allowed ${disabledBulkLock ? ' disabled' : ''}`">ロック</button>
            <button @click="unlockReport(selected.join(','))" type="button" :class="`btn btn-primary ${disabledBulkUnlock ? ' disabled' : ''}`">ロック解除</button>
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-no-bordered table-hover text-left" cellspacing="0" width="100%" style="width:100%">
              <thead>
              <tr>
                <th></th>
                <th>レポート名</th>
                <th>タイプ</th>
                <th>ロック</th>
                <th>グラフ形式</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in reportList">
                <td class="text-center">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input @change="checkBulkCheckbox" class="form-check-input" type="checkbox" :value="item.id" v-model="selected">
                      <span class="form-check-sign"><span class="check"></span></span>
                    </label>
                  </div>
                </td>
                <td v-on:click="goToReportPage(item.id, item.report_type)" class="text-info cursor-pointer">
                  {{ item.name }}
                </td>
                <td>{{ item.report_type }}</td>
                <td>
                  <template v-if="item.report_lock">
                    <span v-on:click="unlockReport(item.id)" class="cursor-pointer text-info">
                      <i class="material-icons">lock</i>
                    </span>
                  </template>
                  <template v-else>
                    <span v-on:click="lockReport(item.id)" class="cursor-pointer text-info">
                      <i class="material-icons">lock_open</i>
                    </span>
                  </template>
                </td>
                <td>
                  <span v-if="item.graph_style.visual_type === 'bar'"><i class="material-icons">bar_chart</i></span>
                  <span v-if="item.graph_style.visual_type === 'line'"><i class="material-icons">show_chart</i></span>
                  <span v-if="item.graph_style.visual_type === 'stacked_bar'"><i class="material-icons">stacked_bar_chart</i></span>
                </td>
              </tr>
              </tbody>
            </table>
            <Pagination :pageNumber.number="pageNumber" :totalPages.number="totalPages" v-on:emitPage="getReportList"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as constants from '../utils/constants'
import ReportMethods from "../components/report_methods.vue";
import Pagination from "../components/pagination.vue";

export default {
  components: {
    Pagination,
    'ReportMethods': ReportMethods
  },
  mounted: async function(){
    await this.getReportList()
  },
  data: function () {
    return {
      dashboardPath: constants.pagePath.dashboard,
      report_type: "",
      reportList: [],
      selected:  [],
      initialized: true,
      nowPage: 0,
      pageNumber: 0,
      totalPages: 0,
      disabledBulkDelete: true,
      disabledBulkLock: true,
      disabledBulkUnlock: true
    }
  },
  methods: {
    goToReportPage: async function(id, reportType){
      const path = this.dashboardPath[reportType]
      window.location.href = path + "?reportId=" + id
    },
    initializeBulkCheckbox: function () {
      this.disabledBulkDelete = true;
      this.disabledBulkLock = true;
      this.disabledBulkUnlock = true;
    },
    checkBulkCheckbox: function () {
      this.initializeBulkCheckbox();

      let lockTypes = this.selected.map((id) => {
        let index = this._findReportIndex(id)
        return this.reportList[index].report_lock;
      }).filter(function(elem, index, self) {
        return self.indexOf(elem) === index;
      });

      // 未選択、もしくはLockとUnlockのデータが選ばれた場合、まとめて実行はできない
      if (lockTypes.length !== 1) return;

      // ロックされていないデータのみ選択
      if (lockTypes[0] === false) {
        this.disabledBulkDelete = false;
        this.disabledBulkLock = false;
        // ロックされているデータのみ選択
      } else {
        this.disabledBulkUnlock = false;
      }
    },
    getReportList: async function(page=0) {
      const result = await this.$refs.reportMethods.getReportList(page)
      if(result){
        this.$nextTick(async () => {
          this.reportList = result.reports
          this.pageNumber = result.page_number
          this.totalPages = result.total_pages
          this.nowPage = page

          this.selected = []
          this.initializeBulkCheckbox()
          return true
        })
      }
    },
    deleteReport: async function() {
      if (!window.confirm("本当に削除しますか?")) return;
      const ids = this.selected.join(',');

      const result = await this.$refs.reportMethods.deleteReport(ids)
      if(result){
        await this.getReportList()

        this.selected = []
        this.initializeBulkCheckbox()
      }
    },
    lockReport: async function(ids){
      const result = await this.$refs.reportMethods.lockReport(ids)
      if(result){
        await this.getReportList(this.nowPage)

        this.selected = []
        this.initializeBulkCheckbox()
      }
    },
    unlockReport: async function(ids){
      const result = await this.$refs.reportMethods.unlockReport(ids)
      if(result){
        await this.getReportList(this.nowPage)

        this.selected = []
        this.initializeBulkCheckbox()
      }
    },
    _findReportIndex(reportId){
      return this.reportList.findIndex(item => item.id === reportId)
    },
  }
}
</script>
<template>
  <Alert ref="alert"/>
  <div v-if="!loading.initialized">
    <div class="loader">Loading...</div>
    <ReportMethods ref="reportMethods" :reportType="serviceType.history"/>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="tab-description" v-if="serviceType.type === 'vpc'">
          料金が発生していないリージョンの情報については表示しません
        </div>
        <div class="card">
          <div class="card-body">
            <IframeDashboard ref="dashboard" height="850px"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as constants       from '../utils/constants'
import ReportMethods from "../components/report_methods.vue"
import IframeDashboard from "../components/iframe_dashboard.vue"

export default {
  props: ['serviceType'],
  components: {
    'ReportMethods'        : ReportMethods,
    'IframeDashboard'      : IframeDashboard
  },
  data: function () {
    return {
      aws_report: {},
      loading: {
        initialized: false
      },
    }
  },
  mounted: async function(){
    const report = await this.$refs.reportMethods.getReport(0, this.serviceType.history)
    if(!report){ return false }
    this.aws_report = report

    this.loading.initialized = true

    this.$nextTick(async () => {
      await this.$refs.dashboard.generateDashboard(this.aws_report)
    })
  },
  methods: {
  }
}
</script>

<style scoped>
.tab-description {
  position: absolute;
}
</style>

<template>
  <transition name="modal">
    <template v-if="showModal">
      <div class="modal-mask-top" key="please-destroy">
        <div class="modal-wrapper-top">
          <div class="modal-container-top">
            <div :class="alertClass.apply" role="alert">
              {{ message }}
              <button type="button" class="close my-2" data-dismiss="alert" aria-label="Close" @click="triggerEmit">
                <span>&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </transition>
</template>
<script>
export default {
  data: function() {
    return {
      message: 'アラートテスト',
      showModal: false,
      alertClass:{
        apply: "alert alert-success alert-dismissible fade show",
        success: "alert alert-success alert-dismissible fade show",
        error:   "alert alert-danger alert-dismissible fade show"
      }
    }
  },
  methods: {
    displayModal(message = '', alertClass = 'error') {
      this.$nextTick(() => {
        this.message = message
        this.alertClass.apply = (alertClass === "success") ? this.alertClass.success : this.alertClass.error
        this.showModal = true
      })
    },
    triggerEmit() {
      this.$nextTick(() => {
        this.showModal = false
      })
    }
  }
}

</script>

<template>
  <PasswordMethods ref="methods"/>
  <div>
    <div class="card card-login card-hidden" style="height: 600px">
      <div class="card-header text-center my-4">
        <h4 class="card-title">パスワード再設定</h4>
      </div>
      <div class="card-body">
        <template v-if="!isSendMail">
          <form name="reset-form" class="mb-5" v-on:submit.prevent="sendmailResetPassword()">
            <div class="form-group mb-5">
              <label for="loginMailInput">メールアドレス</label>
              <input id="loginMailInput" name="pwdResetMail" type="email" maxlength="100" class="form-control" placeholder="メールアドレスを入力してください" v-model="email" @input="validateEmail()">
            </div>
            <div class="justify-content-start">
              <div class="mb-5">
                <button type="submit" class="btn btn-primary d-block mx-auto w-100 py-3">パスワードリセットメールを送信</button>
              </div>
            </div>
          </form>
          <div class="justify-content-start">
            <div class="mb-2">
              <a href="/login" class="d-block mx-auto">キャンセル</a>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="justify-content-start">
            <div class="mb-2">
              <span>メールに記載された内容に従って、パスワードをリセットしてください。</span>
            </div>
            <div class="mb-2">
              <a href="/login" class="d-block mx-auto">キャンセル</a>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup>
import PasswordMethods from "../../components/password_methods.vue";
import { onMounted, ref } from "vue";

const methods = ref(null)
const initialized = ref(false)
const email = ref("")
const isSendMail = ref(false)
const emailValidated = ref(false)
const emailRegex = /^[a-zA-Z0-9.!#$%&*+\/=?^_`{|}~-]{1,64}@[\.a-zA-Z0-9-]+\.[a-zA-Z]+$/
const domainRegex = /^(?=.{1,100}$)(?=.{1,64}@).+@.+\.[a-zA-Z]{2,}$/

const validateEmail = () => {
  const form = document.forms["reset-form"]
  if (!email.value || email.value === "") {
    form.elements["pwdResetMail"].setCustomValidity("メールアドレスを入力してください。")
    emailValidated.value = false
  } else if(!emailRegex.test(email.value) || !domainRegex.test(email.value)) {
    form.elements["pwdResetMail"].setCustomValidity("メールアドレスの入力形式が正しくありません。")
    emailValidated.value = false
  } else {
    form.elements["pwdResetMail"].setCustomValidity("")
    emailValidated.value = true
  }
}

const sendmailResetPassword = async () => {
  validateEmail()
  if(emailValidated.value === true){
    const param = {
      email: email.value
    }
    const result = await methods.value?.sendmailResetPassword(param)
    if (!result) {
      return false
    }
    isSendMail.value = true
  }
}

onMounted(async () => {
  initialized.value = true
  validateEmail()
})

</script>

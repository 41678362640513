<template>
  <div>
    <ServiceAccountMethods ref="methods"/>
    <div v-if="!initialized">
      <div class="loader">Loading...</div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card">
            <div class="card-header card-header-rose card-header-icon">
              <div class="card-title">
                <h4>解約理由を選択、ご入力ください。</h4>
              </div>
            </div>
            <div class="card-body">
              <div>
                <div class="mt-3">
                  <p>解約理由</p>
                  <template v-for="item in quitReason">
                    <div class="form-check form-check-radio">
                      <label class="form-check-label">
                        <input class="form-check-input" type="radio" name="quitReason" id="quitReason" required :value="item.code" v-model="quitParam.quit_reason_type" >
                        {{ item.title }}
                        <span class="circle">
                          <span class="check"></span>
                        </span>
                      </label>
                    </div>
                  </template>
                  <p v-if="validation.quitReasonType">
                    <small class="form-text text-danger">解約理由を選択してください。</small>
                  </p>
                </div>
                <div class="mt-3">
                  <p>主な解約理由を選択してください</p>
                  <template v-for="item in quitMainReason">
                    <div class="form-check">
                      <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" name="quitMainReason" :value="item.code" v-model="quitParam.quit_main_reason_type">
                        {{ item.title }}
                        <span class="form-check-sign">
                          <span class="check"></span>
                      </span>
                      </label>
                    </div>
                  </template>
                  <p v-if="validation.quitMainReasonType">
                    <small class="form-text text-danger">主な解約理由を1つ以上選択してください。</small>
                  </p>
                </div>
                <div class="mt-3">
                  <p>詳細（その他、ご意見がありましたらご入力ください。）</p>
                  <div class="form-group border border-default">
                    <textarea class="form-control" rows="10" maxlength="5000" style="height: 400px" v-model="quitParam.quit_memo"></textarea>
                  </div>
                </div>
                <div class="mt-3">
                  <a href="/mypage/" class="btn btn-default">解約をやめる</a>
                  <button v-on:click="quitRequest" class="btn btn-primary">解約する</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import ServiceAccountMethods from "../../../components/service_account_methods.vue";

const methods = ref(null)
let initialized = ref(false)
const quitReason = [
  { code: "1", title: "AWSアカウントの解約" },
  { code: "2", title: "他サービスへの変更" },
  { code: "3", title: "その他" }
]
const quitMainReason = [
  { code: "1", title: "料金が高い" },
  { code: "2", title: "使用頻度が低い" },
  { code: "3", title: "使いたい機能が少ない" },
  { code: "4", title: "品質に不満がある" },
  { code: "5", title: "Cloud illuminatorの使い方がわかりづらい" },
  { code: "6", title: "サポートに不満がある" },
  { code: "7", title: "他サービスに切り替え" },
  { code: "8", title: "その他" }
]

let quitParam = ref(
  {
    quit_reason_type: "",
    quit_main_reason_type: [],
    quit_memo: ""
  }
)
let quitInfo = ref(
  {
    quit_message: ""
  }
)
let validation = ref(
  {
    quitReasonType: false,
    quitMainReasonType: false
  }
)

const getQuit = async () => {
  const result = await methods.value?.getQuit()
  if (!result) {
    return false
  }
  return result
}

const quitRequest = async () => {
  validation.value.quitReasonType = (quitParam.value.quit_reason_type === "");
  validation.value.quitMainReasonType = (quitParam.value.quit_main_reason_type.length <= 0)
  if(validation.value.quitReasonType || validation.value.quitMainReasonType){
    return false
  }

  if (!window.confirm(quitInfo.value.quit_message)) return;

  const result = await methods.value?.quitRequest(quitParam.value)
  if (!result) {
    return false
  }
  setTimeout(_transition, 1000)
  return true
}

const _transition = () => {
  window.location.href = "/mypage/service-account/quit/finish"
}


onMounted(async () => {
  const result = await getQuit();
  if(result) {
    quitInfo.value = result
    initialized.value = true
  }
})

</script>
<style scoped>
.form-control, .is-focused .form-control {
  background-image: none;
}
</style>

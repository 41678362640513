<template>
  <div v-if="!initialized">
    <Alert ref="alert"/>
    <div class="loader">Loading...</div>
  </div>
  <div v-else>
    <Alert ref="alert"/>
    <SupportMethod ref="supportMethod"/>
    <div class="card">
      <div class="card-body">
        <div class="flex-container-center">
          <div class="default-width">
            <div class="flex-container">
              <div class="text-left">
              </div>
              <div class="text-right">
                <a class="btn default" href="/mypage/settings/aws_accounts">一覧</a>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-container-center">
          <table class="table table-borderless mt-3 table-width" v-if="initialized">
            <tbody>
            <tr>
              <th class="align-top">Step 1</th>
              <td>
                <div>AWSマネジメントコンソールから対象のAWSアカウントにサインインして下さい。</div>
              </td>
            </tr>
            <tr>
              <th class="align-top">Step 2</th>
              <td>
                <p>設定値を入力します。</p>
                <table class="table table-borderless input-table">
                  <tbody>
                  <tr>
                    <th>AWSアカウントID</th>
                    <td>
                      <div>
                        <div class="form-input-read-only">
                          <input class="form-control" type="text" maxlength="12" v-model="form.aws_account_id" v-on:input="resetCheckStatus()" required>
                        </div>
                        <div class="inline-block">
                          <span class="btn btn-primary ml-3" v-on:click="checkRegistrableAwsAccount()">{{ checkStatus.display }}</span>
                        </div>
                      </div>
                      <p id="notice-left">
                        ※AWSアカウントIDを入力したらCheckを実行するようにしてください。
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>別名</th>
                    <td>
                      <input class="form-control w-50" type="text" maxlength="64" v-model="form.alias_name" required>
                    </td>
                  </tr>
                  <tr>
                    <th>Organizationsへの統合の有無</th>
                    <td>
                      <input type="checkbox" v-model="form.linked" v-on:change="resetPayerAccountId()">　
                    </td>
                  </tr>
                  <tr v-if="form.linked">
                    <th>管理アカウント</th>
                    <td>
                      <select class="form-control w-50" v-model="form.payer_account_id">
                        <option :value="null">選択してください</option>
                        <template v-for="item in payerAwsAccounts">
                          <option :value="item.aws_account_id">{{ item.aws_account_id }}</option>
                        </template>
                      </select>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <p id="notice">
                  ※入力したAWSアカウントIDがメンバーアカウントである場合にチェックを入れて管理アカウントを選択してください。
                </p>
                <div class="pl-3" v-if="form.linked">
                  <div class="btn btn-primary btn-width"  v-on:click="creatorLinkedAwsAccount">
                    追加
                  </div>
                </div>
              </td>
            </tr>
            <template v-if="!form.linked">
              <tr>
                <th class="align-top">Step 3</th>
                <td>
                  <p>CostUsageReport(CUR)の出力を設定します。</p>
                  <p>
                    以下の手順を実行して、CURの出力を設定してください。
                  </p>
                  <div>
                    <div class="mt-3">
                      <p>AWSマネジメントコンソールのCloudFormation（東京リージョン）の画面が表示されます。</p>
                      <div class="pl-3">
                        <div class="btn btn-default btn-width" v-on:click="settingS3">
                          S3バケットの作成
                        </div>
                      </div>
                    </div>
                    <div class="mt-3">
                      <p>AWSマネジメントコンソールのCloudFormation（バージニア北部リージョン）の画面が表示されます。</p>
                      <div class="pl-3">
                        <div class="btn btn-default btn-width" v-on:click="settingCur">
                          CURの出力を設定
                        </div>
                      </div>
                    </div>
                    <div class="mt-3">
                      <p>詳細な手順は以下のFAQを参照してください。</p>
                      <p class="pl-3">
                        <a href=# @click.prevent="zendeskButtonClick(constants.pagePath.payerAccount.index)">CURの設定</a>
                      </p>
                      <p>過去に同じAWSアカウントIDを追加していれば、このStepが不要な場合があります。</p>
                      <p class="pl-3">
                        <a href=# @click.prevent="zendeskButtonClick(constants.pagePath.payerAccount.re_registration)">管理アカウントの再登録</a>
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="align-top">Step 4</th>
                <td>
                  <p>CostUsageReport(CUR)の出力を検証します。</p>
                  <p>CURの出力の設定が完了したら、以下のボタンを押下して動作確認をお願いします。</p>
                  <div class="pl-3">
                    <div class="btn btn-primary btn-width"  v-on:click="creatorPayerAwsAccount">
                      設定確認
                    </div>
                  </div>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import Alert from "../components/alert_new.vue";
import SupportMethod from "../components/support_method.vue";
import {createAwsAccount, getPayerAwsAccounts, registrableAwsAccount, validatePayerAwsAccount} from "../lib/axios/api_client";
import * as constants from '../utils/constants'

const alert = ref(null)
const supportMethod = ref(null);

const initialized = ref(false)
const form = ref({})
form.value = {
  aws_account_id: "",
  alias_name: "",
  linked: false,
  payer_account_id: null
}
const payerAwsAccounts = ref([])
const checkStatus = ref({})
checkStatus.value = {
  display: "Check",
  check: "Check",
  checked: "Checked!"
}
const textCopy = ref({})
textCopy.value = {
  display: "Copy",
  copy: "Copy",
  copied: "Copied!"
}

const cloudFormationJpPath = () => {
  return constants.pagePath.cloudFormationJp[process.env.ENV_TYPE]
}

const cloudFormationUsPath = () => {
  return constants.pagePath.cloudFormationUs[process.env.ENV_TYPE]
}

const settingS3 = () => {
  window.open(cloudFormationJpPath())
}

const settingCur = () => {
  window.open(cloudFormationUsPath())
}

const zendeskButtonClick = async (returnTo) => {
  const zendeskUrl = constants.pagePath.zendeskUrl[process.env.ENV_TYPE]
  const param = { return_to : zendeskUrl + returnTo }
  const result = await supportMethod.value?.getZendeskSupportUrl(param);
  window.open(result.url, "_blank");
}

const checkAwsAccountId = () => {
  if (!form.value.aws_account_id) {
    alert.value.displayModal("AWSアカウントIDを入力してください。", 'error')
    return false
  }
  if (!isNumber(form.value.aws_account_id)) {
    alert.value.displayModal("AWSアカウントIDの入力形式が正しくありません。", 'error')
    return false
  }
  return true
}

const checkRegistrableAwsAccount = async () => {
  if (!checkAwsAccountId()) {
    return false
  }
  const res = await registrableAwsAccount(form.value.aws_account_id)
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }

  checkStatus.value.display = checkStatus.value.checked
  return true
}

const resetCheckStatus = () => {
  checkStatus.value.display = checkStatus.value.check
}

// ローカル(http)では操作しない
const writeClipBoard = async (text) => {
  await navigator.clipboard.writeText(text)
  textCopy.value.display = textCopy.value.copied
}

const resetPayerAccountId = () => {
  if (!form.value.linked) {
    form.value.payer_account_id = null
  }
}

const isNumber = (n) => {
  var regexp = new RegExp(/^([0-9]\d*|0)$/);
  if ( regexp.test(n) && Number.isInteger(parseInt(n)) ) {
    return true
  }
  return false
}

const validateForm = () => {
  if (!checkAwsAccountId()) {
    return false
  }
  if (form.value.linked && !form.value.payer_account_id) {
    alert.value.displayModal("管理アカウントが選択されていません。", 'error')
    return false
  }
  if (form.value.payer_account_id === form.value.aws_account_id) {
    alert.value.displayModal("管理アカウントが不正です。", 'error')
    return false
  }

  return true
}

const creatorLinkedAwsAccount = async () => {
  if (!validateForm()) return false
  const res = await createAwsAccount(form.value)
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }
  alert.value.displayModal("作成しました。", 'success')
  setTimeout(_transition, 1000)
}

const creatorPayerAwsAccount = async () => {
  if (!validateForm()) return false
  const validateRes = await validatePayerAwsAccount(form.value.aws_account_id)
  if(!validateRes.successful){
    alert.value.displayModal(validateRes.message, 'error')
    return false
  }

  if(validateRes.data.is_success){
    const res = await createAwsAccount(form.value)
    if(!res.successful){
      alert.value.displayModal(res.message, 'error')
      return false
    }
    alert.value.displayModal("作成しました。", 'success')
    setTimeout(_transition, 1000)
  } else {
    alert.value.displayModal("CURの出力の設定が完了していません", 'error')
  }
}

const _transition = () => {
  window.location.href = `/mypage/settings/aws_accounts/role/${form.value.aws_account_id}/edit`
}

const getterPayerAwsAccounts = async () => {
  const res = await getPayerAwsAccounts()
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }

  payerAwsAccounts.value = res.data.aws_accounts
  return true
}

onMounted( async () => {
  await getterPayerAwsAccounts()

  initialized.value = true
})

</script>
<style scoped>
.flex-container{
  display:flex;
  justify-content: space-between;
}
.flex-container-center{
  display:flex;
  justify-content:center;
}
.btn-copy {
  min-width: 110px;
}
.inline-block {
  display: inline-block;
}
.form-input-read-only{
  display: inline-block;
  min-width: 290px;
  max-width: 290px;
}
.default-width {
  width: 1200px;
}
.table-width {
  max-width: 1200px;
}
.btn-width {
  width: 150px;
}
.input-table {
  width: 100%;
  background-color: #fff;
}
.input-table th {
  white-space: nowrap;
  width: 100px;
}
#notice-left {
  position: relative;
  right: 202px;
}
#notice {
  position: relative;
  bottom:26px;
  left: 8px;
}
</style>

<template>
  <div class="click-outside-box" @click.stop>
    <slot />
  </div>
</template>
<script>
export default {
  methods: {
    onClickOutside(e) {
      this.$emit("click-outside");
    },
  },
  beforeMount() {
    document.addEventListener("click", this.onClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.onClickOutside);
  },
}
</script>
<style>
.click-outside-box {
  display: inline-block;
}
</style>

<template>
  <Alert ref="alert"/>
</template>
<script setup>
import { ref } from "vue";
import Alert from "./alert_new.vue";
import { ApiClient } from "../lib/axios/recommend/simulator/api_client";
import { downloadPdf } from "../lib/download_action";
import {　formatDate　} from "../lib/calendar";
import { analysisTerms } from "../utils/simulator";

const client = new ApiClient()
const alert = ref(null)

const getScenarioList = async () => {
  const res = await client.getScenarioList();
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data
}

const getScenario = async function(id) {
  const res = await client.getScenario(id);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }

  return res.data
}

const createScenario =  async function(form) {
  const res = await client.createScenario(form)
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error')
    return false
  }
  alert.value.displayModal('成功しました。','success');
  return res.data
}

const updateScenario = async function(id, form){
  const res = await client.updateScenario(id, form)
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }
  alert.value.displayModal("成功しました", 'success')
  return true
}

const deleteScenario = async function(id){
  const res = await client.deleteScenario(id);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }

  alert.value.displayModal("削除しました。", 'success')
  return true
}

const getScenarioExpireRiSp = async (id) => {
  const res = await client.getScenarioExpireRiSp(id);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data
}

const getExpireRiSp = async function() {
  const res = await client.getExpireRiSp();
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data
}

const getScenarioDashboard = async (id) => {
  const res = await client.getScenarioDashboard(id);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data
}

const getScenarioDashboardResult = async (id, riskLevel) => {
  const res = await client.getScenarioDashboardResult(id, riskLevel);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data
}

const getScenarioDashboardBasisData = async (param) => {
  const res = await client.getScenarioDashboardBasisData(param);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data
}

const getDownloadDetail = async (id, awsAccountId) => {
  const res = await client.getDownloadDetail(id, awsAccountId);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data.scenario_download
}

const downloadScenarioPdf = async function(id, awsAccountId) {
  const res = await client.downloadPdf(id, awsAccountId)
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  downloadPdf(res.data, !res.filename ? "budget-expire" : res.filename)
}

const minDate = () => {
  const today = new Date()
  const nextDay = today.setDate(today.getDate() + 1)
  return formatDate(nextDay)
}

const maxDate = (expire_ri_sp) => {
  // 現在から2ヶ月後
  let today_two_months_ago = new Date()
  today_two_months_ago.setMonth(today_two_months_ago.getMonth() + 2)
  // ExpireRiSpの最も早いend_date-2日
  let endDates = expire_ri_sp.map( ri_sp => new Date(ri_sp.end_date))
  let earliestEndDate = new Date(Math.min.apply(null,endDates))
  earliestEndDate.setDate(earliestEndDate.getDate() - 2)
  return earliestEndDate <= today_two_months_ago ? formatDate(earliestEndDate) : formatDate(today_two_months_ago)
}

const calcAnalysisTermStartToEnd = (simulator_scheduled_date) => {
  if (simulator_scheduled_date) {
    const scheduled_date = simulator_scheduled_date
    let end = new Date(scheduled_date)
    end.setDate(end.getDate() - 2)
    const startToEnd = analysisTerms.map( term => {
      let start = new Date(scheduled_date)
      start.setDate(start.getDate() - (parseInt(term.value) + 2 -1))
      return {
        term : term.value,
        start: formatDate(start),
        end: formatDate(end)
      }
    })
    return startToEnd
  }
}

defineExpose({
  getScenarioList,
  getScenario,
  createScenario,
  updateScenario,
  deleteScenario,
  getScenarioExpireRiSp,
  getExpireRiSp,
  getScenarioDashboard,
  minDate,
  maxDate,
  calcAnalysisTermStartToEnd,
  getScenarioDashboardResult,
  getScenarioDashboardBasisData,
  getDownloadDetail,
  downloadScenarioPdf
})
</script>

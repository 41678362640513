<template>
    <span v-if="is_show">
        <template v-if="edited">
            <button type="button" class="btn btn-primary btn-sm text-white" v-on:click="updateReport">保存</button>
        </template>
        <template v-else>
            <button type="button" class="btn btn-primary btn-sm text-white disabled">保存済み</button>
        </template>
    </span>
</template>
<script>
export default {
  props: ['report', 'edited'],
  computed: {
    is_show() {
      if(this.isEmptyString()){ return false }
      return !this.report.report_lock
    }
  },
  methods: {
    isEmptyString() {
      // if(this.report === undefined){ return true }
      return (typeof this.report.name === "string" && this.report.name.length === 0)
    },
    updateReport: async function() {
      this.$emit('childMethod')
    }
  }

}
</script>
<template>
  <BudgetMethods ref="methods"/>
  <ReportMethods ref="reportMethods"/>
  <Alert ref="alert"/>
  <div v-show="!initialized">
    <div class="loader">Loading...</div>
  </div>
  <div v-show="initialized">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="pull-left">
          <template v-if="budget.budget_lock">
            <span class="btn btn-primary disabled">編集</span>
            <span class="btn btn-primary" v-on:click="unlockBudget">ロック解除</span>
            <span class="btn btn-primary disabled">削除</span>
          </template>
          <template v-else>
            <a class="btn btn-primary" :href="`/mypage/budget/${id}/edit`">編集</a>
            <span class="btn btn-primary" v-on:click="lockBudget">ロック</span>
            <span class="btn btn-primary" v-on:click="deleteBudget">削除</span>
          </template>
        </div>
        <div class="pull-right">
          <span>表示通貨切替：</span>
          <template v-if="budget.currency === 'usd'">
            <DropDownButton
              dropdownId="currencyTaxItemButton"
              :dropdownItem="budget.permissions.show_apply_tax ? usdCurrencyTaxItem : extUsdCurrencyTaxItem"
              :disabled="!budgetDetailShow"
              v-on:updateReport="changeCurrency"
              v-model:item="selectCurrencyTax"
            />
          </template>
          <template v-else>
            <DropDownButton
              dropdownId="currencyTaxItemButton"
              :dropdownItem="budget.permissions.show_apply_tax ? jpyCurrencyTaxItem : extJpyCurrencyTaxItem"
              :disabled="!budgetDetailShow"
              v-on:updateReport="changeCurrency"
              v-model:item="selectCurrencyTax"
            />
          </template>
          <span><a class="btn default" :href="path">一覧</a></span>
        </div>
      </div>
    </div>
    <template v-if="budget.before_end_date">
    <div class="card">
      <div class="card-header">
        <div class="card-title">予算の状態{{ budgetStatusCurrentMonth }}</div>
      </div>
      <div class="card-body form-border">
        <div class="row">
          <template v-if="budgetDetailShow">
            <div class="col-lg-6 col-md-6 col-sm-12 flex-border">
              <div>予算と実績</div>
              <div>
                <BudgetProgress :ratio.number="budgetStatus.current_month_actual_budget_ratio"/>
              </div>
              <div>
                <small>支出額 {{currency.unit}}{{budgetStatus.current_month_budget_amount.toFixed(2)}}のうち {{currency.unit}}{{budgetStatus.current_month_actual_amount.toFixed(2)}}</small>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div>予算と予測</div>
              <div>
                <BudgetProgress :ratio.number="budgetStatus.current_month_estimate_budget_ratio"/>
              </div>
              <div>
                <small>支出額 {{currency.unit}}{{budgetStatus.current_month_budget_amount.toFixed(2)}}のうち {{currency.unit}}{{budgetStatus.current_month_estimate_amount.toFixed(2)}}</small>
              </div>
            </div>
          </template>
          <template v-else>
            <BudgetNoData :processDate.value="nextProcessDate"/>
          </template>
        </div>
      </div>
    </div>
    </template>
    <template v-if="!budget.expired">
    <div class="card">
      <div class="card-header">
        <div class="card-title">予算の状態(期間全体)</div>
      </div>
      <div class="card-body form-border">
        <div class="row">
          <template v-if="budgetDetailShow">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div>予算総計と実績累計</div>
              <div>
                <BudgetProgress :ratio.number="budgetStatus.actual_budget_ratio"/>
              </div>
              <div>
                <small>支出額 {{currency.unit}}{{budgetStatus.budget_amount_sum.toFixed(2)}}のうち {{currency.unit}}{{budgetStatus.actual_amount_sum.toFixed(2)}}</small>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div>予算総計と期間予測累計</div>
              <div>
                <BudgetProgress :ratio.number="budgetStatus.estimate_budget_ratio"/>
              </div>
              <div>
                <small>支出額 {{currency.unit}}{{budgetStatus.budget_amount_sum.toFixed(2)}}のうち {{currency.unit}}{{budgetStatus.estimate_amount_sum.toFixed(2)}}</small>
              </div>
            </div>
          </template>
          <template v-else>
            <BudgetNoData :processDate.value="nextProcessDate"/>
          </template>
        </div>
      </div>
    </div>
    <div class="card col-lg-6 col-md-6 col-sm-12 no-padding">
      <div class="card-header">
        <div class="card-title">アラート</div>
      </div>
      <div class="card-body">
        <table id="cost_alert-list" class="table table-borderless">
          <thead>
          <tr>
            <th>閾値</th>
            <th>トリガー</th>
            <th>超過状況</th>
            <th>設定</th>
          </tr>
          </thead>
          <tbody>
          <template v-if="alerts.length > 0">
            <tr v-for="alert in alerts">
              <td>
                <span>予算金額の{{ alert.borderline }}%</span>
              </td>
              <td>
                <template v-if="alert.alert_trigger==='actual'">実績</template>
                <template v-else>予測</template>
              </td>
              <td>
                <template v-if="alert.alert_status === 'normal'">
                  <i class="material-icons color-green align-middle">check_circle</i>
                </template>
                <template v-else-if="alert.alert_status === 'warning'">
                  <i class="material-icons color-red align-middle">warning</i>
                </template>
                <template v-else>
                  <span>-</span>
                </template>
              </td>
              <td>
                <a :href="`/mypage/alert/setting/budget/${alert.alert_id}/edit`" class="text-white">
                  <i class="material-icons color-blue cursor-pointer">article</i>
                </a>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="9">
                設定はありません
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <template v-if="alerts.length < 5">
          <div class="text-center">
            <a :href="`/mypage/alert/setting/budget/new?budget_id=${id}`">
              アラート設定を追加する
            </a>
          </div>
        </template>
      </div>
    </div>
    </template>
    <div class="card">
      <div class="card-header">
        <div class="card-title">詳細</div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-12 flex-border">
            <div>
              <div>予算名</div>
              <div class="ml-4">{{ budget.budget_name }}</div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 flex-border">
            <div>
              <div>期間(開始)</div>
              <div class="ml-4">{{ budget.start_date }}</div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div>
              <div>期間(終了)</div>
              <div class="ml-4">{{ budget.end_date }}</div>
            </div>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-lg-4 col-md-4 col-sm-12 flex-border">
            <div>
              <div>通貨</div>
              <div class="ml-4">{{ budget.currency === 'usd' ? "$" : "円"}}</div>
            </div>
          </div>
          <div v-if="budget.currency === 'jpy'" class="col-lg-4 col-md-4 col-sm-12 flex-border">
            <div>
              <div>予算レート</div>
              <div class="ml-4">{{ budget.exchange_rate }}</div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 flex-border">
            <div>
              <div>設定方法</div>
              <div class="ml-4">{{ searchOptionName(budget.setting_type) }}</div>
            </div>
          </div>
          <div v-if="budget.currency === 'usd'" class="col-lg-4 col-md-4 col-sm-12"/>
        </div>
        <div class="row pt-3">
          <div class="col-lg-4 col-md-4 col-sm-12 flex-border">
            <div>
              <div>連結アカウント</div>
              <div class="ml-4">
                <span v-if="budget.filter.linked_account.values.length">
                  {{ budget.filter.linked_account.values.join(", ") }}
                </span>
                <span v-else>
                  全て
                </span>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 flex-border">
            <div>
              <div>料金タイプ</div>
              <div class="ml-4">
                <span v-if="budget.filter.charge_type.values.length">
                  {{ budget.filter.charge_type.values.join(", ") }}
                </span>
                <span v-else>
                  全て
                </span>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div v-if="budget.setting_type === 'pricing_calculator' || budget.setting_type === 'actual_estimate'">
              <div>予算作成時の根拠</div>
              <div class="ml-4">
                <span class="btn btn-primary btn-sm cursor-pointer" v-on:click="downloadBudgetDetail">DL</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="card-title">予算履歴</div>
        <template v-if="budgetDetailShow">
        <DropDownButton
          dropdownId="estimateItemButton"
          :dropdownItem="estimateItem"
          v-on:updateReport="updateDashboard"
          v-model:item="selectEstimate"
        />
        </template>
      </div>
      <div class="card-body">
        <template v-if="budgetDetailShow">
          <IframeDashboard ref="dashboard" height="800px"/>
          <div v-if="currency.value === 'jpy'" class="text-secondary">
            円換算に適用するレートは項目ごとに下記のとおりとなります。<br>
            予算：予算用レート<br>
            予測：各月のレート<br>
            実績：各月のレート又は個別レート（SPOTで購入をした場合に適用されたもの）
          </div>
        </template>
        <template v-else-if="budget.expired">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="d-flex justify-content-center mt-5">
              <span class="text-secondary text-center">
                <span class="text-danger font-weight-bold">
                  予算の期間が終了しました。予算履歴を確認するにはPDFをダウンロードしてください。
                </span>
              </span>
            </div>
            <div class="d-flex justify-content-center mb-5">
              <template v-if="budget.currency === 'usd'">
                <DropDownButton
                  dropdownId="currencyTaxItemButton"
                  :dropdownItem="budget.permissions.show_apply_tax ? usdCurrencyTaxItem : extUsdCurrencyTaxItem"
                  v-model:item="selectCurrencyTax"
                />
              </template>
              <template v-else>
                <DropDownButton
                  dropdownId="currencyTaxItemButton"
                  :dropdownItem="budget.permissions.show_apply_tax ? jpyCurrencyTaxItem : extJpyCurrencyTaxItem"
                  v-model:item="selectCurrencyTax"
                />
              </template>
              <span class="btn btn-primary cursor-pointer" v-on:click="downloadExpireBudgetDetail">DL</span>
            </div>
          </div>
        </template>
        <template v-else>
          <BudgetNoData :processDate.value="nextProcessDate"/>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, nextTick } from "vue";
import { format } from "date-fns"
import Alert from "../components/alert_new.vue";
import BudgetProgress from "../components/budget_progress.vue";
import BudgetNoData from "../components/budget_no_data.vue";
import IframeDashboard from "../components/iframe_dashboard.vue";
import { pagePath } from "../utils/constants";
import { extractId } from "../lib/common";
import BudgetMethods from "../components/budget_methods.vue";
import ReportMethods from "../components/report_methods.vue";
import { useAmount } from "../composables/budget_amount";
import * as constants from "../utils/constants";
import DropDownButton from "../components/dropdown_button.vue";

const id = extractId(3)
const path = pagePath.budget.index
const estimateItem = [
  {name: '通常予測',         value: 'normalcost'},
  {name: '推奨事項(RI)を実践時の予測',    value: 'savedcost'}
]
const usdCurrencyTaxItem = [
  {name: '$ (税抜)', value: 'usd-No'},
  {name: '$ (税込)', value: 'usd-Yes'}
]
const jpyCurrencyTaxItem = [
  {name: '$ (税抜)', value: 'usd-No'},
  {name: '$ (税込)', value: 'usd-Yes'},
  {name: '円 (税抜)', value: 'jpy-No'},
  {name: '円 (税込)', value: 'jpy-Yes'}
]

const extUsdCurrencyTaxItem = [
  {name: '$', value: 'usd-No'},
]
const extJpyCurrencyTaxItem = [
  {name: '$', value: 'usd-No'},
  {name: '円', value: 'jpy-No'},
]

const alert = ref(null)
const methods = ref(null)
const reportMethods = ref(null)
const dashboard = ref(null)
const selectEstimate = ref("normalcost")
const selectCurrencyTax = ref("usd-No")

const { searchOptionName } = useAmount()

let nextProcessDate = ref("2023年12月31日")
let budgetDetailShow = ref(false)
let initialized = ref(false)
let currency = ref({ value: "usd", unit: "$" })
let lock = ref(false)
let budget = ref({
    "budget_name": "XXX",
    "start_date": "2023-04",
    "end_date": "2024-03",
    "currency": "usd",
    "exchange_rate": 200,
    "setting_type": "fixed",
    "filter": {
      linked_account:{
        condition: "include",
        values: ["655146499999", "53433875999"]
      },
      charge_type: {
        condition: "include",
        values: ["Usage", "DiscountedUsage", "Fee"]
      },
      budget_id: {
        condition: "include",
        values: []
      }
    },
    "budget_lock": false,
    "before_end_date": false,
    "expired": false,
    "budget_detail_show": false,
    "next_process_date": "2024-03-01 13:30:03",
    permissions: {}
  })
let budgetStatus= ref( {
    current_month: "2023-12",
    current_month_budget_amount: 100,
    current_month_actual_amount: 80.12,
    current_month_estimate_amount: 90.23,
    current_month_actual_budget_ratio: 80.11,
    current_month_estimate_budget_ratio: 90.11,
    budget_amount_sum: 600,
    actual_amount_sum: 480.11,
    estimate_amount_sum: 601.12,
    actual_budget_ratio: 40.11,
    estimate_budget_ratio: 80.11
})
let alerts = ref([
  {
    "alert_id": 1,
    "borderline": "100",
    "alert_trigger": "actual",
    "alert_status": "normal"
  },
  {
    "alert_id": 2,
    "borderline": "200",
    "alert_trigger": "estimate",
    "alert_status": "warning"
  }
])
let aws_report = ref({})

const budgetStatusCurrentMonth = computed( () => {
  const current_month = budgetStatus.value.current_month
  if(current_month === null){ return "" }
  const yearMonth = current_month.split("-")
  return ' (' + yearMonth[0] + '年' + yearMonth[1] + '月)'
})

const currencyJudgeValue = computed( () => {
  return selectCurrencyTax.value.split('-')[0] === "usd" ? "usd" : "jpy"
})

const taxJudgeValue = computed( () => {
  return selectCurrencyTax.value.split('-')[1] === "Yes" ? "Yes" : "No"
})

const getBudget = async function(id, showAlert) {
  const result = await methods.value.getBudget(id, showAlert)
  if(!result){ return false }
  budget.value = result
}

const getBudgetDetail = async function(id) {
  const result = await methods.value.getBudgetDetail(id, currency.value.value, taxJudgeValue.value.toUpperCase())
  if(!result){ return false }
  budgetStatus.value = result
}

const checkBudgetHistory = async function() {
  const result = await methods.value.getDashboardElementBudgetHistory(aws_report.value)
  if(!result){ return null }
  return result.length !== 0
}

const getBudgetDetailAlert = async function(id) {
  const result = await methods.value.getBudgetDetailAlert(id);
  if(!result){ return false }
  alerts.value = result
}

const downloadBudgetDetail =  async function() {
  await methods.value.downloadBudgetDetail(id)
}

const downloadExpireBudgetDetail = async function() {
  await methods.value.downloadExpireBudgetDetail(id, currencyJudgeValue.value, taxJudgeValue.value.toUpperCase())
}

const lockBudget = async function() {
  const result = await methods.value.lockBudget(id);
  if(!result){ return false }
  await getBudget(id, true)
}

const unlockBudget = async function() {
  const result = await methods.value.unlockBudget(id);
  if(!result){ return false }
  await getBudget(id, true)
}

const deleteBudget = async function(){
  if (!window.confirm("本当に削除しますか？（関連するsubscriptionとアラートも削除されます）")) return;
  const result = await methods.value.deleteBudget(id, true);
  if(!result){ return false }
  setTimeout(transition,1000);
}

const getBudgetDetailShow = async function(detailShow) {
  budgetDetailShow.value = detailShow? await checkBudgetHistory() : false
  return budgetDetailShow.value
}

const updateDashboard = async () => {
  aws_report.value.filter.forecasted_type.values = [ selectEstimate.value ]
  aws_report.value.filter.apply_tax.values = [ taxJudgeValue.value ]
  await dashboard.value.generateDashboard(aws_report.value)
}

const changeCurrency = async () => {
  if(currencyJudgeValue.value === "usd"){
    currency.value = { value: "usd", unit: "$" }
  }else{
    currency.value = { value: "jpy", unit: "¥" }
  }

  await getBudgetDetail(id)
  await getReport()
  await dashboard.value.generateDashboard(aws_report.value)
}

const getReport = async () => {
  const reportType = currency.value.value === "usd" ? constants.budgetHistory : constants.budgetHistoryJpy
  const result = await reportMethods.value.getReport(0, reportType)
  if(!result){ return false }
  result.filter.budget_id.values.push(id)
  result.filter.apply_tax.values = [ taxJudgeValue.value ]
  aws_report.value = result
}

const transition = () => {
  window.location.href = path
}

onMounted(async () => {
  await getBudget(id, false)
  await getBudgetDetail(id)
  if(!budget.value.expired){
    await getBudgetDetailAlert(id)
  }
  await getReport()

  nextProcessDate.value = format(new Date(budget.value.next_process_date), "yyyy年MM月dd日")
  const budgetDetailShow = await getBudgetDetailShow(budget.value.budget_detail_show)
  if (budgetDetailShow !== null){
    initialized.value = true

    if (budgetDetailShow){
      nextTick(async () => {
        await dashboard.value.generateDashboard(aws_report.value)
      })
    }
  }
})

</script>
<style scoped>
.flex-border {
  border-right: solid 1px #d2d2d2
}
.no-padding {
  padding: inherit;
}
.color-blue {
  color: #2196f3;
}
.color-red {
  color: #ff0000;
}
.color-green {
  color: #008000;
}
</style>

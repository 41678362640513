import { ref } from "vue";
export function useAmount() {
    const FIXED = "fixed";
    const PROPORTIONAL_DIVISION = "proportional_division";
    const GROWTH_RATE = "growth_rate";
    const EXISTING_BUDGET = "existing_budget";
    const PRICING_CALCULATOR = "pricing_calculator";
    const ACTUAL_ESTIMATE = "actual_estimate";
    const options = [
        { name: "手動で入力する", value: FIXED },
        { name: "総額を按分する", value: PROPORTIONAL_DIVISION },
        { name: "月額x予算成長率から設定する", value: GROWTH_RATE },
        { name: "既存予算を使う", value: EXISTING_BUDGET },
        { name: "AWS Pricing Calculatorを使う", value: PRICING_CALCULATOR },
        { name: "過去の実績から予測する", value: ACTUAL_ESTIMATE },
    ];
    const searchOptionName = (settingType) => {
        const result = options.find((item) => item.value === settingType);
        return result ? result.name : "";
    };
    let amount = ref({
        setting_type: FIXED,
        proportional_division: {
            budget_amount: 0
        },
        growth_rate: {
            initial_budget_amount: 0,
            growth_rate: 0,
        },
        existing_budget: {
            monthly_budgets: {},
        },
        pricing_calculator: {
            upfront_cost: 0,
            monthly_cost: 0
        },
        actual_estimate: {
            estimate_budget: {},
        }
    });
    let estimateParam = ref({
        cost_data_period: 1,
        buffer: 0,
        costs: {},
        budgets: {}
    });
    const setAmount = (newAmount) => {
        amount.value = newAmount;
    };
    const setEstimateParam = (value) => {
        estimateParam.value = value;
    };
    const setSettingType = (value) => {
        amount.value.setting_type = value;
    };
    const setPastBudget = (monthlyBudgets) => {
        amount.value.existing_budget.monthly_budgets = monthlyBudgets;
    };
    const setUpfrontCost = (value) => {
        amount.value.pricing_calculator.upfront_cost = value;
    };
    const setMonthlyCost = (value) => {
        amount.value.pricing_calculator.monthly_cost = value;
    };
    const getSettingType = () => {
        return amount.value.setting_type;
    };
    const getCostDataPeriod = () => {
        return estimateParam.value.cost_data_period;
    };
    const getEstimateParam = () => {
        return estimateParam.value;
    };
    const getGrowthRate = () => {
        switch (amount.value.setting_type) {
            case GROWTH_RATE:
                return amount.value.growth_rate.growth_rate;
            default:
                return 0;
        }
    };
    const calcEstimateBudget = (cost, budget) => {
        let categories = [];
        let seriesData = [];
        let estimateBudget = {};
        let tmpCosts = {};
        for (const key in cost) {
            categories.push(key);
            let value = convertTwoDecimal(cost[key]);
            tmpCosts[key] = value;
            seriesData.push(value);
        }
        estimateParam.value.costs = tmpCosts;
        const index = categories.length;
        let tmpBudgets = {};
        for (const key in budget) {
            categories.push(key);
            let value = convertTwoDecimal(budget[key] * ((100 + estimateParam.value.buffer) / 100));
            tmpBudgets[key] = value;
            seriesData.push(value);
            estimateBudget[key] = value;
        }
        estimateParam.value.budgets = tmpBudgets;
        amount.value.actual_estimate.estimate_budget = estimateBudget;
        return { categories: categories, seriesData: seriesData, index: index };
    };
    const calcMonthlyBudgets = (monthlyBudgets) => {
        switch (amount.value.setting_type) {
            case FIXED:
                // 何もしない
                break;
            case PROPORTIONAL_DIVISION:
                // 総額を月数で割る
                const budgetAmount = amount.value.proportional_division.budget_amount;
                const result = convertTwoDecimal(budgetAmount / Object.keys(monthlyBudgets).length);
                for (let key in monthlyBudgets) {
                    monthlyBudgets[key] = result;
                }
                break;
            case GROWTH_RATE:
                // 月額x予算成長率を計算して入れる
                monthlyBudgets = calcBudgetBasedOnGrowthRate(monthlyBudgets, amount.value.growth_rate.initial_budget_amount, amount.value.growth_rate.growth_rate);
                break;
            case EXISTING_BUDGET:
                // 既存予算からそのまま入れる
                monthlyBudgets = copyExistingBudget(monthlyBudgets, amount.value.existing_budget.monthly_budgets);
                break;
            case PRICING_CALCULATOR:
                // calculatorから読み取る
                const upfrontCost = amount.value.pricing_calculator.upfront_cost;
                const monthlyCost = amount.value.pricing_calculator.monthly_cost;
                const upfrontDivided = convertTwoDecimal(upfrontCost / Object.keys(monthlyBudgets).length);
                for (let key in monthlyBudgets) {
                    monthlyBudgets[key] = monthlyCost + upfrontDivided;
                }
                break;
            case ACTUAL_ESTIMATE:
                // 予想結果をそのまま入れる
                monthlyBudgets = amount.value.actual_estimate.estimate_budget;
                break;
        }
        return monthlyBudgets;
    };
    const convertTwoDecimal = (target) => {
        return Math.round(target * 100) / 100;
    };
    const calcBudgetBasedOnGrowthRate = (monthlyBudgets, budgetAmount, growthRate) => {
        let index = 1;
        for (const key in monthlyBudgets) {
            monthlyBudgets[key] = index === 1 ? budgetAmount : convertTwoDecimal(budgetAmount + budgetAmount * (growthRate / 100));
            budgetAmount = monthlyBudgets[key];
            index++;
        }
        return monthlyBudgets;
    };
    const copyExistingBudget = (monthlyBudgets, existingBudgetMonthlyBudgets) => {
        const existingAmount = Object.values(existingBudgetMonthlyBudgets);
        let index = 0;
        for (const key in monthlyBudgets) {
            monthlyBudgets[key] = existingAmount[index];
            index++;
        }
        return monthlyBudgets;
    };
    const checkCostDataPeriod = () => {
        return estimateParam.value.cost_data_period < 1 || 12 < estimateParam.value.cost_data_period;
    };
    const checkBuffer = () => {
        return estimateParam.value.buffer < 0 || 100 < estimateParam.value.buffer;
    };
    const isFixed = () => {
        return amount.value.setting_type === FIXED;
    };
    const isProportionalDivision = () => {
        return amount.value.setting_type === PROPORTIONAL_DIVISION;
    };
    const isGrowthRate = () => {
        return amount.value.setting_type === GROWTH_RATE;
    };
    const isExistingBudget = () => {
        return amount.value.setting_type === EXISTING_BUDGET;
    };
    const isPricingCalculator = () => {
        return amount.value.setting_type === PRICING_CALCULATOR;
    };
    const isActualEstimate = () => {
        return amount.value.setting_type === ACTUAL_ESTIMATE;
    };
    return {
        amount,
        options,
        estimateParam,
        setAmount,
        setSettingType,
        setEstimateParam,
        setPastBudget,
        setUpfrontCost,
        setMonthlyCost,
        getSettingType,
        getEstimateParam,
        getCostDataPeriod,
        getGrowthRate,
        calcMonthlyBudgets,
        calcEstimateBudget,
        checkCostDataPeriod,
        checkBuffer,
        isFixed,
        isProportionalDivision,
        isGrowthRate,
        isExistingBudget,
        isPricingCalculator,
        isActualEstimate,
        searchOptionName
    };
}

import { TsBaseClient, } from "../ts_base_client";
export class ApiClient extends TsBaseClient {
    constructor() {
        super();
    }
    async requestZendeskSupport(params) {
        const url = `/support/login/jwt`;
        return await this.get(url, params);
    }
}

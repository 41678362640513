export const costExplorer = [
  { key: "linked_account", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウント"},
  { key: "account_name", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウントの別名"},
  { key: "service", type: "multi", report_type: "cost_explorer", condition: true, title: "サービス" },
  { key: "region", type: "multi", report_type: "cost_explorer", condition: true, title: "リージョン" },
  { key: "instance_type", type: "multi", report_type: "cost_explorer", condition: true, title: "インスタンスタイプ" },
  { key: "usage_type", type: "multi", report_type: "cost_explorer", condition: true, title: "使用タイプ" },
  { key: "charge_type", type: "multi", report_type: "cost_explorer", condition: true, title: "料金タイプ" },
  { key: "api_operation", type: "multi", report_type: "cost_explorer", condition: true, title: "APIオペレーション"},
  { key: "availability_zone", type: "multi", report_type: "cost_explorer", condition: true, title: "アベイラビリティゾーン" },
  { key: "platform", type: "multi", report_type: "cost_explorer", condition: true, title: "プラットフォーム"},
  { key: "purchase_option", type: "multi", report_type: "cost_explorer", condition: true, title: "購入オプション" },
  { key: "tenancy", type: "multi", report_type: "cost_explorer", condition: true, title: "テナンシー"},
  { key: "billing_entity", type: "multi", report_type: "cost_explorer", condition: true, title: "請求エンティティ" },
  { key: "cost_type", type: "multi", report_type: "cost_explorer", condition: false, title: "前払いRI・SPを償却" },
  { key: "chorus_cost_tag_1", tag_type: "illuminatorCost_Tag1", type: "multi", report_type: "cost_explorer", condition: true, title: "ChorusCost_Tag1", extTitle: "illuminatorCost_Tag1" },
  { key: "chorus_cost_tag_2", tag_type: "illuminatorCost_Tag2", type: "multi", report_type: "cost_explorer", condition: true, title: "ChorusCost_Tag2", extTitle: "illuminatorCost_Tag2" },
  { key: "chorus_cost_tag_3", tag_type: "illuminatorCost_Tag3", type: "multi", report_type: "cost_explorer", condition: true, title: "ChorusCost_Tag3", extTitle: "illuminatorCost_Tag3" },
  { key: "chorus_cost_tag_4", tag_type: "illuminatorCost_Tag4", type: "multi", report_type: "cost_explorer", condition: true, title: "ChorusCost_Tag4", extTitle: "illuminatorCost_Tag4" },
  { key: "chorus_cost_tag_5", tag_type: "illuminatorCost_Tag5", type: "multi", report_type: "cost_explorer", condition: true, title: "ChorusCost_Tag5", extTitle: "illuminatorCost_Tag5" },
  { key: "no_tag", type: "multi", report_type: "cost_explorer", condition: false, title: "タグ付けされていないリソース"}
]

export const riUtilizationReport = [
    {key: "linked_account", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウント"},
    {key: "account_name", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウントの別名"},
    {key: "service", type: "single", report_type: "cost_explorer", condition: true, title: "サービス"},
    {key: "region", type: "multi", report_type: "cost_explorer", condition: true, title: "リージョン"},
    {key: "instance_type", type: "multi", report_type: "cost_explorer", condition: true, title: "インスタンスタイプ"},
    {key: "platform", type: "multi", report_type: "cost_explorer", condition: true, title: "プラットフォーム"},
    {key: "tenancy", type: "multi", report_type: "cost_explorer", condition: true, title: "テナンシー"},
    {key: "scope", type: "multi", report_type: "ri_utilization", condition: true, title: "スコープ"},
    {key: "size_flexibility", type: "multi", report_type: "ri_utilization", condition: false, title: "サイズの柔軟性"}
]

export const riCoverageReport = [
    {key: "linked_account", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウント"},
    {key: "account_name", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウントの別名"},
    {key: "service", type: "multi", report_type: "cost_explorer", condition: true, title: "サービス"},
    {key: "region", type: "multi", report_type: "cost_explorer", condition: true, title: "リージョン"},
    {key: "instance_type", type: "multi", report_type: "cost_explorer", condition: true, title: "インスタンスタイプ"},
    {key: "instance_family", type: "multi", report_type: "merged_filter", condition: true, title: "インスタンスファミリー"},
    {key: "platform", type: "multi", report_type: "cost_explorer", condition: true, title: "プラットフォーム"},
    {key: "tenancy", type: "multi", report_type: "cost_explorer", condition: true, title: "テナンシー"},
    {key: "chorus_cost_tag_1",  tag_type: "illuminatorCost_Tag1", type: "multi", report_type: "cost_explorer", condition: true, title: "ChorusCost_Tag1", extTitle: "illuminatorCost_Tag1" },
    {key: "chorus_cost_tag_2",  tag_type: "illuminatorCost_Tag2", type: "multi", report_type: "cost_explorer", condition: true, title: "ChorusCost_Tag2", extTitle: "illuminatorCost_Tag2" },
    {key: "chorus_cost_tag_3",  tag_type: "illuminatorCost_Tag3", type: "multi", report_type: "cost_explorer", condition: true, title: "ChorusCost_Tag3", extTitle: "illuminatorCost_Tag3" },
    {key: "chorus_cost_tag_4",  tag_type: "illuminatorCost_Tag4", type: "multi", report_type: "cost_explorer", condition: true, title: "ChorusCost_Tag4", extTitle: "illuminatorCost_Tag4" },
    {key: "chorus_cost_tag_5",  tag_type: "illuminatorCost_Tag5", type: "multi", report_type: "cost_explorer", condition: true, title: "ChorusCost_Tag5", extTitle: "illuminatorCost_Tag5" }
]

export const riRecommendation = [
    {key: "linked_account", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウント"},
    {key: "account_name", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウントの別名"},
    {key: "service", type: "single", report_type: "cost_explorer", condition: true, title: "サービス"},
    {key: "ri_term", type: "single", report_type: "ri_recommendation", condition: true, title: "RIの期間"},
    {key: "offering_class", type: "single", report_type: "ri_recommendation", condition: true, title: "提供クラス"},
    {key: "payment_option", type: "single", report_type: "ri_recommendation", condition: true, title: "支払い方法"},
    {key: "data_period", type: "single", report_type: "ri_recommendation", condition: true, title: "推薦を算出するための分析期間"}
]

export const spUtilizationReport = [
    {key: "linked_account", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウント"},
    {key: "account_name", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウントの別名"},
    {key: "sp_offering_type", type: "multi", report_type: "sp_utilization", condition: true, title: "Savings Planタイプ"},
    {key: "sp_region", type: "multi", report_type: "sp_utilization", condition: true, title: "リージョン"},
    {key: "sp_instance_family", type: "multi", report_type: "sp_utilization", condition: true, title: "インスタンスファミリー"}
]

export const spCoverageReport = [
    {key: "linked_account", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウント"},
    {key: "account_name", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウントの別名"},
    {key: "service", type: "multi", report_type: "cost_explorer", condition: true, title: "サービス"},
    {key: "instance_family", type: "multi", report_type: "merged_filter", condition: true, title: "インスタンスファミリー"},
    {key: "region", type: "multi", report_type: "cost_explorer", condition: true, title: "リージョン"}
]

export const spRecommendation = [
    {key: "linked_account", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウント"},
    {key: "account_name", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウントの別名"},
    {key: "sp_type", type: "single", report_type: "sp_recommendation", condition: true, title: "Savings Planタイプ"},
    {key: "sp_term", type: "single", report_type: "sp_recommendation", condition: true, title: "Savings Plan 期間"},
    {key: "payment_option", type: "single", report_type: "ri_recommendation", condition: true, title: "支払い方法"},
    {key: "data_period", type: "single", report_type: "ri_recommendation", condition: true, title: "推薦を算出するための分析期間"}
]

export const rightsizingEc2 = [
    {key: "linked_account", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウント"},
    {key: "account_name", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウントの別名"},
    {key: "region", type: "multi", report_type: "cost_explorer", condition: true, title: "リージョン"},
    {key: "cpu_architectures", type: "multi", report_type: "right_sizing_ec2", condition: true, title: "CPUアーキテクチャ"}
]

export const rightsizingEbs = [
    {key: "linked_account", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウント"},
    {key: "account_name", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウントの別名"},
    {key: "region", type: "multi", report_type: "cost_explorer", condition: true, title: "リージョン"}
]

export const rightsizingEip = [
    {key: "linked_account", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウント"},
    {key: "account_name", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウントの別名"},
    {key: "region", type: "multi", report_type: "cost_explorer", condition: true, title: "リージョン"}
]

export const rightsizingElb = [
    {key: "linked_account", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウント"},
    {key: "account_name", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウントの別名"},
    {key: "region", type: "multi", report_type: "cost_explorer", condition: true, title: "リージョン"}
]

export const expireRiSp = [
  {key: "aws_account_id", type: "multi", report_type: "expire_ri_sp", condition: true, title: "連結アカウント"},
  {key: "ri_sp", type: "multi", report_type: "expire_ri_sp", condition: true, title: "RI/SP"},
  {key: "ri_sp_id", type: "multi", report_type: "expire_ri_sp", condition: true, title: "ID"},
  {key: "offering_type", type: "multi", report_type: "expire_ri_sp", condition: true, title: "タイプ"},
  {key: "expire_ri_sp_service", type: "multi", report_type: "expire_ri_sp", condition: true, title: "サービス"},
  {key: "instance_info", type: "multi", report_type: "expire_ri_sp", condition: true, title: "インスタンス情報"},
  {key: "platform", type: "multi", report_type: "expire_ri_sp", condition: true, title: "プラットフォーム"},
  {key: "expire_ri_sp_availability_zone", type: "multi", report_type: "expire_ri_sp", condition: true, title: "アベイラビリティゾーン"},
  {key: "scope", type: "multi", report_type: "expire_ri_sp", condition: true, title: "スコープ"}
]

export const allColumns = [
    {key: "linked_account", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウント"},
    {key: "account_name", type: "multi", report_type: "cost_explorer", condition: true, title: "連結アカウントの別名"},
    {key: "service", type: "multi", report_type: "cost_explorer", condition: true, title: "サービス"},
    {key: "service_ri_utilization", type: "single", report_type: "ri_utilization_report", condition: true, title: "サービス(reserved usage)"}, //例外
    {key: "service_ri_coverage", type: "single", report_type: "ri_coverage_report", condition: true, title: "サービス(reserved coverage)"}, //例外
    {key: "region", type: "multi", report_type: "cost_explorer", condition: true, title: "リージョン"},
    {key: "sp_region", type: "multi", report_type: "sp_utilization_report", condition: true, title: "リージョン(savings plans usage)"},
    {key: "instance_type", type: "multi", report_type: "cost_explorer", condition: true, title: "インスタンスタイプ"},
    {key: "usage_type", type: "multi", report_type: "cost_explorer", condition: true, title: "使用タイプ"},
    {key: "charge_type", type: "multi", report_type: "cost_explorer", condition: true, title: "料金タイプ"},
    {key: "availability_zone", type: "multi", report_type: "cost_explorer", condition: true, title: "アベイラビリティゾーン"},
    {key: "platform", type: "multi", report_type: "cost_explorer", condition: true, title: "プラットフォーム"},
    {key: "purchase_option", type: "multi", report_type: "cost_explorer", condition: true, title: "購入オプション"},
    {key: "tenancy", type: "multi", report_type: "cost_explorer", condition: true, title: "テナンシー"},
    {key: "billing_entity", type: "multi", report_type: "cost_explorer", condition: true, title: "請求エンティティ"},
    {key: "cost_type", type: "multi", report_type: "cost_explorer", condition: false, title: "前払いRI・SPを償却"},
    {key: "api_operation", type: "multi", report_type: "cost_explorer", condition: true, title: "APIオペレーション"},
    {key: "instance_family_ri_coverage", type: "multi", report_type: "ri_coverage_report", condition: true, title: "インスタンスファミリー(reserved coverage)"}, //例外
    {key: "instance_family_sp_coverage", type: "multi", report_type: "sp_coverage_report", condition: true, title: "インスタンスファミリー(savings plans coverage)"}, //例外
    {key: "sp_instance_family", type: "multi", report_type: "sp_utilization_report", condition: true, title: "インスタンスファミリー(savings plans usage)"},
    {key: "scope", type: "multi", report_type: "ri_utilization_report", condition: true, title: "スコープ"},
    {key: "size_flexibility", type: "multi", report_type: "ri_utilization_report", condition: true, title: "サイズの柔軟性"},
    {key: "sp_offering_type", type: "multi", report_type: "sp_utilization_report", condition: true, title: "Savings Planタイプ"},
    {key: "chorus_cost_tag_1", type: "multi", report_type: "cost_explorer", condition: true, title: "ChorusCost_Tag1"},
    {key: "chorus_cost_tag_2", type: "multi", report_type: "cost_explorer", condition: true, title: "ChorusCost_Tag2"},
    {key: "chorus_cost_tag_3", type: "multi", report_type: "cost_explorer", condition: true, title: "ChorusCost_Tag3"},
    {key: "chorus_cost_tag_4", type: "multi", report_type: "cost_explorer", condition: true, title: "ChorusCost_Tag4"},
    {key: "chorus_cost_tag_5", type: "multi", report_type: "cost_explorer", condition: true, title: "ChorusCost_Tag5"},
    {key: "no_tag", type: "multi", report_type: "cost_explorer", condition: false, title: "タグ付けされていないリソース"},
    {key: "cpu_architectures", type: "multi", report_type: "rightsizing_ec2", condition: true, title: "CPUアーキテクチャ"},
    {key: "service_ri_recommend", type: "single", report_type: "ri_recommendation", condition: true, title: "推奨タイプ(reserved recommend)"}, //例外
    {key: "ri_term", type: "single", report_type: "ri_recommendation", condition: true, title: "RIの期間"},
    {key: "offering_class", type: "single", report_type: "ri_recommendation", condition: true, title: "提供クラス"},
    {key: "payment_option", type: "single", report_type: "ri_recommendation", condition: true, title: "支払い方法"},
    {key: "sp_type", type: "single", report_type: "sp_recommendation", condition: true, title: "Savings Planタイプ"},
    {key: "sp_term", type: "single", report_type: "sp_recommendation", condition: true, title: "Savings Plan 期間"},
    {key: "data_period", type: "single", report_type: "ri_recommendation", condition: true, title: "推薦を算出するための分析期間"}
]

export const mappingTable = {
  linked_account: [true, true, true, true, true, true, true, true, true, true, true],
  account_name: [true, true, true, true, true, true, true, true, true, true, true],
  service: [true, false, false, false, true, false, false, false, false, false, false],
  region: [true, true, true, false, true, false, false, true, true, true, true],
  instance_type: [true, true, true, false, false, false, false, false, false, false, false],
  usage_type: [true, false, false, false, false, false, false, false, false, false, false],
  charge_type: [true, false, false, false, false, false, false, false, false, false, false],
  availability_zone: [true, false, false, false, false, false, false, false, false, false, false],
  platform: [true, true, true, false, false, false, false, false, false, false, false],
  purchase_option: [true, false, false, false, false, false, false, false, false, false, false],
  tenancy: [true, true, true, false, false, false, false, false, false, false, false],
  billing_entity: [true, false, false, false, false, false, false, false, false, false, false],
  cost_type: [true, false, false, false, false, false, false, false, false, false, false],
  api_operation: [true, false, false, false, false, false, false, false, false, false, false],
  instance_family: [false, false, true, false, false, false, false, false, false, false, false],
  instance_family_ri_coverage: [false, false, true, false, false, false, false, false, false, false, false],
  instance_family_sp_coverage: [false, false, false, false, true, false, false, false, false, false, false],
  scope: [false, true, false, false, false, false, false, false, false, false, false],
  size_flexibility: [false, true, false, false, false, false, false, false, false, false, false],
  sp_offering_type: [false, false, false, true, false, false, false, false, false, false, false],
  sp_region: [false, false, false, true, false, false, false, false, false, false, false],
  sp_instance_family: [false, false, false, true, false, false, false, false, false, false, false],
  chorus_cost_tag_1: [true, false, true, false, false, false, false, false, false, false, false],
  chorus_cost_tag_2: [true, false, true, false, false, false, false, false, false, false, false],
  chorus_cost_tag_3: [true, false, true, false, false, false, false, false, false, false, false],
  chorus_cost_tag_4: [true, false, true, false, false, false, false, false, false, false, false],
  chorus_cost_tag_5: [true, false, true, false, false, false, false, false, false, false, false],
  no_tag: [true, false, false, false, false, false, false, false, false, false, false],
  cpu_architectures: [false, false, false, false, false, false, false, true, false, false, false],
  instance_id: [false, false, false, false, false, false, false, false, false, false, false],
  volume_id: [false, false, false, false, false, false, false, false, false, false, false],
  allocation_id: [false, false, false, false, false, false, false, false, false, true, false],
  name: [false, false, false, false, false, false, false, false, false, false, true],
  ri_term: [false, false, false, false, false, true, false, false, false, false, false],
  offering_class: [false, false, false, false, false, true, false, false, false, false, false],
  payment_option: [false, false, false, false, false, true, true, false, false, false, false],
  sp_type: [false, false, false, false, false, false, true, false, false, false, false],
  sp_term: [false, false, false, false, false, false, true, false, false, false, false],
  service_ri_utilization: [false, true, false, false, false, false, false, false, false, false, false],
  service_ri_coverage: [false, false, true, false, false, false, false, false, false, false, false],
  service_ri_recommend: [false, false, false, false, false, true, false, false, false, false, false],
  data_period: [false, false, false, false, false, true, true, false, false, false, false]
}

// allには含まれない
export const budget = [
  { key: "linked_account", type: "multi", report_type: "cost_explorer", condition: false, title: "連結アカウント"},
  { key: "charge_type", type: "multi", report_type: "cost_explorer", condition: false, title: "料金タイプ"}
]

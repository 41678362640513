// js
import "./javascript/active_sidebar.js";

// css
import "./stylesheet/loading.scss";
import "./stylesheet/application.scss";
import "./stylesheet/bootstrap-override.scss";
import 'v-calendar/dist/style.css';

// Material Dashboard
import './vendor/material-dashboard/js/core/bootstrap-material-design.min'
import './vendor/material-dashboard/js/plugins/perfect-scrollbar.jquery.min'
import './vendor/material-dashboard/js/plugins/bootstrap-selectpicker'

// Vue
import { createApp } from 'vue'

// Vue Plugin(Global)
import { setupCalendar } from 'v-calendar';
const app = createApp({})
app.use(setupCalendar)

// Vue Pages
import App from "./vue/pages/App.vue";
import Home from './vue/pages/home.vue'
import CostExplore from './vue/pages/cost_explorer.vue'
import Report from './vue/pages/report.vue'
import RiRecommend from './vue/pages/ri_recommend.vue'
import RiUtilization from './vue/pages/ri_utilization.vue'
import RiCoverage from './vue/pages/ri_coverage.vue'
import SpRecommend from './vue/pages/sp_recommend.vue'
import SpUtilization from './vue/pages/sp_utilization.vue'
import SpCoverage from './vue/pages/sp_coverage.vue'
import AssetSummery from './vue/pages/asset_summary.vue'
import AssetUsageDetail from './vue/pages/asset_usage_detail.vue'
import AssetUsageHistory from './vue/pages/asset_usage_history.vue'
import RightSizingEc2 from './vue/pages/right_sizing_ec2'
import RightSizingEbs from './vue/pages/right_sizing_ebs'
import RightSizingEip from './vue/pages/right_sizing_eip'
import RightSizingElb from './vue/pages/right_sizing_elb'
import RightSizingSetting from './vue/pages/right_sizing_setting'
import DashboardFilter from './vue/pages/dashboard_filter.vue'
import DashboardFilterNew from './vue/pages/dashboard_filter_new.vue'
import DashboardFilterEdit from './vue/pages/dashboard_filter_edit.vue'
import Notice from './vue/pages/notice.vue'
import NoticeDetail from './vue/pages/notice_detail.vue'
import Alert from './vue/pages/alert.vue'
import AlertDetail from './vue/pages/alert_detail.vue'
import CostAlerts from './vue/pages/cost_alerts.vue'
import CostAlertsNew from './vue/pages/cost_alerts_new.vue'
import CostAlertsEdit from './vue/pages/cost_alerts_edit.vue'
import RiSpAlerts from './vue/pages/ri_sp_alerts.vue'
import RiSpAlertsNew from './vue/pages/ri_sp_alerts_new.vue'
import RiSpAlertsEdit from './vue/pages/ri_sp_alerts_edit.vue'
import RightsizingAlerts from './vue/pages/rightsizing_alerts.vue'
import RightsizingAlertsNew from './vue/pages/rightsizing_alerts_new.vue'
import RightsizingAlertsEdit from './vue/pages/rightsizing_alerts_edit.vue'
import BudgetAlerts from "./vue/pages/budget_alerts.vue";
import BudgetAlertsNew from "./vue/pages/budget_alerts_new.vue";
import BudgetAlertsEdit from "./vue/pages/budget_alerts_edit.vue";
import ReportSubscription from './vue/pages/report_subscription.vue'
import ReportSubscriptionNew from './vue/pages/report_subscription_new.vue'
import ReportSubscriptionCostEdit from './vue/pages/report_subscription_cost_edit.vue'
import ReportSubscriptionBudgetEdit from './vue/pages/report_subscription_budget_edit.vue'
import ReportSubscriptionDownloadPdf from './vue/pages/report_subscription_download_pdf.vue'
import AwsAccountSettings from './vue/pages/aws_account_settings.vue'
import AwsAccountSettingsNew from './vue/pages/aws_account_settings_new.vue'
import AwsAccountSettingsDetail from './vue/pages/aws_account_settings_detail.vue'
import AwsAccountSettingsEdit from './vue/pages/aws_account_settings_edit.vue'
import AwsAccountSettingsRoleEdit from './vue/pages/aws_account_settings_role_edit.vue'
import TagsSettings from './vue/pages/tags_settings.vue'
import ContractAccountAlias from './vue/pages/contract_account_alias.vue'
import Invoice from './vue/pages/invoice.vue'
import BudgetIndex from './vue/pages/budget_index.vue'
import BudgetCreate from './vue/pages/budget_create.vue'
import BudgetEdit from './vue/pages/budget_edit.vue'
import BudgetDetail from './vue/pages/budget_detail.vue'
import Signup from './vue/pages/signup/signup.vue'
import SignupDetail from './vue/pages/signup/signup_detail.vue'
import SignupComplete from './vue/pages/signup/signup_complete.vue'
import RecommendSimulatorScenario from './vue/pages/recommend/simulator/scenario/index.vue'
import RecommendSimulatorScenarioCreate from './vue/pages/recommend/simulator/scenario/create.vue'
import RecommendSimulatorScenarioEdit from './vue/pages/recommend/simulator/scenario/edit.vue'
import RecommendSimulatorScenarioDetail from './vue/pages/recommend/simulator/scenario/detail.vue'
import ServiceAccountContract from './vue/pages/serviceaccount/contract/index.vue'
import ServiceAccountContractEdit from './vue/pages/serviceaccount/contract/edit.vue'
import ServiceAccountContractQuit from './vue/pages/serviceaccount/contract/quit.vue'
import ServiceAccountContractor from './vue/pages/serviceaccount/contractor/index.vue'
import ServiceAccountContractorEdit from './vue/pages/serviceaccount/contractor/edit.vue'
import ServiceAccountPayment from './vue/pages/serviceaccount/payment/index.vue'
import ServiceAccountPaymentEdit from './vue/pages/serviceaccount/payment/edit.vue'
import Users from './vue/pages/users/users.vue'
import UserDetail from './vue/pages/users/userDetail.vue'
import UserEdit from './vue/pages/users/userEdit.vue'
import NewUser from './vue/pages/users/new_user.vue'
import CreateUser from './vue/pages/users/create_user.vue'
import PasswordChange from './vue/pages/password/password_change.vue'
import Mfa from './vue/pages/mfa.vue'
import EmailChange from './vue/pages/mail/email_change.vue'
import EmailChangeComplete from './vue/pages/mail/email_change_complete.vue'

import Login from './vue/pages/login/login.vue'
import ResetPassword from './vue/pages/login/reset_password.vue'
import ResetPasswordSetting from './vue/pages/login/reset_password_setting.vue'
import Agreement from './vue/pages/login/agreement.vue'

import Support from './vue/pages/support.vue'

// Vue Mount
let newVue3 = (id, components) => {
  const tag = document.getElementById(id)
  if(tag){
    const app = createApp(components)
    app.mount(`#${id}`)
  }
}

// Vue Insert Process
document.addEventListener('DOMContentLoaded', () => {
  newVue3('app', App)
  newVue3('home', Home)
  newVue3('home_report', Report)
  newVue3('filter_list', DashboardFilter )
  newVue3('filter_new', DashboardFilterNew)
  newVue3('filter_edit', DashboardFilterEdit)
  newVue3('cost_viewer', CostExplore)
  newVue3('reserved_coverage', RiCoverage)
  newVue3('reserved_usage', RiUtilization )
  newVue3('savings_plans_usage', SpUtilization)
  newVue3('savings_plans_coverage', SpCoverage)
  newVue3('reserved_recommend', RiRecommend )
  newVue3('savings_plans_recommend', SpRecommend )
  newVue3('asset_summary', AssetSummery)
  newVue3('asset_usage_detail', AssetUsageDetail)
  newVue3('asset_usage_history', AssetUsageHistory)
  newVue3('rightsizing_ec2', RightSizingEc2)
  newVue3('rightsizing_ebs', RightSizingEbs)
  newVue3('rightsizing_eip', RightSizingEip)
  newVue3('rightsizing_elb', RightSizingElb)
  newVue3('rightsizing_setting', RightSizingSetting)
  newVue3('notice', Notice);
  newVue3('notice_detail', NoticeDetail);
  newVue3('alert', Alert);
  newVue3('alert_detail', AlertDetail);
  newVue3('cost_alerts', CostAlerts)
  newVue3('cost_alerts_new', CostAlertsNew)
  newVue3('cost_alerts_edit', CostAlertsEdit)
  newVue3('ri_sp_alerts', RiSpAlerts)
  newVue3('ri_sp_alerts_new', RiSpAlertsNew)
  newVue3('ri_sp_alerts_edit', RiSpAlertsEdit)
  newVue3('rightsizing_alerts', RightsizingAlerts)
  newVue3('rightsizing_alerts_new', RightsizingAlertsNew)
  newVue3('rightsizing_alerts_edit', RightsizingAlertsEdit)
  newVue3('budget_alerts', BudgetAlerts)
  newVue3('budget_alerts_new', BudgetAlertsNew)
  newVue3('budget_alerts_edit', BudgetAlertsEdit)
  newVue3('report_subscription', ReportSubscription);
  newVue3('report_subscription_new', ReportSubscriptionNew);
  newVue3('report_subscription_cost_edit', ReportSubscriptionCostEdit);
  newVue3('report_subscription_budget_edit', ReportSubscriptionBudgetEdit);
  newVue3('report_subscription_download_pdf', ReportSubscriptionDownloadPdf);
  newVue3('aws_account_settings', AwsAccountSettings);
  newVue3('aws_account_settings_new', AwsAccountSettingsNew);
  newVue3('aws_account_settings_edit', AwsAccountSettingsEdit);
  newVue3('aws_account_settings_detail', AwsAccountSettingsDetail);
  newVue3('aws_account_settings_role_edit', AwsAccountSettingsRoleEdit);
  newVue3('tags_settings', TagsSettings);
  newVue3('contract_account_alias', ContractAccountAlias)
  newVue3('invoice', Invoice)
  newVue3('budget_index', BudgetIndex);
  newVue3('budget_create', BudgetCreate);
  newVue3('budget_edit', BudgetEdit);
  newVue3('budget_detail', BudgetDetail);
  newVue3('signup', Signup);
  newVue3('signup_detail', SignupDetail);
  newVue3('signup_complete', SignupComplete);
  newVue3('recommend_simulator_scenario', RecommendSimulatorScenario);
  newVue3('recommend_simulator_scenario_create', RecommendSimulatorScenarioCreate);
  newVue3('recommend_simulator_scenario_edit', RecommendSimulatorScenarioEdit );
  newVue3('recommend_simulator_scenario_detail', RecommendSimulatorScenarioDetail);
  newVue3('service_account_contract', ServiceAccountContract);
  newVue3('service_account_contract_edit', ServiceAccountContractEdit);
  newVue3('service_account_contract_quit', ServiceAccountContractQuit);
  newVue3('service_account_contractor', ServiceAccountContractor);
  newVue3('service_account_contractor_edit', ServiceAccountContractorEdit);
  newVue3('service_account_payment', ServiceAccountPayment);
  newVue3('service_account_payment_edit', ServiceAccountPaymentEdit);
  newVue3('users', Users);
  newVue3('userDetail', UserDetail);
  newVue3('userEdit', UserEdit);
  newVue3('new_user', NewUser);
  newVue3('create_user', CreateUser);
  newVue3('passwordChange', PasswordChange);
  newVue3('mfa', Mfa);
  newVue3('email_change', EmailChange);
  newVue3('email_change_complete', EmailChangeComplete);
  newVue3('login', Login);
  newVue3('reset_password', ResetPassword);
  newVue3('reset_password_setting', ResetPasswordSetting);
  newVue3('agreement', Agreement);
  newVue3('support', Support);
})

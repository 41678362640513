<template>
  <div class="form-group" >
    <label for="exampleFormControlSelect1"></label>
    <select class="form-control selectpicker" data-style="btn btn-link" id="exampleFormControlSelect1" v-model="selected">
      <option value="" disabled selected><slot></slot></option>
      <template v-for="item in selectList">
        <option :value="item">{{ item }}</option>
      </template>
    </select>
  </div>
</template>
<script setup>
import { onMounted, ref, watch } from "vue";

const props = defineProps({
  type1: String,
  type2: String,
  selectList: Array
})
const emits = defineEmits( [ "emitTrigger" ])

const selectedValue = () => {
  if(props.type1 === "start" && props.type2 === "year"){
    return new Date().getFullYear()
  }

  if(props.type1 === "start" && props.type2 === "month"){
    return new Date().getMonth()+1
  }
  return ""
}

const selected = ref("")

watch(selected, ( value ) => {
  emits('emitTrigger', props.type1, props.type2, value)
})

onMounted(async () => {
  selected.value = selectedValue()
})
</script>

<template>
  <Alert ref="alert"/>
  <div v-if="!initialized">
    <div class="loader">Loading...</div>
  </div>
  <div v-else>
    <form class="card repeated_hit_prevention_form" v-on:submit.prevent="executeForm">
      <div class="card-body">
        <div style="display: flex;justify-content: space-between;">
          <div style="display: flex;" class="text-left">
            <button class="btn btn-primary repeated_hit_prevention" type="submit">保存</button>
            <span v-if="isEdit">
              <span class="btn btn-primary" v-on:click="deleteRightsizingAlert">削除</span>
            </span>
          </div>
          <div class="text-right">
            <a class="btn default" href="/mypage/alert/setting/rightsizing">一覧</a>
          </div>
        </div>
        <table class="table table-borderless mt-5">
          <tbody>
          <tr>
            <th>通知有無</th>
            <td>
              <div class="form-check form-check-radio" style="display: inline-block">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" id="valid-notification" value="true" v-model="params.notification">
                  有効
                  <span class="circle"><span class="check"></span></span>
                </label>
              </div>
              <div class="form-check form-check-radio" style="display: inline-block">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" id="invalid-notification" value="false" v-model="params.notification">
                  無効
                  <span class="circle"><span class="check"></span></span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>連結アカウント</th>
            <td>
              <select id="aws_account_id" class="form-control" v-model="params.aws_account_id">
                <option v-if="permissions.show_all_aws_account" value="">全て</option>
                <template v-for="account in Object.keys(awsAccountIds)">
                  <option :value="account">
                    {{ account }}
                    <template v-if="awsAccountIds[account].length > 1">
                      ( {{ awsAccountIds[account] }} )
                    </template>
                  </option>
                </template>
              </select>
            </td>
          </tr>
          <tr>
            <th>通知先(ユーザ)</th>
            <td>
              <div class="form-check form-check-radio" style="display: inline-block">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" id="all-user" v-bind:value="true" v-model="params.send_all_user" v-on:change="selectSendAllUser">
                  全てのユーザ
                  <span class="circle"><span class="check"></span></span>
                </label>
              </div>
              <div class="form-check form-check-radio" style="display: inline-block">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" id="individual-user" v-bind:value="false" v-model="params.send_all_user">
                  個別にユーザを設定
                  <span class="circle"><span class="check"></span></span>
                </label>
              </div>
              <template v-if="!params.send_all_user">
                <br>※個別のユーザを設定する場合、最大5件まで設定できます
                <table id="user_mails-list" class="table table-borderless">
                  <thead>
                    <tr>
                      <th>ユーザーメールアドレス</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(userMail, i) in userMails" :key="i">
                      <td class="btn-group-toggle" data-toggle="checkbox">
                        <!-- 選択中 -->
                        <template v-if="userMailsIds.includes(i)">
                          <label class="btn btn-primary">
                            <input type="checkbox" autocomplete="off" :value="i" v-model="userMailsIds" v-on:change="selectUserMails">
                            <i class="material-icons">check</i>
                            {{ userMail }}
                          </label>
                        </template>
                        <!-- 未選択 -->
                        <template v-else>
                          <label class="btn btn-secondary">
                            <input type="checkbox" autocomplete="off" :value="i" v-model="userMailsIds" v-on:change="selectUserMails">
                            {{ userMail }}
                          </label>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </td>
          </tr>
          <tr>
            <th>通知先(メールアドレス)</th>
            <td>
              <input id="inputEmail1" class="form-control" type="email" v-model="params.mails[0]" v-on:change="validateEmail"/>
            </td>
          </tr>
          <tr>
            <th></th>
            <td>
              <input id="inputEmail2" class="form-control" type="email" v-model="params.mails[1]" v-on:change="validateEmail"/>
            </td>
          </tr>
          <tr>
            <th></th>
            <td>
              <input id="inputEmail3" class="form-control" type="email" v-model="params.mails[2]" v-on:change="validateEmail"/>
            </td>
          </tr>
          <tr>
            <th></th>
            <td>
              <input id="inputEmail4" class="form-control" type="email" v-model="params.mails[3]" v-on:change="validateEmail"/>
            </td>
          </tr>
          <tr>
            <th></th>
            <td>
              <input id="inputEmail5" class="form-control" type="email" v-model="params.mails[4]" v-on:change="validateEmail"/>
            </td>
          </tr>
          <tr>
            <th>Slack WebHook URL</th>
            <td>
              <input id="inputUrl" class="form-control" type="url" placeholder="https://hooks.slack.com" pattern="https://hooks.slack.com.*" v-model="params.slack_url"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </form>
  </div>
</template>
<script>
import { createRightsizingAlert, deleteRightsizingAlert, getRightsizingAlert, getAlertsAwsAccountIds, getAlertsUserMails, updateRightsizingAlert } from "../lib/axios/api_client";
import Alert from "./alert_new.vue";
import ReportMethods from "./report_methods.vue";
import { pagePath } from "../utils/constants";

export default {
  props: {
    isEdit: Boolean,
    alertParam: Object
  },
  components: {
    ReportMethods,
    Alert
  },
  data() {
    return {
      params: {
        notification: false,
        aws_account_id: "",
        send_all_user: true,
        user_mails: {},
        mails: [],
        slack_url: "",
      },
      id: "",
      path: pagePath.rightsizingAlert.index,
      awsAccountIds: [],
      permissions: {},
      userMails: {},
      userMailsIds: [],
      initialized: false,
      input: {
        max: 10000000,
        min: 0
      }
    }
  },
  mounted: async function() {
    const {aws_account_ids, permissions} = await this.getAwsAccountIds()
    if(!aws_account_ids || !permissions){
      return false
    }

    this.awsAccountIds = aws_account_ids
    this.permissions = permissions
    if(!this.permissions.show_all_aws_account) {
      this.params.aws_account_id = Object.keys(this.awsAccountIds)[0]
    }

    const userMails = await this.getUserMails()
    if(!userMails){
      return false
    }
    this.userMails = userMails

    if(this.isEdit){
      this.id = this._getId()
      const alert = await this.getRightsizingAlert(this.id)
      if(!alert){
        return false
      }
      this.params = alert
      this.userMailsIds = Object.keys(alert.user_mails)
    }

    this.initialized = true
  },
  methods: {
    selectSendAllUser() {
      this.userMailsIds = []
      this.params.user_mails = {}
    },
    selectUserMails() {
      this.params.user_mails = {}
      this.userMailsIds.forEach(id => this.params.user_mails[id] = this.userMails[id])
    },
    validateEmail() {
      this.params.mails.forEach(mail => {
        if (mail === '') {
          this.params.mails = this.params.mails.filter(function(v){
            return ! ''.includes(v);
          })
        }
      })
    },
    executeForm(){
      if(this.isEdit){
        this.updateRightsizingAlert(this.id)
      }else{
        this.createRightsizingAlert()
      }
    },
    getRightsizingAlert: async function (id) {
      const res = await getRightsizingAlert(id)
      if (!res.successful) {
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      return res.data.rightsizing_alert
    },
    getAwsAccountIds: async function () {
      const res = await getAlertsAwsAccountIds()
      if (!res.successful) {
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      return res.data
    },
    getUserMails: async function () {
      const res = await getAlertsUserMails()
      if (!res.successful) {
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      return res.data.user_mails
    },
    createRightsizingAlert: async function () {
      const res = await createRightsizingAlert(this.params)

      if (!res.successful) {
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      this.$refs.alert.displayModal('成功しました。', 'success')
      setTimeout(this._transition,1000);
      return true
    },
    updateRightsizingAlert: async function (id) {
      const res = await updateRightsizingAlert(id, this.params)
      if (!res.successful) {
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      this.$refs.alert.displayModal('成功しました。', 'success')
      setTimeout(this._transition,1000);
      return true
    },
    deleteRightsizingAlert: async function() {
      if (!window.confirm('本当に削除しますか？')) return;

      const rightsizingAlertId = this._getId()
      if(!rightsizingAlertId){
        this.$refs.alert.displayModal("データが取得できませんでした。", 'error')
        return false
      }

      const res = await deleteRightsizingAlert(rightsizingAlertId)
      if (!res.successful) {
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      this.$refs.alert.displayModal('成功しました。', 'success')
      setTimeout(this._transition,1000);
    },
    _getId(){
      const pathArray = location.pathname.split("/")
      if(pathArray[5] === "undefined" ){
        return false
      }
      return pathArray[5]
    },
    _transition(){
      window.location.href = this.path
    }
  }
}
</script>
<style scoped>
.table>tbody>tr>th {
  vertical-align: top;
}
.table {
  background-color: transparent;
}
.btn-group-toggle {
  padding: 0px
}
.btn-group-toggle>.btn.btn-primary {
  background-color: #9c27b0;
}
</style>

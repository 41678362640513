<template>
    <div v-if="!initialized">
      <div class="loader">Loading...</div>
      <ReportMethods ref="reportMethods" :reportType="report_type"/>
    </div>
    <div v-else>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pull-left">
                  <ReportButtons
                      :reportType="report_type"
                      v-model:report="aws_report"
                      v-model:edited="edited"
                      v-on:updateReport="updateReport"
                  />
                  <FilterButtons
                      :reportType="report_type"
                      v-model:report="aws_report"
                      v-on:updateReport="updateReport"
                  />
                </div>
                <div class="pull-right">
                  <ButtonHideFilter v-on:triggerEmit="changeDisplayFilter">{{ dashboardSizeClass.apply.text }}</ButtonHideFilter>
                </div>
            </div>
        </div>
        <div class="row">
            <!-- start left-side -->
            <div :class="dashboardSizeClass.apply.class">
                <div class="card">
                    <EditTitleCard :reportType="report_type" :lock="aws_report.report_lock" v-model:reportName="aws_report.name" v-model:edited="edited" v-model:close="close"/>
                    <div class="card-header">
                      <DropDownCalendar  v-model:date_range="aws_report.date_range.date_range" v-model:start="aws_report.date_range.start" v-model:end="aws_report.date_range.end" :timeUnit="aws_report.graph_style.time_unit" :reportType="report_type" v-on:updateReport="updateReport"/>
                      <DropDownButton type="group"    dropdownId="groupMenuButton"    :dropdownItem="groupItem"    v-model:item="aws_report.graph_style.group"     v-on:updateReport="updateReport"/>
                      <DropDownButton type="timeUnit" dropdownId="timeUnitMenuButton" :dropdownItem="timeUnitItem" v-model:item="aws_report.graph_style.time_unit" v-on:updateReport="updateReport"/>
                      <DropDownButton type="graph"    dropdownId="graphMenuButton"    :dropdownItem="graphItem"    v-model:item="aws_report.graph_style.visual_type"     v-on:updateReport="updateReport"/>
                    </div>
                    <div class="card-body">
                      <IframeDashboard ref="dashboard" height="700px"/>
                    </div>
                </div>
            </div>
            <!-- end left-side -->
            <!-- start right-side -->
            <div class="col-lg-3 col-md-3 col-sm-3" v-show="displayFilter">
              <FilterList :reportType="report_type" :reportFilter="aws_report.filter" v-on:updateFilter="updateFilter" v-on:updateDashboard="updateReport"/>
            </div>
            <!-- end right-side -->
        </div>
    </div>
</template>

<script>
    import DropDownButton       from '../components/dropdown_button.vue'
    import DropDownCalendar     from '../components/dropdown_calendar.vue'
    import EditTitleCard        from '../components/edit_title_card.vue'
    import ButtonHideFilter     from '../components/button_hide_filter.vue'
    import IframeDashboard      from '../components/iframe_dashboard.vue'
    import ReportButtons        from '../components/report_buttons.vue'
    import FilterButtons        from '../components/filter_buttons.vue'
    import FilterList           from '../components/filter_list.vue'
    import ReportMethods        from '../components/report_methods.vue'
    import * as format          from '../utils/format_graph_style'
    import * as constants       from '../utils/constants'
    import { extractReportId } from "../lib/common"
    import {differenceInCalendarDays} from "date-fns";
    import {transformDate} from "../lib/calendar";

    export default {
        mounted: async function(){
            const report_id = extractReportId()
            const report = await this.$refs.reportMethods.getReport(report_id)
            if(!report){ return false }
            this.aws_report = report

            this.initialized = true
            this.$nextTick(async () => {
              await this.$refs.dashboard.generateDashboard(this.aws_report)
            })
        },
        components: {
            'DropDownButton'       : DropDownButton,
            'DropDownCalendar'     : DropDownCalendar,
            'EditTitleCard'        : EditTitleCard,
            'ButtonHideFilter'     : ButtonHideFilter,
            'IframeDashboard'      : IframeDashboard,
            'FilterButtons'        : FilterButtons,
            'FilterList'           : FilterList,
            'ReportMethods'        : ReportMethods,
            'ReportButtons'        : ReportButtons
        },
        data: function () {
            return {
                report_type: constants.reportTypeCostExplorer,
                groupItem:    format.groupItemWithNormal,
                timeUnitItem: format.timeUnitItem,
                graphItem:    format.groupItemWithStackedBar,
                aws_report:   {},
                edited: false,
                close: true,
                displayFilter: true,
                dashboardSizeClass: constants.dashboardSizeClass,
                initialized: false
            }
        },
        watch: {
            'aws_report.graph_style.group': function(val) {
              if(val === 'normal'){
                this.graphItem = format.graphItem
              }else{
                this.graphItem = format.groupItemWithStackedBar
              }
            },
            'aws_report.graph_style.visual_type': function(val){
              if(val === 'stacked_bar'){
                this.groupItem = format.groupItem
              }else{
                this.groupItem = format.groupItemWithNormal
              }

            }
        },
        methods: {
            changeEdited(bool){
              this.close = true
              this.edited = bool
            },
            changeDisplayFilter(){
              this.displayFilter = !this.displayFilter
              this.dashboardSizeClass.apply = this.displayFilter ? this.dashboardSizeClass.half : this.dashboardSizeClass.full
            },
            updateFilter(filter) {
              this.aws_report.filter[filter.key] = filter.value
            },
            updateReport(type='no'){
              if(type === 'timeUnit'){
                this.checkTimeUnit()
              }
              this.changeEdited(true)
              this.$refs.dashboard.generateDashboard(this.aws_report)
            },
            checkTimeUnit(){
                if(this.aws_report.graph_style.time_unit !== 'daily') {
                  return true
                }

                if(this.aws_report.date_range.date_range === 'year'){
                  this.aws_report.date_range.date_range = 'month_6'
                }

                if(this.aws_report.date_range.date_range === 'custom'){
                  // 期間のチェック 184日を超えていたら期間内に
                  const maxHalfMonthDays = 184
                  const start = transformDate(this.aws_report.date_range.start)
                  const end = transformDate(this.aws_report.date_range.end)
                  const interval = differenceInCalendarDays(end, start) + 1

                  if(interval > maxHalfMonthDays){
                    this.aws_report.date_range.date_range = 'month_6'
                    this.aws_report.date_range.start = ""
                    this.aws_report.date_range.end = ""
                  }
              }
            }
        }
    }
</script>

<style scoped>
</style>

import { TsBaseClient } from "../ts_base_client";
export class ApiClient extends TsBaseClient {
    constructor() {
        super();
    }
    async changePassword(params) {
        const url = `/password/change`;
        return await this.put(url, params);
    }
    async resetPassword(params) {
        const url = `/password/reset`;
        return await this.put(url, params);
    }
    async checkTokenKey(tokenKey) {
        const url = `/password/reset/check/${tokenKey}`;
        return await this.get(url);
    }
    async sendmailResetPassword(param) {
        const url = `/password/reset/sendmail`;
        return await this.post(url, param);
    }
}

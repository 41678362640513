<template>
  <LoginMethods ref="loginMethods"/>
  <NeoidMethods ref="neoidMethods"/>
  <Alert ref="alert"/>
  <div>
    <div class="card card-login card-hidden">
      <div class="card-header text-center my-4">
        <h4 v-show="!isMfa" class="card-title">ログイン</h4>
        <h4 v-show="isMfa" class="card-title">MFA認証</h4>
      </div>
      <div class="card-body">
        <template v-if="isMfa">
          <form class="mb-5" name="mfa-form" v-on:submit.prevent="loginMfa()">
            <div class="form-group">
              <label for="loginMailInput">認証コード</label>
              <input id="loginMailInput" name="authCode" type="text" class="form-control" minlength="6" maxlength="6" pattern="[0-9]{6}" v-model="authCode" @input="validateAuthCode">
            </div>
            <div class="justify-content-start">
              <div class="mb-5" style="display: flex">
                <button type="submit" class="btn btn-primary d-block w-50 py-3">ログイン</button>
                <button type="button" class="btn d-block w-50 py-3" v-on:click="sendCodeMfaLogin">コード再送信</button>
              </div>
            </div>
          </form>
        </template>
        <template v-else>
          <form class="mb-5" id="login-form-second" v-on:submit.prevent="login()">
            <div class="form-group">
              <label for="loginMailInput">メールアドレス</label>
              <input id="loginMailInput" name="loginMail" type="text" class="form-control" maxlength="100" placeholder="メールアドレスを入力してください" v-model="email" @input="validateEmail()">
            </div>
            <div class="form-group">
              <label for="loginPasswordInput">パスワード</label>
              <input type="password" class="form-control" id="loginPasswordInput" name="loginPassword" minlength="8" maxlength="48" placeholder="パスワードを入力してください" v-model="password" @input="validatePassword()">
            </div>
            <div class="form-check form-check-inline mb-5">
              <label class="form-check-label">
                <input class="form-check-input" type="checkbox" id="rememberEmail" value="option1" v-model="isRememberMailAddress">メールアドレスを記憶させる
                <span class="form-check-sign">
                  <span class="check"></span>
              </span>
              </label>
            </div>
            <div class="justify-content-start">
              <div class="mb-5">
                <button type="submit" class="btn btn-primary d-block mx-auto w-100 py-3">ログイン</button>
              </div>
            </div>
          </form>
          <div class="justify-content-start">
            <div class="mb-2">
              <a href="/password/reset" class="d-block mx-auto">パスワードを忘れた場合はこちら</a>
            </div>
            <div class="mb-2">
              <a href="/signup" class="d-block mx-auto">新規に契約される方はこちら</a>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup>
import LoginMethods from "../../components/login_methods.vue";
import NeoidMethods from "../../components/neoid_methods.vue";
import { onMounted, ref } from "vue";
import Alert from "../../components/alert_new.vue";

const loginMethods = ref(null)
const neoidMethods = ref(null)
const initialized = ref(false)

const email = ref("")
const emailValidated = ref(false)
const passwordValidated = ref(false)
const password = ref("")
const isRememberMailAddress = ref(false)

const tokenKey = ref("")
const isMfa = ref(false)
const authCode = ref("")
const authCodeConfirm = ref(false)

const emailRegex = /^[a-zA-Z0-9.!#$%&*+\/=?^_`{|}~-]{1,64}@[\.a-zA-Z0-9-]+\.[a-zA-Z]+$/
const domainRegex = /^(?=.{1,100}$)(?=.{1,64}@).+@.+\.[a-zA-Z]{2,}$/
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,48}$/
const alert = ref(null);

const login = async () => {
  validateEmail()
  validatePassword()
  if(emailValidated.value === true && passwordValidated.value === true) {
    const mfaParams = {
      email: email.value
    }
    const result = await loginMethods.value?.mfaSetting(mfaParams)
    if (!result) {
      return false
    }
    const type = result.mfa_enabled ? 'CHECK_PASSWORD' : 'LOGIN'

    // 暗号化
    const { ip, sessionKey, rsaString } = await neoidMethods.value?.generateRSAKeys(email.value, password.value, type)

    // ログイン
    const param = {
      email: email.value,
      encrypt_string: rsaString,
      session_key: sessionKey,
      ip: ip
    }
    const resultLogin = await loginMethods.value?.login(param)
    if (!resultLogin) {

      return false
    }
    tokenKey.value = resultLogin.token_key

    if(isRememberMailAddress.value){
      localStorage.setItem('isRememberMailAddress', email.value)
    }

    // リダイレクト判定
    if(result.mfa_enabled){
      isMfa.value = true
    } else {
      _transition(resultLogin.next_url)
    }
  }
}

const validateEmail = () => {
  const form = document.forms["login-form-second"]
  if (!email.value || email.value === "") {
    form.elements["loginMail"].setCustomValidity("メールアドレスを入力してください。")
    emailValidated.value = false
  } else if(!emailRegex.test(email.value) || !domainRegex.test(email.value)) {
    form.elements["loginMail"].setCustomValidity("メールアドレスの入力形式が正しくありません。")
    emailValidated.value = false
  } else {
    form.elements["loginMail"].setCustomValidity("")
    emailValidated.value = true
  }
}

const validatePassword = () => {
  const form = document.forms["login-form-second"]
  if (!password.value || password.value === "") {
    form.elements["loginPassword"].setCustomValidity("パスワードを入力してください。")
    passwordValidated.value = false
  } else if(!passwordRegex.test(password.value)) {
    form.elements["loginPassword"].setCustomValidity("パスワードの入力形式が正しくありません。")
    passwordValidated.value = false
  } else {
    form.elements["loginPassword"].setCustomValidity("")
    passwordValidated.value = true
  }
}

const validateAuthCode = () => {
  const form = document.forms["mfa-form"]
  form.elements["authCode"].setCustomValidity("")
  if (!authCode.value || authCode.value === "") {
    form.elements["authCode"].setCustomValidity("認証コードを入力してください。")
    authCodeConfirm.value = false
  } else {
    form.elements["authCode"].setCustomValidity("")
    authCodeConfirm.value = true
  }
}

const loginMfa = async () => {
  // ログイン
  const { ip, sessionKey, rsaString } = await neoidMethods.value?.generateRSAKeys(email.value, password.value, "LOGIN")
  const form = document.forms["mfa-form"]
  if (form.elements["authCode"].value === "") {
    form.elements["authCode"].setCustomValidity("認証コードを入力してください。")
    form.elements["authCode"].reportValidity()
  }
  const param = {
    auth_code: authCode.value,
    token_key: tokenKey.value,
    ip: ip,
    session_key: sessionKey,
    encrypt_string: rsaString
  }
  validateAuthCode()
  if (authCodeConfirm.value === true) {
    const result = await loginMethods.value?.mfaLogin(param)
    if (!result) {
      return false
    }

    setTimeout( function() { window.location.href = result.next_url }, result.time)
  }
}

const sendCodeMfaLogin = async () => {
  const param = {
    token_key: tokenKey.value
  }
  const result = await loginMethods.value?.sendCodeMfaLogin(param)
  if (!result) {
    return false
  }
  alert.value.displayModal("コードを再送信しました", 'success');
  tokenKey.value = result.token_key
}

const _transition = (url) => {
  window.location.href = url
}

onMounted(async () => {
  if(localStorage.getItem('isRememberMailAddress')){
    email.value = localStorage.getItem('isRememberMailAddress')
    isRememberMailAddress.value = true
  }
  initialized.value = true
  if(isMfa.value === true){
    validateAuthCode()
  } else {
    validateEmail()
    validatePassword()
  }
})

</script>

<template>
  <BudgetMethods ref="methods"/>
  <Alert ref="alert"/>
  <div v-if="!initialized">
    <div class="loader">Loading...</div>
  </div>
  <div v-else>
    <form class="card" @submit.prevent="updateBudget">
      <div class="card-body">
        <div style="display: flex; justify-content: space-between;">
          <!--- save --->
          <div class="text-left">
            <button class="btn btn-primary repeated_hit_prevention" type="submit">保存</button>
          </div>
          <!--- save --->
          <div class="text-right">
            <a class="btn default" :href="`/mypage/budget/${id}/detail`">キャンセル</a>
            <a class="btn default" :href="path">一覧</a>
          </div>
        </div>
        <table class="table table-borderless mt-2">
          <tbody>
          <tr>
            <th>予算名</th>
            <td>
              <input id="budget-name" class="form-control width-30" type="text" minlength="1" maxlength="255" v-model="form.budget_name"/>
            </td>
          </tr>
          </tbody>
        </table>
        <!--- currency --->
        <div class="card-body form-border mt-3">
          通貨設定
          <table class="table table-borderless mt-2">
            <tbody>
            <tr>
              <th>通貨</th>
              <td>
                <span v-if="form.currency === 'usd'">$</span>
                <span v-else>円</span>
              </td>
            </tr>
            <tr v-show="form.currency === 'jpy'">
              <th>予算レート</th>
              <td> {{ form.exchange_rate }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <!--- currency --->
        <!--- result --->
        <div class="card-body form-border mt-3">
          <span>各月の予算</span>
          <div>
            <BudgetResultTemplate :monthly_budgets="form.monthly_budgets" :currency="form.currency" :exchange_rate="form.exchange_rate"/>
          </div>
        </div>
        <!--- result --->
      </div>
    </form>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import Alert from "../components/alert_new.vue";
import BudgetResultTemplate from "../components/budget_result_template.vue"
import BudgetMethods from "../components/budget_methods.vue";
import { extractId } from "../lib/common";
import { pagePath } from "../utils/constants";

const id = extractId(3)
const path = pagePath.budget.index
const valueFormat = "yyyy-MM"

const methods = ref(null)
const initialized = ref(false)
const disabled = ref(false)
const form = ref({})

const getBudget = async () => {
  const result = await methods.value.getBudgetDetailEdit(id);
  if(!result){ return false }
  form.value = result
}

const updateBudget = async () => {
  if(disabled.value){ return false }
  disabled.value = true

  const result = await methods.value.updateBudget(id, form.value.budget_name, form.value.monthly_budgets)
  if(!result){
    disabled.value = false
    return false
  }
  setTimeout(transition, 1000)
}

const transition = async () => {
  window.location.href = path + '/' + id + '/detail'
}

onMounted(async () => {
  await getBudget(id)
  initialized.value = true
})

</script>
<style scoped>
.width-30 {
  width: 30%
}
.form-border {
  border: 0.1rem solid;
  border-color: rgb(135,135,135);
  border-radius: 10px
}
</style>

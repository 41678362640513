import axiosBase, { AxiosError, AxiosHeaders } from "axios";
export const UPLOAD_CSV_HEADER = new AxiosHeaders({
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "multipart/form-data"
});
export const DOWNLOAD_CSV_HEADER = new AxiosHeaders({
    "X-Requested-With": "XMLHttpRequest",
    "Content-Disposition": "attachment; filename='data.csv'",
    "Content-Type": "text/csv"
});
export const DOWNLOAD_PDF_HEADER = new AxiosHeaders({
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/pdf",
    "Accept": "application/pdf"
});
export class TsBaseClient {
    DEFAULT_HEADER = new AxiosHeaders({
        "X-Requested-With": "XMLHttpRequest"
    });
    DEFAULT_HTTP_RESPONSE = {
        code: 999,
        data: '',
        successful: false,
        message: '',
        filename: ''
    };
    constructor() { }
    async get(url, params = {}, headers = this.DEFAULT_HEADER, responseType = "json") {
        const options = {
            url: url,
            method: "get",
            params: params
        };
        return await this.exec(options, headers, responseType);
    }
    async post(url, params = {}, headers = this.DEFAULT_HEADER, responseType = "json") {
        const options = {
            url: url,
            method: "post",
            data: params
        };
        return await this.exec(options, headers, responseType);
    }
    async put(url, params = {}, headers = this.DEFAULT_HEADER, responseType = "json") {
        const options = {
            url: url,
            method: "put",
            data: params
        };
        return await this.exec(options, headers, responseType);
    }
    async patch(url, params = {}, headers = this.DEFAULT_HEADER, responseType = "json") {
        const options = {
            url: url,
            method: "patch",
            data: params
        };
        return await this.exec(options, headers, responseType);
    }
    async delete(url, params = {}, headers = this.DEFAULT_HEADER, responseType = "json") {
        const options = {
            url: url,
            method: "delete"
        };
        return await this.exec(options, headers, responseType);
    }
    async exec(options, headers, responseType) {
        const axios = this.axiosClient(options, headers, responseType);
        let result = this.DEFAULT_HTTP_RESPONSE;
        await axios(options).then((response) => {
            result = this.createResultParams(response, responseType);
        })
            .catch((e) => {
            result = this.createErrorParams(e);
        });
        return result;
    }
    axiosClient(options, headers, responseType) {
        return axiosBase.create({
            baseURL: document.location.origin + "/api/v1.0",
            xsrfCookieName: 'XSRF-TOKEN',
            xsrfHeaderName: 'X-XSRF-Token',
            withCredentials: true,
            headers: headers,
            responseType: responseType,
            timeout: options.url?.includes('/budget/estimate/cost_budget') ? 1200000 : 30000
        });
    }
    createResultParams(response, responseType) {
        let result = this.DEFAULT_HTTP_RESPONSE;
        if (responseType != 'blob') {
            result.code = response.data.header.result_code;
            result.message = response.data.header.result_message;
            result.successful = response.data.header.successful;
        }
        else {
            result.filename = this.getFilename(response.headers['content-disposition']);
        }
        result.data = response.data;
        return result;
    }
    getFilename(disposition) {
        if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1])
                return matches[1].replace(/['"]/g, '');
        }
        return '';
    }
    createErrorParams(error) {
        let result = this.DEFAULT_HTTP_RESPONSE;
        console.log(error);
        if (error.code === "ECONNABORTED") {
            // タイムアウト発生
            result.message = "データの取得ができませんでした。再読み込みをしてください。";
        }
        else if (error.response) {
            // リクエストが行われ、サーバーは 2xx の範囲から外れるステータスコードで応答しました
            result.code = error.response.status;
            result.message = 'サーバーでの処理に失敗しました。';
            // if(error.response.status >= 500){
            //   result.message = error.response.data.message ? error.response.data.message : 'サーバーでの処理に失敗しました。'
            // }else if(error.response.data.message){
            //   result.message = error.response.data.message
            // }
        }
        else if (error.request) {
            result.message = "サーバーから応答がありませんでした。";
        }
        else {
            // console.log('Error', error.message);
            result.message = "不明なエラーが発生しました。";
        }
        result.successful = false;
        return result;
    }
}

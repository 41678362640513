<template>
  <Alert ref="alert"/>
</template>
<script setup>
import { ref } from "vue";
import Alert from "./alert_new.vue";
import {ApiClient} from "../lib/axios/mail/api_client"

const client = new ApiClient();
const alert = ref(null);

const emailChange = async (form) => {
  const res = await client.emailChange(form);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return true;
}

const emailChangeConfirm = async (tokenKey) => {
  return await client.emailChangeConfirm(tokenKey);
}

defineExpose({
  emailChange,
  emailChangeConfirm,
})

</script>
<style scoped>

</style>
<template>
  <PasswordMethods ref="passwordMethods"/>
  <NeoidMethods ref="neoidMethods"/>
  <div>
    <div class="card card-login card-hidden" style="height: 600px">
      <div class="card-header text-center my-4">
        <h4 class="card-title">パスワード再設定</h4>
      </div>
      <div class="card-body">
        <template v-if="initialized">
          <template v-if="!isOk">
            <form class="mb-5" name="reset-password" v-on:submit.prevent="changePassword()">
              <div class="form-group mb-5">
                <label for="passwordInput">新しいパスワード</label>
                <input type="password" class="form-control" id="passwordInput" name="password1" maxlength="48" placeholder="8文字以上、大・小文字・記号を必ず1文字以上ずつ含んでください。" autocomplete="off" v-model="newPassword1" @input="validatePassword()">
              </div>
              <div class="form-group mb-5">
                <label for="newPasswordInput">新しいパスワード(確認)</label>
                <input type="password" class="form-control" id="newPasswordInput" name="password2" maxlength="48" placeholder="8文字以上、大・小文字・記号を必ず1文字以上ずつ含んでください。" autocomplete="off" v-model="newPassword2" @input="validatePasswordConfirm()">
              </div>
              <div class="justify-content-start">
                <div class="mb-5">
                  <button type="submit" class="btn btn-primary d-block mx-auto w-100 py-3">パスワードリセット</button>
                </div>
              </div>
              <div v-if="!isPasswordValid" class="justify-content-start">
                <small class="text-danger">パスワードと確認用パスワードが一致しません。</small>
              </div>
            </form>
          </template>
          <template v-else>
            <div class="justify-content-start">
              <div class="mb-2">
                <span>パスワードの再設定が完了しました。</span>
              </div>
              <div class="mb-2">
                <a href="/login" class="d-block mx-auto">ログイン画面へ</a>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="justify-content-start">
            <div class="mb-2">
              <a href="/password/reset" class="d-block mx-auto">パスワード再設定へ</a>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup>
import PasswordMethods from "../../components/password_methods.vue";
import NeoidMethods from "../../components/neoid_methods.vue";
import { onMounted, ref, nextTick } from "vue";
import { extractId } from "../../lib/common";

const passwordMethods = ref(null)
const neoidMethods = ref(null)
const initialized = ref(false)

const tokenKey = ref("")
const email = ref("")
const newPassword1 = ref("")
const newPassword2 = ref("")
const passwordValidated1 = ref(false)
const passwordValidated2 = ref(false)
const isPasswordValid = ref(true)
const isOk = ref(false)
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,48}$/

const validatePassword = () => {
  const form = document.forms["reset-password"]
  if (!newPassword1.value || newPassword1.value === "") {
    form.elements["password1"].setCustomValidity("パスワードを入力してください。")
    passwordValidated1.value = false
  } else if (!passwordRegex.test(newPassword1.value)) {
    form.elements["password1"].setCustomValidity("パスワードの入力形式が正しくありません。")
    passwordValidated1.value = false
  } else {
    form.elements["password1"].setCustomValidity("")
    passwordValidated1.value = true
  }
}

const validatePasswordConfirm = () => {
  const form = document.forms["reset-password"]
  if (!newPassword2.value || newPassword2.value === "") {
    form.elements["password2"].setCustomValidity("パスワードを入力してください。")
    passwordValidated2.value = false
  } else {
    form.elements["password2"].setCustomValidity("")
    passwordValidated2.value = true
  }
}

const changePassword = async () => {
  // パスワード一致しているか判定
  isPasswordValid.value = (newPassword1.value === newPassword2.value)
  if(!isPasswordValid.value){
    return false
  }
  validatePassword()
  validatePasswordConfirm()
  if(passwordValidated1.value === true && passwordValidated2.value === true){
    // 新パスワード
    const resultNew = await neoidMethods.value?.generateRSAKeys(email.value,newPassword1.value, "CHANGE_PASSWORD")

    // 実行
    const param = {
      token_key: tokenKey.value,
      session_key:  resultNew.sessionKey,
      encrypt_string: resultNew.rsaString
    }
    const result = await passwordMethods.value?.resetPassword(param)
    if (!result) {
      return false
    }
    isOk.value = true
  }
}

onMounted(async () => {
  tokenKey.value = extractId(4)
  const result = await passwordMethods.value?.checkTokenKey(tokenKey.value)
  if(!result){
    return false
  }
  email.value = result.email
  initialized.value = true
  nextTick(()=>{
    validatePassword()
    validatePasswordConfirm()
  })
})
</script>

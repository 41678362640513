<template v-if="totalPages > 0">
  <div class="col-auto">
    <nav>
      <ul class="pagination" style="justify-content: center;">
        <!-- 前へ -->
        <li class="page-item">
          <template v-if="pageNumber > 0">
            <a class="page-link" v-on:click.stop.prevent="emitPage(pageNumber - 1)">
              前へ
            </a>
          </template>
          <template v-else>
            <span class="page-link cursor-default"/>
          </template>
        </li>
        <!-- 最初ページ -->
        <li v-if="pageNumber > 2" class="page-item">
          <a class="page-link" v-on:click.stop.prevent="emitPage(0)">
            1
          </a>
        </li>
        <!-- 前省略 -->
        <li v-if="pageNumber > 3" class="page-item disabled">
          <span class="page-link cursor-default">…</span>
        </li>
        <!-- 表示リスト -->
        <template v-for="i in range(pageNumber - 2, pageNumber + 2)">
          <template v-if="0 <= i && i < totalPages">
            <!-- 選択中 -->
            <template v-if="i === pageNumber">
              <li class="page-item active">
                <a class="page-link">
                  {{ i + 1 }}
                </a>
              </li>
            </template>
            <!-- 未選択 -->
            <template v-else>
              <li class="page-item">
                <a class="page-link" v-on:click.stop.prevent="emitPage(i)">
                  {{ i + 1 }}
                </a>
              </li>
            </template>
          </template>
        </template>
        <!-- 後省略 -->
        <li v-if="totalPages > pageNumber + 4" class="page-item disabled">
          <span class="page-link cursor-default">…</span>
        </li>
        <!-- 最後ページ -->
        <li v-if="totalPages > pageNumber + 3" class="page-item">
          <a class="page-link" v-on:click.stop.prevent="emitPage(totalPages - 1)">
            {{ totalPages }}
          </a>
        </li>
        <!-- 次へ -->
        <li class="page-item">
          <template v-if="pageNumber < totalPages - 1">
            <a class="page-link" v-on:click.stop.prevent="emitPage(pageNumber + 1)">
              次へ
            </a>
          </template>
          <template v-else>
            <span class="page-link cursor-default"/>
          </template>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
export default {
  props: {
    pageNumber: Number,
    totalPages: Number
  },
  emits: [
    'emitPage'
  ],
  methods: {
    emitPage(page) {
      this.$emit('emitPage', page)
    },
    range: function (start, stop, step = 1) {
      return Array.from({length: (stop - start) / step + 1}, (_, i) => start + (i * step))
    }
  }
}
</script>
<style scoped>
.cursor-default {
  cursor: default;
}
</style>
<template>
  <Alert ref="alert"/>
</template>
<script setup>
import { ref } from "vue";
import Alert from "./alert_new.vue";
import { ApiClient } from "../lib/axios/user/api_client";

const client = new ApiClient()
const alert = ref(null)

const getUserList = async () => {
  const res = await client.getUserList();
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data;
}

const getUser = async (id) => {
  const res = await client.getUser(id);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data;
}

const editUser = async (id, param) => {
  const res = await client.editUser(id, param);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return true;
}

const deleteUser = async (id) => {
  const res = await client.deleteUser(id);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return true;
}

const createUser = async (form) => {
  const res = await client.createUser(form);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data;
}

const getUserAuthorized = async (tokenKey) => {
  return await client.getUserAuthorized(tokenKey);
}

const createUserAuthorized = async (form) => {
  const res = await client.createUserAuthorized(form);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return true;
}

const getAwsAccounts = async (params) => {
  const res = await client.getAwsAccounts(params);
  if(!res.successful){
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res;
}

const getLoginUserEmail = async () => {
  const res = await client.getLoginUserEmail();
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data;
}

defineExpose({
  getUserList,
  getUser,
  deleteUser,
  editUser,
  createUser,
  createUserAuthorized,
  getUserAuthorized,
  getAwsAccounts,
  getLoginUserEmail
})

</script>
<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <FilterMethods ref="filterMethods"/>
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-title">
            <h4>保存済みフィルター</h4>
            <button @click="createFilter" type="button" class="btn btn-primary">新規作成</button>
            <template v-if="disableEdit">
              <button type="button" class="btn btn-primary disabled cursor-not-allowed">編集</button>
            </template>
            <template v-else>
              <button @click="editFilter(null)" type="button" class="btn btn-primary">編集</button>
            </template>
            <template v-if="disabledBulkDelete">
              <button type="button" class="btn btn-primary disabled cursor-not-allowed">削除</button>
            </template>
            <template v-else>
              <button @click="deleteFilter" type="button" class="btn btn-primary">削除</button>
            </template>
            <button @click="lockFilter(selected.join(','))"  type="button" :class="`btn btn-primary cursor-not-allowed ${disabledBulkLock ? ' disabled' : ''}`">ロック</button>
            <button @click="unlockFilter(selected.join(','))" type="button" :class="`btn btn-primary ${disabledBulkUnlock ? ' disabled' : ''}`">ロック解除</button>
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-no-bordered table-hover text-left" cellspacing="0" width="100%" style="width:100%">
              <thead>
              <tr>
                <th></th>
                <th>フィルター名</th>
                <th>ロック</th>
                <th>詳細</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in filterList">
                <td class="text-center">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input @change="checkBulkCheckbox" class="form-check-input" type="checkbox" :value="item.id" v-model="selected">
                      <span class="form-check-sign"><span class="check"></span></span>
                    </label>
                  </div>
                </td>
                <td v-on:click="editFilter(item.id)" class="text-info cursor-pointer">
                  {{ item.name }}
                </td>
                <td>
                  <template v-if="item.filter_lock">
                    <span v-on:click="unlockFilter(item.id)" class="cursor-pointer text-info">
                      <i class="material-icons">lock</i>
                    </span>
                  </template>
                  <template v-else>
                    <span v-on:click="lockFilter(item.id)" class="cursor-pointer text-info">
                      <i class="material-icons">lock_open</i>
                    </span>
                  </template>
                </td>
                <td class="exception-td-size"><span>{{ createDisplayFilterValue(item.filter) }}</span></td>
              </tr>
              </tbody>
            </table>
            <Pagination ref="pagination" :pageNumber.number="pageNumber" :totalPages.number="totalPages" v-on:emitPage="getFilterList"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as constants       from '../utils/constants'
import FilterMethods from "../components/filter_methods";
import {allColumns} from "../utils/filter_column_list";
import Pagination from "../components/pagination.vue";

export default {
  components: {
    Pagination,
    'FilterMethods': FilterMethods
  },
  mounted: async function() {
    await this.getFilterList()
  },
  data: function () {
    return {
      path: constants.pagePath.dashboardFilter,
      filterList: [],
      columnList: allColumns,
      selected:  [],
      pageNumber: 0,
      totalPages: 0,
      nowPage: 0,
      disabledBulkDelete: true,
      disabledBulkLock: true,
      disabledBulkUnlock: true
    }
  },
  computed: {
    disableEdit() {
      if (this.selected.length !== 1){ return true }

      for(const id of this.selected){
        let index = this.findIndex(id)
        if(this.filterList[index].filter_lock){ return true }
      }
      return false
    }
  },
  methods: {
    createFilter: function(){
      window.location.href = this.path.new
    },
    editFilter: async function (id) {
      if (id === null) {
        window.location.href = this.path.edit + "?filterId=" + this.selected[0]
      } else {
        window.location.href = this.path.edit + "?filterId=" + id
      }
    },
    initializeBulkCheckbox: function () {
      this.disabledBulkDelete = true;
      this.disabledBulkLock = true;
      this.disabledBulkUnlock = true;
    },
    checkBulkCheckbox: function () {
      this.initializeBulkCheckbox();

      let lockTypes = this.selected.map((id) => {
        let index = this.findIndex(id)
        return this.filterList[index].filter_lock;
      }).filter(function(elem, index, self) {
        return self.indexOf(elem) === index;
      });

      // 未選択、もしくはLockとUnlockのデータが選ばれた場合、まとめて実行はできない
      if (lockTypes.length !== 1) return;

      // ロックされていないデータのみ選択
      if (lockTypes[0] === false) {
        this.disabledBulkDelete = false;
        this.disabledBulkLock = false;
        // ロックされているデータのみ選択
      } else {
        this.disabledBulkUnlock = false;
      }
    },
    getFilterList: async function(page=0) {
      const result = await this.$refs.filterMethods.getFilterList(page)
      if(result){
        this.$nextTick(async () => {
          this.selected = []
          this.filterList = result.filters
          this.pageNumber = result.page_number
          this.totalPages = result.total_pages
          this.nowPage = page
        })
      }
    },
    deleteFilter: async function() {
      if (!window.confirm("本当に削除しますか?")) return;
      const ids = this.selected.join(',');
      const result = await this.$refs.filterMethods.deleteFilter(ids)
      if(result){
        await this.getFilterList()

        this.selected = []
        this.initializeBulkCheckbox()
      }
    },
    lockFilter: async function(ids){
      const result = await this.$refs.filterMethods.lockFilter(ids)
      if(result){
        await this.getFilterList(this.nowPage)

        this.selected = []
        this.initializeBulkCheckbox()
      }
    },
    unlockFilter: async function(ids){
      const result = await this.$refs.filterMethods.unlockFilter(ids)
      if(result){
        await this.getFilterList(this.nowPage)

        this.selected = []
        this.initializeBulkCheckbox()
      }
    },
    findIndex(id){
      return this.filterList.findIndex(item => item.id === id)
    },
    createDisplayFilterValue: function(filter){
      let result = ""
      this.columnList.forEach(item => {
        if(typeof  filter[item.key] === "undefined"){ return }
        if(filter[item.key].values.length > 0){
          const title = filter[item.key].condition === "include" ? `${item.title} (含む)` : `${item.title} (除く)`
          result = `${result} 【${title}】: ${filter[item.key].values.join(", ")} `
        }
      })
      return result
    }
  }
}
</script>

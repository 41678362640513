<template>
  <Alert ref="alert"/>
  <div v-if="!loading.initialized">
    <div class="loader">Loading...</div>
    <ReportMethods ref="reportMethods" :reportType="serviceType.detail"/>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="tab-description" v-if="serviceType.type === 'vpc'">
          料金が発生していないリージョンの情報については表示しません
        </div>
        <div class="card">
          <div class="card-body">
            <IframeDashboard ref="dashboard" height="400px"/>
          </div>
        </div>
        <tableAssetUsageDetail :reportType="serviceType.detail" v-model:tableItem="dashboardTableItem" v-model:loading="this.loading" v-on:emitTrigger="updateDashboardTableItem" v-on:getDashboardElement="getDashboardElement"/>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from "./alert_new.vue";
import * as constants       from '../utils/constants'
import ReportMethods from "../components/report_methods.vue";
import IframeDashboard from "../components/iframe_dashboard.vue";
import tableAssetUsageDetail     from '../components/table_asset_usage_detail.vue'
import { getDashboardElementAssetDetailDashboard } from "../lib/axios/api_client";

export default {
  props: ['serviceType'],
  components: {
    'ReportMethods'        : ReportMethods,
    'IframeDashboard'      : IframeDashboard,
    'tableAssetUsageDetail'     : tableAssetUsageDetail,
    'Alert' : Alert
  },
  data: function () {
    return {
      aws_report: {},
      dashboardTableItem: [],
      loading: {
        initialized: false,
        assetUsageDetailTable: false
      },
    }
  },
  mounted: async function(){
    const report = await this.$refs.reportMethods.getReport(0, this.serviceType.detail)
    if(!report){ return false }
    this.aws_report = report

    const result = await this.getDashboardElement()
    if(result){
      this.loading.initialized = true

      this.$nextTick(async () => {
        await this.$refs.dashboard.generateDashboard(this.aws_report)
      })
    }
  },
  methods: {
    getDashboardElement: async function(){
      this.loading.assetUsageDetailTable = true

      const params = {
        report_type: this.serviceType.detail,
        filter: this.aws_report.filter
      }

      const res = await getDashboardElementAssetDetailDashboard(params)
      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      this.loading.assetUsageDetailTable = false
      this.dashboardTableItem = res.data.filter_item
      return true
    },
    updateDashboardTableItem(newItem){
      this.dashboardTableItem = newItem
    }
  }
}
</script>
<style scoped>
.tab-description {
  position: absolute;
}
</style>

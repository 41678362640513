import { TsBaseClient, } from "../ts_base_client";
export class ApiClient extends TsBaseClient {
    constructor() {
        super();
    }
    async emailChange(params) {
        const url = `user/mail/change`;
        return await this.post(url, params);
    }
    async emailChangeConfirm(params) {
        const url = `user/mail/change/confirm`;
        return await this.put(url, params);
    }
}

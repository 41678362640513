<template>
  <div v-if="showModal">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">
                「正規化された単位」の設定について
              </slot>
            </div>
            <div class="modal-body">
              <p>「正規化された単位」は以下の設定でのみ機能します。</p>
              <p>
                ・サービス: Elastic Computing Cloud(EC2)<br>
                ・プラットフォーム: Linux/UNIX<br>
                ・テナンシー: Shared<br>
                <template v-if="reportType === riUtilization" >
                  ・サイズの柔軟性: Flexible<br>
                </template>
              </p>
              <p>そのためフィルターに上記の設定に書き換えます。問題なければ「はい」を押して下さい</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="triggerEmit(false)">いいえ</button>
              <button type="button" class="btn btn-primary" @click="triggerEmit(true)">はい</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import * as constants from '../utils/constants'

export default {
  props: {
    reportType: String
  },
  data: function() {
    return {
      riUtilization: constants.reportTypeRiUtilization,
      showModal: false
    }
  },
  methods: {
    displayModal() {
      this.showModal = true
    },
    triggerEmit(bool) {
      this.showModal = false
      this.$emit('triggerModal', bool)
    }
  }
}

</script>
<template>
  <div class="right-sizing-table">
    <div class="card">
      <div class="card-header">
        <div class="pull-right">
          <DropdownDownload :reportType="type" :tableItem="tableItem" :keys="scenarioSummaryRiSpKeys" :titles="scenarioSummaryRiSpTitles"/>
        </div>
      </div>
      <div class="card-body">
        <table class="table table-striped table-hover">
          <thead class="sticky-top">
          <tr>
            <template v-for="elements in scenario_summary_elements">
              <TableTh :tableKey="elements.key" v-on:emitTrigger="doSort">{{ elements.title }}</TableTh>
            </template>
          </tr>
          </thead>
          <tbody>
          <template v-if="tableItem.length">
            <template v-for="(item, index) in tableItem">
              <tr>
                <template v-for="elements in scenario_summary_elements">
                  <td>{{ isNaN(item[elements.key])? item[elements.key] : item[elements.key].toFixed(2) }}</td>
                </template>
              </tr>
            </template>
          </template>
          <template v-else>
            <tr>
              <td colspan="4">
                サマリーの結果がありません
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import tableTh from "./table_th.vue";
import { findTableHeaderIndex, selectSort } from "../lib/table";
import { TableKeyScenarioSummaryRi, TableKeyScenarioSummarySp } from "../utils/simulator.js";
import dropdownButtonDownload from "./dropdown_download.vue"

export default  {
  props: ['tableItem', 'type'],
  components: {
    TableTh: tableTh,
    DropdownDownload: dropdownButtonDownload
  },
  data: function () {
    return {
      scenario_summary_elements: []
    }
  },
  computed: {
    scenarioSummaryRiSpKeys() {
      return this.scenario_summary_elements.map(item => item.key)
    },
    scenarioSummaryRiSpTitles() {
      return this.scenario_summary_elements.map(item => item.title)
    }
  },
  mounted() {
    this.scenario_summary_elements = (this.type === 'Reserved') ? TableKeyScenarioSummaryRi : TableKeyScenarioSummarySp
  },
  methods: {
    doSort(columnKey) {
      const index = findTableHeaderIndex(this.scenario_summary_elements, columnKey)
      const isSort = this.scenario_summary_elements[index].sort
      const sort = selectSort(isSort, columnKey);

      this.tableItem.sort(sort)
      this.scenario_summary_elements[index].sort = !isSort
    }
  }
}
</script>
<style scoped>
</style>

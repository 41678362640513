<template>
  <Alert ref="alert"/>
  <div v-if="!initialized">
    <div class="loader">Loading...</div>
  </div>
  <div v-else>
    <form class="card repeated_hit_prevention_form">
      <div class="card-body">
        <div style="display: flex;justify-content: space-between;">
          <div style="display: flex;" class="text-left">
            <template v-if="permissions.show_edit_button">
              <a class="btn btn-primary" :href="`/mypage/settings/aws_accounts/${awsAccount.aws_account_id}/edit`">編集</a>
            </template>
            <template v-if="permissions.show_delete_button">
              <button type="button" class="btn btn-primary" v-on:click="deleterAwsAccounts()">削除</button>
            </template>
          </div>
          <div class="text-right">
            <a class="btn default" href="/mypage/settings/aws_accounts">一覧</a>
          </div>
        </div>
        <table class="table table-borderless mt-5">
          <tbody>
          <tr>
            <th>AWSアカウントID</th>
            <td>{{ awsAccount.aws_account_id }}</td>
          </tr>
          <tr>
            <th>別名</th>
            <td>{{ awsAccount.alias_name }}</td>
          </tr>
          <template v-if="permissions.show_organization_integration">
            <tr>
              <th>Organizationsへの統合の有無</th>
              <td>
                <input type="checkbox" v-model="awsAccount.linked" readonly onclick="return false;" onfocus="this.blur();">
              </td>
            </tr>
          </template>
          <template v-if="permissions.show_admin_account">
            <tr>
              <th>管理アカウント</th>
              <td>{{ awsAccount.payer_account_id }}</td>
            </tr>
          </template>
          <tr>
            <th>ステータス</th>
            <td>{{ statusValue(awsAccount.status) }}</td>
          </tr>
          <tr>
            <th>IAM Role ARN</th>
            <td>{{ awsAccount.iam_role_arn }}</td>
          </tr>
          <tr>
            <th>バーション</th>
            <td>{{ versionValue(awsAccount.version) }}</td>
          </tr>
          <template v-if="permissions.show_create_role">
            <tr>
              <th>Role作成・更新</th>
              <td>
                <template v-if="((awsAccount.status === 'done' && awsAccount.version === 'new') || awsAccount.status === 'setting')">
                </template>
                <template v-else>
                  <a class="text-info" :href="`/mypage/settings/aws_accounts/role/${awsAccount.aws_account_id}/edit`">
                    <i class="material-icons">description</i>
                  </a>
                </template>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </form>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue"
import { getAwsAccount, getLinkedAwsAccounts, deleteAwsAccounts } from "../lib/axios/api_client"
import { status, version } from "../utils/aws_accounts";
import Alert from "../components/alert_new.vue"

const alert = ref(null)
const statusList = status
const versionList = version

const initialized = ref(false)
const awsAccount = ref({})
const linkedAwsAccounts = ref([])
const permissions = ref({})

const statusValue = (target) => {
  const status = statusList.find(item => target === item.value)
  return status.name
}

const versionValue = (target) => {
  const version = versionList.find(item => target === item.value)
  return version.name
}

const getterAwsAccount = async () => {
  const res = await getAwsAccount(_getId())
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }
  awsAccount.value = res.data.aws_accounts
  permissions.value = res.data.permissions
}

const getterLinkedAwsAccounts = async () => {
  const res = await getLinkedAwsAccounts(_getId())
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }
  linkedAwsAccounts.value = res.data.linked_account_ids
}

const judgeDelete = async () => {
  await getterLinkedAwsAccounts()
  if (awsAccount.value.linked || (!awsAccount.value.linked && !linkedAwsAccounts.value.length)) {
    if (!window.confirm('本当に削除しますか？')) return false
  } else {
    if (!window.confirm(deleteMessage())) return false
  }
  return true
}

const deleteMessage = () => {
  return `このアカウントに紐づいたLinkedアカウントも一緒に削除します。よろしいですか？\n一緒に削除されるアカウント：${linkedAwsAccounts.value.join()}`
}

const deleterAwsAccounts = async () => {
  if (!await judgeDelete()) return
  const params = { aws_account_ids: [awsAccount.value.aws_account_id] }
  const res = await deleteAwsAccounts(params)
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }
  alert.value.displayModal('削除しました。','success')
  setTimeout(_transition, 1000)
}

const _getId = () => {
  const pathArray = location.pathname.split("/")
  if(pathArray[4] === "undefined" ){
    return false
  }
  return pathArray[4]
}

const _transition = () => {
  window.location.href = '/mypage/settings/aws_accounts'
}

onMounted( async () => {
  await getterAwsAccount()

  initialized.value = true
})

</script>
<style scoped>
</style>
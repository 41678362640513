<template>
  <Alert ref="alert"/>
  <div v-if="!initialized">
    <div class="loader">Loading...</div>
  </div>
  <div v-else>
    <div class="col-auto">
      <nav>
        <ul class="nav" style="justify-content: center;">
          <li class="nav-item">
            <a
                :class="`nav-link btn btn-sm${alert.previous_id ? '' : ' disabled'}`"
                :disabled="!alert.previous_id"
                :href="alertDetailUrl(alert.previous_id)"
            >
              <i class="material-icons">navigate_before</i>前へ
            </a>
          </li>
          <li class="nav-item">
            <a
                :class="`nav-link btn btn-sm${alert.next_id ? '' : ' disabled'}`"
                :disabled="!alert.next_id"
                :href="alertDetailUrl(alert.next_id)"
            >
              次へ<i class="material-icons">navigate_next</i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="text-right">
          <a :href="alertListUrl()" class="btn btn-default btn-sm">一覧</a>
        </div>
        <table class="table table-borderless alert-detail">
          <tbody>
          <tr>
            <th>掲載日</th>
            <td>{{ alert.release_date }}</td>
          </tr>
          <tr>
            <th>種別</th>
            <td>{{ categoryType.name }}</td>
          </tr>
          <tr>
            <th>タイトル</th>
            <td>{{ alert.title }}</td>
          </tr>
          <tr>
            <th>内容</th>
            <td v-html="alert.html_contents"></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import Alert from "../components/alert_new.vue";
import { getNotice } from "../lib/axios/api_client";
import { extractAlertCategoryType, extractPageNumber } from "../lib/common";
import { categoryTypes } from "../utils/alert";

export default {
  components: {
    Alert
  },
  data() {
    return {
      alert: {},
      categoryType: {},
      pageNumber: "",
      initialized: false,
    }
  },
  mounted: async function() {
    const categoryType = extractAlertCategoryType();
    if(!categoryType){
      return false
    }
    this.categoryType = categoryTypes.find( item => item.type === categoryType)

    const id = this._getId()
    const result = await this.getAlert(id, categoryType)
    if(!result){
      return false
    }
    this.alert = result
    this.pageNumber = extractPageNumber()
    this.initialized = true
  },
  methods: {
    alertListUrl(){
      return `/mypage/alerts?type=${this.categoryType.type}&page=${this.pageNumber}`
    },
    alertDetailUrl(id){
      return `/mypage/alerts/${id}?type=${this.categoryType.type}&page=${this.pageNumber}`
    },
    getAlert: async function (id, categoryType) {
      const res = await getNotice(id, categoryType)
      if (!res.successful) {
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      return res.data.notice
    },
    _getId(){
      const pathArray = location.pathname.split("/")
      if(pathArray[3] === "undefined" ){
        return false
      }
      return pathArray[3]
    }
  }
}
</script>

<template>
  <Alert ref="alert"/>
</template>
<script setup>
import { ref } from "vue";
import Alert from "./alert_new.vue";
import { ApiClient, ExApiClient } from "../lib/axios/neoid/api_client";
import {RSAEncrypt, RSASetPublic} from "../lib/neoid";

const client = new ApiClient()
const exClient = new ExApiClient()
const alert = ref(null)

const getRsaSessionKey = async (ip, type) => {
  const res = await client.getRsaSessionKey(ip, type);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data
}

const getIpAddress = async () => {
  const res = await exClient.getIpAddress();
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data.replace("\n","")
}

async function generateRSAKeys(email, password, type){

  // 1. ip取得
  const result1 = await getIpAddress()
  if (!result1) {
    return false
  }
  const ip = result1

  // 2.セッションキーの取得
  const result2 = await getRsaSessionKey(ip, type)
  if(!result2){
    return false
  }

  // 3. 暗号化
  const publicKeyModulus = result2.body.public_key_modulus
  const publicKeyExponent = result2.body.public_key_exponent
  const sessionKey = result2.body.session_key
  const rsaString = encryptString(publicKeyModulus, publicKeyExponent, sessionKey, email, password)

  // 4. 終了
  return {
    ip: ip,
    sessionKey: sessionKey,
    rsaString: rsaString
  }
}

const encryptString = (publicKeyModulus, publicKeyExponent, sessionKey, email, password) => {
  const param = RSASetPublic(publicKeyModulus, publicKeyExponent)
  const rsaStringOrigin = getLenChar(sessionKey) + sessionKey + getLenChar(email) + email + getLenChar(password) + password;
  return RSAEncrypt(param.n, param.e, rsaStringOrigin)
}

defineExpose({
  getRsaSessionKey,
  getIpAddress,
  generateRSAKeys,
  encryptString
})

const getLenChar = (texts) => {
  let texts2 = texts + ''
  return String.fromCharCode(texts2.length);
}

</script>

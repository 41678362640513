<template>
  <div v-if="!initialized">
    <Alert ref="alert"/>
    <div class="loader">Loading...</div>
  </div>
  <div v-else>
    <Alert ref="alert"/>
    <SupportMethod ref="supportMethod"/>
    <div class="card">
      <div class="card-body">
        <div class="flex-container-center">
          <div class="default-width">
            <div class="flex-container">
              <div class="text-left">
              </div>
              <div class="text-right">
                <a class="btn default" href="/mypage/settings/aws_accounts">一覧</a>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-container-center">
          <table class="table table-borderless mt-3 table-width" id="step" v-if="initialized">
            <tbody>
            <tr>
              <th class="align-top"></th>
              <td>
                <div>AWSマネジメントコンソールから対象のAWSアカウントにサインインして下さい。</div>
              </td>
            </tr>
            <template v-if="awsAccount.version !== 'none'">
              <tr>
                <th class="align-top"></th>
                 <td>
                  <p>既存のIAMロールを削除します。</p>
                  <p>AWSマネジメントコンソールのCloudFormationの画面で、以下のスタックを削除してください。</p>
                  <p>スタック名： nhn-techorus-cloud-illuminator-cross-account-access</p>
                  <p>詳細な手順は以下のFAQを参照してください。</p>
                  <p class="pl-3">
                    <template v-if="permissions.ext_zendesk_url">
                      <a href="#" @click.prevent="zendeskButtonClick(iamRole.delete)">IAMロールの削除</a>
                    </template>
                    <template v-else>
                      <a :href="iamRole.delete" target="_blank">IAMロールの削除</a>
                    </template>
                  </p>
                </td>
              </tr>
            </template>
            <tr>
              <th class="align-top"></th>
              <td>
                <p>IAMロールを作成します。</p>
                <p>外部IDは設定に必要になりますので、コピーをしておいて下さい。</p>
                <div class="my-5 pl-3">
                  <div class="form-input-read-only">
                    <input class="form-control" type="text" :placeholder="externalId" readonly>
                  </div>
                  <div class="inline-block">
                    <span class="btn btn-primary ml-3 btn-copy" v-on:click="writeClipBoard(externalId)">{{ textCopy.display }}</span>
                  </div>
                </div>
                <p>
                  以下の手順を実行して、IAMロールの作成を行ってください。
                </p>
                <div>
                  <div class="mt-3">
                    <p>AWSマネジメントコンソールのCloudFormationの画面が表示されます。</p>
                    <div class="pl-3">
                      <div class="btn btn-default btn-width" v-on:click="createIamRole">
                        IAMロールの作成
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <p>詳細な手順は以下のFAQを参照してください。</p>
                    <p class="pl-3">
                      <template v-if="permissions.ext_zendesk_url">
                        <a href="#" @click.prevent="zendeskButtonClick(iamRole.index)">IAMロールの作成</a>
                      </template>
                      <template v-else>
                        <a :href="iamRole.index" target="_blank">IAMロールの作成</a>
                      </template>
                    </p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th class="align-top"></th>
              <td>
                <p>IAMロールの検証</p>
                <p>IAMロール: nhn-techorus-cloud-illuminator-cross-account-access の作成が完了したら、以下のボタンを押下して動作確認をお願いします。</p>
                <div class="pl-3">
                  <div class="btn btn-primary btn-width"  v-on:click="confirmIamRole">
                    設定確認
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import Alert from "../components/alert_new.vue";
import SupportMethod from "../components/support_method.vue";
import {getAwsAccount, getExternalId, validateAwsAccount} from "../lib/axios/api_client";
import * as constants from '../utils/constants'

const alert = ref(null)
const supportMethod = ref(null);

const initialized = ref(false)
const awsAccount = ref({})
const externalId = ref("")
const textCopy = ref({})
textCopy.value = {
  display: "Copy",
  copy: "Copy",
  copied: "Copied!"
}
const iamRole = ref({})
const permissions = ref({})

const iAmRoleGuidePath = () => {
  let result = permissions.value.ext_zendesk_url? constants.pagePath.iamRoleExt : constants.pagePath.iamRole
  iamRole.value = {
    index: result.index,
    delete: result.delete
  }
}

const iAmRoleSettingPath = () => {
  return constants.pagePath.iAmRoleSetting[process.env.ENV_TYPE]
}

// ローカル(http)では操作しない
const writeClipBoard = (text) => {
  navigator.clipboard.writeText(text)
  textCopy.value.display = textCopy.value.copied
}

const createIamRole = () => {
  window.open(iAmRoleSettingPath())
}

const confirmIamRole = async () => {
  const res = await validateAwsAccount(_getId())
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }

  if(res.data.is_success){
    alert.value.displayModal("IAM Roleの作成ができています。", 'success')
    setTimeout(_transition, 1000)
  }else {
    alert.value.displayModal("IAM Roleの作成ができていません。", 'error')
  }
}

const getterAwsAccount = async () => {
  const res = await getAwsAccount(_getId())
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }
  awsAccount.value = res.data.aws_accounts
  permissions.value = res.data.permissions
}

const getterExternalId = async () => {
  const res = await getExternalId()
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }

  externalId.value = res.data.external_id
  return true
}

const zendeskButtonClick = async (returnTo) => {
  const zendeskUrl = constants.pagePath.zendeskUrl[process.env.ENV_TYPE]
  const param = { return_to : zendeskUrl + returnTo }
  const result = await supportMethod.value?.getZendeskSupportUrl(param);
  window.open(result.url, "_blank");
}

const _transition = () => {
  window.location.href = `/mypage/settings/aws_accounts`
}

const _getId = () => {
  const pathArray = location.pathname.split("/")
  if(pathArray[5] === "undefined" ){
    return false
  }
  return pathArray[5]
}

onMounted( async () => {
  await getterAwsAccount()
  await getterExternalId()
  iAmRoleGuidePath()

  initialized.value = true
})
</script>
<style scoped>
.radio-text-color {
  color: #333
}
.flex-container{
  display:flex;
  justify-content: space-between
}
.flex-container-center{
  display:flex;
  justify-content:center;
}
.btn-copy {
  min-width: 110px
}
.inline-block {
  display: inline-block;
}
.form-input-read-only{
  display: inline-block;
  min-width: 290px;
  max-width: 290px
}
.page-title {
  font-size: 1.2rem;
  margin: auto 0
}
.default-width {
  width: 1200px
}
.table-width {
  max-width: 1200px
}
.btn-width {
  width: 150px
}
table#step tr:nth-child(1) th:first-child::before {
  content: "Step 1";
}
table#step tr:nth-child(2) th:first-child::before {
  content: "Step 2";
}
table#step tr:nth-child(3) th:first-child::before {
  content: "Step 3";
}
table#step tr:nth-child(4) th:first-child::before {
  content: "Step 4";
}
</style>

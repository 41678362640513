document.addEventListener('DOMContentLoaded', () => {
    if(document.URL.match("\/mypage\/home\/dashboard")) {
        $('#home-collapsed').addClass("show")
        $('#sidebar-home-dashboard').addClass("active")
    }

    if(document.URL.match("\/mypage\/home\/report")) {
        $('#home-collapsed').addClass("show")
        $('#sidebar-home-report').addClass("active")
    }

    if(document.URL.match("\/mypage\/home\/filter")) {
        $('#home-collapsed').addClass("show")
        $('#sidebar-home-filter-list').addClass("active")
    }

    if(document.URL.match("\/mypage\/home\/filter\/new")) {
        $('#home-collapsed').addClass("show")
        $('#sidebar-home-filter-list').addClass("active")
    }

    if(document.URL.match("\/mypage\/home\/filter\/edit")) {
        $('#home-collapsed').addClass("show")
        $('#sidebar-home-filter-list').addClass("active")
    }

    if(document.URL.match("\/mypage\/cost_visualization\/cost_viewer")) {
        $('#cv-collapsed').addClass("show")
        $('#sidebar-cv-cost-viewer').addClass("active")
    }

    if(document.URL.match("\/mypage\/cost_visualization\/reserved_usage")) {
        $('#cv-collapsed').addClass("show")
        $('#sidebar-cv-ri-utilization').addClass("active")
    }

    if(document.URL.match("\/mypage\/cost_visualization\/reserved_coverage")) {
        $('#cv-collapsed').addClass("show")
        $('#sidebar-cv-ri-coverage').addClass("active")
    }

    if(document.URL.match("\/mypage\/cost_visualization\/savings_plans_usage")) {
        $('#cv-collapsed').addClass("show")
        $('#sidebar-cv-sp-utilization').addClass("active")
    }

    if(document.URL.match("\/mypage\/cost_visualization\/savings_plans_coverage")) {
        $('#cv-collapsed').addClass("show")
        $('#sidebar-cv-sp-coverage').addClass("active")
    }

    if(document.URL.match("\/mypage\/asset_visualization\/summary")) {
        $('#av-collapsed').addClass("show")
        $('#sidebar-av-summary').addClass("active")
    }

    if(document.URL.match("\/mypage\/asset_visualization\/usage")) {
        $('#av-collapsed').addClass("show")
        $('#sidebar-av-usage').addClass("active")
    }

    if(document.URL.match("\/mypage\/recommend\/reserved")) {
        $('#recommend-collapsed').addClass("show")
        $('#sidebar-recommend-ri').addClass("active")
    }

    if(document.URL.match("\/mypage\/recommend\/savings_plans")) {
        $('#recommend-collapsed').addClass("show")
        $('#sidebar-recommend-sp').addClass("active")
    }

    if(document.URL.match("\/mypage\/recommend\/simulator")) {
        $('#recommend-collapsed').addClass("show")
        $('#sidebar-recommend-simulator').addClass("active")
    }

    if(document.URL.match("\/mypage\/rightsizing\/ec2")) {
        $('#right-sizing-collapsed').addClass("show")
        $('#sidebar-right-sizing-ec2').addClass("active")
    }

    if(document.URL.match("\/mypage\/rightsizing\/ec2\/detail")) {
        $('#right-sizing-collapsed').addClass("show")
        $('#sidebar-right-sizing-ec2').addClass("active")
    }

    if(document.URL.match("\/mypage\/rightsizing\/ebs")) {
        $('#right-sizing-collapsed').addClass("show")
        $('#sidebar-right-sizing-ebs').addClass("active")
    }

    if(document.URL.match("\/mypage\/rightsizing\/ebs\/detail")) {
        $('#right-sizing-collapsed').addClass("show")
        $('#sidebar-right-sizing-ebs').addClass("active")
    }

    if(document.URL.match("\/mypage\/rightsizing\/eip")) {
        $('#right-sizing-collapsed').addClass("show")
        $('#sidebar-right-sizing-eip').addClass("active")
    }

    if(document.URL.match("\/mypage\/rightsizing\/eip\/detail")) {
        $('#right-sizing-collapsed').addClass("show")
        $('#sidebar-right-sizing-eip').addClass("active")
    }

    if(document.URL.match("\/mypage\/rightsizing\/elb")) {
        $('#right-sizing-collapsed').addClass("show")
        $('#sidebar-right-sizing-elb').addClass("active")
    }

    if(document.URL.match("\/mypage\/rightsizing\/elb\/detail")) {
        $('#right-sizing-collapsed').addClass("show")
        $('#sidebar-right-sizing-elb').addClass("active")
    }

    if(document.URL.match("\/mypage/\alert/\setting\/cost")) {
        $('#alert-collapsed').addClass("show")
        $('#sidebar-alert_cost').addClass("active")
    }

    if(document.URL.match("\/mypage/\alert/\setting\/ri_sp")) {
        $('#alert-collapsed').addClass("show")
        $('#sidebar-alert_ri_sp').addClass("active")
    }

    if(document.URL.match("\/mypage/\alert/\setting\/rightsizing")) {
        $('#alert-collapsed').addClass("show")
        $('#sidebar-alert_rightsizing').addClass("active")
    }

    if(document.URL.match("\/mypage/\alert/\setting\/budget")) {
        $('#alert-collapsed').addClass("show")
        $('#sidebar-alert_budget').addClass("active")
    }

    if(document.URL.match("\/mypage/\alert/\setting\/rightsizing\/exclusion")) {
        $('#alert-collapsed').addClass("show")
        $('#sidebar-alert_rightsizing').addClass("active")
    }

    if(document.URL.match("\/mypage\/report_subscriptions")) {
        $('#sidebar-report_subscriptions').addClass("active")
    }

    if(document.URL.match("\/mypage\/settings\/aws_accounts")) {
        $('#settings-collapsed').addClass("show")
        $('#sidebar-aws_account').addClass("active")
    }

    if(document.URL.match("\/mypage\/settings\/tags")) {
        $('#settings-collapsed').addClass("show")
        $('#sidebar-tags').addClass("active")
    }

    if(document.URL.match("\/mypage\/budget")) {
        $('#sidebar-budget').addClass("active")
    }

    if(document.URL.match("\/mypage\/support")) {
        $('#sidebar-support').addClass("active")
    }

    //change sign_up_id
    $("#sign_up_id").change(function(){
        $('#sign_up_id_form').submit();
    });
})

<template>
  <template v-if="route === 'form'">
    <SignupForm :form="form" :formDetails="formDetails" v-on:emitTrigger="router"/>
  </template>
  <template v-else>
    <SignupConfirm :form="form" :formDetails="formDetails" v-on:emitTrigger="router"/>
  </template>
</template>

<script setup>
import '../../../stylesheet/signup.css'
import { onMounted, ref } from "vue";
import { formatDate } from "../../lib/calendar";
import SignupForm from "./pseudo_pages/signup_form.vue";
import SignupConfirm from "./pseudo_pages/signup_confirm.vue";

let route = ref('')
let formDetails = ref({})
formDetails.value = {
  signup_date: "",
  show_password_tab: true
}
let form = ref({})
form.value = {
  company: {
    name: "",
    kana: "",
    representative: "",
    url: "",
    establishment_date: "",
    capital: "",
    recent_year_sales: "",
    number_of_employees: "",
  },
  contact: {
    name: "",
    kana: "",
    department_name: "",
    postal_code: "",
    prefecture_code: "",
    city: "",
    street_address: "",
    building: "",
    phone: "",
    email: "",
  },
  billing: {
    name: "",
    kana: "",
    department_name: "",
    postal_code: "",
    prefecture_code: "",
    city: "",
    street_address: "",
    building: "",
    phone: "",
    email: "",
  },
  same:true,
  password: "",
  token_key: ""
}

const router = (propForm, propFormDetails) => {
  const pathArray = location.pathname.split("/")
  if(pathArray[2] === "confirm") {
    form = propForm
    formDetails = propFormDetails
    route.value = 'confirm'
  } else {
    route.value = 'form'
  }
}

window.addEventListener('popstate', function(e) {
  router()
})

onMounted(async () => {
  router()
})
</script>

<style scoped>
</style>
<template>
  <div>
    <ServiceAccountMethods ref="methods"/>
    <div v-if="!initialized">
      <div class="loader">Loading...</div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div>
            <ul class="nav">
              <li class="nav-item" v-if="payment.permissions.show_edit_button">
                <a
                  class="nav-link btn btn-primary"
                  href="/mypage/service-account/payment/edit"
                >
                  編集
                </a>
              </li>
            </ul>
          </div>
          <div class="card">
            <div class="card-header card-header-rose card-header-icon">
              <div class="card-title">
                <h4>請求先情報</h4>
              </div>
            </div>
            <div class="card-body">
              <table class="table table-borderless table-responsive">
                <tbody>
                <tr>
                  <th>ご担当者名</th>
                  <td>{{ payment.billing.name }}</td>
                </tr>
                <tr>
                  <th>ご担当者名(フリガナ)</th>
                  <td>{{ payment.billing.kana }}</td>
                </tr>
                <tr>
                  <th>部署名</th>
                  <td>{{ payment.billing.department_name }}</td>
                </tr>
                <tr>
                  <th>郵便番号</th>
                  <td>{{ payment.billing.postal_code }}</td>
                </tr>
                <tr>
                  <th>都道府県</th>
                  <td>{{ prefName }}</td>
                </tr>
                <tr>
                  <th>市区町村</th>
                  <td>{{ payment.billing.city }}</td>
                </tr>
                <tr>
                  <th>町名・番地</th>
                  <td>{{ payment.billing.street_address }}</td>
                </tr>
                <tr>
                  <th>建築物名等</th>
                  <td>{{ payment.billing.building }}</td>
                </tr>
                <tr>
                  <th>電話番号</th>
                  <td>{{ payment.billing.phone }}</td>
                </tr>
                <tr>
                  <th>メールアドレス</th>
                  <td>{{ payment.billing.email }}</td>
                </tr>
                </tbody>
              </table>
              <div class="mt-3 caution-message">
                <div>
                  ※ 「楽楽明細の通知メールアドレス」とは連動しておりません。
                </div>
                <div class="text-indent-1">
                  「楽楽明細の通知メールアドレス」の変更は、以下のwebサイトより変更をお願いいたします。
                </div>
                <div class="text-indent-2">
                  <a href="https://invoice.nhn-techorus.com/nhn-techorus/">https://invoice.nhn-techorus.com/nhn-techorus/</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from "vue";
import ServiceAccountMethods from "../../../components/service_account_methods.vue";
import { pref } from "../../../utils/constants";

const methods = ref(null)
let initialized = ref(false)
let payment = ref(
  {
    billing: {
      name: "",
      kana: "",
      department_name: "",
      postal_code: "",
      prefecture_code: "",
      city: "",
      street_address: "",
      building: "",
      phone: "",
      email: ""
    },
    permissions: {
      show_edit_button: false,
    }
  }
)

const getPayment = async () => {
  const result = await methods.value?.getPayment()
  if (!result) {
    return false
  }
  return result
}

const prefName = computed( () => {
  return pref.find( p => p.value === payment.value.billing.prefecture_code).name
})

onMounted(async () => {
  const result = await getPayment()
  if(result){
    payment.value = result
    initialized.value = true
  }
})

</script>
<style scoped>
.caution-message {
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: rgb(136,136,136)
}
.text-indent-1 {
  text-indent: 14px;
}
.text-indent-2 {
  text-indent: 28px;
}
</style>

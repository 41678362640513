import { ref, computed } from "vue";
export function useDashboardFilter() {
    // data
    let filterItem = ref([]);
    let selected = ref([]);
    let isInclude = ref("include");
    let keyword = ref('');
    // computed
    const filteredItem = computed(() => {
        return searchFilterItem();
    });
    const selectedItem = computed(() => {
        return filterItem.value.filter((item) => selected.value.includes(item.name));
    });
    // setter
    const setSelected = (filterKey, reportFilter) => {
        const target = getObjectValues(reportFilter, filterKey);
        if (!target) {
            return false;
        }
        // set isInclude
        isInclude.value = target.condition;
        // set selected
        selected.value = target.values;
    };
    const setFilterItem = (item) => {
        filterItem.value = item.filter((item) => item.value !== '');
    };
    // getter
    const getSelected = () => {
        const result = [];
        filterItem.value.filter((item) => {
            if (selected.value.includes(item.name)) {
                result.push(item.name);
            }
        });
        return { condition: isInclude.value, values: result };
    };
    // methods
    const clearKeyword = () => {
        keyword.value = '';
    };
    const clearSelected = () => {
        selected.value = [];
    };
    const selectAll = () => {
        selected.value = searchFilterItem().map((item) => {
            return item.name;
        });
    };
    const searchFilterItem = () => {
        const regexp = new RegExp(keyword.value, 'i');
        return filterItem.value.filter((item) => regexp.test(item.name));
    };
    function getObjectValues(obj, key) {
        return obj[key];
    }
    return {
        selected,
        isInclude,
        keyword,
        selectedItem,
        filterItem,
        filteredItem,
        setSelected,
        setFilterItem,
        getSelected,
        selectAll,
        clearSelected,
        clearKeyword
    };
}

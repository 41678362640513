<template>
  <emailChangeMethod ref="method"/>
  <div class="row" style="width:100%">
    <div class="col-12">
      <div class="contentsArea">
        <table class="emailTable">
        <thead>
          <tr>
            <th class="completeMessage">
              <p style="color: black">{{ confirmMsg }}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="logoutButton">
              <a :class="`nav-link btn btn-primary`" style="color:white; width: 240px" href="/login">ログインページへ戻る</a>
            </td>
          </tr>
        </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import emailChangeMethod from "../../components/email_change_methods.vue"
import { extractId } from "../../lib/common";

const method = ref(null);
const confirmMsg = ref("");

const emailChangeConfirm = async (tokenKey) => {
  const result = await method.value?.emailChangeConfirm(tokenKey);
  if (!result.successful) {
    confirmMsg.value = result.message;
  } else {
    confirmMsg.value = "メールアドレスの変更が完了しました。";
  }
}

onMounted(async () => {
  const tokenKey = extractId(4);
  const param = {
    token_key: tokenKey
  }
  await emailChangeConfirm(param);
})
</script>
<style scoped>

.contentsArea {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoutButton {
  display: flex;
  justify-content: center;
}

</style>

<template>
  <Alert ref="alert"/>
</template>
<script setup>
import { ref } from "vue";
import Alert from "./alert_new.vue";
import { ApiClient } from "../lib/axios/login/api_client";

const client = new ApiClient()
const alert = ref(null)

const login = async (param) => {
  const res = await client.login(param);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data
}

const mfaSetting = async (params) => {
  const res = await client.mfaSetting(params);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data
}

const mfaLogin = async (param) => {
  const res = await client.mfaLogin(param);
  if (res.code === 1300) {
    alert.value.displayModal(res.message, 'error');
    return { next_url: "/login", time: 3000};
  }

  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }

  return { next_url: res.data.next_url, time: 0}
}

const sendCodeMfaLogin = async (param) => {
  const res = await client.sendCodeMfaLogin(param);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data
}

const logout = async () => {
  const res = await client.logout();
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data
}

defineExpose({
  login,
  mfaSetting,
  mfaLogin,
  sendCodeMfaLogin,
  logout
})
</script>

import {
    getAxios,
    postAxios,
    putAxios,
    patchAxios,
    deleteAxios,
    pdfAxios
} from "./base_client";

// sample
async function getSampleFunction(params){
    const url = "/sample"
    // return await getAxios(url, params)

    return {
        "result_code": 0,
        "result_message": "",
        "successful": true
    }
}

// dashboard操作関連
async function generateDashboardUrl(params){
    const url = "/dashboard/graph"
    return await postAxios(url, params)
}

async function getFilterItems(params){
    const url = "/dashboard/element/filter"
    return await postAxios(url, params)
}

async function getDashboardElementRightsizingDashboard(params){
    const url ="/dashboard/element/dashboard/rightsizing"
    return await postAxios(url, params)
}

async function getDashboardElementAssetSummaryDashboard(params){
    const url ="/dashboard/element/dashboard/asset/summary"
    return await postAxios(url, params)
}

async function getDashboardElementAssetDetailDashboard(params){
    const url ="/dashboard/element/dashboard/asset/detail"
    return await postAxios(url, params)
}

async function getDashboardElementExpireRiSpDashboard(params){
    const url ="/dashboard/element/dashboard/recommend/simulator/scenarios/expire_ri_sp"
    return await postAxios(url, params)
}

async function getDashboardElementScenariosSummaryDashboard(params){
    const url = "/dashboard/element/dashboard/recommend/simulator/scenarios/summary"
    return await postAxios(url, params)
}

// filter操作関連
async function getDashboardFilter(filterId){
    const url = "/filters/" +  filterId
    return await getAxios(url)
}

async function listDashboardFilter(params){
    const url = "/filters"
    return await getAxios(url, params)
}

async function createDashboardFilter(params){
    const url = "/filters"
    return await postAxios(url, params)
}

async function updateDashboardFilter(filterId, params){
    const url = "/filters/" + filterId
    return await putAxios(url, params)
}

async function deleteDashboardFilter(id){
    const url = `/filters/${id}`;
    return await deleteAxios(url);
}

async function lockDashboardFilter(id){
    const url = `/filters/lock/${id}`;
    return await patchAxios(url);
}

async function unlockDashboardFilter(id){
    const url = `/filters/unlock/${id}`;
    return await patchAxios(url);
}

async function validateDashboardFilterName(params){
    const url = '/filters/validation/name'
    return await postAxios(url, params)
}


// report操作関連
async function getReport(reportId, params){
    const url = "/reports/" + reportId

    return await getAxios(url, params)

}

async function getReportList(params){
    const url = "/reports"
    return await getAxios(url, params)
}


async function createReport(params){
    const url = '/reports'
    return await postAxios(url, params)
}


async function updateReport(report_id, params){
    const url = '/reports/' + report_id
    return await putAxios(url, params)
}

async function deleteReports(id){
    const url = `/reports/${id}`;
    return await deleteAxios(url);
}

async function lockReports(id){
    const url = `/reports/lock/${id}`;
    return await patchAxios(url);
}

async function unlockReports(id){
    const url = `/reports/unlock/${id}`;
    return await patchAxios(url);
}

async function validateReportName(params){
    const url = '/reports/validation/name'
    return await postAxios(url, params)
}

// 課金超過通知設定
async function getAlerts(params){
    const url = '/alerts/cost';
    return await getAxios(url, params);
}

async function getAlert(id){
    const url = `/alerts/cost/${id}`;
    return await getAxios(url);
}

async function deleteAlert(id){
    const url = `/alerts/cost/${id}`;
    return await deleteAxios(url);
}

async function createAlert(params){
    const url = `/alerts/cost`;
    return await postAxios(url, params);
}

async function updateAlert(id, params){
    const url = `/alerts/cost/${id}`;
    return await putAxios(url, params);
}

async function lockAlerts(id){
    const url = `/alerts/cost/lock/${id}`;
    return await patchAxios(url);
}

async function unlockAlerts(id){
    const url = `/alerts/cost/unlock/${id}`;
    return await patchAxios(url);
}

// reserved/savings plan通知設定
async function getRiSpAlerts(params){
    const url = '/alerts/ri_sp';
    return await getAxios(url, params);
}

async function getRiSpAlert(id){
    const url = `/alerts/ri_sp/${id}`;
    return await getAxios(url);
}

async function deleteRiSpAlert(id){
    const url = `/alerts/ri_sp/${id}`;
    return await deleteAxios(url);
}

async function createRiSpAlert(params){
    const url = `/alerts/ri_sp`;
    return await postAxios(url, params);
}

async function updateRiSpAlert(id, params){
    const url = `/alerts/ri_sp/${id}`;
    return await putAxios(url, params);
}

async function lockRiSpAlerts(id){
    const url = `/alerts/ri_sp/lock/${id}`;
    return await patchAxios(url);
}

async function unlockRiSpAlerts(id){
    const url = `/alerts/ri_sp/unlock/${id}`;
    return await patchAxios(url);
}

// Rightsizing通知設定
async function getRightsizingAlerts(params){
    const url = '/alerts/rightsizing';
    return await getAxios(url, params);
}

async function getRightsizingAlert(id){
    const url = `/alerts/rightsizing/${id}`;
    return await getAxios(url);
}

async function deleteRightsizingAlert(id){
    const url = `/alerts/rightsizing/${id}`;
    return await deleteAxios(url);
}

async function createRightsizingAlert(params){
    const url = `/alerts/rightsizing`;
    return await postAxios(url, params);
}

async function updateRightsizingAlert(id, params){
    const url = `/alerts/rightsizing/${id}`;
    return await putAxios(url, params);
}

async function lockRightsizingAlerts(id){
    const url = `/alerts/rightsizing/lock/${id}`;
    return await patchAxios(url);
}

async function unlockRightsizingAlerts(id){
    const url = `/alerts/rightsizing/unlock/${id}`;
    return await patchAxios(url);
}

async function getBudgetAlerts(params){
    const url = '/alerts/budget';
    return await getAxios(url, params);
}

async function getBudgetAlert(id){
    const url = `/alerts/budget/${id}`;
    return await getAxios(url);
}

async function deleteBudgetAlert(id){
    const url = `/alerts/budget/${id}`;
    return await deleteAxios(url);
}

async function createBudgetAlert(params){
    const url = `/alerts/budget`;
    return await postAxios(url, params);
}

async function updateBudgetAlert(id, params){
    const url = `/alerts/budget/${id}`;
    return await putAxios(url, params);
}

async function lockBudgetAlerts(id){
    const url = `/alerts/budget/lock/${id}`;
    return await patchAxios(url);
}

async function unlockBudgetAlerts(id){
    const url = `/alerts/budget/unlock/${id}`;
    return await patchAxios(url);
}

async function getAlertsAwsAccountIds(){
    const url = `/alerts/aws_account_ids`;
    return await getAxios(url);
}

async function getAlertsAwsServices(){
    const url = `/alerts/aws_services`;
    return await getAxios(url);
}

async function getAlertsUserMails(){
    const url = `/alerts/user_mails`;
    return await getAxios(url);
}

async function getAlertsBudgetNames(){
    const url = `/alerts/budget_names`;
    return await getAxios(url);
}

async function getReportSubscription(id, type){
    const url = `/subscriptions/${type}/${id}`;
    return await getAxios(url);
}

async function getReportSubscriptions(params) {
    const url = '/subscriptions';
    return await getAxios(url, params);
}

async function createReportSubscriptions(params, type) {
    const url = `/subscriptions/${type}`;
    return await postAxios(url, params);
}

async function updateReportSubscriptions(id, params, type) {
    const url = `/subscriptions/${type}/${id}`;
    return await putAxios(url, params);
}

async function lockReportSubscriptions(id, type){
    const url = `/subscriptions/${type}/lock/${id}`;
    return await patchAxios(url);
}

async function unlockReportSubscriptions(id, type){
    const url = `/subscriptions/${type}/unlock/${id}`;
    return await patchAxios(url);
}

async function deleteReportSubscriptions(id, type){
    const url = `/subscriptions/${type}/${id}`;
    return await deleteAxios(url);
}

async function getDownloadFileInfoReportSubscriptions(id, type){
    const url = `/subscriptions/${type}/${id}/downloads`;
    return await getAxios(url);
}

async function getPdfReportSubscriptions(id, type){
    const url = `/subscriptions/${type}/direct_downloads/${id}`;
    return await pdfAxios(url);
}

// aws account関連
async function getAwsAccount(id) {
    const url = `/aws_accounts/${id}`;
    return await getAxios(url);
}

async function getAwsAccounts(params) {
    const url = `/aws_accounts`;
    return await getAxios(url, params);
}

async function getPayerAwsAccounts(params) {
    const url = `/aws_accounts/payer_account`;
    return await getAxios(url, params);
}

async function createAwsAccount(params) {
    const url = `/aws_accounts`;
    return await postAxios(url, params);
}

async function updateAwsAccount(id, params) {
    const url = `/aws_accounts/edit/${id}`;
    return await putAxios(url, params);
}

async function getLinkedAwsAccounts(id) {
    const url = `/aws_accounts/${id}/linked_account`;
    return await getAxios(url);
}

async function deleteAwsAccounts(params) {
    const url = `/aws_accounts/delete`;
    return await postAxios(url, params);
}

async function getExternalId(id) {
    const url = `/aws_accounts/external_id`;
    return await getAxios(url);
}

async function validateAwsAccount(id) {
    const url = `/aws_accounts/${id}/validation`;
    return await getAxios(url);
}

async function validatePayerAwsAccount(id) {
    const url = `/aws_accounts/${id}/payer/validation`;
    return await getAxios(url);
}

async function registrableAwsAccount(id) {
    const url = `/aws_accounts/${id}/registrable`;
    return await getAxios(url);
}

async function updateStatus(params) {
    const url = `/aws_accounts/status`;
    return await postAxios(url, params);
}

// tag 設定
async function getIlluminatorTags() {
    const url = `/setting/tags`;
    return await getAxios(url);
}

async function getResourceTags() {
    const url = `/setting/tags/resource_tags`;
    return await getAxios(url);
}

async function updateIlluminatorTags(params) {
    const url = `/setting/tags`;
    return await postAxios(url, params);
}

// notice & alert
async function getNoticeList(type, page) {
    const url = `/notices?type=${type}&page=${page}`;
    return await getAxios(url);
}

async function getNotice(id, type) {
    const url = `/notices/${id}?type=${type}`;
    return await getAxios(url);
}

// アカウント 別名
async function getContractAccountAlias() {
    const url = `/contract_accounts/alias`;
    return await getAxios(url);
}

async function updateContractAccountAlias(params) {
    const url = `/contract_accounts/alias`;
    return await postAxios(url, params);
}

// 請求情報 一覧
async function getInvoices(params) {
    const url = `/invoices`;
    return await getAxios(url, params);
}

async function getInvoiceYears() {
    const url = `/invoices/years`;
    return await getAxios(url);
}

// Rightsizing Setting
async function getRightsizingSettings(service_type, params) {
    const url = `/rightsizing/setting/exclusion/${service_type}`;
    return await getAxios(url, params);
}

async function countRightsizingSetting() {
    const url = `/rightsizing/setting/exclusion/registrable`;
    return await getAxios(url);
}

async function createRightsizingSetting(params) {
    const url = `/rightsizing/setting/exclusion`;
    return await postAxios(url, params);
}

async function updateRightsizingSetting(exclusion_id, params) {
    const url = `/rightsizing/setting/exclusion/${exclusion_id}/update`;
    return await postAxios(url, params);
}

async function deleteRightsizingSetting(exclusion_id) {
    const url = `/rightsizing/setting/exclusion/${exclusion_id}/delete`;
    return await deleteAxios(url);
}

// signup
async function createTemporaryServiceAccount(params) {
    const url = `/signup/send-mail`;
    return await postAxios(url, params);
}

async function getTemporaryServiceAccount(token_key) {
    const url = `/signup/new/${token_key}`;
    return await getAxios(url);
}

async function createServiceAccount(params) {
    const url = `/signup/service-account`;
    return await postAxios(url, params);
}

async function getAddress(zipcode) {
    const url = `/address/${zipcode}`;
    return await getAxios(url);
}

export {
    getSampleFunction,
    generateDashboardUrl,
    getDashboardElementRightsizingDashboard,
    getDashboardElementAssetSummaryDashboard,
    getDashboardElementAssetDetailDashboard,
    getDashboardElementExpireRiSpDashboard,
    getDashboardElementScenariosSummaryDashboard,
    getDashboardFilter,
    getFilterItems,
    listDashboardFilter,
    createDashboardFilter,
    updateDashboardFilter,
    deleteDashboardFilter,
    lockDashboardFilter,
    unlockDashboardFilter,
    getReport,
    getReportList,
    createReport,
    updateReport,
    deleteReports,
    unlockReports,
    lockReports,
    getAlerts,
    getAlert,
    createAlert,
    updateAlert,
    lockAlerts,
    unlockAlerts,
    deleteAlert,
    getRiSpAlerts,
    getRiSpAlert,
    createRiSpAlert,
    updateRiSpAlert,
    lockRiSpAlerts,
    unlockRiSpAlerts,
    deleteRiSpAlert,
    getRightsizingAlerts,
    getRightsizingAlert,
    createRightsizingAlert,
    updateRightsizingAlert,
    lockRightsizingAlerts,
    unlockRightsizingAlerts,
    deleteRightsizingAlert,
    getBudgetAlerts,
    getBudgetAlert,
    createBudgetAlert,
    updateBudgetAlert,
    lockBudgetAlerts,
    unlockBudgetAlerts,
    deleteBudgetAlert,
    getAlertsAwsAccountIds,
    getAlertsAwsServices,
    getAlertsUserMails,
    getAlertsBudgetNames,
    getReportSubscription,
    getReportSubscriptions,
    createReportSubscriptions,
    updateReportSubscriptions,
    lockReportSubscriptions,
    unlockReportSubscriptions,
    deleteReportSubscriptions,
    getPdfReportSubscriptions,
    getDownloadFileInfoReportSubscriptions,
    getAwsAccount,
    getAwsAccounts,
    getPayerAwsAccounts,
    createAwsAccount,
    updateAwsAccount,
    getLinkedAwsAccounts,
    deleteAwsAccounts,
    getExternalId,
    validateAwsAccount,
    validatePayerAwsAccount,
    registrableAwsAccount,
    updateStatus,
    getIlluminatorTags,
    getResourceTags,
    updateIlluminatorTags,
    validateReportName,
    validateDashboardFilterName,
    getNotice,
    getNoticeList,
    getContractAccountAlias,
    updateContractAccountAlias,
    getInvoices,
    getInvoiceYears,
    getRightsizingSettings,
    countRightsizingSetting,
    createRightsizingSetting,
    updateRightsizingSetting,
    deleteRightsizingSetting,
    createTemporaryServiceAccount,
    getTemporaryServiceAccount,
    createServiceAccount,
    getAddress,
}


<template>
  <Alert ref="alert"/>
</template>
<script setup>
import { ref } from "vue";
import Alert from "./alert_new.vue";
import { ApiClient } from "../lib/axios/password/api_client";

const client = new ApiClient();
const alert = ref(null);

const changePassword = async (param) => {
  const res = await client.changePassword(param);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data;
}

const resetPassword = async (param) => {
  const res = await client.resetPassword(param);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data
}

const checkTokenKey = async (tokenKey) => {
  const res = await client.checkTokenKey(tokenKey);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data;
}

const sendmailResetPassword = async (param) => {
  const res = await client.sendmailResetPassword(param);
  if (!res.successful) {
    alert.value.displayModal(res.message, 'error');
    return false;
  }
  return res.data
}

defineExpose({
  changePassword,
  resetPassword,
  checkTokenKey,
  sendmailResetPassword,
})
</script>

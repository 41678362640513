<template>
  <emailChangeMethod ref="methods"/>
  <NeoidMethods ref="neoIdMethods"/>
  <div v-if="!initialized">
    <div class="loader">Loading...</div>
  </div>
  <div v-else class="row" style="width:100%">
    <div class="col-12">
      <form id="mail_address_change_form" class="card">
        <div class="card-header">
          <div class="title-part">
            <div class="text-left">
              <h4 class="card-title">メールアドレス変更</h4>
            </div>
          </div>
          <div v-if="isModalOpen === true" id="mailChangedModal" class="confirmModal">
            <div class="modal">
              <div class="modal-header">
                <div class="text-left">
                  <h4>認証メールを送信しました。<br>メール内のリンクにアクセスして変更を完了してください。</h4>
                </div>
                <div class="text-right">
                  <i class="material-icons" id="modalClose" @click="isModalOpen = false">close</i>
                </div>
              </div>
              <div class="modal-footer">
                <div class="backToMailChangeButton">
                  <a class="btn default" href="/mypage/mail/change">メールアドレス変更画面へ戻る</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="input-wrapper">
            <div class="label">
              <label for="mail_address_changed">{{ emailChangedLabel }}</label>
            </div>
            <div class="formInput">
              <input name="mail_address_changed" id="mail_address_changed" v-model="inputValueChanged"
                     maxlength="100" class="form-control" type="email" @input="validateEmailChanged">
            </div>
            <p v-if="validationMessageChanged" class="validationMessage">{{ validationMessageChanged }}</p>
          </div>
          <div class="input-wrapper">
            <div class="label">
              <label for="mail_address_confirm">{{ emailConfirmLabel }}</label>
            </div>
            <div class="formInput">
              <input name="mail_address_confirm" id="mail_address_confirm" v-model="inputValueConfirm"
                     maxlength="100" class="form-control" type="email" @input="validateEmailConfirm">
            </div>
            <p v-if="validationMessageConfirm" class="validationMessage">{{ validationMessageConfirm }}</p>
          </div>
          <div class="buttonArea">
            <div class="cancelButton">
              <a class="btn default" href="/mypage">キャンセル</a>
            </div>
            <div class="confirmButton">
              <button
                  :class="`nav-link btn btn-primary`"
                  style="color:white; width: 120px"
                  type="button"
                @click.prevent="handleSubmit">確定</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import emailChangeMethod from "../../components/email_change_methods.vue"
import NeoidMethods from "../../components/neoid_methods.vue";

const initialized = ref(false);
const emailChangedLabel = ref("変更後のメールアドレス");
const emailConfirmLabel = ref("メールアドレスの再入力")
const inputValueChanged = ref("");
const inputValueConfirm = ref("");
const validationMessageChanged = ref("");
const validationMessageConfirm = ref("");
const validationChanged = ref(false);
const validationConfirm = ref(false);
const isModalOpen = ref(false);
const isModalOpenHelp = ref(false);
const methods = ref(null);
const neoIdMethods = ref(null);
const mailAddressRegex = /^[a-zA-Z0-9.!#$%&*+\/=?^_`{|}~-]{1,46}@[\.a-zA-Z0-9-]+\.[a-zA-Z]+$/;
const domainRegex = /^(?=.{1,100}$)(?=.{1,46}@).+@.+\.[a-zA-Z]{2,}$/;
const email = ref("");

onMounted(async () => {
  initialized.value = true;
})

const validateEmailChanged = async () => {
  if (inputValueChanged.value === "") {
    validationMessageChanged.value = emailChangedLabel.value+"を入力してください。";
    validationChanged.value = false;
  } else if (!mailAddressRegex.test(inputValueChanged.value)||!domainRegex.test(inputValueChanged.value)) {
    validationMessageChanged.value = emailChangedLabel.value+"の入力形式が正しくありません。";
    validationChanged.value = false;
  } else {
    validationMessageChanged.value = "";
    validationChanged.value = true;
  }
}

const validateEmailConfirm = async () => {
  if (inputValueConfirm.value === "") {
    validationMessageConfirm.value = emailConfirmLabel.value+"を入力してください。";
    validationConfirm.value = false;
  } else if (inputValueConfirm.value !== inputValueChanged.value) {
    validationMessageConfirm.value = emailChangedLabel.value+"と"+emailConfirmLabel.value+"が一致しません。";
    validationConfirm.value = false;
  }　else if (!mailAddressRegex.test(inputValueConfirm.value)) {
    validationMessageConfirm.value = emailConfirmLabel.value+"の入力形式が正しくありません。";
    validationConfirm.value = false;
  } else {
    validationMessageConfirm.value = "";
    validationConfirm.value = true;
  }
}

const emailChange = async () => {

  // 暗号化用のRSAセッションキーを取得
  const { ip, sessionKey, rsaString } = await neoIdMethods.value?.generateRSAKeys(inputValueChanged.value, "dummy", "ID_CHANGE");

  const param = {
    email: inputValueChanged.value,
    session_key: sessionKey,
    encrypt_string: rsaString
  }

  const result = await methods.value?.emailChange(param);
  if (!result) {
    return false;
  }
  return true;
}

const handleSubmit = async () => {
  // 変更したメールアドレスに認証メールを送る処理
  await validateEmailConfirm();
  await validateEmailChanged();
  if (validationConfirm.value === true && validationChanged.value === true) {
    const isPassed = await emailChange();
    if (isPassed) {
      isModalOpen.value = true;
    }
  }
}

watch(inputValueChanged, inputValueConfirm, () => {
  validateEmailConfirm();
  validateEmailChanged();
})
</script>

<style scoped>

.title-part {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  height: 60px;
}

.card-title {
  display: inline-block;
  font-size: 22px;
  font-weight: 300;
  margin-top: 10px;
}

.label {
  display: inline-block;
  width: 200px;
  height: 50px;
}

.formInput {
  display: inline-block;
  width: 300px;
  margin-top: 10px;
}

.validationMessage {
  color : red;
  display: inline-block;
  font-size: 12px;
  margin-left: 20px;
}

.buttonArea {
  margin-top: 10px;
}

.cancelButton {
  display: inline-block;
  padding-right: 20px;
}

.confirmButton {
  display: inline-block;
}

.confirmModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: white;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 20%;
  margin-left: 30%;
  display: block;
  height: 200px;
}

.backToMailChangeButton {
  margin-top: 10px;
}

.material-icons {
  color: #aaa;
  cursor: pointer;
}

</style>
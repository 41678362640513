<template>
  <th v-on:mouseover="isShow=true" v-on:mouseleave="isShow=false">
    <div class="right-sizing-table-th-base">
      <div v-if="isShow" class="sort-icon d-inline">
        <span v-on:click="clicked">
          <i class="material-icons" >swap_vert</i>
        </span>
      </div>
      <slot></slot>
    </div>
  </th>
</template>
<script>
export default {
  props: [ 'tableKey' ],
  data: function () {
    return {
      isShow: false
    }
  },
  methods: {
    clicked() {
      this.$emit('emitTrigger', this.tableKey)
    }
  }
}
</script>

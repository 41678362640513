<template>
  <div v-if="!initialized">
    <Alert ref="alert"/>
    <div class="loader">Loading...</div>
  </div>
  <div v-else>
    <Alert ref="alert"/>
    <form class="card" @submit.prevent="updateReportSubscription()">
      <div class="card-body">
        <div style="display: flex; justify-content: space-between;">
          <div class="text-left">
            <button :class="`btn btn-primary repeated_hit_prevention ${ disabled ? 'disabled' : '' }`" type="submit">保存</button>
            <button :class="`btn btn-primary repeated_hit_prevention ${ disabled ? 'disabled' : '' }`" type="button" v-on:click="deleteReportSubscription">削除</button>
          </div>
          <div class="text-right">
            <a class="btn default" href="/mypage/report_subscriptions">一覧</a>
          </div>
        </div>
        <ReportSubscriptionForm ref="form" :reportSubscription="reportSubscription" v-model:disabled="disabled"/>
      </div>
    </form>
  </div>
</template>
<script>

import * as constants from '../utils/report_subscriptions'
import { parse } from "date-fns"
import { getReportSubscription, updateReportSubscriptions, deleteReportSubscriptions, getDownloadFileInfoReportSubscriptions } from "../lib/axios/api_client";
import Alert from "../components/alert_new.vue";
import {pagePath} from "../utils/constants";
import ReportSubscriptionForm from "../components/report_subscription_budget_form.vue"
import {changeOrderEmails} from "../lib/common";

export default {
  created: async function() {
    const result = await this.getReportSubscription()
    if(result){
      this.reportSubscription = result

      while(this.reportSubscription.emails.length < 3){
        this.reportSubscription.emails.push("")
      }

      this.initialized = true
    }else{
      setTimeout(this._transition, 1000)
    }
  },
  components: {
    ReportSubscriptionForm: ReportSubscriptionForm,
    Alert: Alert
  },
  data: function() {
    return {
      initialized: false,
      reportSubscription: constants.reportBudgetSubscription,
      path: pagePath.reportSubscription.index,
      disabled: false
    }
  },
  methods: {
    formatDate(dateTime){
      let date = dateTime.substring(0, dateTime.indexOf('T'))
      let splitYmd = date.split('-')
      return splitYmd[0] + '年' + splitYmd[1] + '月' + splitYmd[2] + '日'
    },
    getReportSubscription: async function(){
      const id = this._getId()
      const type = this._getType()
      const res = await getReportSubscription(id, type)
      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      return res.data.report_subscription
    },
    getDownloadFileInfoReportSubscriptions: async function(){
      const id = this._getId()
      const type = this._getType()
      const res = await getDownloadFileInfoReportSubscriptions(id, type)
      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      return res.data.report_subscription_downloads
    },
    updateReportSubscription: async function(e){
      if(this.disabled){ return false }

      this.disabled = true

      let form = this.$refs.form.form
      form.emails = changeOrderEmails(form.emails)

      // 周期変更チェック
      const result = await this.getReportSubscription()
      const changeCycle = result.cycle_type !== form.cycle_type || result.cycle_schedule.day_of_week !== form.cycle_schedule.day_of_week
      if(changeCycle){
        // 既存ダウンロードデータ確認
        const download = await this.getDownloadFileInfoReportSubscriptions()
        if(download !== null){
          // 発行日取得
          const parsedPublicationDate = this.formatDate(download.publication_date)
          if(!window.confirm("保存しますか？\n※周期を変更した場合、作成済みの" + parsedPublicationDate + "時点の SubscriptionはDLできなくなります。")) return;
        }
      }

      const id = this._getId()
      const type = this._getType()
      const res = await updateReportSubscriptions(id, form, type)
      if(!res.successful){
        this.disabled = false
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      this.$refs.alert.displayModal("成功しました", 'success')
      setTimeout(this._transition, 1000)
    },
    deleteReportSubscription: async function(){
      if (!window.confirm('本当に削除しますか？')) return;
      this.disabled = true

      const id = this._getId()
      const type = this._getType()
      const res = await deleteReportSubscriptions(id, type)
      if(!res.successful){
        this.disabled = false
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }

      this.$refs.alert.displayModal("成功しました", 'success')
      setTimeout(this._transition, 1000)
    },
    _transition(){
      window.location.href = this.path
    },
    _getId(){
      const pathArray = location.pathname.split("/")
      if(pathArray[4] === "undefined" ){
        return false
      }
      return pathArray[4]
    },
    _getType(){
      const pathArray = location.pathname.split("/")
      return pathArray[3]
    }
  }
}
</script>

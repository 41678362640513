<template>
  <span>
      <FilterMethods ref="filterMethods"/>
      <ReportListButton
          :report_list="filterList"
          :pageNumber="pageNumber"
          :totalPages="totalPages"
          type="filter"
          v-on:childMethod="selectAction"
          v-on:pagingAction="pagingAction">
        フィルター
      </ReportListButton>
  </span>
</template>
<script>
import ReportListButton from "./report_list_button.vue";
import FilterMethods from "./filter_methods.vue"
import * as constants from "../utils/constants"
export default {
  components: {
    'ReportListButton': ReportListButton,
    'FilterMethods': FilterMethods,
  },
  props: {
    report: Object,
    reportType: String
  },
  emits: [
    'update:report',
    'updateReport'
  ],
  data() {
    return {
      filterList: [],
      pageNumber: 0,
      totalPages: 1,
    }
  },
  mounted: async function () {
    await this.listAction()
  },
  methods: {
    selectAction: async function (filterId) {
      const newFilter = await this.$refs.filterMethods.getDashboardFilter(filterId)
      if (newFilter) {
        const newReport = this._importFilterToReport(this.reportType, newFilter.filter, this.report)
        this.$emit('update:report', newReport)
        this.$emit('updateReport')
      }
    },
    listAction: async function(page=0){
      const result = await this.$refs.filterMethods.getFilterList(page)
      if(result){
        this.filterList = result.filters
        this.pageNumber = result.page_number
        this.totalPages = result.total_pages
      }
    },
    pagingAction: async function(action){
      if(action === "back"){
        if(this.pageNumber > 0){
          await this.listAction(this.pageNumber - 1)
        }
      }else if(action === "next"){
        if(this.pageNumber  < this.totalPages - 1){
          await this.listAction(this.pageNumber + 1)
        }
      }
    },
    _importFilterToReport(reportType, dashboardFilter, awsReport){
      Object.keys(awsReport.filter).forEach(filterName => {
        if(dashboardFilter[filterName]){
          awsReport.filter[filterName] = dashboardFilter[filterName]
        }
      })

      awsReport = this._exceptionImportFilterToReport(reportType, dashboardFilter, awsReport)
      return awsReport
    },
    _exceptionImportFilterToReport(reportType, dashboardFilter, awsReport){
      if(reportType === constants.reportTypeRiUtilization){
        awsReport.filter["service"] = dashboardFilter["service_ri_utilization"]
      }else if(reportType === constants.reportTypeRiCoverage){
        awsReport.filter["service"] = dashboardFilter["service_ri_coverage"]
        awsReport.filter["instance_family"] = dashboardFilter["instance_family_ri_coverage"]
      }else if(reportType === constants.reportTypeRiRecommend){
        awsReport.filter["service"] = dashboardFilter["service_ri_recommend"]
      }else if(reportType === constants.reportTypeSpCoverage){
        awsReport.filter["instance_family"] = dashboardFilter["instance_family_sp_coverage"]
      }

      return awsReport
    }
  }
}
</script>
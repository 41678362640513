<template>
  <div>
    <Alert ref="alert"/>
    <div v-if="!initialized">
      <div class="loader">Loading...</div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                <div class="row">
                  <div class="col">一覧</div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <ul class="nav">
                <li class="nav-item">
                  <a
                      :class="`nav-link btn btn-primary${Object.keys(reportSubscriptions).length >= budgetCount + 1 ? ' disabled' : ''}`"
                      href="/mypage/report_subscriptions/new"
                  >
                    新規
                  </a>
                </li>
                <li class="nav-item">
                  <a
                      :class="`nav-link btn btn-primary${disabledBulkDelete ? ' disabled' : ''}`"
                      :disabled="disabledBulkDelete"
                      @click.prevent="bulkDelete"
                      href="#"
                  >
                    削除
                  </a>
                </li>
                <li class="nav-item">
                  <a
                      :class="`nav-link btn btn-primary${disabledBulkLock ? ' disabled' : ''}`"
                      :disabled="disabledBulkLock"
                      @click.prevent="bulkLock"
                      href="#"
                  >
                    ロック
                  </a>
                </li>
                <li class="nav-item">
                  <a
                      :class="`nav-link btn btn-primary${disabledBulkUnlock ? ' disabled' : ''}`"
                      :disabled="disabledBulkUnlock"
                      @click.prevent="bulkUnlock"
                      href="#"
                  >
                    ロック解除
                  </a>
                </li>
              </ul>
              <div class="text-secondary">種類（コスト）は最大１件まで登録できます</div>
              <div class="text-secondary mb-4">種類（予算）は予算ごとに最大１件まで登録できます</div>
              <table id="cost_alert-list" class="table table-borderless">
                <thead>
                <tr>
                  <th></th>
                  <th>種類</th>
                  <th>配信する</th>
                  <th>名前</th>
                  <th>配信終了日</th>
                  <th>ロック</th>
                  <th>アクション</th>
                  <th>DL</th>
                </tr>
                </thead>
                <tbody>
                  <template v-if="isReportSubscriptionsLengthZero">
                    <tr>
                      <td></td>
                      <td colspan="7">データが存在しません</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr v-for="(item, i) in reportSubscriptions" :key="i">
                      <td>
                        <input @change="checkBulkCheckbox" type="checkbox" :value="i" v-model="checkedIds">
                      </td>
                      <td>{{ conversionTypeToName(item.type) }}</td>
                      <td>{{ item.enabled ? '有効' : '無効' }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.end_date.replaceAll("-", "/") }}</td>
                      <td>
                        <a v-if="item.locked" @click.prevent="update('unlock', item.id, item.type)" class="text-info" href="#">
                          <i class="material-icons">lock</i>
                        </a>
                        <a v-else @click.prevent="update('lock', item.id, item.type)" class="text-info" href="#">
                          <i class="material-icons">lock_open</i>
                        </a>
                      </td>
                      <td>
                        <a v-if="!item.locked" class="text-info" :href="`/mypage/report_subscriptions/${item.type}/${item.id}/edit`">
                          <i class="material-icons">article</i>
                        </a>
                        <a v-if="!item.locked" @click.prevent="update('delete', item.id, item.type, '本当に削除しますか？')" class="text-danger" href="#">
                          <i class="material-icons">delete</i>
                        </a>
                      </td>
                      <td>
                        <a class="text-info" :href="`/mypage/report_subscriptions/${item.type}/${item.id}/download/pdf`">
                          <i class="material-icons">download</i>
                        </a>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {lockReportSubscriptions, unlockReportSubscriptions, deleteReportSubscriptions, getReportSubscriptions, getAlertsBudgetNames} from "../lib/axios/api_client";
import Alert from "../components/alert_new.vue";
import * as constants from "../utils/report_subscriptions";

export default {
  async mounted() {
    const hasCount = await this.getBudgetCount();
    const result = await this.fetchIndex();
    if(hasCount && result) this.initialized = true;
  },
  components: {
    Alert: Alert,
  },
  data: function () {
    return {
      reportSubscriptions: {},
      budgetCount: 0,
      checkedIds: [],
      disabledBulkDelete: true,
      disabledBulkLock: true,
      disabledBulkUnlock: true,
      initialized: false,
    }
  },
  computed: {
    isReportSubscriptionsLengthZero(){
      return Object.keys(this.reportSubscriptions).length === 0
    }
  },
  methods: {
    getBudgetCount: async function () {
      const res = await getAlertsBudgetNames();
      if (!res.successful) {
        this.$refs.alert.displayModal(res.message, 'error');
        return false;
      }
      this.budgetCount = Object.keys(res.data.budget_names).length;
      return true;
    },
    fetchIndex: async function() {
      const res = await getReportSubscriptions({});
      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }
      let reportSubscriptions = {};
      res.data.report_subscriptions.forEach((item, idx) => {
        reportSubscriptions[idx] = item;
      });
      this.reportSubscriptions = reportSubscriptions;

      return true
    },
    conversionTypeToName: function (type) {
      return constants.reportTypes.find(({ value }) => value === type).name;
    },
    initializeBulkCheckbox: function () {
      this.disabledBulkDelete = true;
      this.disabledBulkLock = true;
      this.disabledBulkUnlock = true;
    },
    checkBulkCheckbox: function () {
      this.initializeBulkCheckbox();

      let lockTypes = this.checkedIds.map((id) => {
        return this.reportSubscriptions[id].locked;
      })

      lockTypes = lockTypes.filter(function(elem, index, self) {
        return self.indexOf(elem) === index;
      });

      // 未選択、もしくはLockとUnlockのデータが選ばれた場合、まとめて実行はできない
      if (lockTypes.length !== 1) return;

      // ロックされていないデータのみ選択されていた場合
      if (lockTypes[0] === false) {
        this.disabledBulkDelete = false;
        this.disabledBulkLock = false;
        // ロックされているデータのみ選択
      } else {
        this.disabledBulkUnlock = false;
      }
    },
    splitCheckedIdsByType: function () {
      let checkedCostIds = []
      let checkedBudgetIds = []
      this.checkedIds.forEach((id) => {
        if (this.reportSubscriptions[id].type === 'cost') {
          checkedCostIds.push(this.reportSubscriptions[id].id)
        } else {
          checkedBudgetIds.push(this.reportSubscriptions[id].id)
        }
      })
      return [
        {type: 'cost', value: checkedCostIds},
        {type: 'budget', value: checkedBudgetIds}
      ]
    },
    update: async function (action, id, type, confirm) {
      if (confirm) {
        if (!window.confirm(confirm)) return;
      }
      const methods = {
        lock: lockReportSubscriptions,
        unlock: unlockReportSubscriptions,
        delete: deleteReportSubscriptions,
      }
      const res = await methods[action](id, type);
      if(!res.successful){
        this.$refs.alert.displayModal(res.message, 'error')
        return false
      }
      this.$refs.alert.displayModal('成功しました。','success')

      this.checkedIds = [];
      this.initializeBulkCheckbox();
      await this.fetchIndex();
    },
    bulkUpdate: function (action) {
      const splitCheckedIds = this.splitCheckedIdsByType()
      splitCheckedIds.forEach((checkedIds) => {
        if (!checkedIds.value.length) return
        let ids = checkedIds.value.join(',')
        this.update(action, ids, checkedIds.type, action === 'delete' ? '本当に削除しますか？' : null)
      })
    },
    bulkDelete: function (e) {
      this.bulkUpdate('delete');
    },
    bulkLock: function (e) {
      this.bulkUpdate('lock');
    },
    bulkUnlock: function (e) {
      this.bulkUpdate('unlock');
    }
  },
}
</script>

<style scoped></style>

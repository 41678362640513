<template>
  <div>
    <Alert ref="alert"/>
    <div v-if="!initialized">
      <div class="loader">Loading...</div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card">
            <div class="card-header card-header-rose card-header-icon">
              <div class="card-title">
                <h4>一覧</h4>
                <ul class="warn-text">
                  <template v-if="!Object.keys(awsAccountList).length">
                    <li class="text-danger">※ 利用するにはAWSアカウントの登録が必要です。</li>
                  </template>
                  <li>※ 未設定またはエラーの項目がある場合はIAM Roleを再設定してください。</li>
                  <li>※ 設定済でないものがある場合は通知が届きます。通知が不要なAWSアカウントIDがある場合は、以下の「設定不要に変更」ボタンでステータスの変更をしてください。</li>
                </ul>
              </div>
            </div>
            <div class="pl-3">
              <ul class="nav">
                <li class="nav-item" v-if="permissions.show_add_button">
                  <a
                      class="nav-link btn btn-primary"
                      href="/mypage/settings/aws_accounts/new"
                  >
                    追加
                  </a>
                </li>
                <li class="nav-item" v-if="permissions.show_delete_button">
                  <a
                      :class="`nav-link btn btn-primary${disabledBulkDelete ? ' disabled' : ''}`"
                      :disabled="disabledBulkDelete"
                      @click.prevent="bulkDelete"
                      href="#"
                  >
                    削除
                  </a>
                </li>
                <li class="nav-item" v-if="permissions.show_change_no_settings_required_button">
                  <button
                      type="button"
                      :class="`nav-link btn btn-primary${disabledBulkChangeNoSetting ? ' disabled' : ''}`"
                      :disabled="disabledBulkChangeNoSetting"
                      data-toggle="modal"
                      data-target="#changeNoSettingModal"
                  >
                    設定不要に変更
                  </button>
                  <!-- Modal -->
                  <div class="modal fade" id="changeNoSettingModal" tabindex="-1" role="dialog" aria-labelledby="changeNoSettingModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <ul>
                            <li>対象のAWSアカウントをIAMロールを使用しないようにステータスを変更します。</li>
                            <li>IAMロールを設定しない場合、一部の機能が正しく動作しないケースがあります。</li>
                            <li>IAMロールを利用したい場合は、AWS Account Settingの一覧から、Role作成を行ってください。</li>
                          </ul>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">キャンセル</button>
                          <button type="button" class="btn btn-primary" v-on:click.prevent="bulkChangeNoSetting">設定不要に変更する</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="card-body">
              <div class="table-responsive pb-3">
                <table class="table table-no-bordered table-hover text-left" cellspacing="0" width="100%" style="width:100%">
                  <thead>
                    <tr class="white-space-nowrap">
                      <th></th>
                      <th>AWSアカウントID</th>
                      <th>別名</th>
                      <th v-if="permissions.show_organization_integration">Organizationsへの統合の有無</th>
                      <th v-if="permissions.show_bucket_name">Bucket Name</th>
                      <th>ステータス</th>
                      <th>IAM Role ARN</th>
                      <th>バージョン</th>
                      <th>Role作成・更新</th>
                    </tr>
                  </thead>
                  <tbody>
                  <template v-if="Object.keys(awsAccountList).length">
                    <tr v-for="(item, index) in awsAccountList">
                      <td>
                        <input @change="checkBulkCheckbox" type="checkbox" :value="item" v-model="checkedItems">
                      </td>
                      <td>
                        <a :href="`/mypage/settings/aws_accounts/${item.aws_account_id}/detail`">{{ item.aws_account_id }}</a>
                      </td>
                      <td>
                        <span v-on:mouseover="mouseOverAction(index)" v-on:mouseleave="mouseLeaveAction()">{{ shortAliasName(item.alias_name) }}</span>
                        <template v-if="hover.flag && index === hover.index">
                          <div class="tooltip-text">
                              {{item.alias_name}}
                          </div>
                        </template>
                      </td>
                      <td style="text-align: center" v-if="permissions.show_organization_integration">
                        <input type="checkbox" v-model="item.linked" readonly onclick="return false;" onfocus="this.blur();">
                      </td>
                      <td v-if="permissions.show_bucket_name">
                        {{item.bucket_name}}
                      </td>
                      <td>
                        <template v-if=statusValidation(item.status)>
                          <span class="text-danger font-weight-bold">{{ statusValue(item.status) }}</span>
                        </template>
                        <template v-else>
                          {{ statusValue(item.status) }}
                        </template>
                      </td>
                      <td>
                        <template v-if="item.status === 'done'">
                          {{ item.iam_role_arn }}
                        </template>
                        <template v-else>
                          -
                        </template>
                      </td>
                      <td>
                        <template v-if="item.status === 'done'">
                          <template v-if=versionValidation(item.version)>
                            <span class="text-danger font-weight-bold">{{ versionValue(item.version) }}</span>
                          </template>
                          <template v-else>
                            {{ versionValue(item.version) }}
                          </template>
                        </template>
                        <template v-else>
                          -
                        </template>
                      </td>
                      <td>
                        <template v-if="((item.status === 'done' && item.version === 'new') || item.status === 'setting')">
                        </template>
                        <template v-else>
                          <a class="text-info" :href="`/mypage/settings/aws_accounts/role/${item.aws_account_id}/edit`">
                            <i class="material-icons">description</i>
                          </a>
                        </template>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="9">
                        アカウントはありません
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
                <Pagination ref="pagination" :pageNumber.number="pageNumber" :totalPages.number="totalPages" v-on:emitPage="updatePage"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue"
import {getAwsAccounts, deleteAwsAccounts, updateStatus} from "../lib/axios/api_client"
import {status, version} from "../utils/aws_accounts"
import Alert from "../components/alert_new.vue"
import Pagination from "../components/pagination.vue"

const alert = ref(null)
const pagination = ref(null)

const statusList = status
const versionList = version
const initialized = ref(false)
const awsAccountList = ref([])
const permissions = ref({})
const pageNumber = ref("")
const totalPages = ref("")
const awsAccountErrorFlag = ref(false)
const disabledBulkDelete = ref(true)
const disabledBulkChangeNoSetting = ref(true)
const checkedItems = ref([])
const hover = ref({})
hover.value = {
  flag: false,
  index: null
}

const mouseOverAction = (index) => {
  hover.value.flag = true
  hover.value.index = index
}

const mouseLeaveAction = () => {
  hover.value.flag = false
}

const shortAliasName = (aliasName) => {
  if(aliasName.length > 15){
    return aliasName.substring(0, 15) + "..."
  }
  return aliasName
}

const statusValue = (target) => {
  const status = statusList.find(item => target === item.value)
  return status.name
}

const statusValidation = (status) => {
  return status === "no" || status === "error" || status === "cur-inactive"
}

const versionValue = (target) => {
  const version = versionList.find(item => target === item.value)
  return version.name
}

const versionValidation = (version) => {
  return version === "old"
}

const initializeBulkCheckbox = () => {
  disabledBulkDelete.value = true
  disabledBulkChangeNoSetting.value = true
}

const checkBulkCheckbox = () => {
  initializeBulkCheckbox()

  let statuses = checkedItems.value.map((item) => item.status)

  // 未選択の場合そのままdisable
  if (statuses.length === 0) return

  // 削除ボタン
  disabledBulkDelete.value = false

  // ステータスが設定不要以外のアカウントを選択
  if (statuses.includes('no-setting') === false) {
    disabledBulkChangeNoSetting.value = false
  }
}

const updatePage = async (page) => {
  initialized.value = false

  const result = await getterAwsAccounts(page)
  if(result){
    awsAccountList.value = result.aws_accounts
    permissions.value = result.permissions
    pageNumber.value = result.page_number
    totalPages.value = result.total_pages

    awsAccountErrorFlag.value = judgeAwsAccountErrorFlag()
  }

  initialized.value = true
}

const getterAwsAccounts = async (page) => {
  const params = {params: {page: page}}
  const res = await getAwsAccounts(params)
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }
  return res.data
}

const judgeAwsAccountErrorFlag = () => {
  const result = awsAccountList.value.find(item => item.status === "error" || item.status === "no" )
  return result ? true : false
}

const updateAccounts = async (ids, status) => {
  const params = { aws_account_ids: ids, status: status }
  const res = await updateStatus(params)
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }
  alert.value.displayModal('成功しました。','success')
}

const deleteAccounts = async (ids) => {
  const params = { aws_account_ids: ids }
  const res = await deleteAwsAccounts(params)
  if(!res.successful){
    alert.value.displayModal(res.message, 'error')
    return false
  }
  alert.value.displayModal('削除しました。','success')
}

const bulkUpdate = async (status, confirm) => {
  if (confirm) {
    if (!window.confirm(confirm)) return
  }
  const ids = checkedItems.value.map((item) => item.aws_account_id)

  if (status === 'no-setting') {
    await updateAccounts(ids, status)
    $('#changeNoSettingModal').modal('hide')
  } else {
    await deleteAccounts(ids)
  }

  checkedItems.value = []
  initializeBulkCheckbox()
  await updatePage(pageNumber.value)
}

const bulkChangeNoSetting = (e) => {
  bulkUpdate('no-setting')
}

const bulkDelete = (e) => {
  bulkUpdate('delete', '選択した項目を本当に削除しますか？\n管理アカウントの場合は紐づいたメンバーアカウントも一緒に削除します。')
}

onMounted( async () => {
  await updatePage(0)
})

</script>
<style scoped>
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.tooltip-text{
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  padding: 0.1rem 0.2rem;
  z-index: 1000;
  border: 1px solid #dddddd;
  border-radius: 0.25rem;
}
.warn-text {
  font-size: 0.8rem
}
</style>
// report_type
export const reportTypeCostExplorer  = "cost_explorer"
export const reportTypeRiCoverage    = "ri_coverage_report"
export const reportTypeRiUtilization = "ri_utilization_report"
export const reportTypeRiRecommend   = "ri_recommendation"
export const reportTypeSpCoverage    = "sp_coverage_report"
export const reportTypeSpUtilization = "sp_utilization_report"
export const reportTypeSpRecommend   = "sp_recommendation"
export const assetUsageDetail = "asset_ec2_detail"
export const assetUsageHistory = "asset_ec2_history"
export const reportTypeRightSizingEc2 = "rightsizing_ec2"
export const reportTypeRightSizingEbs = "rightsizing_ebs"
export const reportTypeRightSizingEip = "rightsizing_eip"
export const reportTypeRightSizingElb = "rightsizing_elb"
export const homeSummary = "home_summary"
export const homeTrend = "home_trend"
export const homeGraph = "home_graph"
export const budgetList = "budget_list"
export const budgetListJpy = "budget_list_jpy"
export const budgetHistory = "budget_history"
export const budgetHistoryJpy = "budget_history_jpy"
export const expireRiSp = "expire_ri_sp"
export const riSimulationSummary = "ri_simulation_summary"
export const spSimulationSummary = "sp_simulation_summary"

// report_type 例外
export const reportTypeMergedFilter   = "merged_filter"

// dashboard_size
export const dashboardSizeClass = {
    apply: {
        class: 'col-lg-9 col-md-9 col-sm-9',
        text: 'フィルターを隠す'
    },
    full: {
        class: 'col-lg-12 col-md-12 col-sm-12',
        text: 'フィルターを表示'
    },
    half: {
        class: 'col-lg-9 col-md-9 col-sm-9',
        text: 'フィルターを隠す'
    },
}

// looker default embed url
export const defaultUrl = "/blank"


// Rails path(route.rb)
export const pagePath = {
    dashboard: {
        cost_explorer:  '/mypage/cost_visualization/cost_viewer',
        ri_utilization_report: '/mypage/cost_visualization/reserved_usage',
        ri_coverage_report:    '/mypage/cost_visualization/reserved_coverage',
        sp_utilization_report: '/mypage/cost_visualization/savings_plans_usage',
        sp_coverage_report:    '/mypage/cost_visualization/savings_plans_coverage',
        asset_usage_detail: '/mypage/cost_visualization/usage/detail',
        asset_usage_history: '/mypage/cost_visualization/usage/history',
        rightsizing_ec2:        '/mypage/rightsizing/ec2',
        rightsizing_ebs:        '/mypage/rightsizing/ebs',
        rightsizing_eip:        '/mypage/rightsizing/eip',
        rightsizing_elb:        '/mypage/rightsizing/elb',
    },
    dashboardFilter: {
        list: '/mypage/home/filter',
        new:  '/mypage/home/filter/new',
        edit: '/mypage/home/filter/edit'
    },
    costAlert: {
        index: '/mypage/alert/setting/cost'
    },
    riSpAlert: {
        index: '/mypage/alert/setting/ri_sp'
    },
    rightsizingAlert: {
        index: '/mypage/alert/setting/rightsizing'
    },
    budgetAlert: {
        index: '/mypage/alert/setting/budget'
    },
    reportSubscription: {
        index: '/mypage/report_subscriptions'
    },
    payerAccount: {
        index: "/hc/ja/articles/39398402506905",
        re_registration: "/hc/ja/articles/39398481692313"
    },
    iamRole: {
        index: "https://nhn-techorus.zendesk.com/hc/ja/articles/19312545220249",
        delete: "https://nhn-techorus.zendesk.com/hc/ja/articles/19312545220249#idx_2"
    },
    iamRoleExt: {
        index: "/hc/ja/articles/39397850815513",
        delete: "/hc/ja/articles/39398647393945"
    },
    iAmRoleSetting: {
        local: "https://console.aws.amazon.com/cloudformation/home?region=ap-northeast-1#/stacks/new?stackName=alpha-nhn-techorus-cloud-illuminator-cross-account-access&templateURL=https://alpha-illuminator-common-cross-account-access.s3.ap-northeast-1.amazonaws.com/cf/cross-account-iam-role.yaml",
        devel: "https://console.aws.amazon.com/cloudformation/home?region=ap-northeast-1#/stacks/new?stackName=alpha-nhn-techorus-cloud-illuminator-cross-account-access&templateURL=https://alpha-illuminator-common-cross-account-access.s3.ap-northeast-1.amazonaws.com/cf/cross-account-iam-role.yaml",
        alpha: "https://console.aws.amazon.com/cloudformation/home?region=ap-northeast-1#/stacks/new?stackName=alpha-nhn-techorus-cloud-illuminator-cross-account-access&templateURL=https://alpha-illuminator-common-cross-account-access.s3.ap-northeast-1.amazonaws.com/cf/cross-account-iam-role.yaml",
        production: "https://console.aws.amazon.com/cloudformation/home?region=ap-northeast-1#/stacks/new?stackName=nhn-techorus-cloud-illuminator-cross-account-access&templateURL=https://production-illuminator-common-cross-account-access.s3.ap-northeast-1.amazonaws.com/cf/cross-account-iam-role.yaml"
    },
    cloudFormationJp: {
        local: "https://console.aws.amazon.com/cloudformation/home?region=ap-northeast-1#/stacks/new?stackName=alpha-nhn-techorus-cloud-illuminator-ext-payer-template-S3CostAndUsageReportBucket&templateURL=https://alpha-illuminator-common-ext-payer-template.s3.ap-northeast-1.amazonaws.com/S3CostAndUsageReportBucket/S3CostAndUsageReportBucket.yaml",
        devel: "https://console.aws.amazon.com/cloudformation/home?region=ap-northeast-1#/stacks/new?stackName=alpha-nhn-techorus-cloud-illuminator-ext-payer-template-S3CostAndUsageReportBucket&templateURL=https://alpha-illuminator-common-ext-payer-template.s3.ap-northeast-1.amazonaws.com/S3CostAndUsageReportBucket/S3CostAndUsageReportBucket.yaml",
        alpha: "https://console.aws.amazon.com/cloudformation/home?region=ap-northeast-1#/stacks/new?stackName=alpha-nhn-techorus-cloud-illuminator-ext-payer-template-S3CostAndUsageReportBucket&templateURL=https://alpha-illuminator-common-ext-payer-template.s3.ap-northeast-1.amazonaws.com/S3CostAndUsageReportBucket/S3CostAndUsageReportBucket.yaml",
        production: "https://console.aws.amazon.com/cloudformation/home?region=ap-northeast-1#/stacks/new?stackName=nhn-techorus-cloud-illuminator-ext-payer-template-S3CostAndUsageReportBucket&templateURL=https://production-illuminator-common-ext-payer-template.s3.ap-northeast-1.amazonaws.com/S3CostAndUsageReportBucket/S3CostAndUsageReportBucket.yaml"
    },
    cloudFormationUs: {
        local: "https://console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/new?stackName=alpha-nhn-techorus-cloud-illuminator-ext-payer-template-TechorusCostAndUsageReportCreate&templateURL=https://alpha-illuminator-common-ext-payer-template.s3.ap-northeast-1.amazonaws.com/TechorusCostAndUsageReportCreate/TechorusCostAndUsageReportCreate.yaml",
        devel: "https://console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/new?stackName=alpha-nhn-techorus-cloud-illuminator-ext-payer-template-TechorusCostAndUsageReportCreate&templateURL=https://alpha-illuminator-common-ext-payer-template.s3.ap-northeast-1.amazonaws.com/TechorusCostAndUsageReportCreate/TechorusCostAndUsageReportCreate.yaml",
        alpha: "https://console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/new?stackName=alpha-nhn-techorus-cloud-illuminator-ext-payer-template-TechorusCostAndUsageReportCreate&templateURL=https://alpha-illuminator-common-ext-payer-template.s3.ap-northeast-1.amazonaws.com/TechorusCostAndUsageReportCreate/TechorusCostAndUsageReportCreate.yaml",
        production: "https://console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/new?stackName=nhn-techorus-cloud-illuminator-ext-payer-template-TechorusCostAndUsageReportCreate&templateURL=https://production-illuminator-common-ext-payer-template.s3.ap-northeast-1.amazonaws.com/TechorusCostAndUsageReportCreate/TechorusCostAndUsageReportCreate.yaml"
    },
    invoice: {
        index: "https://invoice.nhn-techorus.com/nhn-techorus/",
        guide: "/hc/ja/articles/39391534941593",
        specification: "https://nhn-techorus.com/agreement/pdf/service_specification_cloud_illuminator_ext.pdf"
    },
    support: {
        index: "/hc/ja/requests/new"
    },
    zendeskUrl: {
        local: "https://ci-nhn-techorus-dev.zendesk.com",
        devel: "https://ci-nhn-techorus-dev.zendesk.com",
        alpha: "https://ci-nhn-techorus-dev.zendesk.com",
        production: "https://ci-nhn-techorus.zendesk.com"
    },
    budget: {
        index: "/mypage/budget"
    },
    signup: {
        index: "/signup",
        complete: "/signup/complete"
    },
    recommendSimulatorScenario: {
        index: "/mypage/recommend/simulator/scenario/list"
    }
}

// Rightsizing Setting Service Type
export const EC2 = "ec2"
export const EBS = "ebs"
export const EIP = "eip"
export const ELB = "elb"

export const month = [ "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12" ]

export  const pref = [
    { value: "01", name: "北海道" },
    { value: "02", name: "青森県" },
    { value: "03", name: "岩手県" },
    { value: "04", name: "宮城県" },
    { value: "05", name: "秋田県" },
    { value: "06", name: "山形県" },
    { value: "07", name: "福島県" },
    { value: "08", name: "茨城県" },
    { value: "09", name: "栃木県" },
    { value: "10", name: "群馬県" },
    { value: "11", name: "埼玉県" },
    { value: "12", name: "千葉県" },
    { value: "13", name: "東京都" },
    { value: "14", name: "神奈川県" },
    { value: "15", name: "新潟県" },
    { value: "16", name: "富山県" },
    { value: "17", name: "石川県" },
    { value: "18", name: "福井県" },
    { value: "19", name: "山梨県" },
    { value: "20", name: "長野県" },
    { value: "21", name: "岐阜県" },
    { value: "22", name: "静岡県" },
    { value: "23", name: "愛知県" },
    { value: "24", name: "三重県" },
    { value: "25", name: "滋賀県" },
    { value: "26", name: "京都府" },
    { value: "27", name: "大阪府" },
    { value: "28", name: "兵庫県" },
    { value: "29", name: "奈良県" },
    { value: "30", name: "和歌山県" },
    { value: "31", name: "鳥取県" },
    { value: "32", name: "島根県" },
    { value: "33", name: "岡山県" },
    { value: "34", name: "広島県" },
    { value: "35", name: "山口県" },
    { value: "36", name: "徳島県" },
    { value: "37", name: "香川県" },
    { value: "38", name: "愛媛県" },
    { value: "39", name: "高知県" },
    { value: "40", name: "福岡県" },
    { value: "41", name: "佐賀県" },
    { value: "42", name: "長崎県" },
    { value: "43", name: "熊本県" },
    { value: "44", name: "大分県" },
    { value: "45", name: "宮崎県" },
    { value: "46", name: "鹿児島県" },
    { value: "47", name: "沖縄県" }
]


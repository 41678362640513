<template>
  <UserMethods ref="methods"/>
  <NeoidMethods ref="neoIdMethods"/>
  <div v-if="!initialized">
    <div class="loader">Loading...</div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div v-if="errorMessage !== ''" class="contents-area">
          <table>
            <tr>
              <th>
                <p style="color: black">{{ errorMessage }}</p>
              </th>
            </tr>
            <tr>
              <td class="btn-col">
                <a class="nav-link btn btn-primary text-white" style="width: 240px" href="/login">ログインページへ戻る</a>
              </td>
            </tr>
          </table>
        </div>
        <div v-else>
          <div style="text-align: -webkit-center">
            <form id="create_user_form" class="card col-md-10 ml-auto mr-auto">
              <div class="card-header">
                <div class="title-part">
                  <div class="text-left">
                    <h4 class="card-title">ユーザー追加</h4>
                  </div>
                </div>
              </div>
              <div class="text-left">
                <h4 class="card-title-body" style="margin-left: 20px">{{ userInfoLabel }}</h4>
              </div>
              <div class="card-body">
                <div class="userInformation">
                  <div class="userInput">
                    <div class="label">
                      <label for="user_name">{{ userNameLabel }}</label>
                    </div>
                    <div class="formInput">
                      <input name="user_name" id="user_name" class="form-control" v-model="userNameInput" type="text" @input="validateUserName"/>
                    </div>
                    <p v-if="userNameValidationMessage" class="validationMessage">{{ userNameValidationMessage }}</p>
                  </div>
                  <div class="userInput">
                    <div class="label">
                      <span class="custom_label">{{ roleLabel }}</span>
                    </div>
                    <div class="formInput">
                      <p id="user_role">{{ roleTypeValue(createdUser.role_type) }}</p>
                    </div>
                  </div>
                  <div class="userInput">
                    <div class="label">
                      <span class="custom_label">{{ emailLabel }}</span>
                    </div>
                    <div class="formInput">
                      <p id="mail_address">{{ createdUser.email }}</p>
                    </div>
                  </div>
                  <div class="passwordInput">
                    <div class="label">
                      <label for="new_password">{{ newPwdLabel }}</label>
                    </div>
                    <div class="formInput">
                      <input v-model="inputPwdValueNew" name="new_password" id="new_password" class="form-control"
                             maxlength="19" :type='isShowNew?"text":"password"' @input="validatePasswordNew">
                    </div>
                    <div class="visibilityIcon">
                      <i v-show="isShowNew === true" class="material-icons" style="cursor: pointer" @click="pushShowButtonNew">visibility_off</i>
                      <i v-show="isShowNew === false" class="material-icons" style="cursor: pointer" @click="pushShowButtonNew">visibility</i>
                    </div>
                    <p v-if="pwdValidationMessageNew" class="validationMessage">{{ pwdValidationMessageNew }}</p>
                  </div>
                  <div class="passwordInput" style="margin-bottom: 20px;">
                    <div class="label">
                      <label for="password_confirm">{{ confirmPwdLabel }}</label>
                    </div>
                    <div class="formInput">
                      <input v-model="inputPwdValueConfirm" name="password_confirm" id="password_confirm" class="form-control"
                             maxlength="19" :type='isShowConfirm?"text":"password"' @input="validatePasswordConfirm">
                    </div>
                    <div class="visibilityIcon">
                      <i v-show="isShowConfirm === true" class="material-icons" style="cursor: pointer" @click="pushShowButtonConfirm">visibility_off</i>
                      <i v-show="isShowConfirm === false" class="material-icons" style="cursor: pointer" @click="pushShowButtonConfirm">visibility</i>
                    </div>
                    <p v-if="pwdValidationMessageConfirm" class="validationMessage">{{ pwdValidationMessageConfirm }}</p>
                  </div>
                </div>
                <div class="table-responsive" style="height: 300px">
                  <div class="title-part">
                    <div class="text-left">
                      <h4 class="card-title-body">{{ awsAccountsLabel }}</h4>
                    </div>
                  </div>
                  <table id="aws_account_List" class="table table-striped table-hover text-left" style="margin-top: 20px">
                    <thead>
                      <tr>
                        <template v-for="header in headerItems">
                          <TableTh :tableKey="header.key" v-on:emitTrigger="doSort">{{header.title}}</TableTh>
                        </template>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="account in createdUser.aws_accounts" :key="account.aws_account_id">
                        <td>
                          <div class="tableText">{{account.aws_account_id}}</div>
                        </td>
                        <td>
                          <div class="tableText">{{account.alias_name}}</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card-footer">
                <div class="buttonArea">
                  <div class="inline-block">
                    <button type="button" class="nav-link btn btn-primary text-white" style="width: 120px" @click="handleSubmit">確定</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-show="isModalOpen === true" id="authCodeModal" class="confirmModal" >
    <div class="modal">
      <div class="modal-header">
        <div class="text-left">
          <h4 style="color: black">ユーザの登録が完了しました。</h4>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <div class="inline-block">
          <a class="nav-link btn btn-primary text-white"
             href="/login">ログイン画面へ</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref, watch} from "vue";
import UserMethods from "../../components/user_methods.vue";
import TableTh from "../../components/table_th.vue";
import {findTableHeaderIndex, selectSort} from "../../lib/table";
import NeoidMethods from "../../components/neoid_methods.vue";
import { extractId } from "../../lib/common";
import { roleTypes } from "../../utils/user_constants"

const initialized = ref(false);
const errorMessage = ref('');
const accept = ref(false);
const userNameLabel = ref("ユーザー名");
const roleLabel = ref("権限");
const emailLabel = ref("メールアドレス");
const newPwdLabel = ref("パスワード");
const confirmPwdLabel = ref("パスワードの再入力");
const userInfoLabel = ref("ユーザー情報");
const awsAccountsLabel = ref("AWSアカウント情報");
const inputPwdValueNew = ref("");
const inputPwdValueConfirm = ref ("");
const userNameInput = ref("");
const validationUserNamePassed = ref(false);
const validationPwdNewPassed = ref(false);
const validationPwdConfirmPassed = ref(false);
const userNameValidationMessage = ref("");
const pwdValidationMessageNew = ref("");
const pwdValidationMessageConfirm = ref("");
const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,19}$/;
const roleTypeList = roleTypes;
const isShowNew = ref(false);
const isShowConfirm = ref(false);
const methods = ref(null);
const neoIdMethods = ref(null);
const isModalOpen = ref(false);
const tokenKey = ref("");
const email = ref("");
const createdUser = ref({});

onMounted(async () => {
  tokenKey.value = extractId(4);
  // 登録対象の初期表示処理
  if(tokenKey.value) {
    const result = await getUserAuthorized(tokenKey.value);
    if (result.accept) {
      await immediateRegister();
      return;
    }
    initialized.value = true;
  }
});

const doSort = (columnKey) => {
  const index = findTableHeaderIndex(headerItems.value, columnKey);
  const isSort = headerItems.value[index].sort;
  const sort = selectSort(isSort, columnKey);

  createdUser.value.aws_accounts.sort(sort);
  headerItems.value[index].sort = !isSort;
}

const pushShowButtonNew = () => { isShowNew.value = !isShowNew.value; }
const pushShowButtonConfirm = () => { isShowConfirm.value = !isShowConfirm.value; }

const validateUserName = () => {
  if (userNameInput.value === "") {
    userNameValidationMessage.value = userNameLabel.value+"を入力してください。";
    validationUserNamePassed.value = false;
  } else if (userNameInput.value.length > 100) {
    userNameValidationMessage.value = "ユーザー名は100文字以下で設定してください。";
    validationUserNamePassed.value = false;
  } else {
    userNameValidationMessage.value = "";
    validationUserNamePassed.value = true;
  }
}

const validatePasswordNew = () => {
  if (inputPwdValueNew.value === "") {
    pwdValidationMessageNew.value = newPwdLabel.value+"を入力してください。";
    validationPwdNewPassed.value = false;
  } else if (!passwordRegex.test(inputPwdValueNew.value)) {
    pwdValidationMessageNew.value = newPwdLabel.value+"の入力形式が正しくありません。";
    validationPwdNewPassed.value = false;
  } else {
    pwdValidationMessageNew.value = "";
    validationPwdNewPassed.value = true;
  }
}

const validatePasswordConfirm = () => {
  if (inputPwdValueConfirm.value === "") {
    pwdValidationMessageConfirm.value = confirmPwdLabel.value+"を入力してください。";
    validationPwdConfirmPassed.value = false;
  } else if (inputPwdValueConfirm.value !== inputPwdValueNew.value) {
    pwdValidationMessageConfirm.value = newPwdLabel.value+"と確認用パスワードが一致しません。";
    validationPwdConfirmPassed.value = false;
  } else {
    pwdValidationMessageConfirm.value = "";
    validationPwdConfirmPassed.value = true;
  }
}

const getUserAuthorized = async (tokenKey) => {
  const res = await methods.value?.getUserAuthorized(tokenKey);
  if (!res.successful) {
    errorMessage.value = res.message;
  } else {
    createdUser.value = res.data.user;
  }
  return res.data;
}

const generalRegister = async () => {
  const { ip, sessionKey, rsaString } = await neoIdMethods.value?.generateRSAKeys(createdUser.value.email, inputPwdValueNew.value, "SIGN_UP");
  const param = {
    token_key: tokenKey.value,
    session_key: sessionKey,
    username: userNameInput.value,
    encrypt_string: rsaString
  }

  await onSubmit(param);
}

const immediateRegister = async () => {
  const param = {
    token_key: tokenKey.value,
    session_key: 'dummy',
    username: 'dummy',
    encrypt_string: 'dummy'
  }

  await onSubmit(param);
}

const onSubmit = async(param) => {
  const result = await methods.value?.createUserAuthorized(param);
  if (result) {
    isModalOpen.value = true;
  }
}

const handleSubmit = async () => {
  validateUserName();
  validatePasswordNew();
  validatePasswordConfirm();
  if (validationUserNamePassed.value === true && validationPwdNewPassed.value === true && validationPwdConfirmPassed.value === true) {
    await generalRegister();
  }
}

const headerItems = ref([
  { title: "AWSアカウントID", key: "aws_account_id", sort: false },
  { title: "AWSアカウント別名", key: "alias_name", sort: false },
]);

const roleTypeValue = (target) => {
  const roleType = roleTypeList.find(item => target === item.value);
  return roleType.name;
}

watch(userNameInput, inputPwdValueNew, inputPwdValueConfirm, () => {
  validateUserName();
  validatePasswordNew();
  validatePasswordConfirm();
})

</script>
<style scoped>

.card {
  z-index: 0;
}

.card-body {
  text-align: left;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.title-part {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(221, 221, 221);
  height: 60px;
}

.card-title {
  font-size: 22px;
  font-weight: 300;
  margin-top: 10px;
}

.card-title-body {
  margin-top: 10px;
}

.label {
  display: inline-block;
  width: 150px;
  height: 50px;
}

.custom_label {
  color: #aaa;
}

.userInformation{
  border-bottom: 1px solid rgb(221, 221, 221);
}

.formInput {
  display: inline-block;
  width: 400px;
}

.visibilityIcon {
  display: inline-block;
  vertical-align: middle;
  padding-top: 10px;
}

.validationMessage {
  color : red;
  display: inline-block;
  font-size: 12px;
  margin-left: 10px;
}

.table-responsive {
  margin-top: 10px;
}

.table table-striped table-hover text-left {
  width:100%;
}

.confirmModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: white;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 20%;
  margin-left: 30%;
  display: block;
  height: 200px;
}

.contents-area {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-col {
  display: flex;
  justify-content: center;
}

</style>
<template>
  <Alert ref="alert"/>
  <div v-if="!initialized">
    <div class="loader">Loading...</div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              <div class="row">
                <div class="col">一覧</div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <ul class="tabs">
              <template v-for="categoryType in categoryTypes">
                <li v-on:click="change(categoryType.type, categoryTypes.indexOf(categoryType))" v-bind:class="{'active': isActive === categoryType.type}">{{ categoryType.name }}</li>
              </template>
            </ul>

            <ul class="contents">
              <template v-for="categoryType in categoryTypes">
                <li v-show="isActive === categoryType.type">
                  <IndexNotice :categoryType="categoryType.type" ref="indexNotice"/>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IndexNotice from "../components/index_notice.vue";
import Alert from "../components/alert_new.vue";
import { extractCategoryType } from "../lib/common";
import { categoryTypes } from "../utils/notice";

export default {
  components: {
    IndexNotice,
    Alert
  },
  data() {
    return {
      categoryTypes: categoryTypes,
      isActive: "info",
      categoryType: "info",
      noticeList: [],
      initialized: false,
    }
  },
  mounted: async function() {
    const categoryType = extractCategoryType();
    if(!categoryType){
      return false;
    }
    this.categoryType = categoryType;
    this.isActive = categoryType;

    this.initialized = true;
  },
  methods: {
    change: async function(type, index){
      await this.$refs.indexNotice[index].updatePage(0)
      this.isActive = type;
    }
  }
}
</script>
<style scoped>
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.tabs {
  overflow: hidden;
}
.tabs li,
.tabs label {
  float: left;
  padding: 10px 20px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: .3s;
}
.tabs li:not(:first-child),
.tabs label:not(:first-of-type) {
  border-left: none;
}
.tabs li.active,
.tabs :checked + label {
  background-color: #002341;
  border-color: #000;
  color: #fff;
  cursor: auto;
}
.contents {
  overflow: hidden;
  margin-top: -1px;
}
.contents li {
  padding: 20px;
  border: 1px solid #ccc;
}

.jquery .contents li {
  display: none;
}
.jquery .contents li.active {
  display: block;
}

.vue_radio input {
  display: none;
}
</style>

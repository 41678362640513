<template>
  <NeoidMethods ref="neoidMethods"/>
  <div>
    <div class="card card-login card-hidden">
      <div class="card-header text-center my-4">
        <h4 class="card-title">neoid セッションキー生成</h4>
      </div>
      <div class="card-body">
        <form>
          <div class="mb-2">
            <div class="form-group">
              <label for="loginMailInput">メールアドレス</label>
              <input id="loginMailInput" type="email" class="form-control" required v-model="email">
            </div>
            <div class="form-group">
              <label for="loginPasswordInput">パスワード</label>
              <input type="text" class="form-control" id="loginPasswordInput" minlength="8" maxlength="48" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,48}$" required placeholder="8文字以上、大・小文字・記号を必ず1文字以上ずつ含んでください。" v-model="password">
            </div>
          </div>
          <div class="mb-2">
            <div class="justify-content-start">
              <div>
                <button type="button" class="btn btn-primary" v-on:click="getIpAddress">IPアドレスを取得する</button>
              </div>
            </div>
            <div class="form-group">
              <label for="loginPasswordInput">IPアドレス</label>
              <input type="text" class="form-control" id="loginPasswordInput"  required  v-model="ip">
            </div>
          </div>
          <div class="mb-2">
            <div class="form-group">
              <label for="sessionType">セッションタイプを選ぶ</label>
              <div>
                <select class="form-control" v-model="type" id="sessionType" style="position: relative">
                  <option value="LOGIN">LOGIN</option>
                  <option value="SIGN_UP">SIGN_UP</option>
                  <option value="ID_CHANGE">ID_CHANGE</option>
                  <option value="CHANGE_PASSWORD">CHANGE_PASSWORD</option>
                  <option value="CHECK_PASSWORD">CHECK_PASSWORD</option>
                  <option value="RESET_PASSWORD">RESET_PASSWORD</option>
                </select>
              </div>
            </div>
          </div>
          <div class="mb-2">
            <div class="justify-content-start">
              <button type="button" class="btn btn-primary" v-on:click="getRsaSessionKey">RSAセッションキーを作成する</button>
            </div>
            <div class="form-group">
              <label for="loginPasswordInput">セッションキー</label>
              <input type="text" class="form-control" id="loginPasswordInput"  required  v-model="sessionKey">
            </div>
            <div class="form-group">
              <label for="loginPasswordInput">暗号化されたキー</label>
              <textarea type="text" class="form-control" id="loginPasswordInput" rows="5" v-model="encryptString"/>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script setup>
import NeoidMethods from "../../components/neoid_methods.vue";
import {ref } from "vue";

const neoidMethods = ref(null)

const email = ref("dl_cloud_illuminator_common_local@nhn-techorus.com")
const password = ref("Password123$")

const ip = ref("")
const type = ref("LOGIN")
const sessionKey = ref("")
const encryptString = ref("")

const getIpAddress = async () => {
  ip.value = await neoidMethods.value?.getIpAddress()
}

const getRsaSessionKey = async () => {
  const result = await neoidMethods.value?.getRsaSessionKey(ip.value, type.value)
  const publicKeyModulus = result.body.public_key_modulus
  const publicKeyExponent = result.body.public_key_exponent
  sessionKey.value = result.body.session_key
  encryptString.value = neoidMethods.value?.encryptString(publicKeyModulus, publicKeyExponent, sessionKey.value, email.value, password.value)
}
</script>

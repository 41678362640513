<template>
  <div class="cursor-pointer" v-on:mouseover="hover = true" v-on:mouseleave="hover = false">
    {{ text }}
  </div>
  <template v-if="hover && tooltipText !== ''">
    <div class="tooltip-text">
      {{ tooltipText }}
    </div>
  </template>
</template>
<script setup>
import { ref } from "vue";

const props = defineProps({
  text: String,
  tooltipText: String,
})

let hover = ref (false)

</script>
<style scoped>
.tooltip-text{
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  padding: 0.1rem 0.2rem;
  z-index: 1000;
  border: 1px solid #dddddd;
  border-radius: 0.25rem;
}
</style>

import axiosBase from "axios";

async function getAxios(url, params = { params: {} }){
    let result = defaultParams()
    const axios = axiosClient()

    await axios.get(url, params).then( response => {
          result = createResultParams(response)
        }).catch(error => {
          result = createErrorParams(error)
        })
    return result
}

async function postAxios(url, params = {}){
  let result = defaultParams()
  const axios = axiosClient()

  await axios.post(url, params).then( response => {
    result = createResultParams(response)
  }).catch(error => {
    result = createErrorParams(error)
  })

  return result
}

async function putAxios(url, params = {}){
  let result = defaultParams()
  const axios = axiosClient()

  await axios.put(url, params).then( response => {
    result = createResultParams(response)
  }).catch(error => {
    result = createErrorParams(error)
  })
  return result
}

async function patchAxios(url, params = {}){
  let result = defaultParams()
  const axios = axiosClient()

  await axios.patch(url, params).then( response => {
    result = createResultParams(response)
  }).catch(error => {
    result = createErrorParams(error)
  })
  return result
}

async function deleteAxios(url, params = {}){
  let result = defaultParams()
  const axios = axiosClient()

  await axios.delete(url, params).then( response => {
    result = createResultParams(response)
  }).catch(error => {
    result = createErrorParams(error)
  })
  return result
}

async function pdfAxios(url, params = { params: {} }){
  let result = defaultParams()
  const axios = pdfAxiosClient()

  await axios.get(url, params).then( response => {
    result = createPdfResultParams(response)
  }).catch(error => {
    result = createPdfErrorParams(error)
  })
  return result
}

export {
  getAxios,
  postAxios,
  putAxios,
  patchAxios,
  deleteAxios,
  pdfAxios
}

function axiosClient(){
    const axios = axiosBase.create({
        baseURL: document.location.origin + "/api/v1.0",
        xsrfCookieName:'XSRF-TOKEN',
        xsrfHeaderName: 'X-XSRF-Token',
        withCredentials: true,
        headers: {
            "X-Requested-With": "XMLHttpRequest",
        },
        responseType: 'json',
        timeout: 30000
    });

    return axios
}

function pdfAxiosClient(){
  const axios = axiosBase.create({
    baseURL: document.location.origin + "/api/v1.0",
    xsrfCookieName:'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-Token',
    withCredentials: true,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      'Content-Type': 'application/pdf',
      'Accept': 'application/pdf'
    },
    responseType: 'arraybuffer',
    timeout: 30000
  });

  return axios
}

function errorMessage(error){
    let message = 'サーバーでの処理に失敗しました。'

    if(error.response.status >= 500){
        message = error.response.data.message ? error.response.data.message : message
    }else if(error.response.data.message){
        message = error.response.data.message
    }

    return message
}

function defaultParams(){
  return {
    code: 999,
    data: '',
    successful: false,
    message: ''
  }
}

function createResultParams(response){
  let result = defaultParams()
  result.code = response.data.header.result_code
  result.message = response.data.header.result_message
  result.successful = response.data.header.successful
  result.data = response.data
  return result
}

function createPdfResultParams(response){
  let result = defaultParams()
  result.code = 200
  result.message = "success"
  result.successful = true
  result.data = response.data
  return result
}

function createErrorParams(error){
  let result = defaultParams()
  result.code    = error.response.status
  result.message = errorMessage(error)
  return result
}

function createPdfErrorParams(error){
  let result = defaultParams()
  result.code    = 1999
  result.message = 'サーバーでの処理に失敗しました'
  return result
}

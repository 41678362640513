<template>
  <div>
    <Alert ref="alert"/>
    <div v-if="!initialized">
      <div class="loader">Loading...</div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                <div class="row">
                  <div class="col">一覧</div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <ul class="nav">
                <li class="nav-item">
                  <a
                    :class="`nav-link btn btn-primary${contractTotalCount >= limit ? ' disabled' : ''}`"
                    href="/mypage/alert/setting/cost/new"
                  >
                    新規
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    :class="`nav-link btn btn-primary${disabledBulkDelete ? ' disabled' : ''}`"
                    :disabled="disabledBulkDelete"
                    @click.prevent="bulkDelete"
                    href="#"
                  >
                    削除
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    :class="`nav-link btn btn-primary${disabledBulkLock ? ' disabled' : ''}`"
                    :disabled="disabledBulkLock"
                    @click.prevent="bulkLock"
                    href="#"
                  >
                    ロック
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    :class="`nav-link btn btn-primary${disabledBulkUnlock ? ' disabled' : ''}`"
                    :disabled="disabledBulkUnlock"
                    @click.prevent="bulkUnlock"
                    href="#"
                  >
                    ロック解除
                  </a>
                </li>
              </ul>
              <div class="text-secondary mb-4">
                現在の登録件数は{{ contractTotalCount}}件です。（最大{{ limit }}件まで登録できます）<br/>
                ※ユーザの権限が利用者の場合は一覧に表示されていない場合があります。
              </div>
              <table id="cost_alert-list" class="table table-borderless">
                <thead>
                  <tr>
                    <th></th>
                    <th>通知有無</th>
                    <th>連結アカウント</th>
                    <th>サービス</th>
                    <th>設定金額($)</th>
                    <th>事前通知</th>
                    <th>通知先</th>
                    <th>ロック</th>
                    <th>アクション</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="Object.keys(costAlerts).length">
                    <tr v-for="(costAlert, i) in costAlerts" :key="i">
                      <td>
                        <input @change="checkBulkCheckbox" type="checkbox" :value="costAlert.id" v-model="checkedIds">
                      </td>
                      <td>{{ costAlert.notification ? '有効' : '無効' }}</td>
                      <td>{{ costAlert.aws_account_id || '全て' }}</td>
                      <td>{{ costAlert.aws_service || '全て' }}</td>
                      <td>{{ costAlert.borderline.toLocaleString() }}</td>
                      <td>{{ costAlert.pre_notification ? '有効' : '無効' }}</td>
                      <td>
                        <span v-on:mouseover="mouseOverAction(i)" v-on:mouseleave="mouseLeaveAction(i)">
                          <template v-if="costAlert.send_all_user">
                            全てのユーザ
                          </template>
                          <template v-else-if="Object.keys(costAlert.user_mails).length !== 0">
                            <i class="material-icons" id="account_icon">account_circle</i> {{ costAlert.user_mails[Object.keys(costAlert.user_mails)[0]] }}
                          </template>
                          <template v-else>
                            {{costAlert.mails[0]}}
                          </template>
                          <template v-if="checkSettingsMultiple(costAlert)">…</template>
                        </span>
                        <template v-if="hover.flag && i === hover.index">
                          <div class="tooltip-text">
                            <template v-if="costAlert.send_all_user">
                              全てのユーザ<br>
                            </template>
                            <template v-else>
                              <template v-for="user_mail in costAlert.user_mails">
                                <i class="material-icons" id="account_icon">account_circle</i> {{ user_mail }}<br>
                              </template>
                            </template>
                            <template v-for="mail in costAlert.mails">
                              {{ mail }}<br>
                            </template>
                            <template v-if="costAlert.slack_url">
                              {{ costAlert.slack_url }}
                            </template>
                          </div>
                        </template>
                      </td>
                      <td>
                        <a v-if="costAlert.lock" @click.prevent="update('unlock', costAlert.id)" class="text-info" href="#">
                          <i class="material-icons">lock</i>
                        </a>
                        <a v-else @click.prevent="update('lock', costAlert.id)" class="text-info" href="#">
                          <i class="material-icons">lock_open</i>
                        </a>
                      </td>
                      <td>
                        <a v-if="!costAlert.lock" class="text-info" :href="`/mypage/alert/setting/cost/${costAlert.id}/edit`">
                          <i class="material-icons">article</i>
                        </a>
                        <a v-if="!costAlert.lock" @click.prevent="update('delete', costAlert.id, '本当に削除しますか？')" class="text-danger" href="#">
                          <i class="material-icons">delete</i>
                        </a>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="9">
                        設定はありません
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
              <Pagination ref="pagination" :pageNumber.number="pageNumber" :totalPages.number="totalPages" v-on:emitPage="updatePage"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getAlerts, lockAlerts, unlockAlerts, deleteAlert } from "../lib/axios/api_client";
  import Alert from "../components/alert_new.vue";
  import Pagination from "../components/pagination.vue";

  export default {
    async mounted() {
      await this.updatePage(0);
      this.initialized = true;
    },
    components: {
        Alert: Alert,
        Pagination: Pagination
    },
    data: function () {
      return {
        costAlerts: [],
        checkedIds: [],
        disabledBulkDelete: true,
        disabledBulkLock: true,
        disabledBulkUnlock: true,
        initialized: false,
        pageNumber: 0,
        totalPages: 1,
        hover: {
          flag: false,
          index: null
        },
        limit: -1,
        contractTotalCount: -1,
      }
    },
    computed: {
    },
    methods: {
      mouseOverAction(index) {
        this.hover.flag = true
        this.hover.index = index
      },
      mouseLeaveAction() {
        this.hover.flag = false
      },
      checkSettingsMultiple(costAlert) {
        return (costAlert.send_all_user? 1 : 0) + Object.keys(costAlert.user_mails).length + costAlert.mails.length + (costAlert.slack_url? 1 : 0) >= 2
      },
      updatePage: async function(page) {
        const result = await this.getCostAlerts(page);
        let costAlerts = {};
        result.cost_alerts.forEach((costAlert) => {
          costAlerts[costAlert.id] = costAlert;
        });
        this.costAlerts = costAlerts;
        this.pageNumber = result.page_number;
        this.totalPages = result.total_pages;

        this.limit = result.limit;
        this.contractTotalCount = result.contract_total_count;

        this.checkedIds = [];
        this.initializeBulkCheckbox();
      },
      getCostAlerts: async function(page) {
        const params = {params: {page: page}};
        const res = await getAlerts(params);
        if (!res.successful) {
          this.$refs.alert.displayModal(res.message, 'error');
          return false;
        }
        return res.data;
      },
      initializeBulkCheckbox: function () {
        this.disabledBulkDelete = true;
        this.disabledBulkLock = true;
        this.disabledBulkUnlock = true;
      },
      checkBulkCheckbox: function () {
        this.initializeBulkCheckbox();

        let lockTypes = this.checkedIds.map((id) => {
          return this.costAlerts[id].lock;
        }).filter(function(elem, index, self) {
          return self.indexOf(elem) === index;
        });

        // 未選択、もしくはLockとUnlockのデータが選ばれた場合、まとめて実行はできない
        if (lockTypes.length !== 1) return;

        // ロックされていないデータのみ選択
        if (lockTypes[0] === false) {
          this.disabledBulkDelete = false;
          this.disabledBulkLock = false;
        // ロックされているデータのみ選択
        } else {
          this.disabledBulkUnlock = false;
        }
      },
      update: async function (action, id, confirm) {
        if (confirm) {
          if (!window.confirm(confirm)) {
             this.initialized = true;
             return;
          }
        }
        const methods = {
          lock: lockAlerts,
          unlock: unlockAlerts,
          delete: deleteAlert,
        }
        const res = await methods[action](id);
        if(!res.successful){
          this.$refs.alert.displayModal(res.message, 'error')
          return false
        }
        this.$refs.alert.displayModal('成功しました。','success')
        await this.updatePage(this.pageNumber);
        if (action === 'delete') {
          this.deleteAndUpdate();
        }
        this.initialized = true;
      },
      bulkUpdate: function (action) {
        this.initialized = false;
        let ids = this.checkedIds.join(',');
        this.update(action, ids, action == 'delete' ? '本当に削除しますか？' : null);
      },
      bulkDelete: function (e) {
        this.bulkUpdate('delete');
      },
      bulkLock: function (e) {
        this.bulkUpdate('lock');
      },
      bulkUnlock: function (e) {
        this.bulkUpdate('unlock');
      },
      deleteAndUpdate: async function () {
        if (Object.keys(this.costAlerts).length === 0 && this.pageNumber !== 0) {
          await this.updatePage(this.pageNumber - 1);
        }
      }
    },
  }
</script>

<style scoped>
.tooltip-text{
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  padding: 0.1rem 0.2rem;
  z-index: 1000;
  border: 1px solid #dddddd;
  border-radius: 0.25rem;
}
#account_icon{
  font-size: 14px;
  vertical-align: middle;
}
</style>

<template>
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-4">
      <div class="progress-container">
        <div class="progress progress-margin">
          <div class="progress-bar" role="progressbar" :aria-valuenow="ratio" aria-valuemin="0" aria-valuemax="100" :style="`width: ${ratio}%;`">
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 progress-ratio">{{ ratio }}</div>
  </div>
</template>
<script setup>
defineProps({ ratio: Number })
</script>
<style scoped>
.progress {
  background: #edeeee;
}
.progress-bar {
  background-color: #97a3a4;
}
.progress-margin {
  margin-bottom: 0;
  margin-top: 9px;
}
@media (min-width: 768px) {
  .progress-ratio {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>

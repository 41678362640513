<template>
  <div class="mb-3">
    <div class="row">
      <template v-for="key in Object.keys(monthly_budgets)">
        <div class="col-lg-3 col-md-4 col-sm-6 mt-2">
          <div class="d-inline-block width-30">{{ key }}: </div>
          <input
            :class="`form-control d-inline-block text-right width-70 ${ compareDate(key) ? 'disabled' : '' }`"
            type="number"
            min="0"
            max="10000000"
            step="0.01"
            v-model="monthly_budgets[key]" @click="emits('emitTrigger', key, monthly_budgets[key])">
        </div>
      </template>
    </div>
  </div>
  <div class="mb-3" v-if="currency==='jpy'">
    <span>円表示(予算レートで換算しています)</span>
    <div class="row">
      <template v-for="key in Object.keys(monthly_budgets)">
        <div class="col-lg-3 col-md-4 col-sm-6 mt-2">
          <div class="d-inline-block width-30">{{ key }}: </div>
          <div class="d-inline-block text-right width-70">{{ (monthly_budgets[key] * exchange_rate).toFixed(2) }}</div>
        </div>
      </template>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  monthly_budgets: Object,
  currency: String,
  exchange_rate: Number
})
const emits = defineEmits( ["emitTrigger"])

const compareDate = (yearMonth) => {
  const base = new Date();
  const baseDate = createDate(base.getFullYear(), base.getMonth())
  const yearMonthArray = yearMonth.split("-")
  const target = createDate(parseInt(yearMonthArray[0]), parseInt(yearMonthArray[1])-1)
  return target < baseDate
}

const createDate = (year, month) => {
  return new Date(year, month,1,0,0,0)
}

</script>
<style>
.width-30 {
  width: 30%
}
.width-70 {
  width: 70%
}
</style>

<template>
  <div>
    <ServiceAccountMethods ref="methods"/>
    <div v-if="!initialized">
      <div class="loader">Loading...</div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <form v-on:submit.prevent="updateContract(contract.alias_name)">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pull-left">
                  <ul class="nav">
                    <li class="nav-item">
                      <button type="submit" class="btn btn-primary">保存</button>
                    </li>
                  </ul>
                </div>
                <div class="pull-right">
                  <ul class="nav">
                    <li class="nav-item">
                      <a
                        class="nav-link btn btn-default"
                        href="/mypage/service-account/"
                      >
                        キャンセル
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header card-header-rose card-header-icon">
                <div class="card-title">
                  <h4>契約情報</h4>
                </div>
              </div>
              <div class="card-body">
                <table class="table table-borderless table-responsive">
                  <tbody>
                    <tr>
                      <th>契約ID</th>
                      <td>{{ contract.signup_id }}</td>
                    </tr>
                    <tr>
                      <th>別名</th>
                      <td>
                        <div class="form-group bmd-form-group form-width-200" >
                          <input type="text" class="form-control" minlength="0" maxlength="64" v-model="contract.alias_name"/>
                          <small class="form-text text-muted">別名は64文字までです。</small>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import ServiceAccountMethods from "../../../components/service_account_methods.vue";

const methods = ref(null)
let initialized = ref(false)
let contract = ref(
  {
    signup_id: 0,
    alias_name: "",
    contract_start_date: "",
    monthly_fee: 0,
    permissions: {
      show_start_contract: false,
      show_monthly_fee: true,
      show_monthly_fee_evaluation_message: false,
      show_edit_button: false,
      show_quit_button: false,
      show_formal_contract_guidance: false,
      show_formal_contract_button: false,
      show_cp_contract_guidance: false,
      show_cp_contract_button: false
    }
  }
)

const getContract = async () => {
  const result = await methods.value?.getContract()
  if (!result) {
    return false
  }
  return result
}

const updateContract = async (aliasName) => {
  const params = { alias_name: aliasName }
  const result = await methods.value?.updateContract(params)
  if (!result) {
    return false
  }
  setTimeout(_transition, 1000)
  return true
}

const _transition = () => {
  window.location.href = "/mypage/service-account/"
}

onMounted(async () => {
  const result = await getContract()
  if(result){
    contract.value = result
    initialized.value = true
  }
  initialized.value = true
})

</script>
<style scoped>
.table > tbody > tr > th {
  vertical-align: middle;
}
.form-width-200 {
  width: 200px;
}
</style>

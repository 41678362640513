export const recommendTypes= [
  {
    name: "RI(EC2),standard"
  },
  {
    name: "RI(EC2),convertible"
  },
  {
    name: "RI(RDS)"
  },
  {
    name: "RI(Redshift)"
  },
  {
    name: "RI(OpenSearch)"
  },
  {
    name: "RI(ElastiCache)"
  },
  {
    name: "SP(ComputeSavingsPlans)"
  },
  {
    name: "SP(EC2InstanceSavingsPlans)"
  },
  {
    name: "SP(SageMakerSavingsPlans)"
  },
]

export const recommendTypeGroups= [
  {
    name: "Amazon Elastic Compute Cloud",
    select: ["RI(EC2),standard", "RI(EC2),convertible", "SP(ComputeSavingsPlans)", "SP(EC2InstanceSavingsPlans)"]
  },
  {
    name: "Amazon Relational Database Service",
    select: ["RI(RDS)"]
  },
  {
    name: "Amazon Redshift",
    select: ["RI(Redshift)"]
  },
  {
    name: "Amazon OpenSearch Service",
    select: ["RI(OpenSearch)"]
  },
  {
    name: "Amazon ElastiCache",
    select: ["RI(ElastiCache)"]
  },
  {
    name: "ComputeSavingsPlans",
    select: ["SP(ComputeSavingsPlans)", "SP(EC2InstanceSavingsPlans)", "RI(EC2),standard", "RI(EC2),convertible"]
  },
  {
    name: "EC2InstanceSavingsPlans",
    select: ["SP(EC2InstanceSavingsPlans)", "SP(ComputeSavingsPlans)", "RI(EC2),standard", "RI(EC2),convertible"]
  },
  {
    name: "SageMakerSavingsPlans",
    select: ["SP(SageMakerSavingsPlans)"]
  },
]

export const paymentTerms= [
  {
    name: "1年",
    value: "1"
  },
  {
    name: "3年",
    value: "3"
  },
]

export const paymentOptions= [
  {
    name: "All Upfront",
  },
  {
    name: "Partial Upfront",
  },
  {
    name: "No Upfront",
  },
]

export const analysisTerms= [
  {
    value: 7,
  },
  {
    value: 14,
  },
  {
    value: 30,
  },
]

export const TableKeyScenarioSummaryRi = [
  { title: "リスク",                        key: "dimension",            sort: false },
  { title: "オンデマンド費用同等額（$/月）",    key: "table_calculation_1",  sort: false },
  { title: "Reserved 費用（$/月）",          key: "reserved__",           sort: false },
  { title: "推定節約額（$/月）",              key: "table_calculation",    sort: false },
]

export const TableKeyScenarioSummarySp = [
  { title: "リスク",                        key: "dimension",            sort: false },
  { title: "オンデマンド費用同等額（$/月）",    key: "table_calculation_1",  sort: false },
  { title: "Savings Plans費用（$/月）",      key: "reserved__",           sort: false },
  { title: "推定節約額（$/月）",              key: "table_calculation",    sort: false },
]
<template>
  <SimulatorMethods ref="methods"/>
  <div v-if="!loading.initialized">
    <div class="loader">Loading...</div>
  </div>
  <div v-else>
    <!-- Modal -->
    <div>
      <div class="modal fade" :id="'riBasisDataModal' + riskLevel" tabindex="-1" role="dialog" aria-labelledby="riBasisDataModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content" style="width:1200px; margin-left: -300px;">
            <div class="modal-header">
              <h5 class="modal-title">根拠データ</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div v-if="loading.iframe">
                <div class="loader">Loading...</div>
              </div>
              <div v-else>
                <p>
                  時間別に適用可能な利用量を並べて、リスクごとの基準に合致したところをプロットしてあります。<br>
                  推薦結果はその箇所の利用量をもとに算出しています。<br>
                  ※利用量の単位は、推薦したRIのサイズの柔軟性により異なります。<br>
                  Yes：正規化係数（詳細は<a href="https://docs.aws.amazon.com/ja_jp/AWSEC2/latest/UserGuide/apply_ri.html" target="_blank">公式ドキュメント</a>を参照してください）<br>
                  No：時間<br>
                </p>
                <iframe id="targetIframe" :src="embedUrl" height="600" width="100%"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="pull-right">
          <DropdownDownload reportType="reserved_result" :tableItem="dashboardItem" :keys="riResultListKeys()" :titles="riResultListTitles()"/>
        </div>
      </div>
    </div>
    <div class="right-sizing-table" style="overflow: scroll">
      <table id="scenario-result" class="table table-striped table-hover text-nowrap">
        <thead>
        <tr>
          <th>根拠データ</th>
          <template v-for="header in riResultTh">
            <TableTh :tableKey="header.key" v-on:emitTrigger="doSort">{{ header.title }}</TableTh>
          </template>
        </tr>
        </thead>
        <tbody>
        <template v-if="Object.keys(dashboardItem).length">
          <template v-for="(item, index ) in dashboardItem">
            <tr>
              <td>
                <a class="text-info cursor-pointer" data-toggle="modal" v-on:click="getBasisData(index)" :data-target="'#riBasisDataModal' + riskLevel" type="button">
                  <i class="material-icons">bar_chart</i>
                </a>
              </td>
              <td>{{ item.aws_account_id }}</td>
              <td>{{ item.account_name }}</td>
              <td>{{ item.offering_class }}</td>
              <td>{{ item.product_name }}</td>
              <td>{{ item.location }}</td>
              <td>{{ item.platform }}</td>
              <td>{{ item.additional_info }}</td>
              <td>{{ item.lease_contract_length_duration }}</td>
              <td>{{ item.purchase_option }}</td>
              <td>{{ item.flexible_flag }}</td>
              <td>{{ item.instance_type }}</td>
              <td>{{ item.instance_count }}</td>
              <td>{{ item.upfront_fee.toFixed(2) }}</td>
              <td>{{ item.monthly_recurring_fee.toFixed(2) }}</td>
              <td>{{ item.monthly_on_demand_cost_equivalent.toFixed(2) }}</td>
              <td>{{ item.monthly_savings.toFixed(2) }}</td>
              <td>{{ (item.utilization * 100).toFixed(2) }}</td>
            </tr>
          </template>
        </template>
        <template v-else>
          <tr>
            <td colspan="18">
              設定はありません
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script setup>
import TableTh from "./table_th.vue";
import { onMounted, ref } from "vue";
import { findTableHeaderIndex, selectSort } from "../lib/table";
import SimulatorMethods from "./recommend_simulator_methods.vue";
import DropdownDownload from './dropdown_download.vue'

const props = defineProps({
  scenarioId: Number,
  riskLevel: String
})

const methods = ref(null)

const riResultTh = ref([
  { title: "連結アカウント",               key: "aws_account_id",                   sort: false },
  { title: "連結アカウントの別名",          key: "account_name",                     sort: false },
  { title: "提供クラス",                   key: "offering_class",                   sort: false },
  { title: "サービス",                    key: "product_name",                     sort: false },
  { title: "リージョン",                   key: "location",                         sort: false },
  { title: "プラットフォーム",              key: "platform",                         sort: false },
  { title: "その他情報",                   key: "additional_info",                  sort: false },
  { title: "支払い期間",                   key: "lease_contract_length_duration",   sort: false },
  { title: "支払い方法",                   key: "purchase_option",                  sort: false },
  { title: "サイズの柔軟性",                key: "flexible_flag",                    sort: false },
  { title: "インスタンスタイプ",             key: "instance_type",                    sort: false },
  { title: "推奨インスタンス数",             key: "instance_count",                   sort: false },
  { title: "前払いコスト",                  key: "upfront_fee",                      sort: false },
  { title: "定期的なコスト($/月)",           key: "monthly_recurring_fee",            sort: false },
  { title: "推定オンデマンド費用同等額($/月)", key: "monthly_on_demand_cost_equivalent", sort: false },
  { title: "推定節約額($/月)",              key: "monthly_savings",                   sort: false },
  { title: "推定使用率(%)",                 key: "utilization",                       sort: false },
])

let loading = ref({
  initialized: false,
  iframe: false,
})
let dashboardItem = ref([])
let embedUrl = ref("");

const doSort = (columnKey) => {
  const index = findTableHeaderIndex(riResultTh.value, columnKey)
  const isSort = riResultTh.value[index].sort
  const sort = selectSort(isSort, columnKey);

  dashboardItem.value.sort(sort)
  riResultTh.value[index].sort = !isSort
}

const getScenarioDashboardResult = async () => {
  const result = await methods.value?.getScenarioDashboardResult(props.scenarioId, props.riskLevel)
  if (!result) {
    return false
  }
  dashboardItem.value = result.filter_item
}

const getScenarioDashboardBasisData = async (param) => {
  const result = await methods.value?.getScenarioDashboardBasisData(param)
  if (!result) {
    return false
  }
  embedUrl.value = result.embed_url
}

const getBasisData = async (index) => {
  const target = dashboardItem.value[index]
  const param = {
    scenario_id: props.scenarioId,
    region: target.region_code,
    platform: target.platform,
    instance_info: target.instance_info,
    instance_family: "",
    additional_info: target.additional_info,
    payment_term: "",
    payment_option: ""
  }

  loading.value.iframe = true
  await getScenarioDashboardBasisData(param)
  loading.value.iframe = false
}

const riResultListKeys = () => {
  return riResultTh.value.map(item => item.key)
}

const riResultListTitles = () => {
  return riResultTh.value.map(item => item.title)
}

onMounted(async () => {
  await getScenarioDashboardResult()
  loading.value.initialized = true
})
</script>
<style>
.table>tbody>tr>th,
.table>tbody>tr>td {
  vertical-align: top;
  padding: 12px 8px;
  border-color: transparent;
}

.modal-dialog .modal-header .modal-title {
  text-align: left;
  margin-bottom: -3rem;
}
</style>

import { TsBaseClient, } from "../ts_base_client";
export class ApiClient extends TsBaseClient {
    constructor() {
        super();
    }
    async getMfaSettingList() {
        const url = `/user/mfa/setting`;
        return await this.get(url);
    }
    async sendAuthCode(mfaType) {
        const url = `user/mfa/${mfaType}/sendcode`;
        return await this.post(url, {});
    }
    async createMfaSetting(mfaType, params) {
        const url = `user/mfa/${mfaType}/setting`;
        return await this.post(url, params);
    }
    async deleteMfaSetting(mfaType) {
        const url = `user/mfa/${mfaType}/setting/delete`;
        return await this.delete(url);
    }
}

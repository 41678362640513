<template>
  <div>
    <Alert ref="alert"/>
    <div v-if="!initialized">
      <div class="loader">Loading...</div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                <div class="row">
                  <div class="col">一覧</div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <ul class="nav">
                <li class="nav-item">
                  <a
                    :class="`nav-link btn btn-primary${contractTotalCount >= limit ? ' disabled' : ''}`"
                    href="/mypage/alert/setting/ri_sp/new"
                  >
                    新規
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    :class="`nav-link btn btn-primary${disabledBulkDelete ? ' disabled' : ''}`"
                    :disabled="disabledBulkDelete"
                    @click.prevent="bulkDelete"
                    href="#"
                  >
                    削除
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    :class="`nav-link btn btn-primary${disabledBulkLock ? ' disabled' : ''}`"
                    :disabled="disabledBulkLock"
                    @click.prevent="bulkLock"
                    href="#"
                  >
                    ロック
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    :class="`nav-link btn btn-primary${disabledBulkUnlock ? ' disabled' : ''}`"
                    :disabled="disabledBulkUnlock"
                    @click.prevent="bulkUnlock"
                    href="#"
                  >
                    ロック解除
                  </a>
                </li>
              </ul>
              <div class="text-secondary mb-4">
                現在の登録件数は{{ contractTotalCount}}件です。（最大{{ limit }}件まで登録できます）<br/>
                ※ユーザの権限が利用者の場合は一覧に表示されていない場合があります。
              </div>
              <table id="reserved_alert-list" class="table table-borderless">
                <thead>
                  <tr>
                    <th></th>
                    <th>通知有無</th>
                    <th>連結アカウント</th>
                    <th>通知タイミング</th>
                    <th>通知先</th>
                    <th>ロック</th>
                    <th>アクション</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="Object.keys(riSpAlerts).length">
                    <tr v-for="(riSpAlert, i) in riSpAlerts" :key="i">
                      <td>
                        <input @change="checkBulkCheckbox" type="checkbox" :value="riSpAlert.id" v-model="checkedIds">
                      </td>
                      <td>{{ riSpAlert.notification ? '有効' : '無効' }}</td>
                      <td>{{ riSpAlert.aws_account_id || '全て' }}</td>
                      <td>{{ alignFrequency(riSpAlert.frequency) }}</td>
                      <td>
                        <span v-on:mouseover="mouseOverAction(i)" v-on:mouseleave="mouseLeaveAction(i)">
                          <template v-if="riSpAlert.send_all_user">
                            全てのユーザ
                          </template>
                          <template v-else-if="Object.keys(riSpAlert.user_mails).length !== 0">
                            <i class="material-icons" id="account_icon">account_circle</i> {{ riSpAlert.user_mails[Object.keys(riSpAlert.user_mails)[0]] }}
                          </template>
                          <template v-else>
                            {{riSpAlert.mails[0]}}
                          </template>
                          <template v-if="checkSettingsMultiple(riSpAlert)">…</template>
                        </span>
                        <template v-if="hover.flag && i === hover.index">
                          <div class="tooltip-text">
                            <template v-if="riSpAlert.send_all_user">
                              全てのユーザ<br>
                            </template>
                            <template v-else>
                              <template v-for="user_mail in riSpAlert.user_mails">
                                <i class="material-icons" id="account_icon">account_circle</i> {{ user_mail }}<br>
                              </template>
                            </template>
                            <template v-for="mail in riSpAlert.mails">
                              {{ mail }}<br>
                            </template>
                            <template v-if="riSpAlert.slack_url">
                              {{ riSpAlert.slack_url }}
                            </template>
                          </div>
                        </template>
                      </td>
                      <td>
                        <a v-if="riSpAlert.lock" @click.prevent="update('unlock', riSpAlert.id)" class="text-info" href="#">
                          <i class="material-icons">lock</i>
                        </a>
                        <a v-else @click.prevent="update('lock', riSpAlert.id)" class="text-info" href="#">
                          <i class="material-icons">lock_open</i>
                        </a>
                      </td>
                      <td>
                        <a v-if="!riSpAlert.lock" class="text-info" :href="`/mypage/alert/setting/ri_sp/${riSpAlert.id}/edit`">
                          <i class="material-icons">article</i>
                        </a>
                        <a v-if="!riSpAlert.lock" @click.prevent="update('delete', riSpAlert.id, '本当に削除しますか？')" class="text-danger" href="#">
                          <i class="material-icons">delete</i>
                        </a>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="8">
                        設定はありません
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
              <Pagination ref="pagination" :pageNumber.number="pageNumber" :totalPages.number="totalPages" v-on:emitPage="updatePage"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getRiSpAlerts, lockRiSpAlerts, unlockRiSpAlerts, deleteRiSpAlert } from "../lib/axios/api_client";
  import Alert from "../components/alert_new.vue";
  import Pagination from "../components/pagination.vue";

  export default {
    async mounted() {
      await this.updatePage(0);
      this.initialized = true;
    },
    components: {
        Alert: Alert,
        Pagination: Pagination
    },
    data: function () {
      return {
        riSpAlerts: [],
        checkedIds: [],
        disabledBulkDelete: true,
        disabledBulkLock: true,
        disabledBulkUnlock: true,
        initialized: false,
        pageNumber: 0,
        totalPages: 1,
        hover: {
          flag: false,
          index: null
        },
        limit: -1,
        contractTotalCount: -1,
      }
    },
    computed: {
    },
    methods: {
      mouseOverAction(index) {
        this.hover.flag = true
        this.hover.index = index
      },
      mouseLeaveAction() {
        this.hover.flag = false
      },
      checkSettingsMultiple(riSpAlert) {
        return (riSpAlert.send_all_user? 1 : 0) + Object.keys(riSpAlert.user_mails).length + riSpAlert.mails.length + (riSpAlert.slack_url? 1 : 0)  >= 2
      },
      updatePage: async function(page){
        const result = await this.getRiSpAlerts(page);
        let riSpAlerts = {};
        result.ri_sp_alerts.forEach((riSpAlert) => {
          riSpAlerts[riSpAlert.id] = riSpAlert;
        });
        this.riSpAlerts = riSpAlerts;
        this.pageNumber = result.page_number;
        this.totalPages = result.total_pages;

        this.limit = result.limit;
        this.contractTotalCount = result.contract_total_count;

        this.checkedIds = [];
        this.initializeBulkCheckbox();
      },
      getRiSpAlerts: async function(page) {
        const params = {params: {page: page}};
        const res = await getRiSpAlerts(params);
        if (!res.successful) {
          this.$refs.alert.displayModal(res.message, 'error');
          return false;
        }
        return res.data;
      },
      alignFrequency: function (frequency) {
        frequency.sort((x, y) => x - y)
        return frequency.join('日前, ') + '日前'
      },
      initializeBulkCheckbox: function () {
        this.disabledBulkDelete = true;
        this.disabledBulkLock = true;
        this.disabledBulkUnlock = true;
      },
      checkBulkCheckbox: function () {
        this.initializeBulkCheckbox();

        let lockTypes = this.checkedIds.map((id) => {
          return this.riSpAlerts[id].lock;
        }).filter(function(elem, index, self) {
          return self.indexOf(elem) === index;
        });

        // 未選択、もしくはLockとUnlockのデータが選ばれた場合、まとめて実行はできない
        if (lockTypes.length !== 1) return;

        // ロックされていないデータのみ選択
        if (lockTypes[0] === false) {
          this.disabledBulkDelete = false;
          this.disabledBulkLock = false;
        // ロックされているデータのみ選択
        } else {
          this.disabledBulkUnlock = false;
        }
      },
      update: async function (action, id, confirm) {
        if (confirm) {
          if (!window.confirm(confirm)) {
             this.initialized = true;
             return;
          }
        }
        const methods = {
          lock: lockRiSpAlerts,
          unlock: unlockRiSpAlerts,
          delete: deleteRiSpAlert,
        }
        const res = await methods[action](id);
        if(!res.successful){
          this.$refs.alert.displayModal(res.message, 'error')
          return false
        }
        this.$refs.alert.displayModal('成功しました。','success')
        await this.updatePage(this.pageNumber);
        if (action === 'delete') {
          this.deleteAndUpdate();
        }
        this.initialized = true;
      },
      bulkUpdate: function (action) {
        this.initialized = false;
        let ids = this.checkedIds.join(',');
        this.update(action, ids, action == 'delete' ? '本当に削除しますか？' : null);
      },
      bulkDelete: function (e) {
        this.bulkUpdate('delete');
      },
      bulkLock: function (e) {
        this.bulkUpdate('lock');
      },
      bulkUnlock: function (e) {
        this.bulkUpdate('unlock');
      },
      deleteAndUpdate: async function () {
        if (Object.keys(this.costAlerts).length === 0 && this.pageNumber !== 0) {
          await this.updatePage(this.pageNumber - 1);
        }
      }
    },
  }
</script>

<style scoped>
.tooltip-text{
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  padding: 0.1rem 0.2rem;
  z-index: 1000;
  border: 1px solid #dddddd;
  border-radius: 0.25rem;
}
#account_icon{
  font-size: 14px;
  vertical-align: middle;
}
</style>
